import { computed, makeObservable, observable } from "mobx";

import { CalculationInput } from "services/back/injectionPrediction/calculateInjection";
import {
  CompensationCoefficientsRaw,
  getCompensationCoefficients,
  setCompensationCoefficients,
} from "services/back/injectionPrediction/compensationCoefficients";

import { Forecast } from "../forecast";

class CompensationCoefficients {
  private data: CompensationCoefficientsRaw | null | undefined = undefined;

  constructor(private forecast: Forecast) {
    makeObservable<CompensationCoefficients, "data">(this, {
      data: observable.ref,
      coeffs: computed,
      isLoading: computed,
    });

    getCompensationCoefficients(this.forecast.storageKey).then((data) => (this.data = data));
  }

  public get isLoading(): boolean {
    return this.data === undefined;
  }

  public get coeffs(): CompensationCoefficientsRaw | null | undefined {
    if (!this.data) {
      return this.data;
    }
    return JSON.parse(JSON.stringify(this.data));
  }

  public async update(newCoeffs: CompensationCoefficientsRaw): Promise<void> {
    await setCompensationCoefficients(this.forecast.storageKey, newCoeffs);
    this.data = newCoeffs;
  }

  public get inverted(): CalculationInput {
    if (!this.data) {
      return {};
    }
    const result: CalculationInput = {};
    for (const [wellId, wellCoeffs] of Object.entries(this.data)) {
      for (const [prodObjId, coeff] of Object.entries(wellCoeffs)) {
        if (result[prodObjId as any] === undefined) {
          result[prodObjId as any] = {};
        }
        result[prodObjId as any][wellId as any] = coeff;
      }
    }
    return result;
  }
}

export { CompensationCoefficients };
