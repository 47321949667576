import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { computed, makeObservable, runInAction } from "mobx";

import { global } from "models/global";

import { aggregateByDate, DateDataSeries } from "../../production/aggregate";
import { Production, ProductionDatum } from "../../production/production";
import { Well } from "../well";

import { TechDummyRow } from "./helpers";
import { DRow, TechWells } from "./tech";

type AggregatedDatumMining = Partial<{
  fond: number;
  workTimeByType: Record<string, number>;
  expCoef: number;
  sumOilDebit: number;
  sumOilProd: number;
  sumLiquidProd: number;
  sumGasProd: number;
}>;

type AggregatedDatumSupport = Partial<{
  fond: number;
  expCoef: number;
  sumInj: number;
  sumInjCapacity: number;
}>;

type WellDatum = {
  well: Well;
  prod: ProductionDatum;
};

function prepareToAggregate(wells: Well[], production: Production): Array<DateDataSeries<WellDatum>> {
  // TODO: wholeWellData to wellData for fact
  function* wrappedGenerator(well: Well): DateDataSeries<WellDatum> {
    const generator =
      production.forecast === null ? production.wellData(well.id)?.byYear() ?? [] : production.wholeWellData(well.id);
    for (const [date, prod] of generator) {
      yield [date, { well, prod }];
    }
  }
  return wells.map(wrappedGenerator);
}

class SummaryFonds extends TableNode<DRow, TechDummyRow> {
  public asDRow = (): DRow => ({
    name: "Действующий фонд",
    measure: null,
  });

  constructor(private parent: Summary) {
    super(parent, { selectable: false, mutable: false, isExpandedChildren: true });

    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(this, [
        new TechDummyRow(this, undefined, "Действующий фонд добывающих скважин", [12, 1], () =>
          Array.from(this.parent.miningData, ([year, d]) => [year, d.fond] as [number, number])
        ),
        new TechDummyRow(this, undefined, "Действующий фонд нагнетательных скважин", [12, 1], () =>
          Array.from(this.parent.supportData, ([year, d]) => [year, d.fond] as [number, number])
        ),
      ]);
    });
  }

  static aggFunc(type: "mining" | "support"): (...datums: WellDatum[]) => AggregatedDatumMining {
    return (...datums) => {
      const field: keyof ProductionDatum = type === "mining" ? "prod_days" : "inj_days";
      return {
        fond: datums.reduce((count, { prod }) => count + +((prod[field] ?? 0) > 0), 0),
      };
    };
  }
}

class SummaryWorkTimeByType extends TableNode<DRow, TechDummyRow> {
  public asDRow = (): DRow => ({
    name: "Отработанное время действующих скважин",
    measure: null,
  });

  constructor(private parent: Summary) {
    super(parent, { selectable: false, mutable: false, isExpandedChildren: true });
    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        Array.from(
          [...(global.miningWellTypes ?? [])],
          (type) => new TechDummyRow(this, undefined, type, [4, 1], () => this.getDebitByType(type))
        )
      );
    });
  }

  private getDebitByType(type: string): Array<[number, number | null]> {
    return this.parent.miningData.map(([year, d]) => [year, d.workTimeByType?.[type] ?? null]);
  }

  static aggFunc(...datums: WellDatum[]): AggregatedDatumMining {
    const byType = new Map<string, number>();
    datums.forEach(({ well, prod }) => {
      const typeTime = byType.get(well.type ?? "unknown type");
      byType.set(well.type ?? "unknown type", (typeTime ?? 0) + (prod.prod_days ?? 0));
    });
    return { workTimeByType: Object.fromEntries(byType) };
  }
}

class SummaryMining extends TableNode<DRow, TechDummyRow> {
  public asDRow = (): DRow => ({
    name: "Добывающие скважины",
    measure: null,
  });

  constructor(private parent: Summary) {
    super(parent, { selectable: false, mutable: false, isExpandedChildren: true });

    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        (
          [
            [
              "Коэффициент эксплуатации добывающих скважин",
              [3, 1],
              () => this.parent.miningData.map(([year, v]) => [year, v.expCoef]),
            ],
            [
              "Суммарный среднесуточный дебит",
              [6, 1],
              () => this.parent.miningData.map(([year, v]) => [year, v.sumOilDebit]),
            ],
            ["Суммарная добыча нефти", [5, 4], () => this.parent.miningData.map(([year, v]) => [year, v.sumOilProd])],
            [
              "Суммарная добыча жидкости",
              [5, 4],
              () => this.parent.miningData.map(([year, v]) => [year, v.sumLiquidProd]),
            ],
            [
              "Суммарная добыча нефтяного газа",
              [7, 4],
              () => this.parent.miningData.map(([year, v]) => [year, v.sumGasProd]),
            ],
          ] as [string, [number, number], () => [number, number | null][]][]
        ).map(([name, measure, valuesGetter]) => new TechDummyRow(this, undefined, name, measure, valuesGetter))
      );
    });
  }

  static aggFunc(...datums: WellDatum[]): AggregatedDatumMining {
    const result: AggregatedDatumMining = {
      sumOilProd: 0,
      sumLiquidProd: 0,
      sumGasProd: 0,
      sumOilDebit: 0,
    };
    let prodDaysTotal = 0;
    datums.forEach(({ prod }) => {
      result.sumOilProd! += prod.oil_prod ?? 0;
      result.sumLiquidProd! += prod.liquid_prod_t ?? 0;
      result.sumGasProd! += prod.apg_prod ?? 0;
      result.sumOilDebit! += ((prod.prod_days && (prod.oil_prod ?? 0) / prod.prod_days) ?? 0) * 1000;
      prodDaysTotal += prod.prod_days ?? 0;
    });
    result.expCoef = datums.length && prodDaysTotal / 365 / datums.length;
    return result;
  }
}

class SummarySupport extends TableNode<DRow, TechDummyRow> {
  public asDRow = (): DRow => ({
    name: "Нагнетательные скважины",
    measure: null,
  });

  constructor(private parent: Summary) {
    super(parent, { selectable: false, mutable: false, isExpandedChildren: true });

    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        (
          [
            [
              "Коэффициент эксплуатации нагнетательных скважин",
              [3, 1],
              () => this.parent.supportData.map(([year, v]) => [year, v.expCoef]),
            ],
            ["Суммарная закачка", [5, 4], () => this.parent.supportData.map(([year, v]) => [year, v.sumInj])],
            [
              "Суммарная среднесуточная приемистость",
              [6, 1],
              () => this.parent.supportData.map(([year, v]) => [year, v.sumInjCapacity]),
            ],
          ] as [string, [number, number], () => [number, number | null][]][]
        ).map(([name, measure, valuesGetter]) => new TechDummyRow(this, undefined, name, measure, valuesGetter))
      );
    });
  }

  static aggFunc(...datums: WellDatum[]): AggregatedDatumSupport {
    const result: AggregatedDatumSupport = {
      sumInj: 0,
      sumInjCapacity: 0,
    };
    let injDaysTotal = 0;
    datums.forEach(({ prod }) => {
      result.sumInj! += prod.water_inj ?? 0;
      result.sumInjCapacity! += ((prod.inj_days && (prod.water_inj ?? 0) / prod.inj_days) ?? 0) * 1000;
      injDaysTotal += prod.inj_days ?? 0;
    });
    result.expCoef = datums.length && injDaysTotal / 365 / datums.length;
    return result;
  }
}

class Summary extends TableNode<DRow, SummaryFonds | SummaryWorkTimeByType | SummaryMining | SummarySupport> {
  public asDRow = (): DRow => ({
    name: "Обобщающие данные по всему фонду",
    measure: null,
  });

  constructor(private parent: TechWells) {
    super(parent, { selectable: false, mutable: false, isExpandedChildren: true });

    makeObservable(this, {
      miningData: computed,
      supportData: computed,
    });

    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(this, [
        new SummaryFonds(this),
        new SummaryWorkTimeByType(this),
        new SummaryMining(this),
        new SummarySupport(this),
      ]);
    });
  }

  public get miningData(): Array<[year: number, datum: AggregatedDatumMining]> {
    const prepared = prepareToAggregate(this.parent.miningWells, this.parent.source.production);
    const aggregateFunc = Summary.composeAggregators(Summary.aggregateMining, { fond: 0 });
    return Array.from(aggregateByDate<WellDatum, AggregatedDatumMining>(aggregateFunc, prepared), ([date, datum]) => [
      date.year(),
      datum,
    ]);
  }

  public get supportData(): Array<[year: number, datum: AggregatedDatumSupport]> {
    const prepared = prepareToAggregate(this.parent.supportWells, this.parent.source.production);
    const aggregateFunc = Summary.composeAggregators(Summary.aggregateSupport, { fond: 0 });
    return Array.from(aggregateByDate<WellDatum, AggregatedDatumSupport>(aggregateFunc, prepared), ([date, datum]) => [
      date.year(),
      datum,
    ]);
  }

  static composeAggregators<To>(
    aggFunctions: Array<(...datums: WellDatum[]) => To>,
    defaultDatum: To
  ): (datums: WellDatum[]) => To {
    return (datums: WellDatum[]) => {
      return aggFunctions.reduce((result, f) => ({ ...result, ...f(...datums) }), defaultDatum);
    };
  }

  static aggregateMining: Array<(...datums: WellDatum[]) => AggregatedDatumMining> = [
    SummaryFonds.aggFunc("mining"),
    SummaryWorkTimeByType.aggFunc,
    SummaryMining.aggFunc,
  ];

  static aggregateSupport: Array<(...datums: WellDatum[]) => AggregatedDatumSupport> = [
    SummaryFonds.aggFunc("support"),
    SummarySupport.aggFunc,
  ];
}

export { Summary };
