import { useCallback } from "react";
import dayjs from "dayjs";

import { global } from "models/global";
import { Fact } from "models/project/fact/fact";
import { Forecast } from "models/project/fact/forecast/forecast";

type FallbackType = "well" | "wellId" | "gtmType" | "type" | "waterCut" | "date" | "producingObject";

interface FallbackResult {
  value: any;
  fallbackText?: string;
  tooltip?: string;
}

function checkWellExistence(wellId: number, forecast: Forecast | null): FallbackResult | null {
  if (!forecast) {
    return null;
  }
  const existWell = forecast.wells.at(wellId);
  if (existWell) {
    return {
      value: existWell.title,
      fallbackText: existWell.title,
      tooltip: "Cкважина уже существует в проекте",
    };
  }
  return null;
}

function checkWellExistenceById(wellId: number, forecast: Forecast | null): FallbackResult | null {
  if (forecast && !forecast.wells.at(wellId)) {
    return {
      value: null,
      fallbackText: wellId.toString() || " ",
      tooltip: "Скважина отсутствует в проекте",
    };
  }
  return null;
}

function checkGtmType(value: number): FallbackResult | null {
  if (!global.interventionsTypes.at(value)) {
    return {
      value: null,
      fallbackText: "ГТМ, которого нет",
      tooltip: "Тип ГТМ не соответствует утвержденному списку",
    };
  }
  return null;
}

function checkWellType(value: number | string): FallbackResult | null {
  if ((typeof value === "number" && !global.wellTypes.at(value)) || typeof value === "string") {
    return {
      value: null,
      fallbackText: "Новый вид",
      tooltip: "Вид скважины не соответствует утвержденному списку",
    };
  }
  return null;
}

function checkWaterCut(value: number): FallbackResult | null {
  if (value > 100) {
    return {
      value: null,
      fallbackText: value.toString(),
      tooltip: "Обводненность превышает допустимый предел",
    };
  }
  return null;
}

function checkDate(value: string, forecast: Forecast | null): FallbackResult | null {
  const year = dayjs(value).year();
  if (!forecast) {
    return null;
  }
  if (year < forecast.range.from || year > forecast.range.to) {
    return {
      value,
      tooltip: "Дата проведения не входит в интервал планирования",
    };
  }
  return null;
}

function checkProducingObject(value: number | string, fact: Fact): FallbackResult | null {
  if ((typeof value === "number" && !fact.producingObjects.at(value)) || typeof value === "string") {
    return {
      value: null,
      fallbackText: "Новый объект разработки",
      tooltip: "Объект разработки не соответствует списку доступных объектов для проекта",
    };
  }
  return null;
}

function getFallback(forecast: Forecast | null, fact: Fact, value: any, type?: FallbackType): FallbackResult {
  const result: FallbackResult = { value };

  if (!value) {
    return { value: null, fallbackText: "", tooltip: "Отсутствует необходимая информация" };
  }

  const check: Record<FallbackType, () => FallbackResult> = {
    well: () => checkWellExistence(value, forecast) ?? result,
    wellId: () => checkWellExistenceById(value, forecast) ?? result,
    gtmType: () => checkGtmType(value) ?? result,
    type: () => checkWellType(value) ?? result,
    waterCut: () => checkWaterCut(value) ?? result,
    date: () => checkDate(value, forecast) ?? result,
    producingObject: () => checkProducingObject(value, fact) ?? result,
  };
  return type ? check[type]() : result;
}

const useFallBack = (forecast: Forecast | null, fact: Fact) => {
  return useCallback(
    (value?: any, type?: FallbackType): FallbackResult => {
      return getFallback(forecast, fact, value, type);
    },
    [forecast, fact]
  );
};

export { useFallBack };
