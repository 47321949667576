import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { makeObservable, runInAction } from "mobx";

import { StaticSettingsData, StaticSettingsFields } from "./settings";

const titlesMap: Record<StaticSettingsFields, string> = {
  nns: "ННС",
  gs: "ГС",
  mss: "МСС/МЗС",
  deepening: "Углубление забоя",
  radial: "Радиальное бурение",
  transfer: "Перевод на другой горизонт",
  cutting: "Зарезка боковых (вторых) стволов",
};

const workCostMeasure: Record<StaticSettingsFields, string> = {
  nns: "тыс руб / м",
  gs: "тыс руб / м",
  mss: "тыс руб / м",
  deepening: "тыс руб / оп",
  radial: "тыс руб / оп",
  transfer: "тыс руб / оп",
  cutting: "тыс руб / оп",
};

type StaticDRow = {
  key: StaticSettingsFields | null;
  title: string;
  workCost: number;
  workCostMeasure: string;
  teamsWork: number;
  teamsWorkMeasure: string;
};

class RowNode extends TableNode<StaticDRow> {
  public asDRow = (): StaticDRow => ({
    key: this.key,
    title: titlesMap[this.key],
    workCost: this.staticSettings[this.key]["workCost"],
    workCostMeasure: workCostMeasure[this.key],
    teamsWork: this.staticSettings[this.key]["teamsWork"],
    teamsWorkMeasure: "оп/год",
  });

  constructor(
    private parent: SettingNode,
    public readonly staticSettings: StaticSettingsData,
    public readonly key: StaticSettingsFields
  ) {
    super(parent, { selectable: false, mutable: false });
    runInAction(() => (this.childrenStore = null));
  }
}

class SettingNode extends TableNode<StaticDRow, any> {
  public asDRow(): StaticDRow {
    return {
      key: null,
      title: this.title,
      workCost: -1,
      workCostMeasure: "",
      teamsWork: -1,
      teamsWorkMeasure: "",
    };
  }
  constructor(
    private parent: StaticSettings,
    public readonly staticSettings: StaticSettingsData,
    public readonly title: string,
    public readonly settings: StaticSettingsFields[]
  ) {
    super(parent, { isExpandedChildren: true, selectable: false, mutable: false });
    runInAction(
      () =>
        (this.childrenStore = new ChildrenStoreArray(
          this,
          settings.map((key) => new RowNode(this, staticSettings, key))
        ))
    );
  }

  get absoluteIndex(): number {
    return super.absoluteIndex - (this.index ?? 0) - 1;
  }
}

const TABLE = [
  {
    title: "ЭБ",
    settings: ["nns", "gs", "mss"] as StaticSettingsFields[],
  },
  {
    title: "КРС",
    settings: ["deepening", "radial", "transfer"] as StaticSettingsFields[],
  },
  {
    title: "ЗБС",
    settings: ["cutting"] as StaticSettingsFields[],
  },
];

class StaticSettings extends TableNode<StaticDRow, SettingNode> {
  constructor(staticSettings: StaticSettingsData) {
    super(null, { selectable: false, mutable: false });
    this.childrenStore = new ChildrenStoreArray(
      this,
      TABLE.map(({ title, settings }) => new SettingNode(this, staticSettings, title, settings))
    );
    makeObservable(this, {});
  }
}

export { StaticSettings };
export type { StaticDRow };
