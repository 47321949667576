import { FC, PropsWithChildren, useEffect, useState } from "react";
import { WarningOutlined } from "@ant-design/icons";
import { TableContextProvider, Widget } from "@okopok/components/Table";
import { Button, Col, Row, Tooltip, Typography } from "antd";
import { TextProps } from "antd/es/typography/Text";
import classNames from "classnames";
import { observer } from "mobx-react";

import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { useForecast } from "models/project/fact/forecast/forecast";
import { optimizeReq } from "services/back/optimize";

import { useOptimizerModalContext } from "./optimizerModalProvider";

import cn from "./useOptimizedModal.module.less";

type OptimizerModalProps = {
  accept: () => void;
  reject: () => void;
};

// Если измерять размер виджета для таблицы в момент отображения модалки то рамер определяется не правильно. Даём модалке отрисоваться до запуска таблицы
const DelayedShow: FC<PropsWithChildren<{ delay: number }>> = ({ delay, children }) => {
  const [isShown, setShown] = useState(false);

  useEffect(() => {
    setTimeout(() => setShown(true), delay);
  }, [delay]);

  if (isShown === false) {
    return null;
  }

  return <>{children}</>;
};

const { Text: AntText } = Typography;

const Text: FC<PropsWithChildren & TextProps> = ({ children, className, ...props }) => (
  <AntText className={classNames(cn.text, className)} {...props}>
    {children}
  </AntText>
);

const OptimizerModal: FC<OptimizerModalProps> = observer(({ accept, reject }) => {
  const model = useOptimizerModalContext();
  const forecast = useForecast();
  const {
    table,
    setAccumulatedNpv,
    selectedDelete,
    setCurrentNpv,
    setCurrentCf,
    changeFilterRemove,
    changeFilterStop,
    currentNpv,
    currentCf,
    accumulatedNpv,
    selectedStop,
  } = model;

  const apply = async () => {
    await optimizeReq(model.summary);
    forecast?.wells.refresh();
    accept();
  };

  return (
    <TableContextProvider value={table}>
      <div className={cn.container}>
        <div className={cn.header}>
          <Row className={cn["header-row"]} align="middle">
            <Col span={5}>
              <Text>Удаление скважин(НФ + ГТМ)</Text>
            </Col>
            <Col span={3}>
              <Text>Накопленный NPV</Text>
            </Col>
            <Col span={2}>
              <InputNumber placeholder="млн долл" bordered value={accumulatedNpv} onUpdate={(v) => setAccumulatedNpv(v)} />
            </Col>
            <Col span={14} className={cn["filter"]}>
              <Text>Выбрано: {selectedDelete} скв</Text>
              <Tooltip title="Отобразить только скважины на исключение">
                <Button
                  className={cn["filter-button"]}
                  icon={<WarningOutlined />}
                  danger
                  disabled={selectedDelete === 0}
                  onClick={changeFilterRemove}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row className={cn["header-row"]} align="middle">
            <Col span={5}>
              <Text>Остановка(БФ, НФ + ГТМ)</Text>
            </Col>
            <Col span={3}>
              <Text>Текущий NPV</Text>
            </Col>
            <Col span={2}>
              <InputNumber placeholder="млн долл" bordered value={currentNpv} onUpdate={(v) => setCurrentNpv(v)} />
            </Col>
            <Col span={3}>
              <Text className={cn.cf}>Текущий CF</Text>
            </Col>
            <Col span={2}>
              <InputNumber placeholder="млн руб" bordered value={currentCf} onUpdate={(v) => setCurrentCf(v)} />
            </Col>
            <Col span={9} className={cn["filter"]}>
              <Text>Выбрано: {selectedStop} скв</Text>
              <Tooltip title="Отобразить только скважины, которые будут остановлены">
                <Button className={cn["filter-button"]} icon={<WarningOutlined />} danger disabled={selectedStop === 0} onClick={changeFilterStop} />
              </Tooltip>
            </Col>
          </Row>
        </div>
        <div className={cn.table}>
          <DelayedShow delay={200}>
            <Widget headerClassName={cn.tableHeader} />
          </DelayedShow>
        </div>
        <div className={cn.footer}>
          <Button onClick={apply} type="primary">
            Применить настройки оптимизации
          </Button>
          <Button onClick={reject} className={cn["cancel-button"]}>
            Отмена
          </Button>
        </div>
      </div>
    </TableContextProvider>
  );
});

export { OptimizerModal };
