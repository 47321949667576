import { FC, ReactNode } from "react";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";
import { Loader } from "elements/loader";
import { StationType } from "services/back/infrastructure/catalog";

import { ReactComponent as DrainIcon } from "../../../icons/drain.svg";
import { ReactComponent as NodeIcon } from "../../../icons/node.svg";
import { ReactComponent as SourceIcon } from "../../../icons/source.svg";
import { NodeType, useInfrastructureMapContext } from "../../../InfrastructureMapManager/InfrastructureMapManager";
import { Card } from "../../ui/card";
import { Parameter } from "../../ui/parameter";

import { ReactComponent as PerformanceIcon } from "./performance.svg";
import { ReactComponent as PressureDifferenceIcon } from "./pressureDifference.svg";

import icn from "../../inspectorMenu.module.less";
import cn from "./nodeCatalog.module.less";

const getStations = (type: string, stations: StationType[]) => {
  return stations.map((el) => el)?.filter(({ stationType }) => stationType === type) || [];
};

const CardTitle: FC<{ icon: ReactNode; text: string }> = ({ icon: IconComponent, text }) => (
  <>
    <Icon width="16px" height="16px" viewBox="0 0 16 16" content={IconComponent} />
    <span>{text}</span>
  </>
);

type NodeCardProps = {
  title: ReactNode;
  performance?: string;
  pressureDifference?: string;
  children?: ReactNode;
  onClick?: () => void;
};

const NodeCard: FC<NodeCardProps> = ({ title, children, onClick }) => (
  <Card title={title} onClick={onClick}>
    {children}
  </Card>
);

type PumpingCardProps = { title: string; list: StationType[]; onClick: (type: NodeType["type"], catalogItem: Partial<NodeType>) => void };

const PumpingCard: FC<PumpingCardProps> = ({ title, list, onClick }) => (
  <div>
    <div className={icn.categoryTitle}>{title}</div>
    <div className={icn.categoryList}>
      {list?.map((el) => (
        <NodeCard onClick={() => onClick("pumping", el as Partial<NodeType>)} key={el.id} title={el.title}>
          <Parameter
            icon={<Icon content={<PerformanceIcon />} width="16px" height="16px" viewBox="0 0 16 16" />}
          >{`${el.power} тыс м3/сут`}</Parameter>
          {el.deltaPressure && (
            <Parameter icon={<Icon content={<PressureDifferenceIcon />} width="16px" height="16px" viewBox="0 0 16 16" />}>
              {el.deltaPressure} атм
            </Parameter>
          )}
        </NodeCard>
      ))}
    </div>
  </div>
);

type NodeCatalogProps = {
  selectedItem: NodeType;
  onClose?: () => void;
};

const NodeCatalog: FC<NodeCatalogProps> = observer(({ selectedItem, onClose }) => {
  const { updateNode, infrastructure } = useInfrastructureMapContext();

  const handleClick = (id: string, type: NodeType["type"], catalogItem: Partial<NodeType> | null) => {
    if (type === "drain" || type === "source" || type === "node") {
      updateNode(id, { ...catalogItem, type, title: type === "node" ? "Узел" : type === "drain" ? "Сток" : "Источник" });
    } else {
      updateNode(id, { ...catalogItem, type });
    }
    onClose?.();
  };

  if (infrastructure.catalog.isLoading) {
    return <Loader />;
  }

  const pumpingStations = getStations("НС", infrastructure.catalog.stations);
  const oilTreatmentUnits = getStations("УПН", infrastructure.catalog.stations);
  const preWaterDischargeUnits = getStations("УПСВ", infrastructure.catalog.stations);

  return (
    <div className={cn.nodeCatalogContainer}>
      <div>
        <Card
          className={cn.cardNotHeight}
          onClick={() => handleClick(selectedItem.uuid, "node", null)}
          isFlex
          title={<CardTitle icon={<NodeIcon />} text="Узел" />}
        >
          <span>для соединения труб</span>
        </Card>
        <div className={icn.categoryList}>
          <NodeCard onClick={() => handleClick(selectedItem.uuid, "source", null)} title={<CardTitle icon={<SourceIcon />} text="Источник" />}>
            Источник воды для нагнетальной скважины
          </NodeCard>
          <NodeCard onClick={() => handleClick(selectedItem.uuid, "drain", null)} title={<CardTitle icon={<DrainIcon />} text="Сток" />}>
            Сток – узел приема продукции
          </NodeCard>
        </div>
      </div>
      <PumpingCard title="Насосные станции" list={pumpingStations} onClick={(type, item) => handleClick(selectedItem.uuid, type!, item)} />
      <PumpingCard
        title="Установки подготовки нефти"
        list={oilTreatmentUnits}
        onClick={(type, item) => handleClick(selectedItem.uuid, type!, item)}
      />
      <PumpingCard
        title="Установки предварительного сброса воды"
        list={preWaterDischargeUnits}
        onClick={(type, item) => handleClick(selectedItem.uuid, type!, item)}
      />
    </div>
  );
});

export { NodeCatalog };
