import { FC, ReactNode, useMemo, useState } from "react";
import { Divider, Switch, Tabs, TabsProps } from "antd";
import classNames from "classnames";

import { Format } from "elements/format/format";

import { CalculationEmpty } from "../calculationEmpty";
import { FieldParams } from "../fieldParams";
import { Label } from "../label";

import cn from "./paramsTabs.module.less";

type Construction = {
  dsw: number;
  ciw: number;
  equipment: number;
  other: number;
  totalCostPerUnit: number;
};

type Reconstruction = { period?: number; isActive?: boolean } & Construction;

type ParamsProps = { title: string; period?: number; isActive?: boolean; onClick?: (e: boolean) => void } & Construction;

const Params: FC<ParamsProps> = ({ title, onClick, ...props }) => (
  <>
    <div className={cn.paramsTitleBlock}>
      <Label>{title}</Label>
      {props.isActive !== undefined && <Switch onClick={onClick} value={props.isActive} />}
    </div>
    <FieldParams title="Суммарно">
      <Format>{props.totalCostPerUnit}</Format> тыс руб
    </FieldParams>
    <Divider className={cn.divider} />
    <FieldParams title="ПИР">
      <Format>{props.dsw}</Format> тыс руб
    </FieldParams>
    <FieldParams title="Оборудование">
      <Format>{props.equipment}</Format> тыс руб
    </FieldParams>
    <FieldParams title="СМР">
      <Format>{props.ciw}</Format> тыс руб
    </FieldParams>
    <FieldParams title="Прочее">
      <Format>{props.other}</Format> тыс руб
    </FieldParams>
    {props.period && <FieldParams title="Период реконструкции">{props.period} лет</FieldParams>}
  </>
);

type EconomicParamsProps = {
  construction?: Construction;
  reconstruction?: Reconstruction;
  calculate?: () => void;
};

const EconomicParams: FC<EconomicParamsProps> = ({ construction, reconstruction, calculate }) => {
  const [isReconstruction, setIsReconstuction] = useState(false);

  const [constructionTotalCost, reconstructionTotalCost] = [construction?.totalCostPerUnit ?? 0, reconstruction?.totalCostPerUnit ?? 0];
  const totalCost = useMemo(
    () => (isReconstruction ? constructionTotalCost + reconstructionTotalCost : constructionTotalCost),
    [isReconstruction, constructionTotalCost, reconstructionTotalCost]
  );

  return (
    <div className={cn.wrapper}>
      {construction && Object.keys(construction).length ? (
        <>
          <div className={cn.economicTotalSum}>
            <div>
              <Format>{totalCost}</Format> тыс руб
            </div>
            <div>Суммарные затраты</div>
          </div>
          <div className={cn.paramsWrapper}>
            <Params title="Строительство" {...construction} />
            {reconstruction && Object.keys(reconstruction).length && (
              <>
                <Divider className={cn.divider} />
                <Params title="Реконструкция" {...reconstruction} isActive={isReconstruction} onClick={setIsReconstuction} />
              </>
            )}
          </div>
        </>
      ) : (
        <CalculationEmpty type="economic" calculate={calculate} />
      )}
    </div>
  );
};

type Props = { children: ReactNode; className?: string; economicParams?: EconomicParamsProps };

const ParamsTabs: FC<Props> = ({ children, className, economicParams }) => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Технология",
      children: children,
    },
    {
      key: "2",
      label: "Экономика",
      children: <EconomicParams {...economicParams} />,
    },
  ];
  return <Tabs className={classNames(cn.tabs, className)} items={items} />;
};

export { ParamsTabs };
