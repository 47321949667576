import { FC } from "react";

import { ReactComponent as FilledLiquidIcon } from "../icons/filledLiquid.svg";

import { DropRate } from "./dropRate";
import { TechnologySettingsProps } from "./settings";

const OilDropRate: FC<TechnologySettingsProps> = ({ techParameters, setTechParameters, chosenType, wellsNameMap }) => {
  return (
    <DropRate
      propId="oilDropRate"
      techParameters={techParameters}
      setTechParameters={setTechParameters}
      title="Настройки темпа падения нефти"
      icon={<FilledLiquidIcon />}
      chosenType={chosenType}
      wellsNameMap={wellsNameMap}
    />
  );
};

export { OilDropRate };
