import { action, computed, makeObservable, observable } from "mobx";

import { RCPInput, RCPOutput } from "services/back/rcp/calculate";
import { updateStartYears } from "services/back/rcp/updateStartYears";

import { Forecast } from "../../forecast";

import { aggregate, YearsData } from "./utils/aggregate";
import { extractRCPInfo, GTMInfo } from "./utils/extractInfo";
import { Limits, Ranks } from "./types";

class RankingResults {
  public readonly aggregatedData: YearsData;
  public readonly gtms: GTMInfo[];
  public readonly gtmsNotIncluded: GTMInfo[];

  public isSaving = false;
  public isSaved = false;

  constructor(
    private readonly forecast: Forecast,
    public readonly limits: Limits,
    ranks: Ranks,
    rcpInput: RCPInput,
    rcpOutput: RCPOutput
  ) {
    this.aggregatedData = aggregate(rcpOutput);
    const rcpInfo = extractRCPInfo(ranks, rcpInput, rcpOutput);
    this.gtms = rcpInfo.gtms;
    this.gtmsNotIncluded = rcpInfo.gtmsNotIncluded;

    makeObservable(this, {
      isSaving: observable,
      isSaved: observable,
      isUpdated: computed,
      save: action,
    });
  }

  public get isUpdated(): boolean {
    return !this.isSaved;
  }

  public save = async () => {
    this.isSaving = true;
    const wells: { id: number; date: string }[] = [];
    const gtms: { id: number; wellId: number; date: string }[] = [];
    for (const gtm of this.gtms) {
      if (!gtm.period) {
        console.error("got included gtm without period");
        continue;
      }
      if (gtm.gtmId === null) {
        wells.push({
          id: gtm.wellId,
          date: gtm.period?.start,
        });
      } else {
        gtms.push({
          id: gtm.gtmId,
          wellId: gtm.wellId,
          date: gtm.period.start,
        });
      }
    }

    await updateStartYears(wells, gtms);

    await Promise.all([
      this.forecast.production.update(wells.map(({ id }) => ({ wellId: id }))),
      this.forecast.production.update(gtms.map(({ id, wellId }) => ({ wellId, gtmId: id }))),
    ]);
    this.isSaving = false;
    this.isSaved = true;
  };
}

export { RankingResults };
