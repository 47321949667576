import { action, computed, makeObservable, observable } from "mobx";

class LegendContentState {
  public oil: boolean = true;
  public liquid: boolean = true;
  public watering: boolean = true;
  constructor() {
    makeObservable(this, {
      oil: observable,
      liquid: observable,
      watering: observable,

      setOil: action,
      setLiquid: action,
      setWatering: action,

      getOil: computed,
      getLiquid: computed,
      getWatering: computed,
    });
  }

  public setOil = (state: boolean) => {
    this.oil = state;
  };
  public setLiquid = (state: boolean) => {
    this.liquid = state;
  };
  public setWatering = (state: boolean) => {
    this.watering = state;
  };

  get getOil() {
    return this.oil;
  }
  get getLiquid() {
    return this.liquid;
  }
  get getWatering() {
    return this.watering;
  }
}

export { LegendContentState };
