import { UOMResolver } from "elements/uom";
import { req } from "utils/request";

// units of measure

type UOMResponse = {
  uom: Array<{
    measures: [number, string][];
    name: string;
    id: number;
  }>;
};

const getUOMResolver = async (): Promise<UOMResolver[]> => {
  const response = await req.get<UOMResponse>("demo/schemas/uom");
  return response.uom.map(({ measures, ...spread }) => ({
    ...spread,
    measures: measures.map(([_, v]) => v),
  }));
};

export { getUOMResolver, type UOMResponse };

/* eslint-disable-next-line @typescript-eslint/no-unused-expressions */
[
  {
    measures: [[0, ""]],
    name: "",
    id: 0,
  },
  {
    measures: [
      [0, ""],
      [1, "м"],
      [2, "тыс. м"],
      [3, "мм"],
      [4, "фут"],
      [5, "дюйм"],
    ],
    name: "Длина",
    id: 1,
  },
  {
    measures: [
      [0, ""],
      [1, "₽"],
      [2, "тыс. ₽"],
      [3, "млн ₽"],
      [4, "$"],
      [5, "тыс. $"],
      [6, "млн. $"],
    ],
    name: "Валюта",
    id: 2,
  },
  {
    measures: [
      [0, ""],
      [1, "д.ед"],
      [2, "%"],
    ],
    name: "Доля",
    id: 3,
  },
  {
    measures: [
      [0, ""],
      [1, "сут"],
      [2, "год"],
    ],
    name: "Время",
    id: 4,
  },
  {
    measures: [
      [0, ""],
      [1, "г"],
      [2, "кг"],
      [3, "т"],
      [4, "тыс. т"],
      [5, "млн т"],
    ],
    name: "Масса",
    id: 5,
  },
  {
    measures: [
      [0, ""],
      [1, "т/сут"],
    ],
    name: "Расход массы",
    id: 6,
  },
  {
    measures: [
      [0, ""],
      [1, "м^3"],
      [2, "н.м^3"],
      [3, "тыс. н.м^3"],
      [4, "млн н.м^3"],
    ],
    name: "Объем",
    id: 7,
  },
  {
    measures: [
      [0, ""],
      [1, "м^3/сут"],
      [2, "н.м^3/сут"],
      [3, "тыс. н.м^3/сут"],
      [4, "млн н.м^3/сут"],
    ],
    name: "Расход объема",
    id: 8,
  },
  {
    measures: [
      [0, ""],
      [1, "г/н.м^3"],
    ],
    name: "Плотность",
    id: 9,
  },
  {
    measures: [
      [0, ""],
      [1, "м^3/т"],
    ],
    name: "Удельный объем",
    id: 10,
  },
  {
    measures: [
      [0, ""],
      [1, "₽/т"],
      [2, "тыс. ₽/т"],
      [3, "₽/скв"],
      [4, "тыс. ₽/скв"],
      [5, "₽/скв-оп"],
      [6, "тыс. ₽/скв-оп"],
      [7, "₽/м"],
      [8, "тыс. ₽/м"],
      [9, "$/брр"],
      [10, "₽/$"],
      [11, "% (₽/т с 2012 года)"],
      [12, "₽/тыс. м^3"],
      [13, "$/т"],
    ],
    name: "Цены",
    id: 11,
  },
  {
    measures: [
      [0, ""],
      [1, "скв"],
      [2, "скв-оп"],
    ],
    name: "Операции",
    id: 12,
  },
  {
    measures: [
      [0, ""],
      [1, "да/нет"],
    ],
    name: "Опция",
    id: 13,
  },
];
