import React, { FC, useState } from "react";
import { Button, Typography } from "antd";

import { Icon } from "elements/icon/icon";
import { ValidationInput } from "elements/inputs/validationInput/validationInput";

import { ReactComponent as RefreshIcon } from "./refreshIcon.svg";

import cn from "./chartInput.module.less";
type ChartInputProps = {
  onChange: ((value: number | undefined) => void) | undefined;
  value: number | undefined;
  label: string;
};
const ChartInput: FC<ChartInputProps> = ({ value, onChange, label }) => {
  const [localValue, setLocalValue] = useState(value);
  const onSave = (value: number | null) => setLocalValue(value ?? undefined);
  return (
    <div className={cn.coeff}>
      <Typography.Text>{label}</Typography.Text>
      <ValidationInput min={0} max={999999} value={localValue} disabled={onChange === undefined} onSave={onSave} className={cn.input} />
      <Button
        disabled={onChange === undefined}
        onClick={() => onChange?.(localValue)}
        icon={<Icon viewBox="0 0 14 14" width="16" height="16" content={<RefreshIcon />} />}
        className={cn.resetButton}
      />
    </div>
  );
};

export { ChartInput };
