import { useMemo } from "react";
import { TableContextProvider, Widget } from "@okopok/components/Table";
import { Select } from "antd";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Tree } from "elements/tree/tree";
import { useECYStore } from "features/useMetrics/useECYStore";
import { useForecasts } from "models/project/fact/forecast/forecasts";
import { TreeContextProvider } from "models/tree/context";
import { TreeRoot } from "models/tree/tree";

import { generateColumns } from "./models/columns";
import { CompareTableModel } from "./models/tableModel";
import { CompareTableStore } from "./models/tableStore";

import cn from "./compareScenario.module.less";

const CompareScenario = observer(() => {
  const forecastsStore = useForecasts()!;
  const forecasts = useMemo(() => [...forecastsStore.values!], [forecastsStore]);
  const uscStore = useECYStore()!;
  const tree = useMemo(() => new TreeRoot("Сценарии", forecasts, [], [], true, undefined, false), [forecasts]);
  const store = useMemo(() => new CompareTableStore(tree, forecasts, uscStore), [forecasts, tree, uscStore]);

  const columns = generateColumns(forecasts, store.favoriteScenario, store.onFavoriteSelect);

  const model = useMemo(() => new CompareTableModel(tree, store, columns), [tree, store, columns]);

  return (
    <>
      <PageFrameTitlePortal>
        <Select
          value={(model.store as CompareTableStore).currentUSCId}
          options={uscStore.selector}
          onSelect={(model.store as CompareTableStore).setCurrentUSCId}
        />
      </PageFrameTitlePortal>
      <TreeContextProvider value={tree}>
        <div className={cn.root}>
          <div className={cn.leftPane}>
            <Tree className={cn.tree} title="Сценарии" preloadHooks={[]} />
          </div>
          <TableContextProvider value={model}>
            <Widget headerClassName={cn.tableHeader} className={cn.table} />
          </TableContextProvider>
        </div>
      </TreeContextProvider>
    </>
  );
});

export { CompareScenario };
