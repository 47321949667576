import { ChangeEventHandler, useCallback, useRef, useState } from "react";
import { Input, Typography } from "antd";

import { ModelContentComponentType, useModal } from "elements/modal/modal";

type Row = {
  values: Array<null | number>;
  setValue: (value: null | number, yearId: number) => void;
};

const split = (s: string): Array<string> => {
  // если так то в качестве десятичного разделителя используется точка а значит запятая это разделитель чисел
  if (s.includes(".")) {
    return s.split(/[\t,]/);
  }
  // точек нет а табуляция есть. Значит запятая считается десятичным разделителем а табуляция разделителем чисел
  if (s.includes("\t")) {
    return s.replaceAll(",", ".").split("\t");
  }
  // нет ни точек не табуляций, значит это целые числа через запятую
  return s.split(",");
};

const EditRow: ModelContentComponentType<Array<number | null>, Row> = ({ dataRef, setOnAccept, setValid }) => {
  const [value, setValue] = useState<string>(dataRef.current.values.join(", "));

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => {
      setValue(target.value);
      setValid(split(target.value).length === dataRef.current.values.length);
    },
    [setValid, dataRef]
  );

  setOnAccept(() =>
    Promise.resolve({
      result: true,
      data: split(value)
        .map((v) => parseFloat(v))
        .map((v) => (isFinite(v) ? v : null)),
    })
  );

  return (
    <>
      <Typography.Text>{`${split(value).length} (${
        split(value)
          .map((v) => parseFloat(v))
          .filter((v) => isFinite(v)).length
      }) / ${dataRef.current.values.length}`}</Typography.Text>
      <Input value={value} onChange={onChange} />
    </>
  );
};

// const EditRow: ModelContentComponentType<Array<number | null>, Row> = ({ dataRef, setOnAccept, setValid }) => {
//   console.log('dataRef', dataRef);
//   const [value, setValue] = useState<string>(Object.values(dataRef.current.value).join(", "));

//   const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
//     ({ target }) => {
//       setValue(target.value);
//       setValid(split(target.value).length === Object.values(dataRef.current.value).length);
//     },
//     [setValid, dataRef]
//   );

//   setOnAccept(() =>
//     Promise.resolve({
//       result: true,
//       data: split(value)
//         .map((v) => parseFloat(v))
//         .map((v) => (isFinite(v) ? v : null)),
//     })
//   );

//   return (
//     <>
//       <Typography.Text>{`${split(value).length} (${
//         split(value)
//           .map((v) => parseFloat(v))
//           .filter((v) => isFinite(v)).length
//       }) / ${dataRef.current.value.length}`}</Typography.Text>
//       <Input value={value} onChange={onChange} />
//     </>
//   );
// };

const useEditRow = (row: Row) => {
  const showModal = useModal<Row["values"], Row>();
  const rowRef = useRef<Row>(row);
  rowRef.current = row;

  return useCallback(async () => {
    let values = null;
    try {
      values = await showModal(EditRow, rowRef, "Ввод строки целиком");
    } catch {
      return;
    }
    for (const [id, val] of values.entries()) {
      row.setValue(val, id);
    }
  }, [showModal, row]);
};

export { useEditRow };
