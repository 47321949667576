import { createContext, type FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { PageFrame } from "routing/pageFrame/pageFrame";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { TableContextProvider } from "elements/table/table";
import { useForEdit } from "elements/useForEdit";
import { GenericPage } from "features/genericPage/genericPage";
import { useMetricsColumns } from "features/useMetrics/useMetricsColumns";
import { Params as ParamsModel } from "models/params/params";
import { type State } from "models/project/fact/fact";
import { type Forecast, useForecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";

import { LicenseTabs } from "./licenseTabs";
import { useTarget } from "./useTarget";

import cn from "./paramsPage.module.less";

type Tab<T> = {
  key: string;
  title: string;
  page?: JSX.Element;
  model: (data: T, license?: number) => ParamsModel;
  isNeedLicense?: boolean;
  hideSums?: boolean;
};

type TabsMix = Array<Tab<Forecast> | Tab<State>>;

const TabsContext = createContext<TabsMix>([]);

const TabsContextProvider = TabsContext.Provider;

const useTabs = (): TabsMix => {
  const tabs = useContext(TabsContext);
  console.assert(tabs.length !== 0, "Запрошены вкладки вне контекста страницы параметров");
  return tabs;
};

type ParamsPageProps = {
  forecastTabs: Tab<Forecast>[];
  factTabs: Tab<State>[];
};

type ParamsPageContentProps = {
  target: Forecast | State;
};

type ModelsPack = Record<string, ParamsModel | Record<number, ParamsModel>>;

const useModels = (target: Forecast | State): ModelsPack => {
  const tabs = useTabs();
  const { licenseRegions } = useProject()!;
  return useMemo(
    () =>
      Object.fromEntries(
        tabs.map(({ model, key, isNeedLicense }) => [
          key,
          isNeedLicense ? Object.fromEntries(licenseRegions.ids.map((regionId) => [regionId, model(target as any, regionId)])) : model(target as any),
        ])
      ),
    [target, tabs, licenseRegions]
  );
};

const ParamsContext = createContext<ParamsModel | null>(null);

const ParamsContextProvider: FC<{ value: ParamsModel } & PropsWithChildren> = ({ children, value }) => {
  const [forEdit, setForEdit] = useForEdit(value);

  const onSubmit = useCallback(() => {
    value.update(forEdit as any).then(() => setForEdit(value.clone));
  }, [forEdit, value, setForEdit]);

  if (forEdit === null) {
    return <FullScreenLoader />;
  }

  return (
    <ParamsContext.Provider value={forEdit}>
      <PageFrameTitlePortal model={forEdit ?? undefined} onSave={onSubmit} />
      {children}
    </ParamsContext.Provider>
  );
};

const useParamsModel = (): ParamsModel => {
  const params = useContext(ParamsContext);
  console.assert(params !== null, "Запрошен объект параметров вне контекста");
  return params!;
};

const TitleContext = createContext<(value: string) => void>(() => {});

const TitleConsumer: FC = () => {
  const setTitle = useContext(TitleContext);
  const title = useParamsModel().title;
  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);
  return null;
};

const StabilizerTableProviderWrap: React.FunctionComponent<{ hideSums: boolean } & React.PropsWithChildren> = ({ children, hideSums }) => {
  const model = useParamsModel();
  const columns = useMetricsColumns(model.table!, hideSums, true);
  return <TableContextProvider value={{ model: model.table!, columns }}>{children}</TableContextProvider>;
};

const Stabilizer = observer<{ hideSums: boolean } & React.PropsWithChildren>(({ children, hideSums }) => {
  const model = useParamsModel();
  if (model.isHaveTable) {
    return <StabilizerTableProviderWrap hideSums={hideSums}>{children}</StabilizerTableProviderWrap>;
  }
  return <>{children}</>;
});

const LicenseOutline: FC<{ models: Record<number, ParamsModel> } & React.PropsWithChildren> = ({ children, models }) => {
  const [lr, setLR] = useState<number>(parseInt(Object.keys(models)[0], 10));
  return (
    <>
      <LicenseTabs value={lr} onChange={setLR} />
      <ParamsContextProvider value={models[lr]}>
        <TitleConsumer />
        {children}
      </ParamsContextProvider>
    </>
  );
};

type ModelPickerProps = { model: ParamsModel | Record<number, ParamsModel>; hideSums: boolean };

const ModelPicker: FC<ModelPickerProps> = ({ model, hideSums }) => {
  if (model instanceof ParamsModel) {
    return (
      <ParamsContextProvider value={model}>
        <TitleConsumer />
        <Stabilizer hideSums={hideSums}>
          <GenericPage />
        </Stabilizer>
      </ParamsContextProvider>
    );
  } else {
    return (
      <div className={cn.licenseOutlineLayout}>
        <LicenseOutline models={model}>
          <Stabilizer hideSums={hideSums}>
            <GenericPage />
          </Stabilizer>
        </LicenseOutline>
      </div>
    );
  }
};

const ParamsPageContent: FC<ParamsPageContentProps> = observer(({ target }) => {
  // сначала создаём все модели (необходимо, чтобы определить заполнены-ли модели)
  const models: ModelsPack = useModels(target);

  const tabs = useTabs();
  const titlePostfix = useForecast() === null ? "фактические данные" : "данные для расчета";

  const navigationTabs = useMemo(
    () =>
      tabs.map(({ key, title, hideSums = true }, id) => ({
        key,
        label: title,
        children: <ModelPicker model={models[key]} hideSums={hideSums} />,
        default: id === 0,
      })),
    [tabs, models]
  );

  const [title, setTitle] = useState("Параметры");

  return (
    <TitleContext.Provider value={setTitle}>
      <PageFrame title={`${title}: ${titlePostfix}`} tabs={navigationTabs} />
    </TitleContext.Provider>
  );
});

const ParamsPage: FC<ParamsPageProps> = observer(({ factTabs, forecastTabs }) => {
  const [target, tabs] = useTarget(factTabs, forecastTabs);

  if (target === undefined || tabs === undefined) {
    return <FullScreenLoader />;
  }

  return (
    <TabsContextProvider value={tabs}>
      <ParamsPageContent target={target} />
    </TabsContextProvider>
  );
});

export { ParamsPage, useParamsModel };
export type { ParamsPageProps, Tab };
