import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, reaction } from "mobx";

import { calculateInjection } from "services/back/injectionPrediction/calculateInjection";
import { saveInjection } from "services/back/injectionPrediction/saveInjection";
import { calculateVoronoi, type VoronoiGrid } from "services/back/injectionPrediction/voronoi";

import { Forecast } from "../forecast";

class InjectionResults {
  public currentDate: Dayjs | null = dayjs();
  public currentProducingObjectId: number | null = null;
  private currentVoronoiGrid: VoronoiGrid | null | undefined = null;

  public isCalculated: boolean = false;
  public isCalculating: boolean = false;

  private readonly voronoiSaved = observable.map<string, VoronoiGrid>();

  constructor(private forecast: Forecast) {
    makeObservable<InjectionResults, "currentVoronoiGrid" | "resetVoronoi">(this, {
      currentDate: observable.ref,
      currentProducingObjectId: observable,
      currentVoronoiGrid: observable,

      isCalculated: observable,
      isCalculating: observable,

      voronoi: computed,

      calculate: action,
      resetVoronoi: action,
    });

    reaction(
      () => this.currentDate,
      () => this.resetVoronoi()
    );

    reaction(
      () => this.currentProducingObjectId,
      () => this.resetVoronoi()
    );

    this.currentProducingObjectId = forecast.fact.producingObjects?.first?.id ?? null;
    this.resetVoronoi();
  }

  public async calculate() {
    if (this.isCalculating) {
      return;
    }
    this.isCalculating = true;
    try {
      const injData = await calculateInjection(this.forecast.id, this.forecast.compensationCoefficients.inverted);
      await saveInjection(injData);
      await this.forecast.production.update(injData.map(({ well_id: wellId }) => ({ wellId })));
      this.isCalculated = true;
    } catch (err) {
      console.error(err);
    } finally {
      this.isCalculating = false;
    }
  }

  public get voronoi(): VoronoiGrid | null | undefined {
    return this.currentVoronoiGrid;
  }

  private async resetVoronoi(): Promise<void> {
    if (this.currentDate === null || this.currentProducingObjectId === null) {
      this.currentVoronoiGrid = null;
      return;
    }
    this.currentVoronoiGrid = undefined;
    const month = this.currentDate.month() + 1;
    const year = this.currentDate.year();
    const producingObjectId = this.currentProducingObjectId;
    const key = InjectionResults.voronoiMapKey(month, year, producingObjectId);
    if (this.voronoiSaved.has(key)) {
      this.currentVoronoiGrid = this.voronoiSaved.get(key)!;
      return;
    }
    try {
      const calculatedVoronoi = await this.calculateVoronoi(month, year, producingObjectId);
      this.voronoiSaved.set(key, calculatedVoronoi);
      this.currentVoronoiGrid = calculatedVoronoi;
    } catch (err) {
      console.error(err);
      this.currentVoronoiGrid = null;
    }
  }

  private async calculateVoronoi(month: number, year: number, producingObjectId: number) {
    return calculateVoronoi(this.forecast.id, producingObjectId, [month, year]);
  }

  private static voronoiMapKey(month: number, year: number, producingObjectId: number): string {
    return `${month}.${year}-${producingObjectId}`;
  }
}

export { InjectionResults };
