import { type FC, useCallback, useEffect, useState } from "react";
import { Button, Form, Radio } from "antd";
import { observer } from "mobx-react-lite";

import { YearPicker } from "elements/inputs/yearPicker";
import type { LicenseRegion as LicenseRegionModel } from "models/project/licenseRegion/licenseRegion";
import { useProject } from "models/project/project";
import type { NDDGroups, TaxSystem } from "services/back/licenseRegions";

type Inputs = {
  nddYear: number | null;
  nddGroup: NDDGroups;
  extractionStartYear?: number | null;
  isNdd: boolean;
};

const isEqualToOrigin = (origin: LicenseRegionModel, formValue: Inputs): boolean =>
  formValue.isNdd === origin.isNdd &&
  (!formValue.isNdd ||
    (formValue.extractionStartYear === origin.ndd?.ndd.productionYear &&
      formValue.nddGroup === origin.ndd?.ndd.group &&
      formValue.nddYear === origin.ndd?.transitionYear));

const checkValid = (state: Inputs, isNDDYear: boolean) => {
  if (!state.isNdd) {
    return true;
  }
  if (!state.nddYear) {
    return false;
  }
  if (!isNDDYear) {
    return true;
  }
  return !!state.extractionStartYear;
};

const NDDNeedYears = [1, 2, 4];

const LicenseRegion: FC<{ fieldKey: number }> = observer(({ fieldKey }) => {
  const { licenseRegions } = useProject()!;
  const [form] = Form.useForm<Inputs>();
  const licenseRegion = licenseRegions.at(fieldKey)!;
  const { startYear } = useProject()!;
  const [isNddEnabled, setNddEnabled] = useState<boolean>(licenseRegion?.isNdd ?? false);
  const [isNotChanged, setIsNotChanged] = useState(true);
  const initialValues: Inputs = {
    isNdd: isNddEnabled,
    nddYear: licenseRegion.ndd?.transitionYear ?? new Date().getFullYear(),
    nddGroup: licenseRegion.ndd?.ndd.group ?? 1,
    extractionStartYear: licenseRegion.ndd?.ndd.productionYear,
  };
  const [isNDDYear, setIsNDDYear] = useState(NDDNeedYears.includes(initialValues.nddGroup));
  const [isValid, setIsValid] = useState<boolean>(true);
  const onValuesChange = useCallback(
    (changed: Record<string, unknown>, v: Inputs) => {
      if ("isNdd" in changed) {
        setNddEnabled(changed.isNdd as boolean);
      }
      const isNDDYear = NDDNeedYears.includes(v.nddGroup);
      // setNddYearDisabled(!isNDDYear);
      setIsNotChanged(isEqualToOrigin(licenseRegion, v));
      setIsValid(checkValid(v, isNDDYear));
      setIsNDDYear(isNDDYear);
    },
    [licenseRegion]
  );
  useEffect(() => {
    onValuesChange({}, initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = useCallback(
    (values: Inputs) => {
      licenseRegion.update({
        isNdd: values.isNdd,
        nddSystem: values.isNdd
          ? {
              ndd: {
                group: values.nddGroup,
                productionYear: values.extractionStartYear,
              },
              transitionYear: values.nddYear,
            }
          : null,
      } as TaxSystem);
    },
    [licenseRegion]
  );

  const isNDDLocked = licenseRegion.ndd !== null && licenseRegion.ndd.transitionYear < new Date().getFullYear();

  return (
    <Form disabled={isNDDLocked} form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues} onValuesChange={onValuesChange}>
      <Form.Item name="isNdd" label="Режим налоговой системы">
        <Radio.Group>
          <Radio.Button defaultChecked value={true}>
            НДД
          </Radio.Button>
          <Radio.Button value={false}>Действующая налоговая система</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="nddYear" label="Год перехода на НДД">
        <YearPicker disabled={!isNddEnabled || isNDDLocked} min={new Date().getFullYear()} max={2100} />
      </Form.Item>
      <Form.Item name="nddGroup" label="Группа НДД">
        <Radio.Group disabled={!isNddEnabled || isNDDLocked}>
          <Radio value={1}>1</Radio>
          <Radio value={2}>2</Radio>
          <Radio value={3}>3</Radio>
          <Radio value={4}>4</Radio>
          <Radio value={5}>5</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="extractionStartYear" label="Год начала промышленной добычи">
        <YearPicker disabled={!isNddEnabled || isNDDLocked || !isNDDYear} min={startYear} max={2100} />
      </Form.Item>
      <Form.Item>
        <Button disabled={!isValid || isNotChanged} type="primary" htmlType="submit">
          {isValid
            ? isNotChanged
              ? isNDDLocked
                ? "Переход на НДД осуществлён"
                : "Данные ЛУ не изменены"
              : "Сохранить"
            : "Поля формы не заполнены"}
        </Button>
      </Form.Item>
    </Form>
  );
});

export { LicenseRegion };
