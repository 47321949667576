import { type FC, type ReactNode } from "react";
import { Button } from "antd";
import { observer } from "mobx-react";

import { useForecast } from "models/project/fact/forecast/forecast";

const CalculateButton: FC<{ children: ReactNode }> = observer(({ children }) => {
  const forecast = useForecast()!;

  return <Button onClick={forecast.calculate}>{children}</Button>;
});

export { CalculateButton };
