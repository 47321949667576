import { FC } from "react";
import { GradientContext } from "@okopok/axes_context";
import { observer } from "mobx-react";

import { PipeType, useInfrastructureMapContext } from "../InfrastructureMapManager/InfrastructureMapManager";
import { fromUniformArray, LEGEND_MAP_COLOR } from "../utils";

import { Pipe } from "./pipe";

const Pipes: FC = observer(() => {
  const { nodes, waterPipes, oilPipes, pipeMap, range } = useInfrastructureMapContext();

  const [minPressure, maxPressure] = range;
  const colorScale = fromUniformArray(LEGEND_MAP_COLOR, minPressure, maxPressure);

  const getPreparedPipes = (pipes: PipeType[]) => {
    const getPoint = (id: string) => nodes.find((el) => el.uuid === id)!;
    return pipes.map(({ uuid, from, to, isSelected, title, category }) =>
      pipeMap
        ? {
            from: { ...getPoint(from), pressure: pipeMap?.get(uuid)?.pressure[0] || 0 },
            to: { ...getPoint(to), pressure: pipeMap?.get(uuid)?.pressure[1] || 0 },
            uuid,
            isSelected,
            isEmpty: category === null,
            velocity: pipeMap?.get(uuid)?.velocity || 0,
            title,
          }
        : { title, from: getPoint(from), to: getPoint(to), uuid, isEmpty: category === null, isSelected, velocity: 0 }
    );
  };

  const [preparedWaterPipes, preparedOilPipes] = [getPreparedPipes(waterPipes), getPreparedPipes(oilPipes)];
  return (
    <g>
      <GradientContext colorScale={colorScale}>
        {preparedWaterPipes.map((pipe) => (
          <Pipe key={pipe.uuid} pipe={pipe} type="water" />
        ))}
        {preparedOilPipes.map((pipe) => (
          <Pipe key={pipe.uuid} pipe={pipe} type="oil" />
        ))}
      </GradientContext>
    </g>
  );
});

export default Pipes;
