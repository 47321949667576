import { FC, useCallback, useMemo } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { TableContextProvider, TableModel, TableNode } from "@okopok/components/Table";
import { ColumnRaw } from "@okopok/components/Table/models/columns/store";
import { Table } from "@okopok/components/Table/Table";
import { Checkbox, DatePicker, Select } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { CsvSaver } from "elements/csvSaver/csvSaver";
import { Ellipsis } from "elements/ellipsis/ellipsis";
import { Format } from "elements/format/format";
import { DATE_FORMAT, formatDate, NUMBER_FORMAT } from "elements/format/format";
import { Icon } from "elements/icon/icon";
import { ToolbarButton } from "elements/toolbarButton/toolbarButton";
import { useCreateTechnologyForecastModal } from "features/forecast/technologyForecastModal/technologyForecastModal";
import { useCreateTechnologyResultsModal } from "features/forecast/technologyForecastModal/technologyResultsModal";
import { global } from "models/global";
import { useProjectContext } from "models/project/context/projectContext";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import type { DRow, EventNode } from "models/project/fact/forecast/techPrediction/techPrediction";
import { Debet as DebetStore } from "models/project/fact/forecast/techPrediction/techPrediction";
import { useProject } from "models/project/project";
import { ForecastChart, WellCharts } from "services/techParameters";

import { FilterDetails, StaticFilter } from "./filters/types";
import { ReactComponent as ResultsIcon } from "./icons/results.svg";
import { ReactComponent as SettingsIcon } from "./icons/settings.svg";

import cn from "./debet.module.less";

const TECH_PREDICTION_DEBET_STATIC_FILTERS: StaticFilter<DRow, EventNode>[] = [
  {
    title: "Только строки без прогноза",
    predicate: (node) => node.production === undefined,
  },
  {
    title: "Только строки с текущей добычей",
    predicate: (node) => (node.byStratums.oilRate ?? 0) > 0,
  },
];

const TECH_PREDICTION_DEBET_FILTER_MAP = {
  wellTitle: {
    type: "stringer",
    predicateFactory: (condition, value) => (eventNode) => {
      value = value.toLowerCase();
      const contains = eventNode.well.title.toLowerCase().includes(value);
      return condition === "contains" ? contains : !contains;
    },
  } as FilterDetails<"stringer", DRow, EventNode, string>,
  eventTitle: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const interventionTitleMatch = eventNode.intervention?.data.gtmTypeId === value;
      const baseProdMatch = eventNode.well.fond === "Base" && value === -1;
      const newProdMatch = eventNode.well.fond === "New" && value === -2;
      const isEqual = interventionTitleMatch || (eventNode.intervention === null && (baseProdMatch || newProdMatch));
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      return (
        <Select
          value={value}
          options={[
            ...(global.interventionsTypes.selector ?? []),
            { label: "Базовая добыча", value: -1 },
            { label: "Эксплуатационное бурение", value: -2 },
          ]}
          onSelect={(value) => onChange(value)}
          variant="borderless"
          popupMatchSelectWidth={false}
          labelRender={(label) => (
            <Ellipsis limit={20} position="after">
              {label.label}
            </Ellipsis>
          )}
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, number>,
  date: {
    type: "ordering",
    predicateFactory: (condition, value) => (eventNode) => {
      const date = eventNode.well.data.date;
      switch (condition) {
        case "eq":
          return date === value;
        case "ne":
          return date !== value;
        case "ge":
          return date >= value;
        case "gt":
          return date > value;
        case "le":
          return date <= value;
        case "lt":
          return date < value;
      }
    },
    InputNode: ({ value, onChange }) => {
      return <DatePicker value={value} onChange={onChange} />;
    },
  } as FilterDetails<"ordering", DRow, EventNode, Dayjs>,
  wellType: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.data.wellTypeId === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      return <Select value={value} options={global.wellTypes.selector} onSelect={(value) => onChange(value)} variant="borderless" />;
    },
  } as FilterDetails<"equal", DRow, EventNode, number | undefined>,
  wellStatus: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.wellStatus === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      return (
        <Select
          value={value}
          options={["Добывающая", "Нагнетательная", "Прочего назначения"].map((v) => ({ label: v, value: v }))}
          onSelect={(value) => onChange(value)}
          variant="borderless"
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, string>,
  fond: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.fond === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      return (
        <Select
          value={value}
          options={["Base", "New"].map((v) => ({ label: v === "Base" ? "Базовый" : "Новый", value: v }))}
          onSelect={(value) => onChange(value)}
          variant="borderless"
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, "Base" | "New">,
  licenseRegion: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.licenseRegionId === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      const licenseRegions = useProject()!.licenseRegions;
      const options = licenseRegions.selector;
      return (
        <Select
          value={value}
          disabled={options === undefined}
          options={options}
          onSelect={(value) => onChange(value)}
          allowClear
          onClear={() => onChange(undefined)}
          variant="borderless"
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, number | undefined>,
  wellPad: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.data.mineId === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      const pads = useFact()!.wellPads;
      const options = pads.selector;
      return (
        <Select
          value={value}
          disabled={options === undefined}
          options={options}
          onSelect={(value) => onChange(value)}
          allowClear
          onClear={() => onChange(undefined)}
          variant="borderless"
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, number | undefined>,
  stratum: {
    type: "equal",
    predicateFactory: (condition, value) => (eventNode) => {
      const isEqual = eventNode.well.data.stratumId === value;
      return condition === "eq" ? isEqual : !isEqual;
    },
    InputNode: ({ value, onChange }) => {
      const stratums = useFact()!.stratums;
      const options = stratums.selector;
      return (
        <Select
          value={value}
          disabled={options === undefined}
          options={options}
          onSelect={(value) => onChange(value)}
          allowClear
          onClear={() => onChange(undefined)}
          variant="borderless"
        />
      );
    },
  } as FilterDetails<"equal", DRow, EventNode, number | undefined>,
};

const DeleteButton: FC<{ store: DebetStore }> = observer(({ store }) => {
  const forecast = useForecast()!;

  const isNotSelected = store.selectedEvents.length === 0;
  const isNotTechParameters = !store.selectedEvents.some(({ techParameters, well: { id: wId }, intervention }) => {
    const gId = intervention?.id;
    if (techParameters) {
      return true;
    } else if ((gId === undefined || gId === null) && forecast.production.wellData(wId)) {
      return true;
    }
    return gId ? !!forecast.production.interventionData(gId) : false;
  });

  const confirm = async () => {
    const wellIdsInfo = store.selectedEvents.map(
      ({
        well: {
          id,
          data: { stratumId },
        },
        intervention,
      }) => ({ wellId: id, gtmId: intervention?.id, stratumId })
    );
    forecast.techParameters.delete(wellIdsInfo);
  };

  const tooltip = isNotSelected
    ? "Ни одна скважина/ГТМ не выбрана"
    : isNotTechParameters
    ? "Ни для одной из выбранных скважин/ГТМ нет прогноза"
    : "Удалить прогноз у выбранных скважин/ГТМ";

  return (
    <ToolbarButton
      tooltip={{ title: tooltip }}
      popconfirm={{
        title: "Удаление настроек",
        description: "Удалить прогноз для выбранных скважин/ГТМ?",
        onConfirm: confirm,
        onCancel: () => "",
        okText: "Да",
        cancelText: "Нет",
      }}
      disabled={isNotSelected || isNotTechParameters}
      icon={<DeleteOutlined />}
      danger
    />
  );
});

const ForecastModalLauncherButton: FC<{ store: DebetStore }> = observer(({ store }) => {
  const createSettingsModal = useCreateTechnologyForecastModal();
  const forecast = useForecast()!;

  const onForecastSettings = useCallback(() => {
    createSettingsModal(store.selectedEvents).then((techForecastSettingsForSendedWells) => {
      if (techForecastSettingsForSendedWells !== null) {
        forecast.techParameters.update(techForecastSettingsForSendedWells);
      }
    });
  }, [createSettingsModal, forecast, store]);

  const isNotSelected = store.selectedEvents.length === 0;

  const tooltip = isNotSelected
    ? "Для начала настройки технологического прогноза нужно выбрать скважины/ГТМ, для которых будет проводится настройка"
    : "Прогноз технологических показателей";

  return (
    <ToolbarButton
      tooltip={{ title: tooltip }}
      disabled={isNotSelected}
      onClick={onForecastSettings}
      icon={<Icon content={<SettingsIcon />} viewBox="0 0 14 16" />}
    />
  );
});

const ResultsModalLauncherButton: FC<{ store: DebetStore }> = observer(({ store }) => {
  const forecast = useForecast()!;
  const fact = useFact()!;

  const createResultsModal = useCreateTechnologyResultsModal();

  const filteredWellNodes = store.selectedEvents.filter(({ well: { id: wId }, intervention }) => {
    const gId = intervention?.id;
    if ((gId === undefined || gId === null) && forecast.production.wellData(wId)) {
      return true;
    }
    return gId ? !!forecast.production.interventionData(gId) : false;
  });

  const filteredForecast: WellCharts[] = filteredWellNodes.map(({ well: { id: wId, stratum, producingObject }, intervention }) => {
    const gId = intervention?.id;
    const productionForecast = gId ? forecast.production.interventionData(gId) : forecast.production.wellData(wId);
    const productionFact = gId ? fact.production.interventionData(gId) : fact.production.wellData(wId);
    const stratumId = stratum?.id ?? null;

    const factChart: ForecastChart = {
      year: [],
      month: [],
      oilProd: [],
      oilRate: [],
      liquidProd: [],
      liquidRate: [],
      prodDays: [],
    };
    const forecastChart: ForecastChart = {
      year: [],
      month: [],
      oilProd: [],
      oilRate: [],
      liquidProd: [],
      liquidRate: [],
      prodDays: [],
    };

    for (const [date, { liquid_prod, oil_rate, prod_days }] of productionForecast!.byMonth()) {
      forecastChart.year.push(date.year());
      forecastChart.month.push(date.month() + 1); // TODO: fix months to 0-11 instead of 1-12
      forecastChart.oilRate!.push((oil_rate && oil_rate * 1000) ?? 0);
      forecastChart.liquidRate!.push(prod_days && liquid_prod ? (liquid_prod * 1000) / (prod_days === 0 ? 1 : prod_days) : 0);
      forecastChart.prodDays!.push(prod_days ?? 1);
    }

    if (productionFact) {
      for (const [date, { liquid_prod, oil_rate, prod_days }] of productionFact!.byMonth()) {
        factChart.year.push(date.year());
        factChart.month.push(date.month() + 1); // TODO: fix months to 0-11 instead of 1-12
        factChart.oilRate!.push((oil_rate && oil_rate * 1000) ?? 0);
        factChart.liquidRate!.push(prod_days && liquid_prod ? (liquid_prod * 1000) / (prod_days === 0 ? 1 : prod_days) : 0);
        factChart.prodDays!.push(prod_days ?? 1);
      }
    }

    return {
      gtmId: gId ?? null,
      wellId: wId,
      stratumId: stratumId,
      fact: factChart,
      forecastInterval: null,
      forecast: forecastChart,
      oilRelativeDensity: producingObject?.oilRelativeDensity ?? 0.75,
      waterRelativeDensity: producingObject?.waterRelativeDensity ?? 1,
      appliedStopCriterion: null,
    };
  });

  const scenarioId = filteredWellNodes[0] ? filteredWellNodes[0].forecast.id : 0;

  const onResultsClick = async () => {
    createResultsModal({ forecast: filteredForecast, wellNodes: filteredWellNodes }).then((techForecastSettingsForSendedWells) => {
      if (techForecastSettingsForSendedWells !== null) {
        forecast.techParameters.updateForecast(techForecastSettingsForSendedWells.map((forecast) => ({ ...forecast, scenario_id: scenarioId })));
      }
    });
  };

  const isNotSelected = store.selectedEvents.length === 0;

  const tooltip = isNotSelected
    ? "Для начала просмотра технологических показателей нужно выбрать скважины/ГТМ"
    : filteredWellNodes.length === 0
    ? "Не выбрано ни одной скважины/ГТМ с рассчитанным прогнозом технологических параметров"
    : "Результаты прогноза технологических показателей";

  return (
    <ToolbarButton
      tooltip={{ title: tooltip }}
      disabled={filteredWellNodes.length === 0}
      onClick={onResultsClick}
      icon={<Icon content={<ResultsIcon />} viewBox="0 0 16 14" />}
    />
  );
});

const COLUMNS: ColumnRaw<DRow>[] = [
  {
    dataKey: "wellTitle",
    title: "Скважина",
    isSticky: true,
    width: 160,
  },
  {
    dataKey: "eventTitle",
    title: "Мероприятие",
    isSticky: true,
    width: 260,
  },
  {
    dataKey: "date",
    title: "Дата мероприятия",
    width: 160,
    render: (value: Dayjs | null | undefined) => value && <Format>{value}</Format>,
    renderToString: (value: Dayjs | null | undefined) => {
      if (!value) return null;
      return formatDate(dayjs(value), DATE_FORMAT.generic);
    },
  },
  {
    dataKey: "wellType",
    title: "Тип заканчивания",
    width: 160,
  },
  {
    dataKey: "wellStatus",
    title: "Назначение",
    width: 160,
  },
  {
    dataKey: "wellPad",
    title: "Куст",
    width: 160,
  },
  {
    dataKey: "fond",
    title: "Фонд",
    width: 160,
  },
  {
    dataKey: "licenseRegion",
    title: "ЛУ",
    width: 160,
  },
  {
    dataKey: "producingObject",
    title: "Объект разработки",
    width: { min: 160, max: 440, competitiveness: 1 },
  },
  {
    title: "Залежь",
    dataKey: "stratum",
    width: { min: 200, max: 300, competitiveness: 1 },
    onCell: () => ({ className: cn.remove }),
    onHeaderCell: () => ({ className: cn.tableHeader }),
    render: (value: string, { update, expand }) => {
      if (value === undefined) {
        return null;
      }

      return (
        <Ellipsis limit={30} position="mid">
          <Format>{value}</Format>
        </Ellipsis>
      );
    },
    renderToString: (value: string) => {
      if (!value) return null;

      return value;
    },
  },
  // {
  //   dataKey: "stopCriterion",
  //   title: "Достигнутый критерий остановки",
  //   width: 260,
  //   render: (value) => value && <AnnotatedValue {...value} />
  // },
  {
    dataKey: "operationCoef",
    title: "Коэф-т эксплуатации",
    width: 160,
  },
  {
    dataKey: "liquidDebitMethod",
    title: "Способ расчета дебита жидкости",
    width: 260,
  },
  {
    dataKey: "oilDebitMethod",
    title: "Способ расчета дебита нефти",
    width: 260,
  },
  {
    dataKey: "liquidRate",
    title: "Стартовый дебит жид-ти, м³/сут",
    width: 260,
    render: (value) => value && <Format>{value}</Format>,
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_3.format(value);
    },
  },
  {
    dataKey: "oilRate",
    title: "Стартовый дебит нефти, т",
    width: 260,
    render: (value) => value && <Format>{value}</Format>,
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_3.format(value);
    },
  },
  {
    dataKey: "waterCut",
    title: "Стартовая обводненность, %",
    width: 260,
    render: (value) => value && <Format>{value}</Format>,
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_3.format(value);
    },
  },
  {
    dataKey: "accumLiquid",
    title: "Накопленная добыча жид-ти, тыс. м³",
    width: 270,
    render: (value) => value && <Format>{value}</Format>,
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_2.format(value);
    },
  },
  {
    dataKey: "accumOil",
    title: "Накопленная добыча нефти, тыс. т",
    width: 260,
    render: (value) => value && <Format>{value}</Format>,
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_2.format(value);
    },
  },
  {
    dataKey: "recoverableResourcesStart",
    title: "Нач. извлекаемые запасы, тыс т",
    width: 260,
    render: (value) => value && <Format>{value}</Format>,
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_2.format(value);
    },
  },
  {
    dataKey: "recoverableResourcesEnd",
    title: "Ост. извлекаемые запасы, тыс т",
    width: 260,
    render: (value) => value && <Format>{value}</Format>,
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_2.format(value);
    },
    onCell: ({ value }) => ({ style: { backgroundColor: (value?.recoverableResourcesEnd ?? 0) < 0 ? "pink" : undefined } }),
  },
  {
    dataKey: "recoverableResourcesRatio",
    title: "Отбор от НИЗ, %",
    width: 260,
    render: (value) => value && <Format>{value}</Format>,
    renderToString: (value) => {
      if (!value) return null;

      return NUMBER_FORMAT.real_2.format(value);
    },
  },
];

const SelectAll = observer(({ store }: { store: TableNode<DRow, any> }) => {
  const { selectManager } = store;
  const onChange = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      selectManager?.propagateSelected();
    } else {
      selectManager?.propagateDeselected();
    }
  };
  return <Checkbox indeterminate={selectManager?.isPartiallySelected} checked={selectManager?.isSelected} onChange={onChange} />;
});

const Debet = observer(() => {
  const forecast = useForecast()!;

  const projectContext = useProjectContext();
  const tree = projectContext.wellsTree;
  /// TODO: Скважины в дереве должны удовлетворять следующему фильтру
  // .map((well) => [well, forecast.interventions.getInterventionsByWellId(well.id)] as const)
  // .filter(([well, interventions]) => well.data.stratumId !== null || interventions.length > 0)
  // .map(([well]) => well),

  const store = useMemo(() => new DebetStore(forecast, tree), [forecast, tree]);
  const model = useMemo(() => {
    const cols = [
      {
        key: "selectInfo",
        title: <SelectAll store={store} />,
        width: 34,
        isSticky: true,
        isExported: false,
        render: (_, { select }) => {
          if (select === undefined) return undefined;
          const onChange = (event: CheckboxChangeEvent) => {
            if (event.target.checked) {
              select.onSelect();
            } else {
              select.onDeselect();
            }
          };
          return <Checkbox indeterminate={select.status === "partiallySelected"} checked={select.status === "selected"} onChange={onChange} />;
        },
      } as ColumnRaw<DRow>,
      ...COLUMNS,
    ];
    return new TableModel(
      cols,
      store,
      {
        onRow: ({ indexPath, expand }) => ({
          className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
        }),
      },
      {
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }
    );
  }, [store]);

  return (
    <CsvSaver filename="Прогноз добычи" exportArray={() => model.export()}>
      <div className={cn.root}>
        <TableContextProvider value={model}>
          <PageFrameTitlePortal filter={store.filterManager} onFilterChange={store.applyFilters}>
            <ForecastModalLauncherButton store={store} />
            <DeleteButton store={store} />
            <ResultsModalLauncherButton store={store} />
          </PageFrameTitlePortal>
          <Table headerClassName={cn.tableHeader} className={cn.table} />
        </TableContextProvider>
      </div>
    </CsvSaver>
  );
});

export { Debet, TECH_PREDICTION_DEBET_FILTER_MAP, TECH_PREDICTION_DEBET_STATIC_FILTERS };
