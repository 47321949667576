import { useRef } from "react";
import { ColumnsType } from "antd/es/table";
import { UploadFile } from "antd/es/upload";

import { ImportModalContent, ImportModalResolve, TableState } from "elements/importModalContent/ImportModalContent";
import { useModal } from "elements/modal/modal";
import { useForecast } from "models/project/fact/forecast/forecast";
import { WellInterventions } from "models/project/fact/forecast/wellInterventions/wellInterventions";
import { parseExcelFile } from "services/back/interventions";

import { INTERVENTIONS_TEMPLATE_INFO_CARDS } from "./infocards";
import { useColumns } from "./wellInterventions";

import "@okopok/components/style.css";

type MockDataType = {
  id: number;
  well: string;
  oilRate: string;
  liquidRate: string;
  recoverableResources: string;
  waterCut: string;
  type: string;
  date: number | string;
  prodObj: string;
};

export const MOCK_DATA_TABLE: MockDataType[] = [
  {
    id: 1,
    well: "101",
    oilRate: "739,96",
    liquidRate: "439,10",
    recoverableResources: "147,26",
    waterCut: "99,88",
    type: "Перевод на другой горизонт",
    date: "08.2029",
    prodObj: "Объект разработки 1",
  },
  {
    id: 2,
    well: "102",
    oilRate: "614,39",
    liquidRate: "428,47",
    recoverableResources: "401,91",
    waterCut: "53,03",
    type: "Зарезка боковых (вторых) стволов",
    date: "03.2027",
    prodObj: "Объект разработки 2",
  },
  {
    id: 3,
    well: "103",
    oilRate: "850,07",
    liquidRate: "591,80",
    recoverableResources: "311,30",
    waterCut: "89,41",
    type: "Радиальное бурение",
    date: "08.2024",
    prodObj: "Объект разработки 3",
  },
  {
    id: 4,
    well: "104",
    oilRate: "391,13",
    liquidRate: "424,45",
    recoverableResources: "715,22",
    waterCut: "21,53",
    type: "Углубление забоя",
    date: "05.2023",
    prodObj: "Объект разработки 4",
  },
];

export const columnsMock: ColumnsType<MockDataType> = [
  {
    dataIndex: "well",
    title: "Скважина",
    width: 160,
  },
  {
    dataIndex: "oilRate",
    title: "Стартовый дебит нефти, т/сут",
    width: 104,
  },
  {
    dataIndex: "liquidRate",
    title: "Стартовый дебит жид-ти, м3/сут",
    width: 104,
  },
  {
    dataIndex: "recoverableResources",
    title: "Извлекаемые запасы, тыс т",
    width: 100,
  },
  {
    dataIndex: "type",
    title: "Тип ГТМ",
    width: 184,
  },
  {
    dataIndex: "date",
    title: "Дата проведения",
    width: 100,
  },
  {
    dataIndex: "prodObj",
    title: "Объект разработки",
    width: 166,
  },
];

const useImportInterventionsModal = (): ((file: UploadFile | null) => Promise<ImportModalResolve | null>) => {
  const showModal = useModal<ImportModalResolve, TableState<WellInterventions>>();

  const forecast = useForecast()!;
  const columns = useColumns(true);

  const parseExcel = async (file: UploadFile): Promise<WellInterventions> => {
    try {
      const resolve = await parseExcelFile(file, forecast.id);
      return new WellInterventions(forecast, resolve);
    } catch (err) {
      throw new Error("invalid file format");
    }
  };

  const val: { current: TableState<WellInterventions> } = useRef({
    columns: columns.filter(({ key }) => key !== "checked" && key !== "fondName"),
    parseExcel,
    template: {
      infoCards: INTERVENTIONS_TEMPLATE_INFO_CARDS,
      templateLink: (
        <a href="/static/Инвест-ГТМ.xlsx" download="GRM_template.xlsx">
          Скачать шаблон программы ГТМ
        </a>
      ),
      data: MOCK_DATA_TABLE,
      columns: columnsMock,
    },
  });

  return async (file: UploadFile | null) => {
    val.current.file = file;
    return showModal(ImportModalContent, val, "Импорт программы ГТМ", null, null, "1420px", null)
      .then((data) => data)
      .catch(() => null);
  };
};

export { useImportInterventionsModal };
