import { useRef, useState } from "react";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";

import { TooltipDataManager } from "./useTooltipDataManager";

import styles from "./Tooltip.module.less";

const TooltipDiv = observer(({ manager }: { manager: TooltipDataManager | null }) => {
  const [hovered, setHovered] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const onMouseOver = () => {
    setHovered(true);
  };
  const onMouseLeave = () => {
    setHovered(false);
  };

  if (manager === null) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      {(manager.isShowing || hovered) && (
        <div
          className={styles["tooltip"]}
          style={{
            left: `${manager.tooltipCoords?.left}px`,
            top: `${manager.tooltipCoords?.top ?? 0}px`,
          }}
          onMouseEnter={onMouseOver}
          onMouseLeave={onMouseLeave}
          ref={divRef}
        >
          {manager.tooltipData?.map((row, key) => (
            <div key={key}>
              <div className={styles["header"]}>{row.header}</div>
              <div className={styles.valueRow}>
                {row.color !== null && <div className={styles.circle} style={{ backgroundColor: row.color }} />}
                <div className={styles.value}>
                  <Format>{row.value}</Format>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export default TooltipDiv;
