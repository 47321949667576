import { FC, useMemo } from "react";
import { CaretDownOutlined, CaretUpOutlined, RedoOutlined } from "@ant-design/icons";
import { TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { ColumnRaw } from "@okopok/components/Table/models/columns/store";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import { Button, Popover, Tag, Tooltip, Typography } from "antd";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Format } from "elements/format/format";
import { Icon } from "elements/icon/icon";
import { Loader } from "elements/loader";
import { CalculationPreloader } from "features/calculationPreloader/calculationPreloader";
import { useForecast } from "models/project/fact/forecast/forecast";
import { type RankingDRow } from "models/project/fact/forecast/ranking/rankingDrilling";
import { TableType } from "services/back/ranking";

import { OrderSelector } from "./orderSelector";
import { ReactComponent as RankDistributionIcon } from "./rankDistribution.svg";
import { ReactComponent as RankingCriteriaIcon } from "./rankingСriteria.svg";
import { useRankingStore, useTab } from "./useRankingStore";

import "@okopok/components/style.css";
import cn from "./ranking.module.less";
import settingsCn from "./settings.module.less";

const getRangColor = (rank: number) => {
  if (rank <= 50) {
    return "#FC9390";
  } else if (rank > 50 && rank <= 70) {
    return "#FDB022";
  }
  return "#16B364";
};

const renderFormat = (v: any) => <Format>{v}</Format>;

const SHARED_COLUMNS: ColumnRaw<RankingDRow>[] = [
  {
    title: "Накопленная добыча нефти за прогнозный период, тыс т",
    dataKey: "accumOilProd",
    width: { min: 230, max: 600, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
    render: renderFormat,
  },
  {
    title: "PV CAPEX, млн долл.",
    dataKey: "pvCapex",
    width: { min: 140, max: 250, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
    render: renderFormat,
  },
  {
    title: "PV CAPEX к добыче, млн долл./тыс т",
    key: "pvCapexProd",
    width: { min: 190, max: 250, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
    render: renderFormat,
  },
  {
    title: "PI , доли ед",
    dataKey: "pi",
    width: { min: 140, max: 200, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
    render: renderFormat,
  },
  {
    title: "NPV, млн долл",
    dataKey: "npv",
    width: { min: 140, max: 200, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
    render: renderFormat,
  },
  {
    title: "IRR, %",
    dataKey: "irr",
    width: { min: 104, max: 104, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "PBP, лет",
    dataKey: "pbp",
    width: { min: 104, max: 104, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "dPBP, лет",
    dataKey: "dpbp",
    width: { min: 104, max: 104, competitiveness: 1 },
    onCell: () => ({ className: cn.tableCell }),
  },
  {
    title: "Залежь",
    key: "stratumTitle",
    width: { min: 240, max: 440, competitiveness: 1 },
    onCell: () => ({ style: { justifyContent: "start" } }),
  },
];

const DRILLING_COLUMNS: ColumnRaw<RankingDRow>[] = [
  {
    key: "expand",
    title: <div />,
    isSticky: true,
    width: { min: 34, max: 34, competitiveness: 1 },
    render: (_, tableItem) => <ExpandButton expand={tableItem.expand} />,
  },
  {
    title: "Куст",
    dataKey: "mineCode",
    isSticky: true,
    width: { min: 84, max: 84, competitiveness: 1 },
  },
  {
    title: "Скважина",
    dataKey: "wellTitle",
    isSticky: true,
    width: { min: 104, max: 104, competitiveness: 1 },
  },
];

const RECONSTRUCTION_COLUMNS: ColumnRaw<RankingDRow>[] = [
  {
    title: "Скважина",
    dataKey: "wellTitle",
    isSticky: true,
    width: { min: 104, max: 104, competitiveness: 1 },
  },
  {
    title: "Куст",
    dataKey: "mineCode",
    isSticky: true,
    width: { min: 84, max: 84, competitiveness: 1 },
  },
  {
    title: "Мероприятие",
    dataKey: "gtm",
    isSticky: true,
    width: { min: 230, max: 300, competitiveness: 1 },
  },
];

const ORDER_COLUMN: ColumnRaw<RankingDRow>[] = [
  {
    title: <div />,
    key: "setorder",
    isSticky: true,
    width: 30,
    onCell: () => ({ style: { padding: 0 } }),
    render: (_, { value }) => {
      return (
        <div className={cn.arrowButtonsBlock}>
          <Button type="link" onClick={() => value?.move?.("up")} icon={<CaretUpOutlined style={{ fontSize: 12 }} />} />
          <Button type="link" onClick={() => value?.move?.("down")} icon={<CaretDownOutlined style={{ fontSize: 12 }} />} />
        </div>
      );
    },
  },
];

const useColumns = (type: TableType, isDrilling?: boolean): ColumnRaw<RankingDRow>[] => {
  return useMemo(
    (): ColumnRaw<RankingDRow>[] => [
      {
        key: "index",
        title: "No.пп",
        isSticky: true,
        width: { min: 52, max: 52, competitiveness: 1 },
        render: (_, { expand, absoluteIndex }) => (expand === undefined ? absoluteIndex : <div />),
        onCell: () => ({ style: { justifyContent: "right" } }),
      },
      ...(type === "sequential" ? ORDER_COLUMN : []),
      ...(isDrilling ? DRILLING_COLUMNS : RECONSTRUCTION_COLUMNS),
      {
        title: "Очередь",
        key: "queue",
        isSticky: true,
        width: { min: 70, max: 70, competitiveness: 1 },
        render: (_, { indexPath }) => indexPath.map((i) => i + 1).join("."),
      },
      {
        title: "Ранг",
        key: "rank",
        isSticky: true,
        width: { min: 50, max: 50, competitiveness: 1 },
        render: (_, { value }) =>
          value?.rank !== undefined ? (
            <Tag className={cn.rank} style={{ borderRadius: "100px" }} color={getRangColor(value.rank)}>
              {Math.round(value?.rank)}
            </Tag>
          ) : (
            <Format>{null}</Format>
          ),
      },
      ...SHARED_COLUMNS,
    ],
    [type, isDrilling]
  );
};

type RankingProps = {
  type?: TableType;
};

const Ranking: FC<RankingProps> = observer(({ type = "ranking" }) => {
  const tab = useTab();
  const columns = useColumns(type, tab === "drilling");
  const forecast = useForecast();
  const ranking = forecast![type === "ranking" ? "ranking" : "rankingSequential"];
  const store = useRankingStore(type);
  const model = useMemo(
    () =>
      new TableModel(
        columns,
        store,
        {
          onRow: ({ indexPath, expand }) => ({
            className:
              expand === undefined ? cn.tableRowPlain : indexPath.length === 1 && tab === "drilling" ? cn.tableRowPrimary : cn.tableRowSecondary,
          }),
        },
        {
          headerHeight: 49,
          rowHeight: 33,
          borderColor: "#f0f0f0",
        }
      ),
    [columns, store, tab]
  );

  if (model.isLoading) {
    return (
      <div className={cn.body}>
        <CalculationPreloader />
        <div className={cn.loader}>
          <Loader />
          <Typography.Text>Расчет рангов</Typography.Text>
        </div>
      </div>
    );
  }

  return (
    <TableContextProvider value={model}>
      <PageFrameTitlePortal onSave={ranking.submit} model={model} submitCustomTooltip={ranking.customTooltip}>
        {type === "ranking" ? (
          <Popover placement="bottomRight" title="Критерии ранжирования" content={<OrderSelector />}>
            <Button type="text" icon={<Icon width="24" height="24" content={<RankingCriteriaIcon />} />} />
          </Popover>
        ) : null}
        <Tooltip title="Распределение рангов ГТМ">
          <Button type="text" icon={<Icon width="24" height="24" content={<RankDistributionIcon />} />} />
        </Tooltip>
        <Tooltip title="Пересчитать">
          <Button disabled={ranking.isLoading} onClick={ranking.execRanking} type="text" icon={<RedoOutlined />} />
        </Tooltip>
      </PageFrameTitlePortal>
      <div className={cn.table}>
        <Widget headerClassName={settingsCn.tableHeader} />
      </div>
    </TableContextProvider>
  );
});

export { getRangColor, Ranking };
