import { FC, ReactNode } from "react";
import { Button, Card, Table, Typography, Upload, UploadFile } from "antd";
import { ColumnsType } from "antd/es/table";

import { Icon } from "elements/icon/icon";

import { ReactComponent as AttachIcon } from "./icons/attachIcon.svg";
import { ReactComponent as WarningIcon } from "./icons/warningIcon.svg";
import { TemplateCard, TemplateInfoCards } from "./templateInfoCards";

import cim from "./importModalContent.module.less";
import cn from "./templateTable.module.less";

const TableComponent: FC<{ data: any[]; columns: ColumnsType<any>; infoCards?: TemplateCard[] }> = ({ data, columns, infoCards }) => {
  return (
    <div className={cim.templateTableWrapper}>
      <Table rowKey="id" dataSource={data} columns={columns} pagination={false} bordered className={cim.templateTable} />
      {infoCards && <TemplateInfoCards cards={infoCards} />}
    </div>
  );
};

type Props = {
  data: any[];
  columns: ColumnsType<any>;
  infoCards?: TemplateCard[];
  fileName: string;
  isError: boolean;
  templateLink: ReactNode;
  onFile?: (file: UploadFile) => void;
};

const TemplateTable = ({ data, columns, infoCards, templateLink, fileName, isError, onFile }: Props) => {
  return (
    <>
      <div className={cim.titleBlock}>
        {isError ? (
          <Card className={cim.warningBlock}>
            <Icon content={<WarningIcon />} className={cim.attachIcon} viewBox="0 0 16 16" />
            Файл {fileName} не соответствует формату <a href="#downloadFile">шаблона</a>.
          </Card>
        ) : (
          templateLink
        )}
        <div className={cim.uploadButtons}>
          <Upload onChange={({ file }) => onFile?.(file)} accept=".xlsx, .xls" beforeUpload={() => false} showUploadList={false}>
            <Button icon={<Icon content={<AttachIcon />} className={cim.attachIcon} viewBox="0 0 16 16" />}>
              {isError ? "Выбрать другой файл" : "Загрузить файл"}
            </Button>
          </Upload>
        </div>
      </div>
      <div className={cim.template}>
        <Typography.Title className={cim.templateTitle} level={2}>
          Данные для импорта
        </Typography.Title>
        <Typography.Text className={cim.templateText}>Ниже представлен пример таблицы:</Typography.Text>
      </div>
      <div className={cn.wrap}>
        <TableComponent data={data} columns={columns} infoCards={infoCards} />
      </div>
    </>
  );
};

export { TemplateTable };
