import { getConstantsResolver } from "services/back/constants";

import { findObjectByCodeTitle, getUniversalDataSource, MetricTree, ParamsResponse, riseMetrics } from "../utils";

import { getParamsByYearFact } from "./mainTable";
import { getRevenueFact, getRevenueForecast } from "./revenue";

const params = {
  wellRepair: "Ремонт скважин",
  costsForEnhancedOilRecovery: "Затраты по повышению нефтеотдачи пластов",
  unitExpensesOperatingActivities: "Удельные расходы. Операционная деятельность",
} as const;

const reorganizeChildren = (unitCosts: MetricTree) => {
  let { children } = unitCosts;
  unitCosts.children = [...children, ...findObjectByCodeTitle(children[0], params.wellRepair)!];
  unitCosts.children[0].children = findObjectByCodeTitle(children[0], params.wellRepair, true)!;

  unitCosts.children = [
    ...unitCosts.children,
    ...findObjectByCodeTitle(children[1], params.costsForEnhancedOilRecovery)!,
  ];
  unitCosts.children[1].children = findObjectByCodeTitle(children[1], params.costsForEnhancedOilRecovery, true)!;
};

const getUnitCosts = async (): Promise<ParamsResponse> => {
  const { constants } = await getConstantsResolver();
  const unitCosts = riseMetrics(constants).find((el) => el.code_title === params.unitExpensesOperatingActivities)!;
  reorganizeChildren(unitCosts);

  return { scalar: [unitCosts] };
};

const [operatingFactSource, operatingUnitCostsSource, operatingRevenueFactSource, operatingRevenueForecastSource] =
  getUniversalDataSource([
    { key: "OperatingFact", fn: getParamsByYearFact },
    { key: "OperatingUnitCosts", fn: getUnitCosts },
    { key: "OperatingRevenueFact2", fn: getRevenueFact },
    { key: "OperatingRevenue3", fn: getRevenueForecast },
  ]);

export { operatingFactSource, operatingRevenueFactSource, operatingRevenueForecastSource, operatingUnitCostsSource };
