import { PageFrame, TabItem } from "routing/pageFrame/pageFrame";

import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { InjectionResults } from "features/injPrediction/injectionResults";
import { InjectionPredictionInputParams } from "features/injPrediction/inputParams";
import { Preloader } from "features/preloader/preloader";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";

const useProducingObjects = () => useProject()?.producingObjects;
const useWellsFact = () => useFact()?.wells;
const useWellsForecast = () => useForecast()?.wells;
const useInterventions = () => useForecast()?.interventions;
const useProductionFact = () => useFact()?.production;
const useProductionForecast = () => useForecast()?.production;

const TABS: TabItem[] = [
  {
    key: "params",
    label: "Входные параметры",
    children: <InjectionPredictionInputParams />,
    default: true,
  },
  {
    key: "results",
    label: "Результаты прогнозирования",
    children: <InjectionResults />,
  },
];

const InjectionPrediction = () => {
  return (
    <ForecastSelector>
      <Preloader hooks={[useProducingObjects, useWellsFact, useWellsForecast, useInterventions, useProductionFact, useProductionForecast]}>
        <PageFrame title={"Прогноз закачки"} tabs={TABS} tabsWidthFix={444} />
      </Preloader>
    </ForecastSelector>
  );
};

export { InjectionPrediction };
