import { action, computed, makeAutoObservable, makeObservable, observable, runInAction, when } from "mobx";

import { BackendStorageMock } from "services/back/backendStorage";
import { getDecreaseCoefficients } from "services/back/decreaseCoefficients";

import { Forecast } from "../forecast";

type DecreaseCoefficientDump = {
  data: {
    title: string;
    key: string;
    data: [number, number | null][] | null;
  }[];
};

const decreaseCoefficientsSource = (key: { fact: number; forecast?: number }) => {
  return new BackendStorageMock<void, DecreaseCoefficientDump>(
    `DecreaseCoefficientSource_${key.fact}_${key.forecast}`,
    key.fact,
    key.forecast
  );
};

class DecreaseCoefficient {
  title: string;
  key: string;
  data: Map<number, number | null> | null;
  constructor(key: string, title: string, data: Map<number, number | null> | null) {
    makeObservable(this, {
      title: observable,
      key: observable,
      data: observable,
      setData: action,
      dump: computed,
    });
    this.key = key;
    this.title = title;
    this.data = data;
  }

  public setData = (year: number, value: number | null) => {
    this.data?.set(year, value);
  };

  get dump() {
    return {
      title: this.title,
      key: this.key,
      data: this.data ? Array.from(this.data.entries()) : null,
    };
  }
}
class DecreaseCoefficients {
  coefficients: DecreaseCoefficient[] = [];
  source: BackendStorageMock<void, DecreaseCoefficientDump, DecreaseCoefficientDump> | undefined;
  isLoading: boolean = true;
  constructor(private forecast: Forecast) {
    makeAutoObservable<DecreaseCoefficients, "init">(this, {
      coefficients: observable,
      init: action,
      createFromScratch: action,
      save: action,
    });

    when(
      () => forecast.production.isLoading === false && forecast.fact.production.isLoading === false,
      () => this.init()
    );
  }

  private init = async () => {
    this.source = decreaseCoefficientsSource(this.forecast.storageKey);

    this.source.getItem().then((results) => {
      runInAction(() => {
        if (results && results.data.length > 0) {
          try {
            this.coefficients = results.data.map(
              (coefficient) =>
                new DecreaseCoefficient(
                  coefficient.key,
                  coefficient.title,
                  coefficient.data !== null ? new Map(coefficient.data) : null
                )
            );
          } catch {
            this.createFromScratch();
          }
        } else {
          this.createFromScratch();
        }
      });
    });
    this.isLoading = false;
  };

  public setData = (parentKey: string, paramKey: string, year: number, value: number | null) => {
    this.coefficients.find((coefficient) => coefficient.key === paramKey)?.setData(year, value);
  };

  public clear = async () => {
    await this.createFromScratch();
    await this.save();
  };

  public async save() {
    if (this.source) {
      this.source.setItem({
        data: this.coefficients.map((coefficient) => coefficient.dump),
      });
    }
  }

  public async createFromScratch() {
    await getDecreaseCoefficients().then((results) => {
      this.coefficients = [];
      this.coefficients = results.map(
        (coefficient) =>
          new DecreaseCoefficient(
            coefficient.key,
            coefficient.title,
            new Map(
              new Array(this.forecast.range!.length)
                .fill(null)
                .map((_, index) => [this.forecast.range.from + index, null])
            )
          )
      );
    });
  }
}
export { DecreaseCoefficient, DecreaseCoefficients };
