import { FC, useState } from "react";
import { Button, Typography, Upload } from "antd";

import { Icon } from "elements/icon/icon";
import { req } from "utils/request";

import { ReactComponent as DownloadIcon } from "../../icons/download.svg";
import { Chart } from "../../types";

import cn from "./uploadChart.module.less";

type UploadChartProps = {
  title: string;
  onSave: (chart: Chart) => void;
};

const UploadChart: FC<UploadChartProps> = ({ title, onSave }) => {
  const [loading, setLoading] = useState<"noFile" | "uploading" | "uploaded">("noFile");

  return (
    <div className={cn.container}>
      <Typography.Text>{`${title}${loading === "uploaded" ? " (загружено)" : ""}`}</Typography.Text>
      <Upload
        name="file"
        customRequest={async ({ file }) => {
          const formData = new FormData();
          formData.append("file", file);
          setLoading("uploading");
          const res = await req.post<{ data: Chart }>("/decline/parse_excel_dc/", formData);
          onSave(res.data);
          setLoading("uploaded");
        }}
        showUploadList={false}
        maxCount={1}
      >
        <Button icon={<Icon content={<DownloadIcon />} width="16" height="16" viewBox="0 0 16 16" />} loading={loading === "uploading"}>
          Загрузить график
        </Button>
      </Upload>
    </div>
  );
};

export { UploadChart };
