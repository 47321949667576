import { FC } from "react";

import { FondType } from "models/project/fact/well/well";

import { WellPadMode } from "../Cells/WellPad";

import { ReactComponent as PressurePadIcon } from "./pressurePadIcon.svg";
import { ReactComponent as PressurePadNewIcon } from "./pressurePadNewIcon.svg";
type PadIconProps = {
  mode: WellPadMode;
  fond: FondType | undefined;
};
const PadIcon: FC<PadIconProps> = ({ mode, fond }) => {
  if (mode === "injection") {
    return fond === "Base" ? <PressurePadIcon /> : <PressurePadNewIcon />;
  }

  if (mode === "mining") {
    return <circle r={6} strokeWidth={2} stroke="rgb(77, 87, 97)" fill={fond === "Base" ? "rgb(77, 87, 97)" : "white"} />;
  }
  return <></>;
};

export { PadIcon };
