import { FC } from "react";

import { Label } from "elements/inputs/label/label";
import { ValidationInputProps } from "elements/inputs/validationInput/validationInput";

import { CustomInput } from "./customInput";
import { SettingContainer } from "./settingContainer";
import { TechnologySettingsProps } from "./settings";

import cn from "./workCriteria.module.less";

type InputProps = {
  title: string;
  id: StopCriterion;
  validationProps: ValidationProps;
};

type ValidationProps = Pick<ValidationInputProps, "min" | "max" | "accuracy" | "maximumFractionDigits" | "integer">;

type StopCriterion = "waterCut" | "oilFlowRate" | "periodOilWorkingOff";

const WorkCriteria: FC<TechnologySettingsProps> = ({ techParameters, setTechParameters }) => {
  const inputs: InputProps[] = [
    {
      title: "Обводненность, %",
      id: "waterCut",
      validationProps: {
        min: 0,
        max: 100,
      },
    },
    {
      title: "Дебит нефти, т/сут",
      id: "oilFlowRate",
      validationProps: {
        min: 0,
        max: 5,
      },
    },
    {
      title: "Период отработки на нефть, мес",
      id: "periodOilWorkingOff",
      validationProps: {
        min: 1,
        max: 999,
      },
    },
  ];

  const getOnChange = (id: StopCriterion) => (value: number | null) => {
    setTechParameters.applyForAll(["stopCriterion", id], value);
  };

  return (
    <SettingContainer title="Критерии остановки">
      <div className={cn.container}>
        {inputs.map(({ title, id, validationProps }) => (
          <Label title={title} key={title}>
            <CustomInput {...validationProps} path={`stopCriterion,${id}`} onSave={getOnChange(id)} techParameters={techParameters[0]} />
          </Label>
        ))}
      </div>
    </SettingContainer>
  );
};

export { WorkCriteria };
