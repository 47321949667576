import { Forecast } from "models/project/fact/forecast/forecast";
import { StaticSettingsData, YearsSettingsData } from "models/project/fact/forecast/ranking/settings";
import { reqCamel as req } from "utils/request";

import { provideProperties } from "./calculate/calculate";
import { constantsSchema } from "./calculate/constants";
import { extractUSCModels } from "./calculate/ecy";
import { metCoefs } from "./calculate/metCoefs";
import { operatingSchema } from "./calculate/operating";
import { backendStorage } from "./backendStorage";

type RankType = "pi" | "npv" | "irr" | "pvCapexProd" | "pvCapex" | "pbp" | "dpbp";

type TableType = "ranking" | "sequential";

type Ranks = Record<RankType, number>;

type Economic = Ranks & {
  pvCAPEX: number;
};

type Datum = {
  ranks: Ranks;
  economics: Economic;
  accumOilProd: number;
};

type Well = Datum & {
  wellId: number;
  wellTitle: string;
  mineId: number;
  mineCode: string;
};

type Intervention = Well & {
  gtmId: number;
  gtmTypeId: number;
  gtmTypeTitle: string;
};

type Mine = Datum & {
  mineId: number;
  mineCode: string;
};

type Ranking = {
  wells: Well[];
  gtms: Intervention[];
  mines: Mine[];
};

type RankingSave = {
  result: Ranking;
  drilling: RankType;
  reconstruction: RankType;
};

type SettingsType = {
  years: YearsSettingsData;
  static: StaticSettingsData;
};

const { post, args } = req;

const provideRequest = (data: Forecast) => {
  const request: any = {};

  request.constants = {
    schema: [
      ...constantsSchema(data).schema,
      {
        code_title: "Коэффициент дисконтирования",
        id: 9999,
        title: "Коэффициент дисконтирования",
        unit: {
          measure: 1,
          quantity: 3,
        },
        values: [data.ecyStore.first!.discount],
        visible: {},
      },
    ],
  };
  request.properties = {
    ...provideProperties(data),
    // hard code waiting new schema from back
    ndd_group: 3,
    ndd_year: 2021,
    invest_year: 2004,
  };
  request.schema_usc = extractUSCModels(data)[0].schema_usc;
  request.unit_costs = data.operatingCosts.optionedScalars!.data.map((v) => v.totalDump) as any;
  const opSchema = operatingSchema(data, data.licenseRegions.ids[0]);
  const metSchema = metCoefs(data);
  request.schema_operation = {
    schema: [...opSchema.schema, ...metSchema.schema],
  };

  return request;
};

const isChangedInvestCost = async (data: Forecast, projectId: number, scenarioId: number) => {
  const current = provideRequest(data);
  const saved = await getInvestCost(projectId, scenarioId);
  return JSON.stringify(current) !== JSON.stringify(saved);
};

const getRankingBack = async (forecast: Forecast) => {
  const result = await post<Ranking>(`ranking?${args({ scenarioId: forecast.id })}`, provideRequest(forecast));
  return { result, drilling: "pi" as RankType, reconstruction: "pi" as RankType };
};

// const getRankingMock = (_data: Forecast): Promise<Ranking> =>
//   delayedResolve<Ranking>(objectToCamel<Ranking>(JSON.parse(JSON.stringify(rankingMock))));

// const getRankingMock2 = (_data: Forecast): Promise<Ranking> =>
//   delayedResolve<Ranking>(objectToCamel<Ranking>(JSON.parse(JSON.stringify(rankingMock2))));

const saveSettings = async (data: SettingsType, projectId: number, scenarioId: number): Promise<SettingsType | null> =>
  await backendStorage.setItem<SettingsType>(data, "ranking_settings", projectId, scenarioId, true);

const getSettings = (projectId: number, scenarioId: number): Promise<SettingsType | null> =>
  backendStorage.getItem<SettingsType>("ranking_settings", projectId, scenarioId);

const saveRankingQueue = async (
  data: RankingSave,
  projectId: number,
  scenarioId: number
): Promise<RankingSave | null> => {
  return await backendStorage.setItem<RankingSave>(data, "ranking_sequential", projectId, scenarioId, true);
};

const getRankingQueue = async (projectId: number, scenarioId: number): Promise<RankingSave | null> =>
  backendStorage.getItem<RankingSave>("ranking_sequential", projectId, scenarioId);

const getInvestCost = async (projectId: number, scenarioId: number): Promise<any | null> =>
  backendStorage.getItem<any>("ranking_invest", projectId, scenarioId, true);

const saveInvestCost = async (data: any, projectId: number, scenarioId: number): Promise<any | null> =>
  backendStorage.setItem<any>(data, "ranking_invest", projectId, scenarioId, true);

export type { Intervention, Mine, Ranking, RankType, TableType, Well };
export {
  getInvestCost,
  getRankingBack,
  getRankingQueue,
  getSettings,
  isChangedInvestCost,
  provideRequest,
  saveInvestCost,
  saveRankingQueue,
  saveSettings,
};
