import { FC } from "react";
import { Divider, Form } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";
import { MonthPicker } from "elements/inputs/monthPicker";
import { PipeType } from "features/infrastructure/infrastructureMap/InfrastructureMapManager/InfrastructureMapManager";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { setupAccuracy } from "utils/round";

import { CalculationEmpty } from "../../ui/calculationEmpty";
import { FieldParams } from "../../ui/fieldParams";

import cn from "./selectedItem.module.less";

type Props = { selectedItem: PipeType };

const TechnologyParams: FC<Props> = observer(({ selectedItem }) => {
  const infrastructure = useInfrastructure();

  const [firstNode, secondNode] = [infrastructure.nodes.at(selectedItem.from), infrastructure.nodes.at(selectedItem.to)];
  return (
    <div className={cn.tehnologyWrapper}>
      <Form.Item name="startedAt" label="Дата ввода">
        <MonthPicker
          className={cn.date}
          placeholder="Выбрать..."
          locale={locale}
          onChange={(date) => infrastructure.pipes.update({ uuid: selectedItem.uuid, startedAt: date })}
          start={dayjs(infrastructure.range.from.toString())}
          end={dayjs(infrastructure.range.to.toString())}
        />
      </Form.Item>
      <Form.Item name="finishedAt" label="Дата выбытия">
        <MonthPicker
          className={cn.date}
          placeholder="Выбрать..."
          locale={locale}
          onChange={(date) => infrastructure.pipes.update({ uuid: selectedItem.uuid, finishedAt: date })}
          start={selectedItem.startedAt}
          end={dayjs(infrastructure.range.to.toString())}
        />
      </Form.Item>
      <div className={cn.paramsWrap}>
        <FieldParams title="Протяженность">
          <Format>{selectedItem.length}</Format> м
        </FieldParams>
        <FieldParams title="Корректирующий коэф-т на трение">{selectedItem.frictionCorrectionFactor}</FieldParams>
      </div>
      {selectedItem.category !== null && (
        <div className={cn.paramsWrap}>
          <Divider className={cn.divider} />
          <FieldParams title="Внешний диметр">{selectedItem.diameterOuter} мм</FieldParams>
          <FieldParams title="Толщина стенки">{selectedItem.thickness} мм</FieldParams>
          <FieldParams title="Шероховатость стенки">{selectedItem.roughness} мм</FieldParams>
          <FieldParams title="Категория">{selectedItem.category}</FieldParams>
        </div>
      )}
      <div className={cn.paramsWrap}>
        <Divider className={cn.divider} />
        <FieldParams title="Узел начала">{firstNode?.title}</FieldParams>
        <FieldParams title="Координаты начала">
          X <Format>{setupAccuracy(firstNode?.x || 0, "round")}</Format> Y <Format>{setupAccuracy(firstNode?.y || 0, "round")}</Format> м
        </FieldParams>
        <FieldParams title="Альтитуда начала">{firstNode?.altitude} м</FieldParams>
      </div>
      <div className={cn.paramsWrap}>
        <Divider className={cn.divider} />
        <FieldParams title="Узел конца">{secondNode?.title}</FieldParams>
        <FieldParams title="Координаты конца">
          X <Format>{setupAccuracy(secondNode?.x || 0, "round")}</Format> Y <Format>{setupAccuracy(secondNode?.y || 0, "round")}</Format> м
        </FieldParams>
        <FieldParams title="Альтитуда конца">{secondNode?.altitude} м</FieldParams>
      </div>
      <CalculationEmpty type="hydraulic" />
    </div>
  );
});

export { TechnologyParams };
