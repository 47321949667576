import { FC } from "react";
import { Scale } from "@okopok/axes_context";
import { MapTooltip } from "@okopok/axes_context";
import * as d3 from "d3";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";

import { type GridMapDataManager } from "../GridMapDataManager";
import { useHoverContext } from "../hoverManager";
import { PadIcon } from "../icons/padIcon";

import Cell from "./Cell";
import { WellPad, WellPadMode } from "./WellPad";

type CellsProps = {
  dataManager: GridMapDataManager;
  scale: Scale;
  zoom: number;
  active?: boolean;
  mode: WellPadMode;
};

const Cells: FC<CellsProps> = observer(({ dataManager, scale, zoom, active, mode }) => {
  const colorName = mode === "mining" ? "rgb(219, 68, 50)" : "rgb(100, 200, 50)";
  const color = d3.color(colorName)!;
  console.assert(color !== null, "Ошибка задания цвета");
  const colorBorder = color?.toString();
  color.opacity = 0.3;
  const colorHover = active ? color?.toString() : "rgba(0, 0, 0, 0)";
  const hoverManager = useHoverContext();
  const cells = dataManager.wells!.map((point) => {
    const cell = (
      <Cell
        onMouseEnter={() => hoverManager?.findIntersectId(point.id)}
        onMouseLeave={() => hoverManager?.reset()}
        key={point.id}
        id={point.id}
        scale={scale}
        cell={point.polygon}
        color={colorBorder}
        colorHover={colorHover}
      >
        <g style={{ transform: `translate(${scale.x(point.x)}px, ${scale.y(point.y)}px)` }}>
          <WellPad zoom={0.5} showLabel={zoom >= 1} title={point.well?.title}>
            <PadIcon mode={mode} fond={point.well?.fond} />
          </WellPad>
        </g>
      </Cell>
    );
    if (!active) {
      return cell;
    }
    return (
      <MapTooltip
        key={point.id}
        title={
          <>
            Остаточный запас: <Format>{point.balance}</Format>
          </>
        }
      >
        {cell}
      </MapTooltip>
    );
  });

  return <>{cells}</>;
});

export { Cells };
