import { FC, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useMainRouterParams } from "routing/authorizedRouter";

import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { Preloader } from "features/preloader/preloader";
import { global } from "models/global";
import { useProject } from "models/project/project";

import { SecondaryMenu } from "./secondaryMenu/secondaryMenu";
import { ProjectContextProvider } from "./projectContextProvider";

import cn from "./projectOutline.module.less";

const ProjectOutline: FC = observer(() => {
  const project = useProject();
  const params = useParams();
  const paramsParsed = useMainRouterParams();

  useEffect(() => {
    if (project?.id) {
      global.logger.setProjectId(project.id);
    }
  }, [project]);

  if (project === null) {
    if (paramsParsed.project === undefined) {
      return <FullScreenEmpty>Страница {params.project} не найдена</FullScreenEmpty>;
    }
    return <FullScreenEmpty>Не удалось найти проект с ключом {params.project}</FullScreenEmpty>;
  }
  return (
    <Preloader hooks={[useProject]}>
      <ProjectContextProvider>
        <SecondaryMenu />
        <div className={cn.main}>
          <Outlet />
        </div>
      </ProjectContextProvider>
    </Preloader>
  );
});

export { ProjectOutline };
