import { createContext, useContext, useMemo, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Tabs, TabsProps } from "antd";
import { observer } from "mobx-react";
import { SubmitButton } from "routing/outlines/submitButton/submitButton";

import { ToolbarButton } from "elements/toolbarButton/toolbarButton";
import { Preloader } from "features/preloader/preloader";
import { useForecast } from "models/project/fact/forecast/forecast";

import { CalcEffectTableModel } from "./calcEffectTable/calcEffectTableModel";
import { DecreaseCoeffsTableModel } from "./decreaseCoeffsTable/decreaseCoeffsTableModel";
import { Main } from "./main/main";

import cn from "./operatingGTM.module.less";

const TABS_ITEMS: TabsProps["items"] = [
  {
    key: "calcEffect",
    label: "Расчет эффекта",
  },
  {
    key: "decreaseCoeffs",
    label: "Понижающий коэф-т",
  },
  {
    key: "miningValue",
    label: "Объем добычи",
  },
  {
    key: "gtmAmount",
    label: "Кол-во ГТМ",
  },
  {
    key: "decreaseEffect",
    label: "Снижение эффекта от ГТМ",
  },
];

const OperatingGTMContext = createContext<
  | {
      CalcEffectTableModel: CalcEffectTableModel;
      DecreaseCoeffsTableModel: DecreaseCoeffsTableModel;
    }
  | undefined
>(undefined);

const useOperatingGTMContext = ():
  | {
      CalcEffectTableModel: CalcEffectTableModel;
      DecreaseCoeffsTableModel: DecreaseCoeffsTableModel;
    }
  | undefined => {
  const context = useContext(OperatingGTMContext);
  return context;
};

const OperatingGTM = observer(() => {
  const [currentTab, setCurrentTab] = useState<string>("calcEffect");
  const onChangeTab = (key: string) => {
    setCurrentTab(key);
  };

  const forecast = useForecast()!;
  const decreaseCoeffsStore = useMemo(() => new DecreaseCoeffsTableModel(forecast), [forecast]);
  const calcEffectStore = useMemo(() => new CalcEffectTableModel(forecast), [forecast]);

  return (
    <Preloader hooks={[useForecast, () => useForecast()?.decreaseCoefficients, () => useForecast()?.operatingGTM]}>
      <div className={cn.layout}>
        <div className={cn.header}>
          <div className={cn.tabs}>
            <Tabs defaultActiveKey={currentTab} items={TABS_ITEMS} style={{ display: "flex" }} onChange={onChangeTab} />
          </div>
          <ButtonsGroup
            model={currentTab === "calcEffect" ? calcEffectStore : currentTab === "decreaseCoeffs" ? decreaseCoeffsStore : undefined}
            currentTab={currentTab}
          />
        </div>
        <OperatingGTMContext.Provider
          value={{
            CalcEffectTableModel: calcEffectStore,
            DecreaseCoeffsTableModel: decreaseCoeffsStore,
          }}
        >
          <Main currentTab={currentTab} />
        </OperatingGTMContext.Provider>
      </div>
    </Preloader>
  );
});

const ButtonsGroup = ({ model, currentTab }: { model: CalcEffectTableModel | DecreaseCoeffsTableModel | undefined; currentTab: string }) => {
  const confirm = async () => {
    model?.clear();
  };
  return (
    <div className={cn.buttons}>
      <ToolbarButton
        tooltip={{ title: "Очистить таблицу" }}
        popconfirm={{
          title: "Очистить таблицу?",
          description: "Данные будут безвозвратно удалены!",
          onConfirm: confirm,
          onCancel: () => "",
          okText: "Да",
          cancelText: "Нет",
          placement: "bottom",
        }}
        disabled={!(currentTab === "calcEffect" || currentTab === "decreaseCoeffs")}
        icon={<DeleteOutlined />}
        danger
      />
      <SubmitButton model={model} onSubmit={model?.submit} />
    </div>
  );
};

export { OperatingGTM, useOperatingGTMContext };
