import { memo, ReactNode, useState } from "react";
import { Tooltip } from "antd";
import classNames from "classnames";

import efd from "./editableFallbackDisplay.module.less";

type Props = {
  isImport: boolean;
  children: ReactNode & { onBlur?: () => void };
  isWrapper?: boolean;
  fallbackText?: string;
  value: string | number | null;
  tooltip?: string;
};

const EditableFallbackDisplay = memo(({ children, tooltip, fallbackText, value, isImport = false, isWrapper = true }: Props) => {
  const [editing, setEditing] = useState(false);
  return (
    <Tooltip title={!value && isImport ? tooltip : undefined}>
      <div
        style={{ padding: editing || !isImport || !isWrapper ? 0 : undefined, boxSizing: editing ? undefined : "inherit" }}
        className={classNames(efd.field, !value && isImport && efd.fallbackText)}
        onClick={() => setEditing(true)}
        onFocus={() => setEditing(true)}
        onBlur={() => setEditing(false)}
        autoFocus
      >
        {isWrapper && isImport && !editing ? <>{value || fallbackText}</> : <>{children}</>}
      </div>
    </Tooltip>
  );
});

export { EditableFallbackDisplay };
