import { FC, useCallback, useState } from "react";
import { useMatches, useNavigate } from "react-router-dom";
import { MenuProps } from "antd";
import { observer } from "mobx-react";
import { useMainRouterParams } from "routing/authorizedRouter";

import { Format } from "elements/format/format";
import { CompleteIcon } from "elements/icons/complete/complete";
import { EditableLabel } from "elements/inputs/editableLabel/editableLabel";
import { Loader } from "elements/loader";
import { useCreateForecastModal } from "features/forecast/createForecastModal";
import { useForecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";
import { conditionallyArr } from "utils/conditionally";

import { MenuByItSelf } from "./menuByItSelf";
import { useForecastCopyDialog } from "./useForecastCopyDialog";

import cn from "./scenarioMenu.module.less";

type ScenarioMenuProps = {
  split?: boolean;
};

const usePagePostfix = (): string[] => {
  const matches = useMatches();
  const p = matches.at(-1)!.pathname.split("/");
  const sliceIndex = Math.min("scenario" in useMainRouterParams() ? 3 : 2, p.length - 1);
  return p.slice(sliceIndex);
};

const ScenarioMenu: FC<ScenarioMenuProps> = observer(({ split = false }) => {
  const [searchScenario, onSearchScenarioChange] = useState<string | undefined>(undefined);
  const pagePostfix = usePagePostfix();

  const onCopy = useForecastCopyDialog();
  const navigate = useNavigate();
  const params = useMainRouterParams();
  const project = useProject();
  const forecast = useForecast();

  const createForecastModal = useCreateForecastModal();
  const onForecastClick: (a: { key: string }) => void = useCallback(
    ({ key }) => {
      const path = ["", params.project, ...conditionallyArr(key !== "fact", key), ...pagePostfix].join("/");
      navigate(path);
    },
    [navigate, pagePostfix, params.project]
  );

  const forecasts: MenuProps["items"] = [
    {
      key: "fact_group",
      label: "Фактические данные",
      children: [
        {
          key: "fact",
          icon: <CompleteIcon completed={project?.fact?.isComplete ?? false} />,
          label: <Format>{project?.actualStateDate}</Format>,
        },
      ],
    },
    {
      key: "forecast_group",
      label: `Прогнозные данные ${project?.fact?.forecasts.size ?? ""}`,
      children: project?.fact?.forecasts.map(({ title, submitTitle, isComplete, id }) => ({
        label: (
          <EditableLabel
            label={title}
            onSave={submitTitle}
            onCopy={onCopy(id)}
            onDelete={async () => {
              await project?.fact?.forecasts.deleteForecast(id);
              navigate(["", params.project].join("/"));
            }}
          />
        ),
        icon: <CompleteIcon completed={isComplete} />,
        key: id,
      })) ?? [{ key: "loading", label: <Loader /> }],
    },
  ];

  const onAdd = async () => {
    const forecast = await createForecastModal();
    if (forecast) {
      project?.fact.forecasts.add(forecast);
      navigate(["", params.project, forecast.id, ...pagePostfix].join("/"));
    }
  };
  return (
    <MenuByItSelf
      title="Данные"
      addTooltip="Создать новый сценарий"
      flex={1}
      alwaysOpen
      items={forecasts}
      onNavigate={onForecastClick}
      onAdd={onAdd}
      selectedKey={`${forecast?.id ?? "fact"}`}
      search={searchScenario}
      onSearchChange={onSearchScenarioChange}
      className={split ? cn.split : ""}
    />
  );
});

export { ScenarioMenu, usePagePostfix };
