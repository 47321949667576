import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Loader } from "elements/loader";
import { useProject } from "models/project/project";

import { Tables } from "./model/tables";
import { ProducingObjectsTable } from "./producingObjectsTable";
import { StratumsTable } from "./stratumsTable";

import "@okopok/components/style.css";
import cn from "./producingObjects.module.less";

const ProducingObjects = observer(() => {
  const project = useProject()!;
  const tables = useMemo(() => new Tables(project), [project]);
  return (
    <>
      <PageFrameTitlePortal model={tables} onSave={tables.save} />
      <Loader spinning={tables.isUpdating} wrapperClassName={cn.loader} tip="Сохранение">
        <div className={cn.container}>
          <ProducingObjectsTable tables={tables} />
          <StratumsTable tables={tables} />
        </div>
      </Loader>
    </>
  );
});

export { ProducingObjects };
