import { action, computed, makeObservable, observable, when } from "mobx";

import type { RecoverableResourcesDatum, UnsavedWells, WellRaw } from "services/back/wells";
import { getWells, setBaseFondRecoverableResourses, setWells } from "services/back/wells";

import type { Fact } from "../fact";
import type { Forecast } from "../forecast/forecast";

import { Well } from "./well";

class Wells {
  public readonly wells = observable.array<Well>();
  private isLoadingValue = true;

  constructor(private readonly fact: Fact, private readonly forecast: Forecast | null = null) {
    makeObservable<Wells, "isLoadingValue" | "init" | "reset">(this, {
      wells: observable,
      isLoadingValue: observable,
      isLoading: computed,
      allWells: computed,
      miningWells: computed,
      supportWells: computed,
      selector: computed,
      hasInjectionWells: computed,
      init: action,
      reset: action,
    });

    this.init();
  }

  protected async init() {
    this.isLoadingValue = true;
    const wellsRawData = await getWells(this.fact.projectId, this.forecast?.id);
    this.reset(wellsRawData);
  }

  public async update(dataset: UnsavedWells) {
    if (this.forecast === null) {
      console.assert(
        dataset.createdWells === undefined || dataset.createdWells.length === 0,
        "Got new wells in base fond"
      );
      console.assert(
        dataset.deletedIds === undefined || dataset.deletedIds.length === 0,
        "Got deleted wells in base fond"
      );
      const toUpdate = dataset.updatedWells
        ?.map((well) => ({
          projectId: this.fact.projectId,
          wellId: well.id,
          stratumId: well.stratumId,
          recoverableResources: well.recoverableResources,
        }))
        .filter((datum): datum is RecoverableResourcesDatum => {
          const { wellId, stratumId, recoverableResources } = datum;
          return ![wellId ?? null, stratumId, recoverableResources].includes(null);
        });
      if (toUpdate && toUpdate.length > 0) {
        this.isLoadingValue = true;
        const wellsRawData = await setBaseFondRecoverableResourses(this.fact.projectId, toUpdate);
        this.reset(wellsRawData);
      }
      return;
    }
    this.isLoadingValue = true;
    const wellsRawData = await setWells(dataset, this.fact.projectId, this.forecast.id);
    this.reset(wellsRawData);
  }

  protected reset(wells: WellRaw[]) {
    this.wells.replace(wells.map((well) => new Well(well, this.fact, this.forecast)));
    this.isLoadingValue = false;
  }

  public refresh = async () => {
    const wellsRawData = await getWells(this.fact.projectId, this.forecast?.id);
    this.reset(wellsRawData);
  };

  public get isLoading(): boolean {
    return this.isLoadingValue;
  }

  public at(wellId: number): Well | null {
    return this.allWells.find(({ id }) => wellId === id) ?? null;
  }

  public get allWells(): Well[] {
    return [...this.fact.wells.wells, ...(this.forecast?.wells.wells ?? [])];
  }

  public get selector(): Array<{ value: number; label: string }> {
    return this.allWells.map(({ id, title }) => ({ value: id, label: title }));
  }

  public get miningWells(): Well[] | undefined {
    const production = (this.forecast ?? this.fact).production;
    if (production.isLoading) {
      return undefined;
    }
    return this.allWells.filter(({ id }) => production.wellStatus(id)?.isMining === true);
  }

  public get supportWells(): Well[] | undefined {
    const production = (this.forecast ?? this.fact).production;
    if (production.isLoading) {
      return undefined;
    }
    return this.allWells.filter(({ id }) => production.wellStatus(id)?.isInjecting === true);
  }

  public getWellsByType(typeOfWell: string): Well[] {
    return this.allWells.filter(({ type }) => type === typeOfWell) ?? [];
  }

  public getNewForYear(year: number): Well[] {
    return this.allWells.filter(({ date }) => date.year() === year) ?? [];
  }

  public getOldForYear(year: number): Well[] {
    return this.allWells.filter(({ date }) => date.year() !== year) ?? [];
  }

  public get hasInjectionWells(): boolean {
    return this.wells?.some(({ type }) => type === "Нагнетательная");
  }

  public async cloneInstance(forecast: Forecast) {
    await when(() => !this.isLoading);
    const clone = new Wells(this.fact, forecast);
    clone.update({
      createdWells: this.wells.map((w) => ({
        ...w.data,
        date: w.date.format("YYYY-MM-DD"),
        scenarioId: forecast.id,
      })),
    });
    return clone;
  }
}

export { Wells };
