import { createContext, type FC, useCallback, useContext } from "react";
import { Table as AntdTable } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { type Column, type Table as TableModel } from "models/table";

import cn from "./table.module.less";

const TableContext = createContext<{
  model: TableModel;
  columns: Column[];
} | null>(null);

const useTable = () => useContext(TableContext)!;

type TableProps = {
  noChild?: boolean;
  className?: string;
  rowClassName?: (record: any, index: number) => string;
};

const Table: FC<TableProps> = observer(({ noChild = false, className, rowClassName }) => {
  const context = useTable();
  console.assert(context != null, "Использование виджета таблицы вне контекста таблицы");

  const rowClassNames = useCallback(
    (record: any, index: number) => {
      const classNames = rowClassName?.(record, index) ?? "";
      const notparent = !("children" in record) ? "notparent" : "";
      return `${classNames} ${notparent}`;
    },
    [rowClassName]
  );

  return (
    <AntdTable
      className={classNames(className, cn.table, { [cn.child]: !noChild })}
      columns={context.columns}
      dataSource={context.model.tableItems}
      pagination={false}
      bordered
      rowClassName={rowClassNames}
      expandable={{ defaultExpandAllRows: true, expandRowByClick: true }}
    />
  );
});

const TableContextProvider = TableContext.Provider;

export type { TableProps };
export { Table, TableContextProvider, useTable };
