import { useRef } from "react";

import { type ModelContentComponentType, useModal } from "elements/modal/modal";
import { EventNode } from "models/project/fact/forecast/techPrediction/techPrediction";
import { useProject } from "models/project/project";

import { TechForecast } from "./techForecast";
import { WellTechForecast } from "./types";

export type TechModalState = "settings" | "loading" | "results";

const ModalContent: ModelContentComponentType<WellTechForecast[] | null, EventNode[] | undefined> = ({ accept, dataRef, reject, setOnAccept }) => {
  const stratums = useProject()!.stratums;
  if (dataRef.current === undefined) {
    reject();
    return null;
  }

  return (
    <TechForecast
      accept={accept}
      reject={reject}
      wells={dataRef.current.map(({ well: { data, forecast, pad, type, licenseRegion, stratum }, techParameters, intervention, stratumId }) => ({
        well: data,
        wellInfo: {
          bush: pad?.title ?? "",
          deposit: "",
          licenseRegion: licenseRegion ? licenseRegion.title : "",
          construction: type ?? "",
          enterDate: data,
          stratum: stratums.at(stratumId ?? -1)?.title ?? "",
          stratumId: stratumId ?? -1,
        },
        techParameters,
        gtmId: intervention?.id,
        typeName: intervention?.typeName,
        forecast,
      }))}
      setOnAccept={setOnAccept}
    />
  );
};

const useCreateTechnologyForecastModal = (): ((wellNodes: EventNode[]) => Promise<WellTechForecast[] | null>) => {
  const showModal = useModal<WellTechForecast[] | null, EventNode[] | undefined>();
  const wellNodesRef = useRef<EventNode[]>();
  return async (wellNodes) => {
    wellNodesRef.current = wellNodes;

    return showModal(ModalContent, wellNodesRef, "Настройки расчета технологических параметров", null, null, "fit-content", null, {
      padding: 0,
      fontSize: "12px",
    })
      .then((data) => data)
      .catch(() => null);
  };
};

export { useCreateTechnologyForecastModal };
