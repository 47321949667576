import { FC } from "react";

import { ReactComponent as LiquidIcon } from "../icons/liquid.svg";

import { DropRate } from "./dropRate";
import { TechnologySettingsProps } from "./settings";

const LiquidDropRate: FC<TechnologySettingsProps> = ({ techParameters, setTechParameters, chosenType, wellsNameMap }) => {
  return (
    <DropRate
      propId="liquidDropRate"
      techParameters={techParameters}
      setTechParameters={setTechParameters}
      title="Настройки темпа падения жидкости"
      icon={<LiquidIcon />}
      chosenType={chosenType}
      wellsNameMap={wellsNameMap}
    />
  );
};

export { LiquidDropRate };
