import React, { type FC, type ReactNode, useCallback, useMemo, useState } from "react";
import { Route, Routes, useMatches, useNavigate, useParams } from "react-router-dom";
import { Tabs, TabsProps } from "antd";
import classNames from "classnames";
import { SubmitButton, SubmitButtonProps } from "routing/outlines/submitButton/submitButton";

import { EllipsisTitle } from "elements/ellipsis/ellipsis";
import { useInteractiveRef } from "utils/useInteractiveRef";

import { Breadcrumb } from "./breadcrumb";
import { ToolboxPortalContextProvider } from "./pageFrameTitlePortal";

import cn from "./pageFrame.module.less";

type TabItem = NonNullable<TabsProps["items"]>[number] & {
  default?: boolean;
};

const toTabRoute = (tab: TabItem) => (
  <React.Fragment key={tab.key}>
    <Route path={tab.key} element={tab.children} />
    <Route path={`${tab.key}/*`} element={tab.children} />
    {tab.default && <Route path="" element={tab.children} />}
  </React.Fragment>
);

const useOnNavigate = (suffix?: string, tabs?: TabItem[]) => {
  const navigate = useNavigate();
  const { project, scenario } = useParams<"project" | "scenario">();

  const matches = useMatches();
  console.assert(matches.length > 1, "использование вкладок в неожиданном контексте мэтчинга");
  const pathFragments = matches.at(-1)!.pathname.split("/");
  const pageKey = pathFragments[scenario ? 3 : 2];
  const currentTab = suffix ? pathFragments.at(-1) : pathFragments[scenario ? 4 : 3];
  const activeTab = tabs?.find(({ key }) => (key.includes("/*") ? key.slice(0, -2) : key) === currentTab);

  return [
    useCallback(
      (key: string) => {
        navigate(["", project, scenario, pageKey, suffix, key].filter((segment) => segment !== undefined).join("/"));
      },
      [navigate, project, scenario, suffix, pageKey]
    ),
    tabs ? (activeTab ? currentTab : tabs.find(({ default: d }) => d)?.key) : currentTab,
  ] as const;
};

type PageFrameProps = {
  tabsClassName?: string;
  className?: string;
  bodyClassName?: string;
  title?: ReactNode;
} & ({ children: ReactNode } | { tabs: TabItem[]; tabsWidthFix?: number });

const PageFrame: FC<PageFrameProps> = ({ title, tabsClassName, className, bodyClassName, ...props }) => {
  let children;

  if ("tabs" in props) {
    console.assert(props.tabs.find(({ default: d }) => d) !== undefined, "Не указана вкладка по умолчанию");
    console.assert(props.tabs.length > 1, "Массив вкладок не должен быть пустым");
    children = <Routes>{props.tabs.map(toTabRoute)}</Routes>;
  } else {
    children = props.children;
  }

  const [onNavigate, currentTab] = useOnNavigate(undefined, "tabs" in props ? props.tabs : undefined);

  const toolboxRef = useInteractiveRef<HTMLDivElement>();
  const [submitButtonProps, setSubmitButtonProps] = useState<SubmitButtonProps | null>(null);

  const toolboxContext = useMemo(
    () => ({
      toolboxRef: toolboxRef,
      setSubmitButtonProps,
    }),
    [toolboxRef]
  );

  return (
    <div className={classNames(className, cn.container)}>
      <Breadcrumb title={title} />
      <div className={cn.header}>
        {title && <EllipsisTitle position="after">{title}</EllipsisTitle>}
        <div className={cn.controls} ref={toolboxRef} />
        {"tabs" in props && props.tabs.length !== 1 && (
          <Tabs
            style={"tabsWidthFix" in props ? { width: props.tabsWidthFix } : undefined}
            className={classNames(tabsClassName, cn.tabs)}
            activeKey={currentTab}
            onChange={onNavigate}
            items={props.tabs.map(({ key, label }) => ({ key, label }))}
          />
        )}
        {submitButtonProps !== null && <SubmitButton {...submitButtonProps} />}
      </div>
      <main className={classNames(cn.content, bodyClassName)}>
        <ToolboxPortalContextProvider value={toolboxContext}>{children}</ToolboxPortalContextProvider>
      </main>
    </div>
  );
};

export { PageFrame, type TabItem, toTabRoute, useOnNavigate };
