import { useMemo } from "react";

import { Column } from "features/tableDebug/simpleTable";

import { useInfrastructure } from "../useInfrastructure";

import { WellPads } from "./facilitiesManager/mines";

import cn from "./infrastructureFacilities.module.less";

const columns: Column[] = [
  {
    title: "Кол-во скважин, шт",
    type: "number",
    width: { min: 160, max: 200, competitiveness: 1 },
    dataKey: "numberOfWells",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Итого, тыс руб / скв",
    type: "number",
    width: { min: 200, max: 300, competitiveness: 1 },
    dataKey: "total",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "ПИР, тыс руб / скв",
    width: { min: 200, max: 300, competitiveness: 1 },
    dataKey: "dsw",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Оборудование, тыс руб / скв",
    width: { min: 220, max: 300, competitiveness: 1 },
    dataKey: "equipment",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "СМР, тыс руб / скв",
    width: { min: 200, max: 300, competitiveness: 1 },
    dataKey: "ciw",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Прочее, тыс руб / скв",
    width: { min: 200, max: 300, competitiveness: 1 },
    dataKey: "other",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
];

const useFacilitiesMinesTableModel = () => {
  const infrastructure = useInfrastructure();
  const store = useMemo(() => new WellPads(infrastructure), [infrastructure]);
  return { columns, store };
};

export { useFacilitiesMinesTableModel };
