import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, override } from "mobx";

import { type Ranking, type RankType } from "services/back/ranking";

import { WellsRow } from "./wellRow";

type DRow = {
  rank?: number;
  mineCode: string;
  wellTitle?: string;
  accumOilProd: number;
  pvCapexProd: number;
  pvCapex: number;
  pi: number;
  npv: number;
  irr: number;
  pbp: number;
  dpbp: number;
  stratumTitle?: string;
  gtm?: string;
  move?: (move: "up" | "down") => void;
};

class RankingDrilling extends TableNode<DRow, WellsRow> {
  order: RankType = "pi";
  isMoved: boolean = false;

  constructor() {
    super(null, { selectable: false, mutable: false });

    makeObservable(this, {
      order: observable,
      isMoved: observable,
      isUpdated: override,
      isManualRanking: computed,
      setOrder: action,
      initChildren: action,
      drop: action,
    });
  }

  initChildren(wells: Ranking["wells"], _mines: Ranking["mines"]) {
    const wellsByMine = new Map();
    for (const well of wells) {
      const wells = wellsByMine.get(well.mineCode) || [];
      wells.push(well);
      wellsByMine.set(well.mineCode, wells);
    }

    const wellsTree: WellsRow[] = [];
    wellsByMine.forEach((wells, mineCode) => {
      wellsTree.push(new WellsRow(this, _mines.find((el) => el.mineCode === mineCode)!, wells));
    });

    this.childrenStore = new ChildrenStoreArray(this, wellsTree);
  }

  drop() {
    this.childrenStore = undefined;
  }

  setOrder(order: RankType) {
    this.order = order;
    this.isMoved = true;
    this.sort();
  }

  private sort() {
    this.childrenStore?.sort((a, b) => b.rank - a.rank);
    for (const child of this.childrenStore?.children ?? []) {
      child.childrenStore?.sort((a, b) => b.rank - a.rank);
    }
  }

  get isManualRanking(): boolean {
    return this.isMoved;
  }

  get isUpdated(): boolean {
    if (super.isUpdated) {
      return super.isUpdated;
    }
    return this.isMoved;
  }

  public getOrder = () => {
    const order = {
      result: {
        wells: [] as Ranking["wells"],
        mines: [] as Ranking["mines"],
      },
      drilling: this.order,
    };
    for (const child of this.children ?? []) {
      order.result.mines.push(child.mine);
      order.result.wells.push(
        ...[...(child.children ?? [])].map((well) => child.wells.find(({ wellId }) => well.wellId === wellId)!)
      );
    }
    return order;
  };
}

export { RankingDrilling, type DRow as RankingDRow };
