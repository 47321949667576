import { reqCamel } from "utils/request";

import { ProjectParticipantInfo } from "./projectParticipants";

const getUsers = async (): Promise<(ProjectParticipantInfo & { title: string })[]> => {
  return (await reqCamel.get<(ProjectParticipantInfo & { title: string })[]>("users")).map(({ fullName, ...rest }) => ({
    ...rest,
    fullName,
    title: fullName,
  }));
};

export { getUsers };
