import { FC, PropsWithChildren, ReactNode } from "react";
import { Typography } from "antd";

import cn from "./projectInfoCard.module.less";

type ProjectInfoSectionProps = {
  title: string;
  icon?: ReactNode;
};

const ProjectInfoCard: FC<ProjectInfoSectionProps & PropsWithChildren> = ({ title, children, icon }) => {
  return (
    <div className={cn.card}>
      <div className={cn.header}>
        <Typography.Text type="secondary" className={cn.title}>
          {title}
        </Typography.Text>
        {icon}
      </div>
      {children}
    </div>
  );
};

export { ProjectInfoCard };
