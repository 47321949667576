import { FC } from "react";
import { Typography } from "antd";
import dayjs from "dayjs";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";
import { Icon } from "elements/icon/icon";
import { LinkButton } from "elements/linkButton/linkButton";
import { Loader } from "elements/loader";
import { global } from "models/global";
import { Project } from "models/project/project";

import { ReactComponent as ScalesIcon } from "./icons/scales.svg";
import { ReactComponent as ToIcon } from "./icons/to.svg";
import { ReactComponent as WellIcon } from "./icons/well.svg";
import { ProjectInfoCard } from "./projectInfoCard";
import { ProjectInfoSection } from "./projectInfoSection";
import { useProjectsContext } from "./projectsContextProvider";
import { UserCard } from "./userCard";

import cn from "./projectsInfo.module.less";

const { Text, Title } = Typography;

const ProjectParticipants: FC<{ project: Project }> = observer(({ project }) => {
  return project.participants.isLoading ? (
    <Loader className={cn.loader} />
  ) : (
    <>
      {project.participants.data.map(({ id }) => (
        <UserCard key={id} className={cn["user-wrapper"]} id={id} large />
      ))}
    </>
  );
});

const ProjectInfo: FC = observer(() => {
  const p = useProjectsContext();

  const lastNode = global.logger.lastNodeByProject(p.currentProject.id ?? 0);
  const getBody = () => {
    if (!p.currentProject) {
      return <>Выберите проект для отображения информации</>;
    } else {
      const { id, title, description, licenseRegions, createdBy, createdAt, scenarios, wellsBaseNumber } = p.currentProject;

      return (
        <>
          <Title level={2}>{title}</Title>
          <div>{description}</div>
          <LinkButton
            icon={<Icon content={<ToIcon />} width="14" height="14" viewBox="0 0 14 12" />}
            className={cn["link-button"]}
            to={id.toString()}
          >
            Перейти к проекту
          </LinkButton>
          <ProjectInfoSection title="Создан">
            <div className={cn["creator-container"]}>
              <UserCard id={createdBy} large />
              {createdAt && <Format dateFormat="accurate">{dayjs(createdAt)}</Format>}
            </div>
          </ProjectInfoSection>
          <ProjectInfoSection title="Последнее изменение">
            <div className={cn["creator-container"]}>
              {lastNode ? (
                <>
                  <UserCard id={lastNode.user.id} large />
                  <Format dateFormat="accurate">{dayjs(lastNode.date)}</Format>
                </>
              ) : lastNode === null ? (
                "Изменений не было"
              ) : (
                <Loader />
              )}
            </div>
          </ProjectInfoSection>
          <ProjectInfoSection title="Лицензионные участки">
            {licenseRegions.data.map(({ title, id }) => (
              <div key={id} className={cn["license-region"]}>
                <Text>{title}</Text>
                <span className={cn["license-region-deposit"]}>Месторождение</span>
              </div>
            ))}
          </ProjectInfoSection>
          <div className={cn["card-container"]}>
            <ProjectInfoCard title="Скважин БФ" icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<WellIcon />} />}>
              <Typography.Text className={cn["card-text"]}>{wellsBaseNumber}</Typography.Text>
            </ProjectInfoCard>
            <ProjectInfoCard title="Сценариев" icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ScalesIcon />} />}>
              <Typography.Text className={cn["card-text"]}>{scenarios.length}</Typography.Text>
            </ProjectInfoCard>
          </div>
          <ProjectInfoSection title="Остальные участники проекта">
            <ProjectParticipants project={p.currentProject} />
          </ProjectInfoSection>
        </>
      );
    }
  };

  return (
    <div className={cn.container}>
      <div className={cn.header}>
        <Text className={cn.title} strong>
          Информация о проекте
        </Text>
      </div>
      <div className={cn.body}>{getBody()}</div>
    </div>
  );
});

export { ProjectInfo };
