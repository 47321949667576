import dayjs, { type Dayjs } from "dayjs";

import { req, reqCamel } from "utils/request";

import type { LicenseRegionBackend, NDDGroups } from "./licenseRegions";
import { reqLogged } from "./logger";

type ScenarioRaw = {
  title: string;
  description: string;
  id: number;
  isFactual: boolean;
  isAssetAlgoNdd: boolean;
  isInfrastructure: boolean;
  isLossTransfer: boolean;
  isMajorProject: boolean;
  projectId: number;
};

type ScenarioNewRaw = Pick<ScenarioRaw, "projectId" | "title">;

/* Это объект, в котором бекенд собирает параметры проекта, которые из-за свойств ORM,
которую они используют. Так получилось, что нам из этого объекта пока почти ничего не
нужно и нужные поля перекладываются в корень объекта в appendProperties, если нужно
что-то ещё, просто добавь это там и поправь ProjectRaw и ProjectNewRaw
*/
type ProjectPropertyRaw = {
  // признаки
  infrastructural: boolean;
  isRealYears: boolean;
  lossesTransfer: boolean;
  nddAssetAlgorithm: boolean;
  // другие параметры
  preferences: "Льгот нет" | "Льготная" | "0%" | "10%";
  lz: LicenseRegionBackend[];
  nddGroup: null | NDDGroups;
  // года
  nddYear: number;
  planYear: number;
  investYear: number;
  start: number;
  stop: number;
  isAssetAlgoNdd: boolean;
  isInfrastructure: boolean;
  isLossTransfer: boolean;
  isMajorProject: boolean;
};

// объект, который реально прилетает с бекенда
type ProjectBackend = {
  title: string;
  fieldTitle: string;
  description: string;
  id: number;
  scenarios: ScenarioRaw[];
  investStartYear: number;
  actualStateDate: string;
  stratumIds: number[];
  catalogDirectionIds: number[];
  licenseZoneIds: number[];
  property: ProjectPropertyRaw;
  createdBy: number | null;
  createdAt: string | null;
  wellsBaseNumber: number | null;
};

// То, что нужно собрать от пользователя чтобы создать проект
type ProjectNewRaw = Omit<
  ProjectBackend,
  "id" | "stratumIds" | "catalogDirectionIds" | "licenseZoneIds" | "scenarios" | "property" | "lz"
> & { stop: number };

// Объект, который передаётся сервисом в модель
type ProjectRaw = Omit<ProjectBackend, "actualStateDate" | "property"> & {
  actualStateDate: Dayjs;
} & Pick<
    ProjectPropertyRaw,
    "preferences" | "stop" | "lz" | "isAssetAlgoNdd" | "isInfrastructure" | "isLossTransfer" | "isMajorProject"
  >;

const appendProperties = ({ actualStateDate, property, scenarios, ...data }: ProjectBackend): ProjectRaw => {
  const factScenario = scenarios.find(({ isFactual }) => isFactual);
  console.assert(factScenario, "Получен проект не содержащий фактических данных");
  return {
    ...data,
    scenarios,
    actualStateDate: dayjs(actualStateDate),
    preferences: property.preferences,
    stop: property.stop,
    lz: property.lz,
    isAssetAlgoNdd: property.isAssetAlgoNdd,
    isInfrastructure: property.isInfrastructure,
    isLossTransfer: property.isLossTransfer,
    isMajorProject: property.isMajorProject,
  };
};

const postProject = (data: ProjectNewRaw): Promise<ProjectRaw> =>
  (reqCamel.post<ProjectBackend, ProjectNewRaw>("projects/", data) as Promise<ProjectBackend>).then(appendProperties);

const getProjects = async (): Promise<ProjectRaw[]> => {
  const projects = ((await reqCamel.get<ProjectBackend[]>("projects/")) as ProjectBackend[]).map(appendProperties);

  // only for debug purposes
  if (projects.length === 0) {
    await postProject({
      title: "Месторождение имени В.И.Некрасова",
      fieldTitle: "Месторождение имени В.И.Некрасова",
      description: "Автоматически созданный проект",
      investStartYear: 2004,
      stop: 2054,
      actualStateDate: "2023-12-31",
      createdBy: null,
      createdAt: new Date().toString(),
      wellsBaseNumber: 250,
    });
    return getProjects();
  }

  for (const project of projects) {
    const factualScenariosAmount = project.scenarios.filter(({ isFactual }) => isFactual).length;
    console.assert(factualScenariosAmount === 1, "Ошибка API (число фактических сценариев не равно 1)");
  }

  const emptyProjects = projects.filter((project) => project.scenarios.length === 1);
  if (emptyProjects.length !== 0) {
    await Promise.all(
      emptyProjects.map(({ id }) =>
        postForecast({
          title: "Отладочный сценарий",
          projectId: id,
        })
      )
    );
    return getProjects();
  }

  return projects;
};

const getMaxFactDate = () => req.get<string>("projects/max-date-factual-well-prod/");

const getProjectProperty = async (projectId: number): Promise<ProjectPropertyRaw> => {
  return (await reqCamel.get<ProjectPropertyRaw>(`projects/${projectId}/property`)) as ProjectPropertyRaw;
};

const take = <T>(obj: Record<string, T>, ...keys: string[]): Record<string, T> => {
  const result: Record<string, T> = {};
  for (const key of keys)
    if (key in obj) {
      result[key] = obj[key];
      delete obj[key];
    }
  return result;
};

const updateProject = async (projectId: number, data: Partial<ProjectRaw>): Promise<Partial<ProjectRaw>> => {
  const requests = [];
  const result: Partial<ProjectRaw> = { ...data };
  if (
    data.preferences !== undefined ||
    data.lz !== undefined ||
    data.isAssetAlgoNdd !== undefined ||
    data.isInfrastructure !== undefined ||
    data.isLossTransfer !== undefined ||
    data.isMajorProject !== undefined
  ) {
    requests.push(
      reqCamel.patch(
        `projects/${projectId}/property`,
        take(data, "preferences", "lz", "isAssetAlgoNdd", "isInfrastructure", "isLossTransfer", "isMajorProject")
      )
    );
  }
  if (Object.keys(data).length > 0) {
    requests.push(reqCamel.patch(`projects/${projectId}`, data));
  }
  await Promise.all(requests);

  // тут девяти-этапная концепция обновления разбилась о мою лень. Ответ с бека по уму конечно игнорироваться не должен
  return result;
};

const postForecast = (data: ScenarioNewRaw) => reqLogged.post<ScenarioRaw, ScenarioNewRaw>("scenarios/", data);
const pathForecastTitle = (scenarioId: number, newTitle: string) =>
  reqCamel.patch<ScenarioRaw>(`scenarios/${scenarioId}`, { title: newTitle });
const deleteForecast = (scenarioId: number) => reqCamel.delete<ScenarioRaw>(`scenarios/${scenarioId}`);

const deleteProject = (projectId: number) =>
  reqCamel.delete<Pick<ProjectBackend, "title" | "id">>(`projects/${projectId}`);

const scenarioCopy = (scenarioId: number, title: string, description: string) =>
  reqLogged.post<ScenarioRaw>("scenarios/copy", { scenarioId, title, description });

export {
  deleteForecast,
  deleteProject,
  getMaxFactDate,
  getProjectProperty,
  getProjects,
  pathForecastTitle,
  postForecast,
  postProject,
  type ProjectNewRaw,
  type ProjectRaw,
  scenarioCopy,
  type ScenarioNewRaw,
  type ScenarioRaw,
  updateProject,
};
