import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable } from "mobx";

import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { NodeType } from "models/project/fact/infrastructure/nodes";
import { Well as WellType } from "models/project/fact/well/well";

type DRow = {
  coords?: { x: number; y: number };
  startedAt: Dayjs;
  well?: string;
  title: string;
  altitude?: number;
  cost?: number;
};

class Well extends TableNode<DRow> {
  public asDRow = (): DRow => ({
    well: this.data.title,
    startedAt: this.data.date,
    title: this.parent.data.title,
  });
  public readonly data: WellType;

  constructor(private parent: WellPad, data: WellType) {
    super(parent);

    this.data = data;
    makeObservable(this, {
      data: observable,
    });
  }
}

class WellPad extends TableNode<DRow, Well> {
  public asDRow = (): DRow => ({
    ...this.data,
    coords: this.coords,
    startedAt: this.startedAt,
  });
  constructor(private parent: WellPads, public data: NodeType, private wells: WellType[]) {
    super(parent, { isExpandedChildren: true });

    makeObservable(this, {
      coords: computed,
      startedAt: computed,
      initChildren: action,
      updateValue: action,
    });
    this.initChildren();
  }

  initChildren = () => {
    this.childrenStore = new ChildrenStoreArray(
      this,
      this.wells.map((el) => new Well(this, el))
    );
  };

  get coords() {
    const { x, y } = this.data;
    return { x, y };
  }

  get startedAt() {
    return this.wells.sort((a, b) => (a.date.isBefore(b.date) ? -1 : 1))[0].date;
  }

  updateValue(key: any, newValue: any): [prevValue: any, currValue: any] {
    const [k, value] = [key as keyof NodeType, newValue as never];
    if (key === "altitude") {
      value && this.parent.infrastructure.nodes.update({ ...this.data, [k]: value });
      const prev = this.data[k];
      this.data[k] = value;
      return [prev, value];
    }
    return [undefined, undefined];
  }
}

class WellPads extends TableNode<DRow, WellPad> {
  constructor(public infrastructure: Infrastructure) {
    super();

    this.initChildren();
  }

  private initChildren = () => {
    const wells = this.infrastructure.wells || [];
    const nodes = this.infrastructure.nodes.data || [];

    const wellsMap = new Map<number, WellType[]>();
    for (const well of wells) {
      if (wellsMap.get(well.mineId)) {
        wellsMap.set(well.mineId, [...wellsMap.get(well.mineId)!, well]);
      } else {
        wellsMap.set(well.mineId, [well]);
      }
    }

    const mines = nodes.filter((mine) => wellsMap.get(mine.mineId!));
    this.childrenStore = new ChildrenStoreArray(
      this,
      mines.map((el) => new WellPad(this, el, el.mineId ? wellsMap.get(el.mineId) || [] : []))
    );
  };
}

export { type DRow as DRowMines, WellPads as WellPadsModel };
