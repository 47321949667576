import { UploadFile } from "antd";
import { objectToCamel } from "ts-case-convert";

import { req, reqCamel } from "utils/request";

import { reqLogged } from "./logger";

type MineRaw = {
  id: number;
  code: string;
  title: string;
  fieldId: number;
  wells: WellRaw[];
};

type Mine = Omit<MineRaw, "wells" | "fieldId"> & { field: Field };

type FieldRaw = {
  id: number;
  code: string;
  title: string;
  mines: MineRaw[];
};

type Field = Omit<FieldRaw, "mines">;

type Well = Omit<WellRaw, "mineId"> & { mine: Mine };

type WellRaw = {
  id: number;
  title: string;
  mineId: number;
  isFactual: boolean;

  code: string | null;
  stratumId: number | null;
  wellTypeId: number | null;

  topX: number | null;
  topY: number | null;
  botX: number | null;
  botY: number | null;

  date: string | null;

  md: number | null;
  oilRate: number | null;
  liquidRate: number | null;
  waterCut: number | null;
  recoverableResources: number | null;

  licenseZoneId: number | null;

  stratumIds: number[];
  byStratums: {
    [stratumId: number]: {
      oilRate: number | null;
      liquidRate: number | null;
      waterCut: number | null;
      recoverableResources: number | null;
    };
  };
};

type WellUnsavedBackend = Omit<WellRaw, "id" | "stratumIds" | "byStratums"> & {
  scenarioId: number;
};

type WellParsedBackend = Omit<WellUnsavedBackend, "title" | "code" | "mineId" | "stratumId" | "wellTypeId"> & {
  id: number;

  wellId: number | null;
  wellTitle: string | null;

  wellTypeId: number | null;
  wellTypeTitle: string | null;

  producingObjectIdAfter: number | null;
  producingObjectTitleAfter: string | null;

  stratumId: number | null;
  stratumTitle: string | null;

  mineId: number | null;
  mineCode: string | null;

  licenseZoneTitle: string | null;
};

type OmitedFields = "scenarioId" | "isFactual" | "code";
type WellUnsaved = { id: number | undefined } & Omit<WellUnsavedBackend, OmitedFields>;
type WellParsedRaw = { id: undefined } & Omit<WellParsedBackend, "id" | OmitedFields>;

const toParsedWell = ({ id, ...d }: WellParsedBackend): WellParsedRaw => ({
  ...d,
  id: undefined,
});
const toBackendWell = ({ id, ...d }: WellUnsaved, scenarioId: number): WellUnsavedBackend => ({
  ...d,
  isFactual: false,
  code: null,
  scenarioId,
});

async function getWells(projectId: number, scenarioId?: number): Promise<WellRaw[]> {
  const path = `wells/?${reqCamel.args({ projectId, scenarioId })}`;
  const rawWells = await reqCamel.get<WellRaw[]>(path);
  return rawWells
    .map((well) => {
      if (scenarioId === undefined) {
        const stratumIds = [...Object.keys(well.byStratums)].map((v) => parseInt(v));
        well.stratumId = stratumIds[0] ?? null;
        well.stratumIds = stratumIds;
      }
      return well;
    })
    .sort((a, b) => a.title.localeCompare(b.title));
}

type UnsavedWells = {
  createdWells?: WellUnsaved[];
  updatedWells?: WellUnsaved[];
  deletedIds?: number[];
};

type RecoverableResourcesDatum = {
  projectId: number;
  wellId: number;
  stratumId: number;
  recoverableResources: number;
};

// only for base fond
async function setBaseFondRecoverableResourses(
  projectId: number,
  dataset: RecoverableResourcesDatum[]
): Promise<WellRaw[]> {
  await reqCamel.post<Array<RecoverableResourcesDatum & { id: number }>>(`wells/recoverable_resources/`, dataset);
  return getWells(projectId);
}

// only for new fond
async function setWells(
  { createdWells, updatedWells, deletedIds }: UnsavedWells,
  projectId: number,
  forecastId: number
): Promise<WellRaw[]> {
  await deleteWells(deletedIds ?? []);
  await Promise.all([createWells(createdWells ?? [], forecastId), updateWells(updatedWells ?? [], forecastId)]);
  return getWells(projectId, forecastId);
}

async function deleteWells(ids: number[]) {
  return Promise.all(ids.map((id) => reqCamel.delete(`wells/${id}`)));
}

async function createWells(data: WellUnsaved[], forecastId: number) {
  if (forecastId === undefined) {
    console.error("Попытка создать скважину не в прогнозе");
    return Promise.reject();
  }
  return Promise.all(
    data.map((d) => {
      return reqLogged.post(`wells/`, toBackendWell(d, forecastId));
    })
  );
}

async function updateWells(data: WellUnsaved[], forecastId: number) {
  return Promise.all(
    data.map((d) => {
      console.assert(d.id !== undefined, `Изменение скважины без id: ${d}`);
      return reqCamel.put(`wells/${d.id}`, toBackendWell(d, forecastId));
    })
  );
}

type WellTypeBackend = {
  id: number;
  title: string;
};

async function getWellTypes(): Promise<WellTypeBackend[]> {
  return await reqCamel.get<WellTypeBackend[]>("wells/types/");
}

const parseExcelFile = async (file: UploadFile, forecastId: number): Promise<WellParsedRaw[]> => {
  const formData = new FormData();
  formData.append("file", file as unknown as Blob);

  try {
    const response = await req
      .post<WellParsedBackend[]>(`wells/parse_excel/?${reqCamel.args({ scenarioId: forecastId })}`, formData)
      .then(objectToCamel);

    return response.map(toParsedWell);
  } catch (err) {
    throw new Error("invalid file format");
  }
};

const getFields = async () => {
  return await reqCamel.get<FieldRaw[]>(`fields/?load_info=true`);
};

const getAllWells = async () => {
  const fields = await getFields();
  const fieldsInfo: Field[] = [];
  const minesInfo: Mine[] = [];
  const wellsInfo: Well[] = [];
  for (const { id: fId, code: fCode, title: fTitle, mines } of fields) {
    fieldsInfo.push({ id: fId, code: fCode, title: fTitle });
    for (const { id: mId, code: mCode, title: mTitle, wells, fieldId } of mines) {
      minesInfo.push({ id: mId, code: mCode, title: mTitle, field: fieldsInfo.find(({ id }) => fieldId === id)! });
      for (const { mineId, ...restWell } of wells) {
        wellsInfo.push({ ...restWell, mine: minesInfo.find(({ id }) => mineId === id)! });
      }
    }
  }

  return wellsInfo;
};

export type {
  RecoverableResourcesDatum,
  UnsavedWells,
  Well,
  WellParsedRaw,
  WellRaw,
  WellTypeBackend as WellType,
  WellUnsaved,
};
export { getAllWells, getFields, getWells, getWellTypes, parseExcelFile, setBaseFondRecoverableResourses, setWells };
