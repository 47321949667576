import { useMemo } from "react";
import { ColumnRaw, TableContextProvider, TableItem, TableModel, Widget } from "@okopok/components/Table";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import classNames from "classnames";
import { observer } from "mobx-react";

import { EllipsisTable as Ellipsis } from "elements/ellipsis/ellipsis";
import { Format } from "elements/format/format";
import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { useForecast } from "models/project/fact/forecast/forecast";

import { useOperatingGTMContext } from "../operatingGTM";

import { DRow } from "./calcEffectTableModel";

import cn from "./calcEffectTable.module.less";

const isDisabled = (tableItem: TableItem<DRow>, cellIndex: number): boolean => {
  if (tableItem.value?.parentDataKey === "miningWells" && !(tableItem.value.dataKey === "miningWells-5" && cellIndex === 0)) {
    return true;
  }
  if (tableItem.value?.parentDataKey === "pumpingWells") {
    return true;
  }
  if (tableItem.value?.title === "Переходящая доп. добыча от ГТМ") {
    return true;
  }
  if (tableItem.value?.title === "Переходящая доп. добыча от ФХ МУН") {
    return true;
  }
  if (tableItem.value?.title === "Коэффициент снижения эффекта ГТМ во времени" && cellIndex < 3) {
    return true;
  }
  if (
    tableItem.value?.parentDataKey &&
    ["otherGTM-s", "otherGTM-p", "otherGTM-pa", "otherGTM-o", "otherGTM-l", "otherGTM-pr", "otherGTM-se"].includes(tableItem.value?.parentDataKey) &&
    tableItem.value.dataKey !== "otherGTM-i" &&
    cellIndex > 2
  ) {
    return true;
  }

  return false;
};

const useColumns = (): ColumnRaw<DRow>[] => {
  const forecast = useForecast();

  return useMemo(
    (): ColumnRaw<DRow>[] => [
      {
        key: "index",
        title: "No.пп",
        isSticky: true,
        width: { min: 52, max: 52, competitiveness: 1 },
        render: (_, { absoluteIndex }) => <div style={{ width: "100%", textAlign: "right" }}>{absoluteIndex ?? 0}</div>,
      },
      {
        key: "expand",
        title: null,
        isSticky: true,
        width: { min: 34, max: 34, competitiveness: 1 },
        render: (_, tableItem) => <ExpandButton expand={tableItem.expand} />,
      },
      {
        dataKey: "title",
        title: "Параметр",
        isSticky: true,
        width: { min: 380, max: 380, competitiveness: 1 },
        render: (value, tableItem) => <Ellipsis value={value} row={{ level: tableItem.indexPath.length }} />,
        onCell: ({ indexPath }) => ({ style: { paddingLeft: indexPath.length * 15 } }),
      },
      {
        dataKey: "measure",
        title: "Ед. измерения",
        isSticky: true,
        width: { min: 130, max: 130, competitiveness: 1 },
      },
      {
        key: "accuracy",
        title: "Точность",
        isSticky: true,
        width: { min: 80, max: 80, competitiveness: 1 },
        render: (value: number | undefined, tableItem: TableItem<DRow>) =>
          tableItem.expand === undefined ? (
            <InputNumber variant="borderless" min={0} max={3} value={value} onUpdate={(value) => tableItem.update?.("accuracy", value)} />
          ) : null,
      },
      ...[...forecast!.range].map((year, index) => ({
        dataKey: year,
        title: `${year}`,
        width: { min: 130, max: 130, competitiveness: 1 },
        render: (value: number | undefined, tableItem: TableItem<DRow>) =>
          tableItem.expand === undefined ? (
            isDisabled(tableItem, index) ? (
              <Format>{(tableItem.value?.accuracy && value ? Number(value?.toFixed(tableItem.value?.accuracy)) : value) ?? null}</Format>
            ) : (
              <InputNumber
                variant="borderless"
                min={0}
                value={
                  value && tableItem.value?.accuracy !== null
                    ? tableItem.value?.accuracy === 0
                      ? Math.round(value)
                      : Number(value?.toFixed(tableItem.value?.accuracy))
                    : value
                }
                onUpdate={(value) => tableItem.update?.(year, value)}
              />
            )
          ) : null,
        onCell: (tableItem: TableItem<DRow>) => ({
          className: isDisabled(tableItem, index) && tableItem.expand === undefined ? cn.tableCellLock : "",
        }),
      })),
    ],
    [forecast]
  );
};
const CalcEffectTable = observer(() => {
  const store = useOperatingGTMContext()!.CalcEffectTableModel;

  const columns = useColumns();

  const model = useMemo(() => {
    const model = new TableModel(
      columns,
      store,
      {
        onRow: (tableItem) => ({
          className: classNames([
            tableItem.expand === undefined ? cn.tableRowPlain : tableItem.indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
          ]),
        }),
      },
      {
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }
    );
    return model;
  }, [store, columns]);

  return (
    <TableContextProvider value={model}>
      <Widget headerClassName={cn.tableHeader} />
    </TableContextProvider>
  );
});

export { CalcEffectTable };
