import dayjs from "dayjs";
import { action, computed, makeObservable, observable, when } from "mobx";

import { global } from "models/global";
import type { InterventionRaw, InterventionsUnsaved } from "services/back/interventions";
import { getInterventions, setInterventions } from "services/back/interventions";
import { Range } from "utils/range";

import { Forecast } from "../forecast";

import { Intervention } from "./intervention";

class Interventions {
  public readonly interventions = observable.array<Intervention>();
  private isLoadingValue: boolean = true;

  constructor(public readonly forecast: Forecast) {
    makeObservable<Interventions, "isLoadingValue" | "init" | "reset">(this, {
      isLoadingValue: observable,
      isLoading: computed,
      init: action,
      reset: action,
      update: action,
    });
    this.init();
  }

  public get isLoading(): boolean {
    return this.isLoadingValue;
  }

  private async init() {
    this.isLoadingValue = true;
    const interventions = await getInterventions(this.forecast.id);
    this.reset(interventions);
  }

  public async update(interventions: InterventionsUnsaved) {
    this.isLoadingValue = true;
    this.reset(await setInterventions(this.forecast.id, interventions));
  }

  private reset(interventionsRaw: InterventionRaw[]) {
    this.interventions.replace(interventionsRaw.map((d) => new Intervention(this.forecast, d)));
    this.isLoadingValue = false;
  }

  public get safelyData(): Intervention[] | undefined {
    return this.interventions?.filter(({ well }) => well);
  }

  public get perTypes(): Array<Intervention[]> | undefined {
    if (this.isLoading || global.interventionsTypes.isLoading === undefined) {
      return undefined;
    }

    const result = new Map<number, Intervention[]>(
      Array.from(global.interventionsTypes.values!).map(({ id }) => [id, []])
    );
    for (const intervention of this.interventions) {
      if (intervention.well) {
        result.get(intervention.data.gtmTypeId!)?.push(intervention);
      }
    }
    return [...result.values()];
  }

  public countByType(years: Range, requiredTypeId: number): number[] | undefined {
    if (this.isLoading) {
      return undefined;
    }
    const result = [...years];
    for (const {
      data: { gtmTypeId, date },
    } of this.interventions) {
      result[dayjs(date).year() - years.from] += requiredTypeId === gtmTypeId ? 1 : 0;
    }
    return result;
  }

  public getInterventionsByWellId(targetWellId: number): Intervention[] {
    return this.interventions.filter(({ data: { wellId } }) => wellId === targetWellId) ?? [];
  }

  public async cloneInstance(forecast: Forecast): Promise<Interventions> {
    const result = new Interventions(forecast);
    await when(() => !result.isLoading);
    result.update({ createdInterventions: this.interventions.map(({ data }) => data) });
    return result;
  }
}

export { Interventions };
