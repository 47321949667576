import { createContext, type FC, useContext, useMemo } from "react";
import { Radio } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useResultModel } from "routing/outlines/result/resultPage";
import { ResultPageTitlePortal } from "routing/outlines/result/resultPageTitlePortal";

import { Card } from "elements/card/card";
import { CalculationMode, SummaryModel } from "models/summary";

import { ExpensesChart } from "./expensesChart/expensesChart";
import { IncomingPartChart } from "./incomingPartChart/incomingPartChart";
import { MiningOil } from "./miningOil/miningOil";
import { MiningOilGas } from "./miningOilGas/miningOilGas";
import { Donut } from "./Donut";

import styles from "./summaryPage.module.less";

export const SummaryContext = createContext<SummaryModel | null>(null);

const SummaryPage: FC = observer(function SummaryPage() {
  const result = useResultModel();
  const model = useMemo(() => new SummaryModel(result), [result]);

  return (
    <SummaryContext.Provider value={model}>
      <ResultPageTitlePortal>
        <Radio.Group onChange={model.handleModeChange} value={model?.mode} buttonStyle="solid">
          <Radio.Button value={CalculationMode.Full}>
            {model.ranges[0].from}-{model.ranges[0].to}
          </Radio.Button>
          <Radio.Button value={CalculationMode.Forecast}>
            {model.ranges[1].from ?? 0 + 1}-{model.ranges[1].to}
          </Radio.Button>
        </Radio.Group>
      </ResultPageTitlePortal>
      <div className={styles.wrap}>
        <div className={styles.body}>
          <Donut></Donut>
          <Card title="Доходная часть" className={classNames(styles.card)}>
            <IncomingPartChart model={model} />
          </Card>
          <Card title="Добываемые УВ" className={classNames(styles.card)}>
            <div className={styles.leftDown}>
              <MiningOil model={model} />
              <MiningOilGas model={model} />
            </div>
          </Card>
          <Card title="Динамика расходов" className={classNames(styles.rightDown, styles.card)}>
            <ExpensesChart model={model} />
          </Card>
        </div>
      </div>
    </SummaryContext.Provider>
  );
});

const useSummaryContext = () => {
  const context = useContext(SummaryContext);
  if (context === null) {
    console.assert("Контекст не указан");
  }
  return context!;
};

export { SummaryPage };
export { useSummaryContext };
