import { LineDataModel } from "@okopok/axes_context";
import { makeObservable, observable } from "mobx";

import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { colorCarousel } from "services/colorCarousel";

import { TechFactResultAxisInfo } from "./techFactResultChart";

class TechFactChartModel {
  tooltipManager: TooltipDataManager;
  curveFactOil: LineDataModel;
  curveFactLiquid: LineDataModel;
  curveFactWatering: LineDataModel;

  constructor(oil: TechFactResultAxisInfo, liquid: TechFactResultAxisInfo, waterCut: TechFactResultAxisInfo) {
    makeObservable(this, {
      curveFactOil: observable,
      curveFactLiquid: observable,
      curveFactWatering: observable,
      tooltipManager: observable,
    });

    this.curveFactLiquid = new LineDataModel({
      y: liquid.curveFactData?.y ?? [],
      x: liquid.curveFactData?.x ?? [],
      axisKey: "yLiquid",
      key: "curveFactLiquid",
      color: colorCarousel(2),
      title: "Дебит жидкости, м³/сут",
    });

    this.curveFactOil = new LineDataModel({
      y: oil.curveFactData?.y ?? [],
      x: oil.curveFactData?.x ?? [],
      axisKey: "yOil",
      key: "curveFactOil",
      color: colorCarousel(4),
      title: "Дебит нефти, т/сут",
    });

    this.curveFactWatering = new LineDataModel({
      y: waterCut.curveFactData?.y ?? [],
      x: waterCut.curveFactData?.x ?? [],
      axisKey: "yWatering",
      key: "curveFactWatering",
      color: colorCarousel(1),
      title: "Обводненность (об.), %",
    });

    this.tooltipManager = new TooltipDataManager([this.curveFactLiquid, this.curveFactOil, this.curveFactWatering]);
  }
}

export { TechFactChartModel };
