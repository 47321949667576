import { FC, KeyboardEventHandler, memo, MouseEventHandler, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import type { SelectProps } from "antd";
import { Button as AntdButton, Select, Tooltip } from "antd";

import { Icon } from "elements/icon/icon";
import { colorPrimary } from "theme/global";
import { useBooleanState } from "utils/useBooleanState";

import { ReactComponent as LikeIcon } from "./likeIcon.svg";
import { ReactComponent as PlayIcon } from "./playIcon.svg";
import { ReactComponent as RefreshIcon } from "./refreshIcon.svg";
import { ReactComponent as StepLeftIcon } from "./stepLeft.svg";
import { ReactComponent as StepRightIcon } from "./stepRight.svg";
import { ReactComponent as StopIcon } from "./stopIcon.svg";
import { ReactComponent as TimeIcon } from "./timeIcon.svg";

import cn from "./player.module.less";
type PlayerProps = {
  count: number;
  onCurrentChange?: (value: number) => void;
  current: number;
  onLike?: (wellIndex: number) => void;
  isLiked?: boolean;
};

type LabelRender = SelectProps["labelRender"];
const TimeoutLabelRender: LabelRender = (props: { label: any; value: any }) => {
  const { label, value } = props;

  if (label) {
    return value;
  }
  return (
    <span className={cn.selectLabel}>
      <Icon viewBox="0 0 16 16" width="16" height="16" content={<TimeIcon />}></Icon>
      <span className={cn.text}>
        <span>{value}</span>
        <span className={cn.grey}>сек</span>
      </span>
    </span>
  );
};

const TIMEOUT_OPTIONS = [1, 2, 5, 7, 10, 15, 20, 30].map((value) => ({ value }));

type ButtonProps = {
  icon: FC;
  onClick: () => void;
  type?: "primary";
  children?: ReactNode;
  iconFillRule?: "nonzero" | "evenodd";
  disabled?: boolean;
};

const Button: FC<ButtonProps> = memo(({ icon: IconWgt, onClick, type, children, iconFillRule, disabled }) => {
  const onClickWrapped: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if ("pointerType" in event.nativeEvent && event.nativeEvent.pointerType === "mouse") {
        onClick();
      }
    },
    [onClick]
  );

  const iconStyle = useMemo(() => ({ fillRule: iconFillRule }), [iconFillRule]);

  return (
    <AntdButton
      onClick={onClickWrapped}
      type={type}
      disabled={disabled}
      icon={<Icon style={iconStyle} viewBox="0 0 14 14" width="16" height="16" content={<IconWgt />} />}
    >
      {children}
    </AntdButton>
  );
});

const PREVENT_DEFAULT: KeyboardEventHandler<unknown> = (event) => event.preventDefault();

const ANIMATION_TICK = 200;

const Player: FC<PlayerProps> = ({ count, current, onCurrentChange, onLike, isLiked }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPlaying, _play, pause, switchPlay] = useBooleanState();
  const [hovered, setHoverIn, setHoverOut] = useBooleanState(false);
  const [timeOut, setTimeOut] = useState<number>(TIMEOUT_OPTIONS[3].value);
  const [progress, setProgress] = useState<number>(0);
  const incCurrent = useCallback(() => {
    onCurrentChange?.(current + 1 >= count ? 0 : current + 1);
  }, [count, current, onCurrentChange]);
  const decCurrent = useCallback(() => {
    onCurrentChange?.(current === 0 ? count - 1 : current - 1);
  }, [count, current, onCurrentChange]);
  const keyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      event.stopPropagation();

      if (event.code === "ArrowRight") {
        incCurrent();
      }
      if (event.code === "ArrowLeft") {
        decCurrent();
      }
      if (event.code === "Space") {
        switchPlay();
      }
      if (event.code === "KeyS") {
        if (onLike !== undefined) onLike(current);
      }
    },
    [current, decCurrent, incCurrent, onLike, switchPlay]
  );

  useEffect(() => {
    setProgress(0);
  }, [current]);

  useEffect(() => {
    window.document.body.addEventListener("keydown", keyDownHandler);
    return () => {
      window.document.body.removeEventListener("keydown", keyDownHandler);
    };
  }, [current, keyDownHandler]);
  useEffect(() => {
    const step = () => {
      if (isPlaying) {
        if (progress >= 100) {
          incCurrent();
        } else {
          setProgress(progress + 100 / ((timeOut * 1000) / ANIMATION_TICK));
        }
      }
    };
    const progressTimeOut = setInterval(step, ANIMATION_TICK);
    return () => {
      clearInterval(progressTimeOut);
    };
  }, [isPlaying, incCurrent, timeOut, progress]);

  const onResetTimeout = useCallback(() => {
    setTimeOut(TIMEOUT_OPTIONS[3].value);
  }, []);

  if (count === 1) {
    return null;
  }

  const currentPercent = (current / (count - 1)) * 95 + 2.5;

  return (
    <>
      <div className={cn.playerContainer}>
        <div className={cn.btnGroup}>
          <div className={cn.switch}>
            <Button type="primary" onClick={switchPlay} icon={isPlaying ? StopIcon : PlayIcon}>
              Пробел
            </Button>
            <Button icon={StepLeftIcon} onClick={decCurrent}>
              Клавиша ←
            </Button>
            <Button icon={StepRightIcon} onClick={incCurrent}>
              Клавиша →
            </Button>
          </div>
          <div className={cn.timerGroup} style={{ "--animation-tick": `${ANIMATION_TICK}ms`, "--progress": `${progress}%` } as React.CSSProperties}>
            <Select
              suffixIcon={null}
              className={cn.timeOut}
              labelRender={TimeoutLabelRender}
              options={TIMEOUT_OPTIONS}
              value={timeOut}
              onChange={setTimeOut}
              onKeyDown={PREVENT_DEFAULT}
            ></Select>
            <Button icon={RefreshIcon} disabled={timeOut === TIMEOUT_OPTIONS[3].value} onClick={onResetTimeout} />
          </div>
        </div>
        <div>
          <Button
            iconFillRule={isLiked ? "nonzero" : "evenodd"}
            icon={LikeIcon}
            disabled={onLike === undefined || isLiked === undefined}
            onClick={() => onLike?.(current)}
          >
            <span>
              {" "}
              <span>Отметить</span> <span className={cn.grey}>Клавиша S</span>
            </span>
          </Button>
        </div>
      </div>
      <div className={cn.progressBar}>
        <div
          className={cn.progress}
          onMouseOver={setHoverIn}
          onMouseLeave={setHoverOut}
          style={{
            background: `linear-gradient(90deg, ${colorPrimary} 0%, ${colorPrimary} calc(${currentPercent}% - 1px), #f0f0f0 calc(${currentPercent}% - 1px), #f0f0f0 100%)`,
            height: hovered ? 4 : 2,
            top: hovered ? -2 : 0,
          }}
        ></div>
        <Tooltip title={`${current + 1} из ${count}`} placement={"left"}>
          <div
            className={cn.circle}
            onMouseOver={setHoverIn}
            onMouseLeave={setHoverOut}
            style={{ left: `calc(${currentPercent}% - 7px)`, opacity: hovered ? 1 : 0 }}
          ></div>
        </Tooltip>
      </div>
    </>
  );
};

export { Player };
