import { useMemo } from "react";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { useForecast } from "models/project/fact/forecast/forecast";
import { Summary } from "models/project/fact/forecast/ranking/results/summary";
import { useRankingResults } from "pages/ranking/rankingResultsContext";

import { PlanningChart } from "./chart/planningChart";
import { Crews } from "./models/crews";
import { Events } from "./models/events";
import { Expenses } from "./models/expenses";

import cn from "./planningCharts.module.less";

const PlanningCharts = observer(() => {
  const forecast = useForecast()!;
  const results = useRankingResults();
  const store = useMemo(() => new Summary(forecast), [forecast]);

  const eventsModel = useMemo(() => new Events(store), [store]);
  const expensesModel = useMemo(() => new Expenses(store), [store]);
  const crewModel = useMemo(() => new Crews(store), [store]);
  return (
    <>
      <PageFrameTitlePortal model={results} submitting={results.isSaving} onSave={results.save} />
      <div className={cn.layout}>
        <PlanningChart chartModel={expensesModel} />
        <PlanningChart chartModel={eventsModel} />
        <PlanningChart chartModel={crewModel} />
      </div>
    </>
  );
});

export { PlanningCharts };
