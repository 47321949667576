import { action, computed, makeAutoObservable, runInAction, when } from "mobx";

import { aggregateByDate } from "models/project/fact/production/aggregate";
import { sumUp } from "models/project/fact/production/aggregateFunctions";
import { ProductionDatum } from "models/project/fact/production/production";
import { BackendStorageMock } from "services/back/backendStorage";
import { getOperatingGTM } from "services/back/operatingGTM";
import { OperatingGTMDump } from "services/operatingGTM";
import { Range } from "utils/range";

import { Forecast } from "../forecast";

const operationGTMSource = (key: { fact: number; forecast?: number }) => {
  return new BackendStorageMock<void, OperatingGTMDump>(
    `OperatingGTMSource_${key.fact}_${key.forecast}`,
    key.fact,
    key.forecast
  );
};

class OperatingGTMParam {
  public title: string;
  public dataKey: string;
  public measure: string | null;
  public accuracy: number | null;
  public data: Map<number, number | null> | null;
  public children: Map<string, OperatingGTMParam> | null;
  constructor(
    title: string,
    dataKey: string,
    measure: string | null,
    accuracy: number | null,
    children: Map<string, OperatingGTMParam> | null,
    data: Map<number, number | null> | null
  ) {
    makeAutoObservable<OperatingGTMParam>(this);
    this.title = title;
    this.dataKey = dataKey;
    this.measure = measure;
    this.accuracy = accuracy;
    this.children = children;
    this.data = data;
  }

  get dump(): {
    title: string;
    dataKey: string;
    measure: string | null;
    accuracy: number | null;
    data: [number, number | null][] | null;
  } {
    return {
      title: this.title,
      dataKey: this.dataKey,
      measure: this.measure,
      accuracy: this.accuracy,
      data: this.data ? Array.from(this.data.entries()) : null,
    };
  }
}

class OperatingGTM {
  params: Map<string, OperatingGTMParam> = new Map();
  source: BackendStorageMock<void, OperatingGTMDump, OperatingGTMDump> | undefined;
  range: Range | undefined;
  isLoading: boolean = true;
  constructor(private forecast: Forecast) {
    makeAutoObservable<OperatingGTM, "init">(this, {
      init: action,
      miningWells: computed,
      basedMining: computed,
      basicMining: computed,
    });

    when(
      () => forecast.production.isLoading === false && forecast.fact.production.isLoading === false,
      () => this.init()
    );
  }

  private init = async () => {
    this.range = this.forecast.range;
    this.source = operationGTMSource(this.forecast.storageKey);

    this.source.getItem().then((results) => {
      runInAction(() => {
        if (results && results.data.length > 0) {
          try {
            this.params = new Map(
              results.data.map((param) => [
                param.dataKey,
                new OperatingGTMParam(
                  param.title,
                  param.dataKey,
                  null,
                  null,
                  new Map(
                    param.children.map((child) => [
                      child.dataKey,
                      child.children
                        ? new OperatingGTMParam(
                            child.title,
                            child.dataKey,
                            null,
                            child?.accuracy ?? 3,
                            new Map(
                              child.children.map((subChildren) => [
                                subChildren.dataKey,
                                new OperatingGTMParam(
                                  subChildren.title,
                                  subChildren.dataKey,
                                  subChildren.measure ?? null,
                                  subChildren?.accuracy ?? 3,
                                  null,
                                  subChildren.data !== null
                                    ? new Map(
                                        Array.from(subChildren.data.values()).map(([year, value], index) => [
                                          year,
                                          value,
                                        ])
                                      )
                                    : new Map(
                                        new Array(this.range!.length)
                                          .fill(null)
                                          .map((_, index) => [this.forecast.range.from + index, null])
                                      )
                                ),
                              ])
                            ),
                            null
                          )
                        : new OperatingGTMParam(
                            child.title,
                            child.dataKey,
                            child.measure ?? null,
                            child?.accuracy ?? 3,
                            null,
                            child.data !== null
                              ? new Map(Array.from(child.data.values()).map(([year, value], index) => [year, value]))
                              : new Map(
                                  new Array(this.range!.length)
                                    .fill(null)
                                    .map((_, index) => [this.forecast.range.from + index, null])
                                )
                          ),
                    ])
                  ),
                  null
                ),
              ])
            );
          } catch {
            this.createFromScratch();
          }
        } else {
          this.createFromScratch();
        }
      });
    });
    this.isLoading = false;
  };

  public setData = (
    parentKey: string,
    paramKey: string,
    subParamKey: string,
    key: number | "accuracy",
    value: number | null
  ) => {
    if (parentKey === "otherGTM") {
      if (key === "accuracy") {
        try {
          this.params.get(parentKey)!.children!.get(paramKey)!.children!.get(subParamKey)!.accuracy = value;
        } catch {}
      } else {
        this.params.get(parentKey)?.children?.get(paramKey)?.children?.get(subParamKey)?.data?.set(key, value);
      }
    } else {
      if (key === "accuracy") {
        try {
          this.params.get(paramKey)!.children!.get(subParamKey)!.accuracy = value;
        } catch {}
      } else {
        this.params.get(paramKey)?.children?.get(subParamKey)?.data?.set(key, value);
      }
    }
  };

  public clear = async () => {
    await this.createFromScratch();
    await this.save();
  };

  public async save() {
    if (this.source) {
      this.source.setItem({
        data: Array.from(this.params.values()).map((param) => ({
          title: param.title,
          measure: param.measure,
          dataKey: param.dataKey,
          data: null,
          children:
            param.dataKey === "otherGTM"
              ? Array.from(param.children!.values()).map((child) => ({
                  title: child.title,
                  measure: child.measure,
                  dataKey: child.dataKey,
                  data: null,
                  children: Array.from(child.children!.values()).map((subChild) => subChild.dump),
                }))
              : Array.from(param.children!.values()).map((child) => child.dump),
        })),
      });
    }
  }

  public async createFromScratch() {
    await getOperatingGTM().then((results) => {
      runInAction(() => {
        if (results && results.length > 0) {
          this.params = new Map(
            results.map((param) => {
              if (param.dataKey === "miningWells") {
                return [
                  param.dataKey,
                  new OperatingGTMParam(
                    param.title,
                    param.dataKey,
                    null,
                    null,
                    new Map(
                      param.children.map((child) => {
                        if (child.title === "Количество скважин") {
                          return [
                            child.dataKey,
                            new OperatingGTMParam(
                              child.title,
                              child.dataKey,
                              child.measure ?? null,
                              child?.accuracy ?? 3,
                              null,
                              new Map(this.miningWells.map((value) => [value[0].year(), value[1].miningFond]))
                            ),
                          ];
                        }
                        return [
                          child.dataKey,
                          new OperatingGTMParam(
                            child.title,
                            child.dataKey,
                            child.measure ?? null,
                            child?.accuracy ?? 3,
                            null,
                            new Map(
                              new Array(this.range!.length)
                                .fill(null)
                                .map((_, index) => [this.forecast.range.from + index, null])
                            )
                          ),
                        ];
                      })
                    ),
                    null
                  ),
                ];
              }
              if (param.dataKey === "pumpingWells") {
                return [
                  param.dataKey,
                  new OperatingGTMParam(
                    param.title,
                    param.dataKey,
                    null,
                    null,
                    new Map(
                      param.children.map((child) => {
                        if (child.title === "Количество скважин") {
                          return [
                            child.dataKey,
                            new OperatingGTMParam(
                              child.title,
                              child.dataKey,
                              child.measure ?? null,
                              child?.accuracy ?? 3,
                              null,
                              new Map(this.miningWells.map((value) => [value[0].year(), value[1].injectionFond]))
                            ),
                          ];
                        }
                        return [
                          child.dataKey,
                          new OperatingGTMParam(
                            child.title,
                            child.dataKey,
                            child.measure ?? null,
                            child?.accuracy ?? 3,
                            null,
                            new Map(
                              new Array(this.range!.length)
                                .fill(null)
                                .map((_, index) => [this.forecast.range.from + index, null])
                            )
                          ),
                        ];
                      })
                    ),
                    null
                  ),
                ];
              }

              return [
                param.dataKey,
                new OperatingGTMParam(
                  param.title,
                  param.dataKey,
                  null,
                  null,
                  new Map(
                    param.children.map((child) => [
                      child.dataKey,
                      child.children
                        ? new OperatingGTMParam(
                            child.title,
                            child.dataKey,
                            null,
                            child?.accuracy ?? 3,
                            new Map(
                              child.children.map((subChildren) => [
                                subChildren.dataKey,
                                new OperatingGTMParam(
                                  subChildren.title,
                                  subChildren.dataKey,
                                  subChildren.measure ?? null,
                                  subChildren?.accuracy ?? 3,
                                  null,
                                  new Map(
                                    new Array(this.range!.length)
                                      .fill(null)
                                      .map((_, index) => [this.forecast.range.from + index, null])
                                  )
                                ),
                              ])
                            ),
                            null
                          )
                        : new OperatingGTMParam(
                            child.title,
                            child.dataKey,
                            child.measure ?? null,
                            child?.accuracy ?? 3,
                            null,
                            new Map(
                              new Array(this.range!.length)
                                .fill(null)
                                .map((_, index) => [this.forecast.range.from + index, null])
                            )
                          ),
                    ])
                  ),
                  null
                ),
              ];
            })
          );
        } else {
          console.error("Нет данных", results);
        }
      });
    });
  }

  // public createParam(param: ParamInfo) {
  //   const children: Map<string, OperatingGTMParam> | null = param.children ? new Map() : null;
  //   const data = param.data
  //     ? new Map(Array.from(param.data.values()).map(([year, value], index) => [year, value]))
  //     : new Map(new Array(this.range!.length).fill(null).map((_, index) => [this.forecast.range.from + index, null]));
  //   return new OperatingGTMParam(param.title, param.dataKey, param.measure ?? null, children, param.data);
  // }

  get basicMining() {
    const params = ["optimization", "rir", "grp", "perforation", "otherOPZ", "otherGTM", "physic"];
    const dataSets: Map<number, number | null>[] = [];

    this.params.forEach((parentParam) => {
      if (params.includes(parentParam.dataKey)) {
        if (parentParam.dataKey === "otherGTM") {
          for (let [, param] of parentParam.children!.get("otherGTM-i")!.children!) {
            if (param.title === "Доп. добыча за год проведения ГТМ") {
              dataSets.push(param.data!);
            }
          }
        } else {
          if (parentParam.dataKey === "physic") {
            for (let [, param] of parentParam.children!) {
              if (param.title === "Доп. добыча за год проведения ФХ МУН") {
                dataSets.push(param.data!);
              }
            }
          } else {
            for (let [, param] of parentParam.children!) {
              if (param.title === "Доп. добыча за год проведения ГТМ") {
                dataSets.push(param.data!);
              }
            }
          }
        }
      }
    });

    return new Map(
      Array.from(this.basedMining.entries()).map(([year, record]) => {
        let value = record;
        if (value === null) {
          return [year, null];
        }
        dataSets.forEach((data) => {
          if (data.get(year) !== undefined && data.get(year) !== null) {
            value! -= data.get(year)!;
          }
        });
        return [year, Math.max(0, value)];
      })
    );
  }

  get basedMining(): Map<number, number | null> {
    const production = this.forecast?.production;
    const toAggregate = this.forecast.wells.allWells.map(({ id }) => production?.wholeWellData(id) ?? []);

    const sumByYear = aggregateByDate(sumUp, toAggregate);
    const result = Array.from(sumByYear, ([year, datum]) => ({ year: year.year(), value: datum.oil_prod })).filter(
      (record) => record.year >= this.forecast.range.from
    );
    return new Map(result.map((value) => [value.year, value.value]));
  }

  get miningWells() {
    const production = this.forecast?.production;
    const toAggregate = this.forecast.wells.allWells.map(({ id }) => production?.wholeWellData(id) ?? []);
    const aggFunc = (datums: ProductionDatum[]): { miningFond: number; injectionFond: number } => {
      const result = { miningFond: 0, injectionFond: 0 };

      for (const datum of datums) {
        if ((datum.inj_days ?? 0) > 0) {
          ++result.injectionFond;
        } else {
          result.miningFond += +((datum.prod_days ?? 0) > 0);
        }
      }

      return result;
    };
    const fond = aggregateByDate(aggFunc, toAggregate);
    return [...fond];
  }
}
export { OperatingGTM, OperatingGTMParam };
