import { FC } from "react";
import { Empty } from "antd";
import { observer } from "mobx-react";
import { PageFrame, TabItem } from "routing/pageFrame/pageFrame";

import { FullScreen } from "elements/fullScreen/fullScreen";
import { LinkButton } from "elements/linkButton/linkButton";
import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { Preloader } from "features/preloader/preloader";
import { PlanningCharts } from "features/ranking/results/charts/planningCharts";
import { Events } from "features/ranking/results/events";
import { Summary } from "features/ranking/results/summary";
import { useForecast } from "models/project/fact/forecast/forecast";
import { useProject } from "models/project/project";

import { RankingResultsContextProvider } from "./rankingResultsContext";
import { SettingsPreloader } from "./settingPreloader";

const TABS: TabItem[] = [
  {
    key: "summary",
    label: "Сводные показатели",
    children: <Summary />,
    default: true,
  },
  {
    key: "events",
    label: "Мероприятия",
    children: <Events gtmsList="included" />,
  },
  {
    key: "events-not-included",
    label: "Невошедшие ГТМ",
    children: <Events gtmsList="notIncluded" />,
  },
  {
    key: "charts",
    label: "Графики",
    children: <PlanningCharts />,
  },
];

const Switch = observer(() => {
  const project = useProject()!;
  const forecast = useForecast()!;
  const settings = forecast.rankingSettings;

  if (settings.result === undefined) {
    return (
      <FullScreen>
        <Empty description={<>Необходимо провести планирование</>}>
          <LinkButton to={`/${project?.id}/${forecast.id}/settings`}>Настройки планирования</LinkButton>
        </Empty>
      </FullScreen>
    );
  }
  return (
    <RankingResultsContextProvider value={settings.result}>
      <PageFrame title="Результаты планирования" tabs={TABS} tabsWidthFix={488} />
    </RankingResultsContextProvider>
  );
});

const RCPResults: FC = () => {
  return (
    <Preloader hooks={[useForecast]}>
      <ForecastSelector>
        <SettingsPreloader>
          <Switch />
        </SettingsPreloader>
      </ForecastSelector>
    </Preloader>
  );
};

export { RCPResults };
