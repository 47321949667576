import { ElementType } from "react";
import classNames from "classnames";

import { global } from "models/global";

import cn from './debugZone.module.less';

const DebugZone: React.FC<React.PropsWithChildren<{ tag?: ElementType }>> = ({ children, tag: Tag = "div" }) => {
  return <>{ global.IS_DEBUG_ZONE && <Tag className={classNames(cn.wrap, Tag === 'div' && cn.inline)}>{children}</Tag> }</>;
}

export { DebugZone };
