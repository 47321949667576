import React from "react";
import dayjs, { Dayjs } from "dayjs";

import { DATE_FORMAT, formatNumber, NUMBER_FORMAT } from "./format";

type FormatDataAdditionalProps = {
  dateFormat?: keyof typeof DATE_FORMAT;
  unit?: keyof typeof NUMBER_FORMAT | "mem";
  accuracy?: number;
  maximumFractionDigits?: number;
  sign?: boolean;
};

const formatData = (
  children: string | number | null | undefined | Date | Array<number> | Array<string> | boolean | Dayjs,
  { dateFormat = "generic", unit, accuracy, maximumFractionDigits, sign }: FormatDataAdditionalProps
) => {
  let txt: string = "";

  if (typeof children === "undefined") {
    return "";
  }
  if (children === null) {
    return "—";
  }
  if (React.isValidElement(children)) {
    return `${children}`;
  }
  if (children instanceof Date) {
    return DATE_FORMAT[dateFormat].format(children);
  }
  if (dayjs.isDayjs(children)) {
    return DATE_FORMAT[dateFormat].format(children.toDate());
  }
  if (typeof children === "boolean") {
    return children ? "Да" : "Нет";
  }

  if (typeof children === "number") {
    txt = formatNumber(children, unit);
    if (accuracy !== undefined) {
      txt = formatNumber(Math.round(Math.pow(10, accuracy) * children) / Math.pow(10, accuracy), unit);
    }
    if (maximumFractionDigits !== undefined) {
      NUMBER_FORMAT[`real_${maximumFractionDigits}`] = new Intl.NumberFormat("ru-RU", { maximumFractionDigits });
      txt = formatNumber(children, `real_${maximumFractionDigits}`);
    }
    if (sign) {
      if (txt.length > 0 && txt[0] !== "-") {
        txt = `+${txt}`;
      }
    }
  } else if (typeof children === "string") {
    txt = children;
  } else if (Array.isArray(children)) {
    txt = children
      .map((v: number | string): string => {
        if (typeof v === "number") {
          return formatNumber(v, unit);
        }
        return v;
      })
      .join(", ");
  } else {
    console.error("unknown cell data", children);
    txt = `${children}`;
  }

  return txt;
};

export { formatData };
