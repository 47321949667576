import { useCallback, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { ModelContentComponentType, useModal } from "elements/modal/modal";
import { global } from "models/global";
import { Project as ProjectModel } from "models/project/project";
import { useProjects } from "models/project/projects";
import { postProject, type ProjectNewRaw } from "services/back/project";
import { assignRole } from "services/back/roles";

import { CreateProject } from "./createProjectModel";
import { CreateProjectsContextProvider } from "./createProjectProvider";
import { Settings } from "./settings";
import { WellsTree } from "./wellsTree";

import cn from "./createProjectModal.module.less";

const CreateProjectForm: ModelContentComponentType<ProjectNewRaw> = ({ setOnAccept, setLoading }) => {
  const projects = useProjects();
  const navigate = useNavigate();
  const creator = global.user!;
  const createProjectModel = useMemo(() => new CreateProject(creator), [creator]);

  setOnAccept(async () => {
    setLoading(true);
    const created = await postProject(createProjectModel.project);
    const project = ProjectModel.fromRawData(created);
    const { participants } = createProjectModel;
    for (const {
      user: { id: uId },
      roleId,
    } of participants) {
      await assignRole(roleId, project.id, uId);
    }
    projects.add(project);
    navigate(project.id.toString());
    return Promise.resolve({
      result: true,
      data: createProjectModel.project,
    });
  });

  return (
    <CreateProjectsContextProvider value={createProjectModel}>
      <div className={cn.container}>
        <WellsTree />
        <Settings />
      </div>
    </CreateProjectsContextProvider>
  );
};

const useCreateProject = () => {
  const showModal = useModal<ProjectNewRaw>();
  const data = useRef(undefined);

  return useCallback(
    () =>
      showModal(CreateProjectForm, data, "Создание проекта", "Создать проект", "Отменить", "1165px", undefined, {
        padding: 0,
      }),
    [showModal]
  );
};

export { useCreateProject };
