import { useMemo } from "react";

import { Column } from "features/tableDebug/simpleTable";

import { useInfrastructure } from "../useInfrastructure";

import { Pipes } from "./facilitiesManager/pipes";

import cn from "./infrastructureFacilities.module.less";

const columns: Column[] = [
  {
    title: "Название вида нефтепровода",
    width: { min: 250, max: 300, competitiveness: 1 },
    isSticky: true,
    dataKey: "title",
    type: "string",
    editable: true,
    onCell: () => ({ className: cn.tableCellInput }),
  },
  {
    title: "Внешний диаметр, мм",
    width: { min: 120, max: 120, competitiveness: 1 },
    dataKey: "diameterOuter",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Толщина стенки, мм",
    width: { min: 100, max: 120, competitiveness: 1 },
    dataKey: "thickness",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Шерохов-ть стенки, мм",
    width: { min: 110, max: 120, competitiveness: 1 },
    dataKey: "roughness",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Пред.скорость потока, м/с",
    width: { min: 120, max: 150, competitiveness: 1 },
    dataKey: "limitingVelocity",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Пред. градиент давления, атм/км",
    width: { min: 150, max: 150, competitiveness: 1 },
    dataKey: "limitingPressureGradient",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Итого, тыс руб / км",
    width: { min: 160, max: 180, competitiveness: 1 },
    dataKey: "total",
    type: "number",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "ПИР, тыс руб / км",
    width: { min: 160, max: 180, competitiveness: 1 },
    dataKey: "dsw",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Оборудование, тыс руб / км",
    width: { min: 220, max: 250, competitiveness: 1 },
    dataKey: "equipment",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "СМР, тыс руб / км",
    width: { min: 160, max: 180, competitiveness: 1 },
    dataKey: "ciw",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Прочее, тыс руб / км",
    width: { min: 180, max: 200, competitiveness: 1 },
    dataKey: "other",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Итого, тыс руб / км",
    width: { min: 160, max: 180, competitiveness: 1 },
    dataKey: "totalReconstruction",
    type: "number",
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "ПИР, тыс руб / км",
    width: { min: 160, max: 180, competitiveness: 1 },
    dataKey: "reconstructionDsw",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Оборудование, тыс руб / км",
    width: { min: 220, max: 250, competitiveness: 1 },
    dataKey: "reconstructionEquipment",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "СМР, тыс руб / км",
    width: { min: 160, max: 180, competitiveness: 1 },
    dataKey: "reconstructionCiw",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Прочее, тыс руб / км",
    width: { min: 180, max: 200, competitiveness: 1 },
    dataKey: "reconstructionOther",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
  {
    title: "Период реконструкции, года",
    width: { min: 230, max: 250, competitiveness: 1 },
    dataKey: "period",
    type: "number",
    editable: true,
    onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
    onCell: () => ({ className: cn.tableCellAlignRight }),
  },
];

const useFacilitiesPipesTableModel = (mode: "prod" | "inj") => {
  const infrastructure = useInfrastructure();
  const store = useMemo(() => new Pipes(infrastructure, mode), [infrastructure, mode]);
  return { columns, store };
};

export { useFacilitiesPipesTableModel };
