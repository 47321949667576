import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, reaction } from "mobx";

import { UOM } from "elements/uom";
import { global } from "models/global";
import { ECYStore } from "models/project/fact/ecyStore/ecyStore";
import { Forecast } from "models/project/fact/forecast/forecast";
import { TreeRoot } from "models/tree/tree";
import { MetricTree } from "services/finance/utils";

import { COMPARISON_TABLE_METRICS } from "./tableLayout";

type MetricsByForecast = {
  [forecastId: number]: {
    value: number | null;
    delta: number | null;
  };
};
type DRow = MetricsByForecast & {
  param: string;
  measure: string | null;
};

class CompareTableStore extends TableNode<DRow, Datum> {
  public readonly root = this;
  public selectedForecasts: Forecast[] = [];

  public currentUSCId: number;

  public favoriteScenario: number | null = null;

  constructor(public readonly tree: TreeRoot<Forecast>, public readonly forecasts: Forecast[], uscStore: ECYStore) {
    super();
    this.childrenStore = new ChildrenStoreArray(
      this,
      COMPARISON_TABLE_METRICS.map((row) => new Datum(this, row))
    );

    console.assert(uscStore.isLoading === false);
    this.currentUSCId = uscStore.first?.id ?? -1;

    makeObservable<CompareTableStore, "onTreeSelect">(this, {
      selectedForecasts: observable.ref,
      currentUSCId: observable,
      favoriteScenario: observable,

      onTreeSelect: action,
      setCurrentUSCId: action,
      onFavoriteSelect: action,
    });

    reaction(
      () => tree.selectedItems,
      (selectedForecasts) =>
        this.onTreeSelect(selectedForecasts.map((node) => node.item).filter((id): id is Forecast => id !== null)),
      { fireImmediately: true }
    );
  }

  public onFavoriteSelect = (id: number) => {
    if (this.favoriteScenario === id) {
      this.favoriteScenario = null;
    } else {
      this.favoriteScenario = id;
    }
  };

  private onTreeSelect(selectedForecastsIds: Forecast[]) {
    this.selectedForecasts = selectedForecastsIds;
  }

  public setCurrentUSCId = (uscId: number) => {
    this.currentUSCId = uscId;
  };
}

class Datum extends TableNode<DRow, Datum> {
  public get root(): CompareTableStore {
    return this.parent.root;
  }

  public readonly uom: UOM;

  constructor(private readonly parent: Datum | CompareTableStore, private readonly row: MetricTree) {
    super(parent, { isExpandedChildren: true });
    this.uom = new UOM(row.unit?.quantity ?? 0, row.unit?.measure ?? 0, global.uomResolver);

    makeObservable(this, {
      currentUSCId: computed,
    });

    if (row.children) {
      this.childrenStore = new ChildrenStoreArray(
        this,
        row.children?.map((row) => new Datum(this, row))
      );
    } else {
      this.childrenStore = null;
    }
  }

  get currentUSCId(): number {
    return this.root.currentUSCId;
  }

  asDRow = (): DRow => ({
    param: this.row.title,
    measure: this.uom.unit,
    ...Object.fromEntries(
      this.root.selectedForecasts.map((fc) => {
        const result = fc.anyResult?.get(this.currentUSCId);
        if (!result) {
          return [fc.id, { value: result, delta: null }];
        }
        return [fc.id, { value: result.total.comparisonValues[this.row.title] ?? null, delta: null }];
      })
    ),
  });
}

export { CompareTableStore };
export type { DRow };
