import { FC } from "react";
import { Button } from "antd";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";

import { ReactComponent as EyeIcon } from "./eye.svg";
import { ReactComponent as EyeClosedIcon } from "./eyeClosed.svg";
import { LegendContentState } from "./LegendContentState";

import cn from "./legendContent.module.less";

type LegendContentProps = {
  state: LegendContentState;
};

const LegendContent: FC<LegendContentProps> = observer(({ state }) => {
  return (
    <div className={cn.container}>
      <div className={cn.row}>
        <span>Нефть</span>
        <Button
          type="text"
          icon={<Icon className={cn.icon} content={state.getOil ? <EyeIcon /> : <EyeClosedIcon />} />}
          onClick={() => {
            state.setOil(!state.getOil);
          }}
        />
      </div>
      <div className={cn.row}>
        <span>Жидкость</span>
        <Button
          type="text"
          icon={<Icon className={cn.icon} content={state.getLiquid ? <EyeIcon /> : <EyeClosedIcon />} />}
          onClick={() => {
            state.setLiquid(!state.getLiquid);
          }}
        />
      </div>
      <div className={cn.row}>
        <span>Обводненность</span>
        <Button
          type="text"
          icon={<Icon className={cn.icon} content={state.getWatering ? <EyeIcon /> : <EyeClosedIcon />} />}
          onClick={() => {
            state.setWatering(!state.getWatering);
          }}
        />
      </div>
    </div>
  );
});

export { LegendContent };
