import { RadioChangeEvent } from "antd";
import dayjs from "dayjs";
import { computed, makeObservable, observable, runInAction } from "mobx";

import { UOM } from "elements/uom";
import { ForecastResult } from "models/project/fact/forecast/forecastResult";
import { simplify } from "pages/project/finance/results/debugResultsPage";
import { START_YEAR } from "services/field";
import { zip } from "utils/itertools";
import { Range } from "utils/range";
import { ofTree } from "utils/tree";

import { ParamsNode } from "./params/paramsNode";
import { global } from "./global";

enum CalculationMode {
  Full = 0,
  Forecast = 1,
}

class SummaryModel {
  public ranges: [Range, Range];
  private investYear: number;

  get currentRange(): Range {
    return this.ranges[this.mode]!;
  }

  private operationCashFlow: (number | null)[];
  private investCashFlow: (number | null)[];
  private investCashFlowFromReturn: (number | null)[];

  public mode: CalculationMode = CalculationMode.Forecast;

  private getSingleData = (name: string) => {
    return Object.entries(simplify(this.forecastResult.result)).find((item) =>
      item[0].startsWith(`${name}`)
    )![1] as number;
  };

  private getFromSchema(name: string): { data: Array<number | null>; uom: UOM | null } | undefined {
    const metric =
      this.forecastResult.result["schema_operation"].schema.find((item) => item.title === name) ??
      this.forecastResult.result["schema_invest"].schema.find((item) => item.title === name) ??
      this.forecastResult.result["schema_cashflow"].schema.find((item) => item.title === name);
    if (metric === undefined) {
      console.error("unknown name", name);
      return {
        data: [],
        uom: new UOM(0, 0, global.uomResolver),
      };
    }
    return {
      data: metric.values!.slice(this.investYear - START_YEAR),
      uom: new UOM(metric.unit?.quantity!, metric.unit?.measure!, global.uomResolver),
    };
  }

  constructor(public forecastResult: ForecastResult) {
    makeObservable(this, {
      mode: observable,
      currentRange: computed,
    });

    this.ranges = [forecastResult.forecast.wholeRange, forecastResult.forecast.range];
    this.investYear = forecastResult.forecast.fact.investYear;

    this.operationCashFlow = forecastResult.result.schema_cashflow.schema[1].values!;
    this.investCashFlow = forecastResult.result.schema_cashflow.schema[2].values!;
    this.investCashFlowFromReturn = forecastResult.result.schema_cashflow.schema[3].values!;
  }

  public get investStream(): ParamsNode {
    for (const node of ofTree(this.forecastResult.result.schemaInvest)) {
      if (node.title.includes("Денежный поток от инвестиционной деятельности")) {
        return ParamsNode.fromRaw(node);
      }
    }
    console.error("Объект денежного потока в инвестиционках не найден.");
    return ParamsNode.fromRaw({ key: -1, title: "", unit: { quantity: 0, measure: 0 }, values: null, children: [] });
  }

  public handleModeChange = (e: RadioChangeEvent) => {
    runInAction(() => {
      this.mode = e.target.value;
    });
  };

  get DynamicOperationCashFlow() {
    return [...zip([...this.ranges[CalculationMode.Full]], this.operationCashFlow)].map(([year, value]) => ({
      x: new Date(year, 0, 1),
      y: value ?? 0,
    }));
  }

  get DynamicInvestCashFlow() {
    return [this.investCashFlow, this.investCashFlowFromReturn].map((values) =>
      [...zip([...this.ranges[CalculationMode.Full]], values)].map(([year, value]) => ({
        x: new Date(year, 0, 1),
        y: value ?? 0,
      }))
    );
  }

  get payback() {
    return {
      data: [
        {
          title: "IRR",
          value: this.getSingleData(`IRR ${2 - this.mode}`),
        },
        {
          title: "",
          value: 100 - this.getSingleData(`IRR ${2 - this.mode}`),
        },
      ],
      irr: this.getSingleData(`IRR ${2 - this.mode}`),
      pi: this.getSingleData(`PI ${2 - this.mode}`),
      dpbp: this.getSingleData(`dPBP ${2 - this.mode}`),
      pbp: this.getSingleData(`PBP ${2 - this.mode}`),
    };
  }

  get cashFlowUnit() {
    const unit = this.forecastResult.result.schema_cashflow.schema[5].unit;
    const label = new UOM(unit?.quantity ?? 0, unit?.measure ?? 0, global.uomResolver).unit;
    return label;
  }

  get cashFlowData() {
    const values = this.forecastResult.result.schema_cashflow.schema[5].values!.slice(this.investYear - START_YEAR);
    const data = [...zip([...this.ranges[CalculationMode.Full]], values)].map(([year, value]) => ({
      x: dayjs(`${year}`),
      y: value ?? 0,
    }));

    return data;
  }

  get npvUnit() {
    const unit = this.forecastResult.result.schema_cashflow.schema[5].unit;

    const label = new UOM(unit?.quantity ?? 0, unit?.measure ?? 0, global.uomResolver).unit;
    return label;
  }

  get NPV() {
    const values = Object.entries(simplify(this.forecastResult.result)).find((item) =>
      item[0].startsWith(`Накопленный денежный поток ${2 - this.mode}`)
    )?.[1] as (number | null)[];
    const data = [...zip([...this.ranges[CalculationMode.Full]], values)].map(([year, value]) => ({
      x: dayjs(`${year}`),
      y: value ?? 0,
    }));

    return {
      data: data,
      stickerValue: values[values.length - 1],
    };
  }

  get oilSum() {
    const rawData = this.getFromSchema("Товарная нефть");
    if (!rawData?.data) {
      return 0;
    }
    return rawData.data.reduce((prev, curr) => prev! + curr!, 0);
  }

  get oil() {
    const values = this.getFromSchema("Товарная нефть")?.data;
    let data = [{ x: dayjs(`${START_YEAR}`), y: 0 }];
    if (values) {
      data = [...zip([...this.ranges[CalculationMode.Full]], values)].map(([year, value]) => ({
        x: dayjs(`${year}`),
        y: value ?? 0,
      }));
    }

    return { data: data, stickerValue: this.oilSum };
  }

  get oilGasSum() {
    const rawData = this.getFromSchema("Товарный нефтяной газ");
    return rawData?.data.reduce((prev, curr) => prev! + curr!, 0);
  }

  get oilGas() {
    const values = this.getFromSchema("Товарный нефтяной газ")?.data;
    let data = [{ x: dayjs(`${START_YEAR}`), y: 0 }];
    if (values) {
      data = [...zip([...this.ranges[CalculationMode.Full]], values)].map(([year, value]) => ({
        x: dayjs(`${year}`),
        y: value ?? 0,
      }));
    }

    return { data: data, stickerValue: this.oilGasSum };
  }

  get CAPEXUnit() {
    const rawData = this.getFromSchema("Денежный поток от инвестиционной деятельности");

    const unit = rawData?.uom ?? new UOM(0, 0, global.uomResolver);
    return unit.unit;
  }

  get CAPEX() {
    const values = this.getFromSchema("Денежный поток от инвестиционной деятельности")?.data;
    let data = [{ x: dayjs(`${START_YEAR}`), y: 0 }];
    if (values) {
      data = [...zip([...this.ranges[CalculationMode.Full]], values)].map(([year, value]) => ({
        x: dayjs(`${year}`),
        y: -1 * (value ?? 0),
      }));
    }

    return {
      data: data,
      stickerValue: data.reduce((a, b): number => a + b.y, 0),
    };
  }

  get OPEXUnit() {
    const rawData = this.getFromSchema("Операционные расходы (с инфляцией)");

    const unit = rawData?.uom ?? new UOM(0, 0, global.uomResolver);
    return unit.unit;
  }

  get OPEXSum() {
    const rawData = this.getFromSchema("Операционные расходы (с инфляцией)");

    if (!rawData) {
      return 0;
    }

    return rawData.data.reduce((prev, curr) => prev! + curr!, 0);
  }

  get OPEX() {
    const values = this.getFromSchema("Операционные расходы (с инфляцией)")?.data;
    let data = [{ x: dayjs(`${START_YEAR}`), y: 0 }];
    if (values) {
      data = [...zip([...this.ranges[CalculationMode.Full]], values)].map(([year, value]) => ({
        x: dayjs(`${year}`),
        y: value ?? 0,
      }));
    }

    return {
      data: data,
      stickerValue: this.OPEXSum,
    };
  }
}

export { CalculationMode, SummaryModel };
