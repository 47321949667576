import React, { FC } from "react";
import { useChartContext } from "@okopok/axes_context";
import { observer } from "mobx-react";

import { BarInfo, BarsDataModel } from "elements/charts/stackedBars/barDataModel";
import { Format } from "elements/format/format";

type BarsProps = {
  bars: BarsDataModel;
};

type FactorAnalysisBarProps = {
  y: BarInfo;
  x: number;
  axisKey: string;
};

const getBarWidth = (yearLength: number) => {
  return Math.min(40, (2 * yearLength) / 3);
};

const FactorAnalysisBar: FC<FactorAnalysisBarProps> = observer(({ y, x, axisKey }) => {
  const axes = useChartContext();
  if (axes.isNotReady || !axes.isShapeReady) {
    return null;
  }
  const yScale = axes.at(axisKey)?.scale;
  const xScale = axes.axisX.scale;
  const yearWidth = axes.yearWidth;
  if (yScale === undefined) {
    console.error("Не определён масштаб для отображаемой кривой");
    return null;
  }

  if (y.limit === undefined) {
    if (y.value > 0) {
      return (
        <>
          <rect
            x={xScale(x) - getBarWidth(yearWidth) / 2}
            y={yScale(y.value)}
            width={getBarWidth(yearWidth)}
            height={Math.abs(yScale(0) - yScale(y.value))}
            style={{ fill: y.color }}
          />
          <text style={{ textAnchor: "middle" }} x={xScale(x)} y={yScale(y.value) - 2}>
            <Format>{y.value}</Format>
          </text>
        </>
      );
    } else {
      return (
        <>
          <rect
            x={xScale(x) - getBarWidth(yearWidth) / 2}
            y={yScale(y.value) - Math.abs(yScale(0) - yScale(y.value))}
            width={getBarWidth(yearWidth)}
            height={Math.abs(yScale(0) - yScale(y.value))}
            style={{ fill: y.color }}
          />
          <text style={{ textAnchor: "middle" }} x={xScale(x)} y={yScale(y.value) + 12}>
            <Format>{y.value}</Format>
          </text>
        </>
      );
    }
  } else {
    if (y.value > 0) {
      return (
        <>
          <rect
            x={xScale(x) - getBarWidth(yearWidth) / 2}
            y={yScale(y.limit)}
            width={getBarWidth(yearWidth)}
            height={Math.abs(yScale(0) - yScale(y.value))}
            style={{ fill: y.color }}
          />
          <text style={{ textAnchor: "middle" }} x={xScale(x)} y={yScale(y.limit) - 2}>
            +<Format>{y.value}</Format>
          </text>
        </>
      );
    } else {
      return (
        <>
          <rect
            x={xScale(x) - getBarWidth(yearWidth) / 2}
            y={yScale(y.limit - y.value)}
            width={getBarWidth(yearWidth)}
            height={Math.abs(yScale(0) - yScale(-y.value))}
            style={{ fill: y.color }}
          />
          <text style={{ textAnchor: "middle" }} x={xScale(x)} y={yScale(y.limit - y.value) + Math.abs(yScale(0) - yScale(-y.value)) + 12}>
            <Format>{y.value}</Format>
          </text>
        </>
      );
    }
  }
});

const FactorAnalysisBars: FC<BarsProps> = observer(({ bars }) => {
  const axisKey = bars.axisKey;
  const preparedBars = bars.preparedBars;
  return (
    <>
      {(bars.inRow === undefined || bars.inRow === false) &&
        Array.from(preparedBars).map(([year, barInfo], index) => <FactorAnalysisBar key={index} axisKey={axisKey} x={+year} y={barInfo[0]} />)}
    </>
  );
});

export { FactorAnalysisBars };
