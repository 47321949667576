import { FC } from "react";
import { Button, Popover, Tooltip } from "antd";

import { Icon } from "elements/icon/icon";
import { ModeSelector } from "elements/modeSelector/modeSelector";
import { ModeSelectorModel } from "elements/modeSelector/modeSelectorModel";

import { LegendContent } from "../LegendContent/legendContent";
import { LegendContentState } from "../LegendContent/LegendContentState";

import { ReactComponent as LegendIcon } from "./infoIcon.svg";

type TechForecastChartControlsProps = {
  legendContent: LegendContentState;
  modeModel: ModeSelectorModel;
};

const TechForecastChartControls: FC<TechForecastChartControlsProps> = ({ legendContent, modeModel }) => {
  return (
    <>
      <Popover content={<LegendContent state={legendContent} />} trigger={["click"]}>
        <Tooltip title="Легенда">
          <Button type="text" icon={<Icon width="24px" height="24px" viewBox="-4 -4 24 24" content={<LegendIcon />} />} />
        </Tooltip>
      </Popover>
      <ModeSelector modeModel={modeModel} />
    </>
  );
};

export { TechForecastChartControls };
