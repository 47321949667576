import { FC } from "react";
import { Dayjs } from "dayjs";

import { Ellipsis, EllipsisProps } from "elements/ellipsis/ellipsis";
import { formatData } from "elements/format/formatData";
import { Loader } from "elements/loader";
import { plural } from "utils/plural";

/*
https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
minimumFractionDigits
The minimum number of fraction digits to use. Possible values are from 0 to 20; the default for plain number and percent formatting is 0; the default for currency formatting is the number of minor unit digits provided by the ISO 4217 currency code list (2 if the list doesn't provide that information).

maximumFractionDigits
The maximum number of fraction digits to use. Possible values are from 0 to 20; the default for plain number formatting is the larger of minimumFractionDigits and 3; the default for currency formatting is the larger of minimumFractionDigits and the number of minor unit digits provided by the ISO 4217 currency code list (2 if the list doesn't provide that information); the default for percent formatting is the larger of minimumFractionDigits and 0.

minimumSignificantDigits
The minimum number of significant digits to use. Possible values are from 1 to 21; the default is 1.

maximumSignificantDigits
The minimum number of significant digits to use. Possible values are from 1 to 21; the default is 1.
 */

const NUMBER_FORMAT: { [k: string]: Intl.NumberFormat } = {
  real_0: new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 0 }),
  real_1: new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 1 }),
  real_2: new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 2 }),
  real_3: new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 3 }), //, { maximumSignificantDigits: 3 }),
  real_4: new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 4 }),
  real_5: new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 5 }),
  int: new Intl.NumberFormat("ru-RU", { useGrouping: false, maximumFractionDigits: 0 }),
  usd: new Intl.NumberFormat("ru-RU", { style: "currency", currency: "USD" }),
  rub: new Intl.NumberFormat("ru-RU", { style: "currency", currency: "RUB" }),
  eur: new Intl.NumberFormat("ru-RU", { style: "currency", currency: "EUR" }),
  cny: new Intl.NumberFormat("ru-RU", { style: "currency", currency: "CNY" }),
  locale_C: new Intl.NumberFormat("ru-RU", { useGrouping: false, maximumFractionDigits: 8 }),
};

const DATE_FORMAT = {
  generic: new Intl.DateTimeFormat("ru-RU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }),
  monthYear: new Intl.DateTimeFormat("ru-RU", {
    year: "numeric",
    month: "long",
  }),
  monthYearNumeric: new Intl.DateTimeFormat("ru-RU", {
    year: "numeric",
    month: "numeric",
  }),
  accurate: new Intl.DateTimeFormat("ru-RU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }),
} as const;

//Intl.DateTimeFormat
//Intl.RelativeTimeFormat

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 *
 * @return Formatted string.
 */
function memSize(bytes: number, si: boolean = false) {
  const thresh = si ? 1000 : 1024;

  const units = ["", "К", "М", "Г", "Т", "П"];
  let u = 0;

  while (Math.abs(bytes) >= thresh && u < units.length) {
    bytes /= thresh;
    ++u;
  }

  return `${NUMBER_FORMAT.real_3.format(bytes)} ${units[u]}${plural(Math.floor(bytes), ["байт", "байт", "байта"])}`;
}

const formatNumber = (value: number, format: keyof typeof NUMBER_FORMAT | "mem" = "real_3") => {
  if (format === "mem") {
    return memSize(value);
  } else {
    return NUMBER_FORMAT[format ?? "real"].format(value);
  }
};

const formatDate = (date: Dayjs, format: Intl.DateTimeFormat): string => format.format(date.toDate());

type FormatProps = {
  children: string | number | null | undefined | Date | Array<number> | Array<string> | boolean | Dayjs;
  unit?: keyof typeof NUMBER_FORMAT | "mem";
  accuracy?: number;
  maximumFractionDigits?: number;
  dateFormat?: keyof typeof DATE_FORMAT;
  ellipsisPosition?: EllipsisProps["position"];
  ellipsisLimit?: EllipsisProps["limit"];
  sign?: boolean;
};

/**
 * Функция не занимается визуалом. Только форматом данных. Для формата строк есть
 * @param param0
 * @returns отформатированный ReactNode с данными
 */
const Format: FC<FormatProps> = ({
  children,
  unit = "real_3",
  accuracy,
  dateFormat = "generic",
  maximumFractionDigits,
  ellipsisPosition = "mid",
  ellipsisLimit,
  sign,
}: FormatProps) => {
  if (children === undefined) {
    return <Loader />;
  }

  const txt = formatData(children, { dateFormat, unit, accuracy, maximumFractionDigits, sign });

  if (typeof children !== "string" || !ellipsisLimit) {
    return <>{txt}</>;
  }

  return (
    <Ellipsis limit={ellipsisLimit} position={ellipsisPosition}>
      {txt}
    </Ellipsis>
  );
};

export { DATE_FORMAT, Format, formatDate, formatNumber, NUMBER_FORMAT };
