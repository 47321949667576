import { ChildrenStoreArray, ITableNode, TableItem, TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, reaction, transaction } from "mobx";

import { ExternalFilterOption, Filters, type TreeFilter } from "./filters/filters";

type WithTitle = {
  title: string;
};

type NestedField<Leaf extends WithTitle, Level extends WithTitle | string> = {
  key: string;
  title: string;
  getter: (item: Leaf) => Level;
};

type LevelPacker<Leaf extends WithTitle, Level extends WithTitle | string> = (item: Leaf) => Level;

type LeavesDistribution<CurrentLevel extends WithTitle, Leaf extends WithTitle> = Map<
  CurrentLevel,
  LeavesDistribution<any, Leaf> | Leaf[]
>;

function hasChildren(node: TreeNode<any>): boolean {
  return (node.childrenStore?.visibleChildrenLength ?? 0) > 0;
}

class TreeNode<Leaf extends WithTitle, T extends WithTitle | string = any> extends TableNode<
  WithTitle,
  TreeNode<Leaf>
> {
  asDRow = (): WithTitle => ({
    title: this.title,
  });

  private get root(): TreeRoot {
    if (this.parent instanceof TreeRoot) {
      return this.parent;
    }
    return this.parent.root;
  }

  public readonly title: string;
  public readonly isLastLevel: boolean = false;

  constructor(
    private parent: TreeNode<Leaf> | TreeRoot<Leaf>,
    public readonly item: T | null,
    distribution?: LeavesDistribution<any, Leaf> | Leaf[]
  ) {
    super(parent, { mutable: false });
    if (item === null) {
      this.title = "Остальные";
    } else {
      this.title = typeof item === "string" ? item : item.title;
    }

    if (distribution === undefined) {
      this.childrenStore = null;
    } else if (Array.isArray(distribution)) {
      this.isLastLevel = true;
      this.childrenStore = new ChildrenStoreArray(
        this,
        distribution.map((leaf) => new TreeNode(this, leaf))
      );
    } else {
      this.childrenStore = new ChildrenStoreArray(
        this,
        Array.from(distribution.entries(), ([level, levelDistribution]) => new TreeNode(this, level, levelDistribution))
      );
    }
  }

  public filter(predicate: (node: TreeNode<any>) => boolean) {
    transaction(() => {
      this.childrenStore?.reset();
      this.childrenStore?.sort(TreeRoot.titleCmp);

      if (!this.childrenStore) {
        return;
      }
      if (this.isLastLevel) {
        this.childrenStore.filter(predicate);
      } else {
        for (const child of this.children!) {
          child.filter(predicate);
        }
        this.childrenStore.filter(hasChildren);
      }
    });
  }
}

class TreeRoot<Leaf extends WithTitle = any> extends TableNode<WithTitle, TreeNode<Leaf>> {
  asDRow = (): WithTitle => ({ title: this.rootTitle ?? "" });

  public selectedLevels: number[] = [];
  public readonly filterManager?: Filters<Leaf>;

  constructor(
    private rootTitle: string | null,
    private items: Leaf[],
    public nestingFields: NestedField<Leaf, any>[],
    filters: TreeFilter<Leaf, any>[],
    public selectable = true,
    externalFilters?: Record<string, ExternalFilterOption<Leaf>>,
    selectAll: boolean = true
  ) {
    super(null, { mutable: false, selectable });

    makeObservable(this, {
      selectedLevels: observable.ref,
      setSelectedLevels: action,
      levels: computed,
      selectedCount: computed,
      selectedItems: computed,
      selectValue: computed,
      onChange: action,
    });

    if (selectable && selectAll) {
      this.selectManager!.isRootSelected = true;
    }

    reaction(
      () => this.levels,
      () => this.rebuild(),
      { fireImmediately: true }
    );

    if (filters) {
      this.filterManager = new Filters(filters, externalFilters);
      reaction(
        () => this.filterManager!.predicate,
        (pred) => this.filter(pred)
      );
    }
  }

  onChange = (keys: string[]) => {
    this.selectedLevels = keys.map((key) => {
      return this.nestingFields.findIndex((nest) => nest.key === key);
    });
  };

  get selectValue(): string[] {
    return this.selectedLevels.map((index) => this.nestingFields[index].key);
  }

  private rebuild() {
    if (this.levels.length === 0) {
      this.childrenStore = new ChildrenStoreArray(
        this,
        this.items.map((item) => new TreeNode(this, item))
      );
      if (this.filterManager) {
        this.filter(this.filterManager.predicate);
      }
      return;
    }
    const distribution = new Map();
    for (const item of this.items) {
      let currentDistribution = distribution;
      for (const levelGetter of this.levels.slice(0, -1)) {
        const level = levelGetter(item);
        if (!currentDistribution.has(level)) {
          currentDistribution.set(level, new Map());
        }
        currentDistribution = currentDistribution.get(level);
      }
      const lastLevel = this.levels.at(-1)!(item);
      currentDistribution.get(lastLevel)?.push(item) ?? currentDistribution.set(lastLevel, [item]);
    }
    this.childrenStore = new ChildrenStoreArray(
      this,
      Array.from(distribution.entries(), ([level, distribution]) => new TreeNode(this, level, distribution))
    );
    if (this.filterManager) {
      this.filter(this.filterManager.predicate);
    }
  }

  public filter(predicate: (item: Leaf) => boolean) {
    transaction(() => {
      this.childrenStore?.reset();
      this.childrenStore?.sort(TreeRoot.titleCmp);
      if (this.levels.length === 0) {
        this.childrenStore?.filter((node) => predicate(node.item) ?? true);
      } else {
        for (const node of this.children ?? []) {
          node.filter((node) => predicate(node.item));
        }
        this.childrenStore?.filter(hasChildren);
      }
    });
  }

  static titleCmp = (a: TreeNode<any>, b: TreeNode<any>) =>
    a.title.localeCompare(b.title, undefined, { numeric: true, sensitivity: "base" });

  get length(): number {
    if (this.rootTitle === null) {
      return super.length;
    }
    return super.length + 1;
  }

  public get levels(): LevelPacker<Leaf, any>[] {
    return this.selectedLevels.map((index) => this.nestingFields[index].getter);
  }

  public setSelectedLevels(value: number[]) {
    this.selectedLevels = value;
  }

  get asTableItem(): TableItem<WithTitle> {
    const { expand, ...item } = super.asTableItem;
    return item;
  }

  at(index: number): ITableNode<WithTitle> | undefined {
    if (this.rootTitle === null) {
      return super.at(index);
    }
    if (index === 0) {
      return this;
    }
    return super.at(index - 1);
  }

  public get totalCount(): number {
    return this.items.length;
  }

  public get selectedCount(): number {
    return this.selectedItems.length;
  }

  public get selectedItems(): TreeNode<Leaf, Leaf>[] {
    return [...TableNode.selectedNodes(this)].filter((node) => node.children === null) as TreeNode<Leaf, Leaf>[];
  }
}

export { TreeNode, TreeRoot };
export type { WithTitle };
