import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";

const useFactWells = () => useFact()?.wells;
const useForecastWells = () => useForecast()?.wells;
const useInterventions = () => useForecast()?.interventions;
const useProductionFact = () => useFact()?.production;
const useProduction = () => useForecast()?.production;
const useWellTypes = () => global.wellTypes;
const useProducingObjects = () => useFact()?.producingObjects;
const useForecastOperatingGTM = () => useForecast()?.operatingGTM;
const useForecastDecreaseRatio = () => useForecast()?.decreaseCoefficients;
const useTechParameters = () => useForecast()?.techParameters;

const SUMMARY_PRELOADER = [useProducingObjects, useWellTypes, useFactWells, useForecastWells, useInterventions, useProductionFact, useProduction];
const OPERATING_PRELOADER = [
  useForecastOperatingGTM,
  useForecastDecreaseRatio,
  useWellTypes,
  useFactWells,
  useForecastWells,
  useInterventions,
  useProductionFact,
  useProduction,
];
const DEBIT_PRELOADER = [useWellTypes, useFactWells, useForecastWells, useInterventions, useProductionFact, useProduction, useTechParameters];

export { DEBIT_PRELOADER, OPERATING_PRELOADER, SUMMARY_PRELOADER };
