import { getConstantsResolver } from "services/back/constants";
import { getOperatingResolver } from "services/back/operating";
import type { Range } from "utils/range";

import { Metric, riseMetrics } from "../utils";

const params = {
  cashFlowOperating: "Денежный поток от операционной деятельности",
  productionCommercialHC: "Добыча товарных УВ",
  totalRevenue: "Выручка всего (без налогов и транспорта), млн руб.",
  totalRevenueForOil: "Выручка всего (без налогов и транспорта) за нефть, включая льготы по ЭП",
  totalRevenueForOilGas: "Выручка всего (без налогов и транспорта) за нефтяной газ",
  oilSalesDirection3: "Реализация нефти по направлению 3",
} as const;

const scalarParentKeys = {
  "Коэффициент расчета товарного нефтяного газа": 2,
  "Коэффициент расчета товарной нефти": 1,
  "Газовый фактор": 1,
} as const;

const parentSchema = [
  { title: "Коэффициенты", key: 0, unit: { quantity: 0, measure: 0 }, values: null },
  {
    title: "Товарная нефть",
    key: 1,
    parent: 0,
    unit: { quantity: 0, measure: 0 },
    values: null,
  },
  {
    title: "Нефтяной газ",
    key: 2,
    unit: { quantity: 0, measure: 0 },
    parent: 0,
    values: null,
  },
];

const includeParentParams: string[] = [params.cashFlowOperating, params.productionCommercialHC];
const includeCashFlowOperatingParams: string[] = [
  params.totalRevenueForOil,
  params.totalRevenueForOilGas,
  params.oilSalesDirection3,
];

const getRevenueFact = async (range: Range) => {
  const { schema_operation } = await getOperatingResolver(range);
  const metricsSchema = riseMetrics(schema_operation);

  let [cashFlow, productionCommercial] = metricsSchema.filter((el) => includeParentParams.includes(el.code_title!));

  cashFlow = cashFlow.children.find((el) => el.code_title === params.totalRevenue)!;
  cashFlow.children = cashFlow.children.filter((el) => !includeCashFlowOperatingParams.includes(el.code_title!));
  productionCommercial = productionCommercial.children[0].children[0];

  const result = [...cashFlow.children, productionCommercial].sort((a, b) => a.title[0].localeCompare(b.title[0]));
  return { table: result };
};

const getRevenueForecast = async (range: Range) => {
  const { constants } = await getConstantsResolver();
  const { table } = await getRevenueFact(range);
  let metricsSchema = riseMetrics(constants);
  metricsSchema = metricsSchema.filter((el) => el.code_title === "Для расчета выручки");
  metricsSchema = metricsSchema[0].children;

  const mergeSchema = [...parentSchema, ...metricsSchema] as Metric[];
  const result = mergeSchema.map((el) => ({
    ...el,
    parent:
      typeof el.parent === "number" ? el.parent : scalarParentKeys[el.title as keyof typeof scalarParentKeys] || null,
  }));

  return { table, scalar: riseMetrics(result as Metric[]) };
};

export { getRevenueFact, getRevenueForecast };
