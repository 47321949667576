import React, { ReactNode, useMemo } from "react";
import { ChartContext, Lines } from "@okopok/axes_context";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Stick } from "elements/card/card";
import { useScaleFromLines } from "elements/charts/lines/useScaleFromLines";
import { PointerLine } from "elements/charts/pointerLine/pointerLine";
import { Format } from "elements/format/format";
import TooltipDiv from "features/plot/Tooltip/TooltipDiv";
import { TooltipContext } from "features/plot/Tooltip/useTooltipDataManager";
import { SummaryModel } from "models/summary";

import { ExpensesChartModel } from "./expensesChartModel";

import cn from "./expensesChart.module.less";

const YEAR = 31536000000 / 2;

const ExpensesChart = observer(({ model }: { model: SummaryModel }) => {
  const chartModel = useMemo(() => new ExpensesChartModel(model), [model]);

  return (
    <div className={cn.layout}>
      <div className={cn.wrap}>
        <div className={classNames(cn.chartStick, cn.inLine)}>
          <Stick title="CAPEX">
            <span className={cn.big}>
              <Format>{chartModel.capexStickerValue}</Format>
            </span>{" "}
            {model.CAPEXUnit}
          </Stick>
          <Stick title="OPEX">
            <span className={cn.big}>
              <Format>{chartModel.opexStickerValue}</Format>
            </span>{" "}
            {model.OPEXUnit}
          </Stick>
        </div>
        <ChartContext
          axes={useScaleFromLines(
            chartModel.lines,
            YEAR,
            new Map<string, ReactNode>(
              Object.entries({
                capex: `CAPEX, млн ₽`,
                opex: `OPEX, млн ₽`,
              })
            ),
            new Set(["opex", "capex"]),
            undefined
          )}
          className={cn.chart}
        >
          <TooltipContext.Provider value={chartModel.tooltipManager}>
            <Lines data={chartModel.lines} />
            <PointerLine />
          </TooltipContext.Provider>
        </ChartContext>
        <TooltipDiv manager={chartModel.tooltipManager} />
      </div>
    </div>
  );
});

export { ExpensesChart };
