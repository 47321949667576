import { FC } from "react";
import FullScreen from "react-fullscreen-crossbrowser";
import { ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons";
import { ChartContext } from "@okopok/axes_context";
import { Button } from "antd";
import { observer } from "mobx-react";

import { PointerLine } from "elements/charts/pointerLine/pointerLine";
import { useScaleFromBars } from "elements/charts/stackedBars/useScaleFromBars";
import TooltipDiv from "features/plot/Tooltip/TooltipDiv";
import { TooltipContext } from "features/plot/Tooltip/useTooltipDataManager";
import { useBooleanState } from "utils/useBooleanState";

import { FactorAnalysisBars } from "./factorAnalysisBars/factorAnalysisBars";
import { FactorAnalysisChartModel } from "./factorAnalysisChartModel";

import cn from "./factorAnalysisChart.module.less";

type FactorAnalysisChartProps = {
  chartModel: FactorAnalysisChartModel;
};

const FactorAnalysisChart: FC<FactorAnalysisChartProps> = observer(({ chartModel }) => {
  const [isFullscreen, openFullscreen, closeFullscreen, switchFullscreen] = useBooleanState();

  const axes = useScaleFromBars(chartModel.bars, chartModel.axisTitle, undefined, undefined, 1, 10, 10);

  if (chartModel.bars === null) {
    return <></>;
  }

  return (
    <div className={cn.layout}>
      <FullScreen enabled={isFullscreen} onClose={closeFullscreen} onOpen={openFullscreen}>
        <div className={cn.titleContainer}>
          <Button onClick={switchFullscreen} icon={isFullscreen ? <ShrinkOutlined /> : <ArrowsAltOutlined />} />
        </div>
        <div className={cn.wrap}>
          <ChartContext axes={axes} className={cn.chart}>
            <TooltipContext.Provider value={chartModel.tooltipManager}>
              <FactorAnalysisBars bars={chartModel.bars} />
              <PointerLine />
            </TooltipContext.Provider>
          </ChartContext>
          <TooltipDiv manager={chartModel.tooltipManager} />
        </div>
      </FullScreen>
    </div>
  );
});

export { FactorAnalysisChart };
