import { useMemo } from "react";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";

import { HTRTypesModel } from "./tableModel";
import { DRow } from "./tableModel";

import cn from "./htrTypes.module.less";

const HTRTypes = observer(() => {
  const fact = useFact()!;
  const forecast = useForecast() ?? null;

  const store = useMemo(() => new HTRTypesModel(fact, forecast), [fact, forecast]);
  const columns = useMemo(
    (): ColumnRaw<DRow>[] => [
      {
        dataKey: "year",
        title: "Год",
        width: 100,
        isSticky: true,
      },
      ...[...(fact.producingObjects.values ?? [])].map(
        (po): ColumnRaw<DRow> => ({
          dataKey: po.id,
          title: po.title,
          width: { min: 300, max: 600, competitiveness: 1 },
          render: (htrTypeId: number | null, { value }) =>
            htrTypeId !== undefined && value?.year !== undefined ? (
              <SelectStorable
                values={[htrTypeId, undefined]}
                store={global.htrTypes}
                allowClear={true}
                setValues={(id: number | null) => store.updateTypeId(po.id, value.year, id)}
              />
            ) : null,
        })
      ),
    ],
    [fact, store]
  );
  const model = useMemo(
    () =>
      new TableModel(
        columns,
        store,
        {
          onRow: ({ indexPath, expand }) => ({
            className:
              indexPath[0] + 1 === 0
                ? cn.summaryRow
                : expand === undefined
                ? cn.tableRowPlain
                : indexPath.length === 1
                ? cn.tableRowPrimary
                : cn.tableRowSecondary,
          }),
        },
        {
          headerHeight: 39,
          rowHeight: 33,
          borderColor: "#f0f0f0",
        }
      ),
    [columns, store]
  );

  return (
    <TableContextProvider value={model}>
      <PageFrameTitlePortal model={model} onSave={store.submit} />
      <Widget headerClassName={cn.tableHeader} />
    </TableContextProvider>
  );
});

export { HTRTypes };
