import dayjs, { Dayjs } from "dayjs";
import { action, makeObservable, observable } from "mobx";

import {
  calculateInfrastructure,
  calculateMultipleInfrastructures,
  calculatePipeCost,
  ResponsePipeCost,
  SimulationInput,
  SimulationOutput,
} from "services/back/infrastructure/calculate";
import { PipeBoundaryCondition } from "services/back/infrastructure/types";

import { Infrastructure } from "./infrastructure";

class CalculateStore {
  hydraulicData?: SimulationOutput | null;
  hydraulicDataCollection: ({ date: Dayjs; drainSources: PipeBoundaryCondition[] } & SimulationOutput)[] | null = null;
  economicData: {
    mines: ResponsePipeCost["mines"]["res"];
    segments: ResponsePipeCost["segments"]["res"];
    stations: ResponsePipeCost["stations"]["res"];
  } | null = null;
  isCalculation: boolean = false;
  error?: string | null;

  constructor(private parent: Infrastructure) {
    makeObservable(this, {
      hydraulicData: observable,
      hydraulicDataCollection: observable,
      economicData: observable,
      calculateHydraulic: action,
      calculateEconomic: action,
      clearCalculation: action,
      isCalculation: observable,
    });
  }

  public calculateHydraulic = async (isMulti?: boolean) => {
    const { from: startYear, to: endYear } = this.parent.range;
    const [startDate, endDate] = [dayjs().year(startYear).startOf("year"), dayjs().year(endYear).endOf("year")];

    try {
      this.isCalculation = true;
      if (isMulti) {
        const result: ({ date: Dayjs; drainSources: PipeBoundaryCondition[] } & SimulationInput)[] = [];
        let currentDate = startDate;
        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
          const pipeSystemJSON = await this.parent.forSolverJSON(undefined, currentDate);
          result.push({ date: currentDate, ...pipeSystemJSON });
          currentDate = currentDate.add(1, "month");
        }
        const response = await calculateMultipleInfrastructures(result);
        this.hydraulicDataCollection = response;
        this.isCalculation = false;
      } else {
        const pipeSystemJSON = await this.parent.forSolverJSON();
        const result = await calculateInfrastructure(pipeSystemJSON);
        this.hydraulicData = result;
        this.isCalculation = false;
      }
    } catch (error) {
      console.error("Error during hydraulic calculation", error);
    } finally {
      this.isCalculation = false;
    }
  };

  public calculateEconomic = async () => {
    try {
      this.isCalculation = true;
      const pipeSystemJSON = await this.parent.forSolverJSON();
      const { mines, segments, stations } = await calculatePipeCost({
        ...pipeSystemJSON,
        scenarioId: this.parent.forecast?.id!,
      });
      this.economicData = { mines: mines.res, segments: segments.res, stations: stations.res };
      this.isCalculation = false;
    } catch (error) {
      console.error("Error during economic calculation", error);
    } finally {
      this.isCalculation = false;
    }
  };

  clearCalculation = () => {
    this.hydraulicData = null;
  };
}

export { CalculateStore };
