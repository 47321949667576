import { FC } from "react";

import { WellTypes } from "../techForecastMock";
import { WellParametersForm } from "../types";

import { LiquidDropRate } from "./liquidDropRate";
import { OilDropRate } from "./oilDropRate";
import { OperatingCoefficient } from "./operatingCoefficient";
import { WorkCriteria } from "./workCriteria";

import cn from "./settings.module.less";

type TechnologySettingsProps = {
  techParameters: WellParametersForm[];
  setTechParameters: {
    applyForAll: (path: string[], value: any) => void;
    applyForId: (path: string[], value: any, id: number, gtmId?: number | null) => void;
  };
  wellsNameMap?: Record<number, string>;
  chosenType?: WellTypes;
  type?: WellTypes;
};

const TechnologySettings: FC<TechnologySettingsProps> = ({ techParameters, setTechParameters, chosenType, type, wellsNameMap }) => {
  const settingWindows: FC<TechnologySettingsProps>[] = [LiquidDropRate, OilDropRate, WorkCriteria, OperatingCoefficient];

  return (
    <div className={chosenType === type ? cn.container : cn["hide-settings"]}>
      {techParameters.length === 0
        ? "Ни одна скважина не выбрана"
        : settingWindows.map((Window, index) => (
            <div className={cn["setting-windows"]} key={index}>
              <Window techParameters={techParameters} setTechParameters={setTechParameters} chosenType={type} wellsNameMap={wellsNameMap} />
            </div>
          ))}
    </div>
  );
};

export { TechnologySettings, type TechnologySettingsProps };
