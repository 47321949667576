import { FC, useEffect, useMemo } from "react";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { Button, Empty, Select, Switch } from "antd";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreen, FullScreenLoader } from "elements/fullScreen/fullScreen";
import { LazyInputNumber } from "elements/inputs/lazyInputNumber/lazyInputNumber";
import { LinkButton } from "elements/linkButton/linkButton";
import { useForecast } from "models/project/fact/forecast/forecast";
import { RankingSettings } from "models/project/fact/forecast/ranking/settings";
import { StaticDRow, StaticSettings } from "models/project/fact/forecast/ranking/settingsStatic";
import { YearsDRow, YearsSettings } from "models/project/fact/forecast/ranking/settingsYears";
import { useProject } from "models/project/project";
import { getSettings, saveSettings } from "services/back/ranking";

import { SettingsContextProvider } from "./settingsContextProvider";
import { SettingsModule, SettingsSection } from "./settingsModule";

import cn from "./settings.module.less";

const useYearColumns = (settings: RankingSettings) =>
  useMemo<ColumnRaw<YearsDRow>[]>(
    () => [
      {
        key: "index",
        title: "No.пп",
        isSticky: true,
        width: { min: 54, max: 54, competitiveness: 1 },
        render: (_, { expand, absoluteIndex }) => (expand === undefined ? absoluteIndex : <div />),
        onCell: () => ({ style: { justifyContent: "right" } }),
      },
      {
        key: "title",
        title: "Мероприятие",
        isSticky: true,
        width: { min: 250, max: 350, competitiveness: 1 },
      },
      {
        key: "measure",
        title: "Ед. измерения",
        isSticky: true,
        width: { min: 150, max: 350, competitiveness: 1 },
      },
      ...[...settings.yearsRange].map(
        (year): ColumnRaw<YearsDRow> => ({
          dataKey: year,
          title: year,
          width: { min: 100, max: 400, competitiveness: 1 },
          render: (yearValue, { value }) =>
            yearValue !== undefined ? (
              <LazyInputNumber value={yearValue} onUpdate={(newValue) => settings.setYearsSetting(newValue ?? 0, value!.key!, year)} />
            ) : null,
        })
      ),
    ],
    [settings]
  );

const useStaticSettingsColumns = (settings: RankingSettings) =>
  useMemo<ColumnRaw<StaticDRow>[]>(
    () => [
      {
        key: "index",
        title: "No.пп",
        isSticky: true,
        width: { min: 54, max: 54, competitiveness: 1 },
        render: (_, { expand, absoluteIndex }) => (expand === undefined ? absoluteIndex : <div />),
        onCell: () => ({ style: { justifyContent: "right" } }),
      },
      {
        dataKey: "title",
        title: "Мероприятие",
        isSticky: true,
        width: { min: 250, max: 650, competitiveness: 1 },
      },
      {
        dataKey: "workCost",
        title: "Стоимость работ",
        width: { min: 250, max: 650, competitiveness: 1 },
        render: (cost, { value }) =>
          cost === -1 ? null : (
            <LazyInputNumber value={cost} onUpdate={(newCost) => settings.setStaticSetting(newCost ?? 0, value!.key!, "workCost")} />
          ),
      },
      {
        dataKey: "workCostMeasure",
        title: "Ед. измерения",
        width: { min: 250, max: 650, competitiveness: 1 },
      },
      {
        dataKey: "teamsWork",
        title: "Выработка бригад",
        width: { min: 250, max: 650, competitiveness: 1 },
        render: (teamWork, { value }) =>
          teamWork === -1 ? null : (
            <LazyInputNumber value={teamWork} onUpdate={(newTeamWork) => settings.setStaticSetting(newTeamWork ?? 0, value!.key!, "teamsWork")} />
          ),
      },
      {
        dataKey: "teamsWorkMeasure",
        title: "Ед. измерения",
        width: { min: 250, max: 350, competitiveness: 1 },
      },
    ],
    [settings]
  );

const Settings: FC = observer(() => {
  const forecast = useForecast()!;
  const { id: scenarioId } = forecast;
  const project = useProject();

  const settings = forecast.rankingSettings;

  const [yearsStore, staticStore] = useMemo(
    () => [new YearsSettings(settings.yearsSettings, settings.yearsRange), new StaticSettings(settings.staticSettings)],
    [settings]
  );

  const yearsSettingsColumns = useYearColumns(settings);
  const staticSettingsColumns = useStaticSettingsColumns(settings);

  const tableYearsModel = useMemo(
    () =>
      new TableModel(
        yearsSettingsColumns,
        yearsStore,
        {
          onRow: ({ expand }) => ({
            className: expand === undefined ? cn.tableRowPlain : cn.tableRowPrimary,
          }),
        },
        {
          headerHeight: 39,
          rowHeight: 33,
          borderColor: "#f0f0f0",
        }
      ),
    [yearsSettingsColumns, yearsStore]
  );

  const tableStaticModel = useMemo(
    () =>
      new TableModel(
        staticSettingsColumns,
        staticStore,
        {
          onRow: ({ expand }) => ({
            className: expand === undefined ? cn.tableRowPlain : cn.tableRowPrimary,
          }),
        },
        {
          headerHeight: 39,
          rowHeight: 33,
          borderColor: "#f0f0f0",
        }
      ),
    [staticSettingsColumns, staticStore]
  );

  const {
    rankingSequential: { isLoading },
  } = forecast;

  useEffect(() => {
    settings.setLoading(true);
    getSettings(project?.id ?? 0, scenarioId)
      .then((newSettings) => {
        if (newSettings) {
          settings.setYearsSettings(newSettings.years);
          settings.setStaticSettings(newSettings.static);
        }
      })
      .finally(() => {
        settings.setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (settings.isLoading) {
    return <FullScreenLoader />;
  }

  if (isLoading) {
    return (
      <FullScreen>
        <Empty description={<>Для начала настройки планирования нужно настроить Очередность бурения </>}>
          <LinkButton to={`/${project?.id}/${scenarioId}/queue`}>Очередность бурения</LinkButton>
        </Empty>
      </FullScreen>
    );
  }

  return (
    <SettingsContextProvider value={settings}>
      <PageFrameTitlePortal
        onSave={async () => {
          saveSettings({ years: settings.yearsSettings, static: settings.staticSettings }, project?.id!, scenarioId);
        }}
      >
        <Button
          onClick={async () => {
            await saveSettings({ years: settings.yearsSettings, static: settings.staticSettings }, project?.id!, scenarioId);
            await settings.calculate();
          }}
          loading={settings.isCalculating}
        >
          Провести планирование
        </Button>
      </PageFrameTitlePortal>
      <SettingsModule title="Параметры по годам">
        <TableContextProvider value={tableYearsModel}>
          <div className={cn["years-container"]}>
            <Widget headerClassName={cn.tableHeader} />
          </div>
        </TableContextProvider>
      </SettingsModule>
      <SettingsModule title="Статичные параметры" className={cn.static}>
        <SettingsSection title="Стоимость работ и выработка">
          <TableContextProvider value={tableStaticModel}>
            <div className={cn["static-container"]}>
              <Widget headerClassName={cn.tableHeader} />
            </div>
          </TableContextProvider>
          {/* <Alert
            message={
              <>
                <span>Указанные значения удельной стоимости работ используются также в разделе </span>
                <LinkButton to={`/${project?.id}/${scenarioId}/invest/costs/`} type="link" className={cn.link}>
                  Параметры инвестиционной деятельности: удельные затраты.
                </LinkButton>
              </>
            }
            type="warning"
            showIcon
            className={cn.alert}
          /> */}
        </SettingsSection>
        <SettingsSection title="Дополнительные настройки">
          <div className={cn["additional"]}>
            <div className={cn.ecy}>Модель ECY</div>
            <div className={cn["additional-inputs"]}>
              <Select className={cn["cost-selector"]} disabled />
              <Switch className={cn.switch} disabled />
              <span className={cn.onns}>Учет затрат на ОНСС</span>
            </div>
          </div>
        </SettingsSection>
      </SettingsModule>
    </SettingsContextProvider>
  );
});

export { Settings };
