import { computed, makeAutoObservable, reaction } from "mobx";

import { CalcEffectTableNode, TableRow } from "../calcEffectTableModel";
import { CalcModel } from "../calcModel";

class CalcModelSimilarParam {
  public dataKey: string;
  public averageDaysWork: TableRow;
  public gtmAmount: TableRow;
  public relativeGTMAmount: TableRow;
  public decreaseCoefficients: TableRow;
  public averageIncrease: TableRow;
  public additionalMining: TableRow;
  public transferringMining: TableRow;

  public rawTransferringMining: (number | null)[][] = [];

  constructor(public node: CalcEffectTableNode, public calcModel: CalcModel) {
    makeAutoObservable(this, {
      decreaseCoefficientsForTransfering: computed,
    });
    this.dataKey = node.dataKey;
    this.averageDaysWork = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Среднее количество дней работы скважины"
    )!;
    this.gtmAmount = Array.from(node.children!).find((child) => child.rowInfo.title === "Количество ГТМ")!;
    this.relativeGTMAmount = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Удельное количество ГТМ"
    )!;
    this.decreaseCoefficients = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Коэффициент снижения эффекта ГТМ во времени"
    )!;
    this.averageIncrease = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Средний прирост дебита на одну скважину"
    )!;
    this.additionalMining = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Доп. добыча за год проведения ГТМ"
    )!;
    this.transferringMining = Array.from(node.children!).find(
      (child) => child.rowInfo.title === "Переходящая доп. добыча от ГТМ"
    )!;
    this.calcRawTransferring();

    // Переходящая добыча

    reaction(
      () => [
        this.decreaseCoefficientsForTransfering,
        ...Object.values(this.averageIncrease.data),
        ...Object.values(this.averageDaysWork.data),
        ...Object.values(this.gtmAmount.data),
        this.calcModel.getFirstNElements(this.calcModel.miningWellsModel.serviceCoefficient, 1),
      ],
      () => {
        this.calcRawTransferring();

        for (let i = 0; i < this.calcModel.range.length; ++i) {
          let result = null;

          for (let j = 0; j < this.calcModel.range.length; ++j) {
            if (this.rawTransferringMining[j][i]) {
              if (result === null) {
                result = this.rawTransferringMining[j][i];
              } else {
                result += this.rawTransferringMining[j][i]!;
              }
            }
          }
          if (this.rawTransferringMining[i][i] !== null && result !== null) {
            result -= this.rawTransferringMining[i][i]!;
          }
          this.transferringMining.mutationsManager?.updateWrapper(this.calcModel.range.from + i, result);
        }
      }
    );

    // Доп добыча за год проведения ГТМ

    reaction(
      () => [
        ...Object.values(this.gtmAmount.data),
        ...Object.values(this.averageIncrease.data),
        ...Object.values(this.averageDaysWork.data),
      ],
      () => {
        Object.keys(this.additionalMining.data).forEach((year) => {
          if (
            this.gtmAmount.data[+year] !== null &&
            this.averageIncrease.data[+year] !== null &&
            this.averageDaysWork.data[+year] !== null
          ) {
            const newValue =
              (this.gtmAmount.data[+year]! * this.averageIncrease.data[+year]! * this.averageDaysWork.data[+year]!) /
              1000;
            this.additionalMining.mutationsManager?.updateWrapper(+year, newValue);
          }
        });
      }
    );

    // Удельное количество ГТМ

    reaction(
      () => [
        ...Object.values(this.gtmAmount.data),
        ...Object.values(this.calcModel.miningWellsModel.wellsAmount.data),
        ...calcModel.getFirstNElements(this.relativeGTMAmount),
      ],
      (values) => {
        Object.keys(this.relativeGTMAmount.data).forEach((year, index) => {
          if (index < 3) {
            if (this.gtmAmount.data[+year] && this.calcModel.miningWellsModel.wellsAmount.data[+year])
              this.relativeGTMAmount.mutationsManager?.updateWrapper(
                +year,
                this.gtmAmount.data[+year]! / this.calcModel.miningWellsModel.wellsAmount.data[+year]!
              );
          } else {
            if (
              this.relativeGTMAmount.data[this.calcModel.range.from] ||
              this.relativeGTMAmount.data[this.calcModel.range.from + 1] ||
              this.relativeGTMAmount.data[this.calcModel.range.from + 2]
            ) {
              const meanItems = [
                this.relativeGTMAmount.data[+this.calcModel.range.from],
                this.relativeGTMAmount.data[+this.calcModel.range.from + 1],
                this.relativeGTMAmount.data[+this.calcModel.range.from + 2],
              ].filter((item) => item !== null);

              const mean = meanItems.reduce((prev, curr) => prev! + curr!, 0)! / meanItems.length;
              this.relativeGTMAmount.mutationsManager?.updateWrapper(+year, mean);
            }
          }
        });
      }
    );

    // Количество ГТМ

    reaction(
      () => [
        ...Object.values(this.calcModel.miningWellsModel.wellsAmount.data),
        ...Object.values(this.relativeGTMAmount.data),
      ],
      () => {
        Object.keys(this.gtmAmount.data).forEach((year, index) => {
          if (index > 2) {
            if (this.calcModel.miningWellsModel.wellsAmount.data[+year] && this.relativeGTMAmount.data[+year]) {
              const newValue =
                this.calcModel.miningWellsModel.wellsAmount.data[+year]! * this.relativeGTMAmount.data[+year]!;
              this.gtmAmount.mutationsManager?.updateWrapper(+year, Number(newValue));
            }
          }
        });
      }
    );
    reaction(
      () => [...Object.values(this.decreaseCoefficients.data), ...calcModel.getFirstNElements(this.averageIncrease)],
      () => {
        Object.keys(this.averageIncrease.data).forEach((year, index) => {
          if (index > 2) {
            if (this.decreaseCoefficients.data[+year]) {
              if (
                this.averageIncrease.data[this.calcModel.range.from] ||
                this.averageIncrease.data[this.calcModel.range.from + 1] ||
                this.averageIncrease.data[this.calcModel.range.from + 2]
              ) {
                const meanItems = [
                  this.averageIncrease.data[this.calcModel.range.from],
                  this.averageIncrease.data[this.calcModel.range.from + 1],
                  this.averageIncrease.data[this.calcModel.range.from + 2],
                ].filter((item) => item !== null);
                const mean = meanItems.reduce((prev, curr) => prev! + curr!, 0)! / meanItems.length;

                this.averageIncrease.mutationsManager?.updateWrapper(
                  +year,
                  mean * this.decreaseCoefficients.data[+year]!
                );
              }
            }
          }
        });
      }
    );

    reaction(
      () => Object.values(this.decreaseCoefficients.data),
      () => {
        const lastKey = this.decreaseCoefficients.lastUpdatedKey;
        Object.keys(this.decreaseCoefficients.data).forEach((year, index) => {
          if (lastKey !== null && isFinite(lastKey) && this.decreaseCoefficients.data[lastKey] !== null) {
            if (+year > lastKey) {
              this.decreaseCoefficients.mutationsManager?.updateWrapper(+year, this.decreaseCoefficients.data[lastKey]);
            }
          }
        });
      }
    );

    reaction(
      () => [...calcModel.getFirstNElements(this.averageDaysWork)],
      () => {
        Object.keys(this.averageDaysWork.data).forEach((year, index) => {
          if (index > 2) {
            if (
              this.averageDaysWork.data[this.calcModel.range.from] ||
              this.averageDaysWork.data[this.calcModel.range.from + 1] ||
              this.averageDaysWork.data[this.calcModel.range.from + 2]
            ) {
              const meanItems = [
                this.averageDaysWork.data[this.calcModel.range.from],
                this.averageDaysWork.data[this.calcModel.range.from + 1],
                this.averageDaysWork.data[this.calcModel.range.from + 2],
              ].filter((item) => item !== null);
              const mean = meanItems.reduce((prev, curr) => prev! + curr!, 0)! / meanItems.length;

              this.averageDaysWork.mutationsManager?.updateWrapper(+year, mean);
            }
          }
        });
      }
    );
  }

  get decreaseCoefficientsForTransfering() {
    return Array.from(
      this.calcModel.forecast.decreaseCoefficients.coefficients
        .find((coeff) => coeff.key === this.dataKey)
        ?.data?.values() ?? []
    );
  }

  calcRawTransferring = () => {
    const coefficients = Array.from(
      this.calcModel.forecast.decreaseCoefficients.coefficients
        .find((coeff) => coeff.key === this.dataKey)
        ?.data?.values() ?? []
    );

    const debetIncrease: (number | null)[][] = new Array(this.calcModel.range.length)
      .fill(null)
      .map((value, index) => new Array(coefficients.length + 1).fill(null));

    for (let i = 0; i < debetIncrease.length; ++i) {
      debetIncrease[i][0] = this.averageIncrease.data[this.calcModel.range.from + i];
      for (let j = 1; j < debetIncrease[i].length; ++j) {
        if (debetIncrease[i][j - 1] !== null && coefficients.at(i) !== null) {
          debetIncrease[i][j] = debetIncrease[i][j - 1]! * coefficients[i]!;
        }
      }
    }

    this.rawTransferringMining = new Array(this.calcModel.range.length)
      .fill(null)
      .map((value, index) => new Array(this.calcModel.range.length + coefficients.length + 1).fill(null));

    for (let i = 0; i < this.calcModel.range.length; ++i) {
      if (
        debetIncrease[i][0] &&
        this.gtmAmount.data[this.calcModel.range.from + i] &&
        this.averageDaysWork.data[this.calcModel.range.from + i]
      ) {
        this.rawTransferringMining[i][i] =
          (debetIncrease[i][0]! *
            this.gtmAmount.data[this.calcModel.range.from + i]! *
            this.averageDaysWork.data[this.calcModel.range.from + i]!) /
          1000;

        for (let j = 1; j < debetIncrease[i].length; ++j) {
          if (
            debetIncrease[i][j] &&
            this.gtmAmount.data[this.calcModel.range.from + i] &&
            this.calcModel.miningWellsModel.serviceCoefficient.data[this.calcModel.range.from + i]
          )
            this.rawTransferringMining[i][i + j] =
              (debetIncrease[i][j]! *
                this.gtmAmount.data[this.calcModel.range.from + i]! *
                365 *
                this.calcModel.miningWellsModel.serviceCoefficient.data[this.calcModel.range.from + i]!) /
              1000;
        }
      }
    }
  };
}

export { CalcModelSimilarParam };
