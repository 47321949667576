import { makeAutoObservable, when } from "mobx";

import { Ranking } from "services/back/ranking";
import { calculate, RCPInput } from "services/back/rcp/calculate";
import { Metric } from "services/finance/utils";
import { zip } from "utils/itertools";
import { Range } from "utils/range";

import { Forecast } from "../forecast";

import { RankingResults } from "./results/results";
import { StaticDRow } from "./settingsStatic";

const STATIC_SETTINGS_FIELDS = ["nns", "gs", "mss", "deepening", "radial", "transfer", "cutting"];
type StaticSetting = Pick<StaticDRow, "workCost" | "teamsWork">;
type StaticSettingsFields = (typeof STATIC_SETTINGS_FIELDS)[number];
type StaticSettingsData = Record<StaticSettingsFields, StaticSetting>;

const YEARS_SETTINGS_FIELDS = ["ebLimits", "reconstructionLimits", "krsTeams", "zbsTeams", "ebTeams"] as const;
type YearsSetting = number[];
type YearsSettingsFields = (typeof YEARS_SETTINGS_FIELDS)[number];
type YearsSettingsData = Record<YearsSettingsFields, YearsSetting>;

const getMetricValue = (metrics: Metric[], key: number) =>
  metrics.find(({ key: mKey }) => key === mKey)!.values![0] ?? 0;

const initStaticSettings = (metrics: Metric[]) => ({
  nns: {
    workCost: getMetricValue(metrics, 41),
    teamsWork: 0,
  },
  gs: {
    workCost: getMetricValue(metrics, 42),
    teamsWork: 0,
  },
  mss: {
    workCost: getMetricValue(metrics, 43),
    teamsWork: 0,
  },
  deepening: {
    workCost: getMetricValue(metrics, 48),
    teamsWork: 0,
  },
  radial: {
    workCost: getMetricValue(metrics, 49),
    teamsWork: 0,
  },
  transfer: {
    workCost: getMetricValue(metrics, 47),
    teamsWork: 0,
  },
  cutting: {
    workCost: getMetricValue(metrics, 50),
    teamsWork: 0,
  },
});

const getLimitsArray = <GTMGroup extends "ЭБ" | "КРС" | "ЗБС" | "ГТМ">(
  range: Range,
  limits: YearsSetting,
  gtmGroup: GTMGroup
) => {
  return Array.from(zip([...range], limits), ([year, val]) => ({
    gtmGroup,
    val,
    year,
  }));
};

const getMax = (yearsData: YearsSetting) => {
  return Math.max(...yearsData);
};

const wellsByMine = (mineId: number, wells: Ranking["wells"]) =>
  wells.filter(({ mineId: mId }) => mId === mineId).map(({ wellId }) => wellId);

const createCrews = <CrewInfo extends { gtmGroup: "ЭБ" | "КРС" | "ЗБС" }>(number: number, crewInfo: CrewInfo) =>
  new Array(number).fill(null).map((_, index) => ({ id: index, ...crewInfo }));

class RankingSettings {
  public readonly staticSettings: StaticSettingsData;
  public readonly yearsSettings: YearsSettingsData;

  public result: RankingResults | undefined;
  public isCalculating: boolean = false;

  public isLoading = false;

  constructor(private forecast: Forecast) {
    const metrics = forecast.investCosts.scalar!.flatten();

    this.staticSettings = initStaticSettings(metrics);
    this.yearsSettings = YEARS_SETTINGS_FIELDS.reduce((obj, field) => {
      obj[field] = this.yearsRange.array.fill(20);
      return obj;
    }, {} as YearsSettingsData);

    makeAutoObservable(this);
  }

  public get yearsRange(): Range {
    return this.forecast.range;
  }

  setStaticSetting(value: number, rowKey: StaticSettingsFields, key: "workCost" | "teamsWork") {
    this.staticSettings[rowKey][key] = value;
  }

  setYearsSetting(value: number, rowKey: YearsSettingsFields, year: number) {
    const yearIdx = this.forecast.range.id(year);
    this.yearsSettings[rowKey][yearIdx] = value;
  }

  public async settingsData(): Promise<RCPInput> {
    const res: RCPInput = {
      years: [...this.yearsRange],
      gtms: [],
      wells: [],
      mines: [],
      crews: [],
      investmentLimits: [],
      crewLimits: [],
    };

    await when(() => this.forecast.rankingSequential.isLoading === false);

    const { gtms, wells, mines } = this.forecast.rankingSequential.currentResult;

    const wellIds = new Set(wells.map(({ wellId }) => wellId));

    res.gtms = gtms.map(({ gtmId, gtmTypeTitle, wellId }, index) => {
      const gtmGroup = gtmTypeTitle === "Зарезка боковых (вторых) стволов" ? "ЗБС" : "КРС";
      const gtmPrice =
        {
          "Углубление забоя": this.staticSettings.deepening.workCost,
          "Радиальное бурение": this.staticSettings.radial.workCost,
          "Перевод на другой горизонт": this.staticSettings.transfer.workCost,
          "Зарезка боковых (вторых) стволов": this.staticSettings.cutting.workCost,
        }[gtmTypeTitle] ?? 0;

      wellIds.add(wellId);
      return {
        id: gtmId,
        priority: index,
        gtmGroup,
        wellId,
        gtmType: gtmTypeTitle,
        gtmPrice,
      };
    });
    res.mines = mines.map(({ mineId }, index) => {
      return {
        id: mineId,
        priority: index,
        gtmGroup: "ЭБ",
        wellCluster: {
          idListOfWells: wellsByMine(mineId, wells),
        },
      };
    });
    wellIds.forEach((wellId) => {
      const well = this.forecast.wells.at(wellId);
      if (!well) {
        console.error(`well not found: wellId = ${wellId}`);
        return;
      }
      const { type, length, fond } = well;
      if (!type || length === null) {
        console.error(`well type or length is not set. ${wellId}`);
        return;
      }

      if (fond === "Base") {
        res.wells.push({
          id: wellId,
          wellType: type,
          length: length,
          isFactual: true,
        });
        return;
      }

      const prodDrillingPrice =
        {
          ННС: this.staticSettings.nns.workCost,
          ГС: this.staticSettings.gs.workCost,
          "МС/МЗС": this.staticSettings.mss.workCost,
        }[type] ?? 0;

      res.wells.push({
        id: wellId,
        wellType: type,
        length: length,
        prodDrillingPrice,
        isFactual: false,
      });
    });
    res.crews = [
      ...createCrews(getMax(this.yearsSettings.ebTeams), {
        gtmGroup: "ЭБ",
        nnsProduction: this.staticSettings.nns.teamsWork,
        gsProduction: this.staticSettings.gs.teamsWork,
        msProduction: this.staticSettings.mss.teamsWork,
      }),
      ...createCrews(getMax(this.yearsSettings.krsTeams), {
        gtmGroup: "КРС",
        transferProduction: this.staticSettings.transfer.teamsWork,
        radDrillingProduction: this.staticSettings.radial.teamsWork,
        deepeningProduction: this.staticSettings.deepening.teamsWork,
      }),
      ...createCrews(getMax(this.yearsSettings.zbsTeams), {
        gtmGroup: "ЗБС",
        zbsProduction: this.staticSettings.cutting.teamsWork,
      }),
    ].map(({ id, ...rest }, i) => ({ ...rest, id: i }));
    res.crewLimits = [
      ...getLimitsArray(this.yearsRange, this.yearsSettings.ebTeams, "ЭБ"),
      ...getLimitsArray(this.yearsRange, this.yearsSettings.krsTeams, "КРС"),
      ...getLimitsArray(this.yearsRange, this.yearsSettings.zbsTeams, "ЗБС"),
    ];
    res.investmentLimits = [
      ...getLimitsArray(this.yearsRange, this.yearsSettings.ebLimits, "ЭБ"),
      ...getLimitsArray(this.yearsRange, this.yearsSettings.reconstructionLimits, "ГТМ"),
    ];

    return res;
  }

  setLoading(state: boolean) {
    this.isLoading = state;
  }

  calculate = async () => {
    if (this.isCalculating) {
      return;
    }
    this.isCalculating = true;
    this.result = undefined;

    try {
      const input = await this.settingsData();
      const output = await calculate(this.forecast.id, input);
      this.result = new RankingResults(
        this.forecast,
        {
          invest: {
            eb: this.yearsSettings.ebLimits,
            reconstruction: this.yearsSettings.reconstructionLimits,
          },
          crews: {
            ЭБ: this.yearsSettings.ebTeams,
            ЗБС: this.yearsSettings.zbsTeams,
            КРС: this.yearsSettings.krsTeams,
          },
        },
        this.forecast.rankingSequential.ranks,
        input,
        output
      );
    } catch (err) {
      console.error(err);
    }

    this.isCalculating = false;
  };

  setYearsSettings(newSettings: YearsSettingsData) {
    for (const key of YEARS_SETTINGS_FIELDS) {
      newSettings[key as YearsSettingsFields].forEach((value, index) => {
        this.setYearsSetting(value, key as YearsSettingsFields, this.forecast.range.from + index);
      });
    }
  }

  setStaticSettings(newSettings: StaticSettingsData) {
    for (const key of STATIC_SETTINGS_FIELDS) {
      const staticSetting = newSettings[key];

      this.setStaticSetting(staticSetting.teamsWork, key, "teamsWork");
      this.setStaticSetting(staticSetting.workCost, key, "workCost");
    }
  }
}

export { RankingSettings };
export type { StaticSettingsData, StaticSettingsFields, YearsSettingsData, YearsSettingsFields };
