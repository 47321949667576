import { action, computed, makeObservable, observable, runInAction, transaction, when } from "mobx";

import { UOM } from "elements/uom";
import { global } from "models/global";
import { CostDump } from "services/back/costs";
import type { ParamsFlat, ParamsResponse } from "services/finance/finance";
import type { MetricTree as RawMetric } from "services/finance/finance";
import { conditionallyArr } from "utils/conditionally";
import { Range } from "utils/range";

import { OptionedParams } from "./optionedParams/optionedParams";
import { ParamsNode } from "./paramsNode";
import { ParamsTable } from "./paramsTable";

const CLONE_SERVICES_PLUG = {
  get: () => {
    console.error("Попытка загрузки данных для копии объекта параметров");
    return Promise.reject();
  },
  set: () => {
    console.error("Попытка сохранения копии без обращения к оригинальному объекту");
    return Promise.reject();
  },
};

const TABLE_SAVE_PLUG = () => {
  console.error("Попытка прямого сохранения таблицы-компонента объекта параметра");
  return Promise.reject();
};

const tableRequest = (data: RawMetric[]) => () => Promise.resolve(data);

type ParamsServices = Readonly<
  [(range: Range) => Promise<ParamsResponse>, (data: ParamsFlat) => Promise<ParamsResponse>]
>;

class Params {
  public scalar: undefined | null | ParamsNode;
  public table: undefined | null | ParamsTable;
  public optionedScalars: undefined | null | OptionedParams = null;
  public years: Range | undefined;
  #origin: Params | null = null;

  protected async postOptionedScalars(_: OptionedParams): Promise<CostDump[]> {
    console.error("postOptionedScalars call for instance without implementation");
    return [];
  }

  protected applyOptionedScalars(_: CostDump[]): void {
    console.error("applyOptionedScalars call for instance without implementation");
  }

  private get getParams() {
    return this.services[0];
  }

  private get setParams() {
    return this.services[1];
  }

  constructor(
    public readonly title: string,
    years: Range | Promise<Range>,
    private services: ParamsServices,
    private TableModel = ParamsTable
  ) {
    makeObservable<Params, "applyData">(this, {
      isLoading: computed,
      isUpdated: computed,
      isCompleted: computed,
      isCompletedTable: computed,
      isCompletedScalars: computed,
      clone: computed,
      applyData: action,
      scalar: observable,
      table: observable,
      optionedScalars: observable,
    });

    if (this.getParams !== CLONE_SERVICES_PLUG.get) {
      if (years instanceof Promise) {
        years.then((resolvedYears) => {
          this.years = resolvedYears;
          this.getParams(resolvedYears).then(this.applyData.bind(this));
        });
      } else {
        this.years = years;
        this.getParams(years).then(this.applyData.bind(this));
      }
    }
  }

  protected applyData({ table, scalar }: ParamsResponse): void {
    transaction(() => {
      console.assert(
        this.years !== undefined,
        "Попытка сохранения модели до резолвинга годов. Ситуация возможно только в случае НДД, если модель попытались клонировать до загрузки ЛУ и до завершения загрузки параметров модели"
      );
      this.table = table ? new this.TableModel(this.title, this.years!, tableRequest(table), TABLE_SAVE_PLUG) : null;
      this.scalar = scalar
        ? new ParamsNode(
            -1,
            "root",
            scalar.map((node) => ParamsNode.fromRaw(node)),
            new UOM(0, 0, global.uomResolver),
            null
          )
        : null;
      this.scalar?.propagateParent();
    });
  }

  get isOnlyTable(): boolean | undefined {
    if (this.isLoading) {
      return undefined;
    }
    return this.scalar === null && this.optionedScalars === null && this.table !== null;
  }

  get isHaveTable(): boolean | undefined {
    if (this.isLoading) {
      return undefined;
    }
    return this.table !== null;
  }

  public get isLoading(): boolean {
    return (
      this.scalar === undefined ||
      this.optionedScalars === undefined ||
      this.table === undefined ||
      this.table?.isLoading === true
    );
  }

  public get isUpdated(): boolean {
    if (this.#origin === null) {
      console.error("Проверка обновлённости для оригинала объекта");
      return false;
    }
    console.assert(this.isLoading === false, "Запрос статуса до завершения загрузки");
    if (this.scalar === null || this.#origin.scalar === null) {
      if (this.scalar !== this.#origin.scalar) {
        return true;
      }
    } else {
      if (!ParamsNode.isEqual(this.scalar!, this.#origin.scalar!)) {
        return true;
      }
    }
    if (this.table === null || this.#origin.table === null) {
      if (this.table !== this.#origin.table) {
        return true;
      }
    } else {
      if (this.table!.isUpdated) {
        return true;
      }
    }
    if (this.optionedScalars) {
      if (this.optionedScalars.isUpdated) {
        return true;
      }
    }
    return false;
  }

  public get isCompletedScalars(): boolean {
    if (this.isLoading) {
      return false;
    }
    return this.scalar === null || this.scalar!.isCompleted;
  }

  public get isCompletedTable(): boolean {
    if (this.isLoading) {
      return false;
    }
    return this.table === null || this.table!.isCompleted;
  }

  public get isCompleted(): boolean {
    if (this.isLoading) {
      return false;
    }
    return this.isCompletedScalars && this.isCompletedTable;
  }

  public get clone(): Params {
    console.assert(
      this.years !== undefined,
      "Попытка клонирования модели до резолвинга годов. Ситуация возможно только в случае НДД, если модель попытались клонировать до загрузки ЛУ и до завершения загрузки параметров модели"
    );
    console.assert(this.isLoading === false, "попытка клонирования до завершения загрузки");
    const result = new Params(this.title, this.years!, [CLONE_SERVICES_PLUG.get, CLONE_SERVICES_PLUG.set]);
    transaction(() =>
      runInAction(() => {
        result.table = this.table?.clone ?? null;
        result.scalar = this.scalar?.cloneAsRoot() ?? null;
        result.optionedScalars = this.optionedScalars?.clone() ?? null;
        result.#origin = this;
      })
    );
    return result;
  }

  private async postModel(updatedModel: Params): Promise<[ParamsResponse, CostDump[] | undefined]> {
    return Promise.all([
      this.setParams({
        scalar: updatedModel.scalar?.children?.flatMap((node) => node.flatten()),
        table: updatedModel.table?.metrics!.flatMap((m) => m.flatten()),
      }),
      ...conditionallyArr(this.optionedScalars !== null && this.optionedScalars !== undefined, () =>
        this.postOptionedScalars(updatedModel.optionedScalars!)
      ),
    ]) as any;
  }

  public async cloneInstance(services: ParamsServices): Promise<Params> {
    await when(() => this.isLoading === false);
    const result = this.clone;
    result.#origin = null;
    result.services = services;
    await result.postModel(result);
    return result;
  }

  public async update(updatedModel: Params): Promise<void> {
    if (updatedModel === null) return;

    return this.postModel(updatedModel).then(([params, costs]) => {
      this.applyData(params);
      if (costs) {
        this.optionedScalars?.setParams(costs);
      }
    });
  }
}

export { Params, type ParamsServices };
