import { FC } from "react";

import { TechForecastResultAxisInfo, TechForecastResultChart } from "features/techForecastResultChart/techForecastResultChart";

import { GetOnChangeType } from "./results";

import cn from "./resultTab.module.less";

const isChartEmpty = ({ curveForecastData, curveFactData, curveFactForecastData }: TechForecastResultAxisInfo): boolean =>
  ((Array.isArray(curveFactData?.x) && curveFactData?.x.length === 0) || !curveFactData) &&
  Array.isArray(curveForecastData?.x) &&
  curveForecastData?.x.length === 0 &&
  ((Array.isArray(curveFactForecastData?.x) && curveFactForecastData?.x.length === 0) || !curveFactForecastData);

type ResultTabProps = {
  start: number;
  end: number;
  oil: TechForecastResultAxisInfo;
  liquid: TechForecastResultAxisInfo;
  oilRelativeDensity: number;
  disabledCoefficients?: boolean;
  disableVerticalLines?: boolean;
  getOnChartChange: GetOnChangeType;
};

const ResultTab: FC<ResultTabProps> = ({
  oil,
  liquid,
  oilRelativeDensity,
  disabledCoefficients,
  disableVerticalLines,
  start,
  end,
  getOnChartChange,
}) => {
  return (
    <div className={cn["result-tab"]}>
      <div className={cn["chart-container"]}>
        {isChartEmpty(oil) ? (
          "Нет данных для отображения"
        ) : (
          <TechForecastResultChart
            oil={oil}
            liquid={liquid}
            rho={oilRelativeDensity}
            start={start}
            end={end}
            a={0}
            k={0}
            disabledCoefficients={disabledCoefficients}
            disableVerticalLines={disableVerticalLines}
            getOnChangeCurves={getOnChartChange}
          />
        )}
      </div>
    </div>
  );
};

export { ResultTab };
