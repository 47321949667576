import { byPairs, zipN } from "utils/itertools";
import { req } from "utils/request";

const PRODUCTION_FIELDS_BACKEND = [
  "oil_prod",
  "oil_rate",
  "water_prod",
  "water_prod_m3",
  "liquid_prod",
  "liquid_prod_m3",
  "liquid_m3_rate",
  "apg_prod",
  "prod_time_ratio",
  "prod_days",
  "water_inj",
  "gas_inj",
  "inj_time_ratio",
  "inj_days",
] as const;

const PRODUCTION_FIELDS = [
  "prod_days",
  "oil_prod",
  "oil_rate",
  "liquid_prod",
  "liquid_prod_t",
  "water_prod",
  "apg_prod",
  "water_inj",
  "inj_days",
] as const;

type ProductionFields = (typeof PRODUCTION_FIELDS)[number];
type ProductionFieldsBackend = (typeof PRODUCTION_FIELDS_BACKEND)[number];

type StratumData = {
  stratumId: number;
  status: "prod" | "inj";
  start: [year: number, month: number];
  end: [year: number, month: number];
} & Record<ProductionFields, (number | null)[]>;

type WellData = {
  wellId: number;
  stratums: StratumData[];
};

type WellDataBackend = {
  well_id: number;
  stratums: (Record<ProductionFieldsBackend, (number | null)[]> & {
    stratum_id: number;
    month: number[];
    year: number[];
  })[];
};
async function getProductions(project_id: number, scenario_id?: number): Promise<WellData[] | null> {
  const response = await req.get<{ data: WellDataBackend[] | null }>(
    `productions/?${req.args({
      project_id,
      scenario_id,
      by_month: true,
      is_total: false,
    })}`
  );
  if (!response.data || response.data.length === 0) {
    return null;
  }
  return response.data.map(back2Raw);
}

type ProductionId = {
  wellId: number;
  gtmId?: number;
};

async function getProduction(
  { wellId, gtmId }: ProductionId,
  project_id: number,
  scenario_id?: number
): Promise<WellData | null> {
  const response = await req.get<{ data: WellDataBackend[] }>(
    `productions/?${req.args({
      well_id: wellId,
      gtm_id: gtmId,
      project_id,
      scenario_id,
      by_month: true,
      is_total: false,
    })}`
  );
  if (!response.data || response.data.length === 0) return null;
  const [wellProd] = response.data;
  return back2Raw(wellProd);
}

function back2Raw(wellData: WellDataBackend): WellData {
  console.assert(wellData.well_id !== null);
  return {
    wellId: wellData.well_id,
    stratums: wellData.stratums.flatMap(processStratumData),
  };
}

function processStratumData(stratumData: WellDataBackend["stratums"][number]): StratumData[] {
  if (stratumData.stratum_id === null) {
    console.error("production with stratum_id = null");
    return [];
  }
  const thresholds = [0, ...extractStratumPeriodThresholds(stratumData), undefined];

  const result: StratumData[] = [];
  for (const [start, end] of byPairs(thresholds)) {
    const adjusted = adjustStratumData({
      stratum_id: stratumData.stratum_id,
      month: stratumData.month.slice(start, end),
      year: stratumData.year.slice(start, end),
      ...(Object.fromEntries(
        PRODUCTION_FIELDS_BACKEND.map((field) => [field, stratumData[field].slice(start, end)])
      ) as Record<ProductionFieldsBackend, (number | null)[]>),
    });
    if (adjusted !== null) {
      result.push(adjusted);
    }
  }
  return result;
}

function adjustStratumData(stratumData: WellDataBackend["stratums"][number]): StratumData | null {
  const length = stratumData.month.length;
  if (length === 0) {
    console.error(`got empty stratumData`, stratumData);
    return null;
  }
  console.assert(stratumData.year.length === length);
  for (const field of PRODUCTION_FIELDS_BACKEND) {
    console.assert(
      stratumData[field] && stratumData[field].length === length,
      `wrong length. ${stratumData.stratum_id} ${field}`,
      stratumData
    );
  }
  const status = identifyIntervalStatus(stratumData.inj_time_ratio[0]);
  const startMonth = stratumData.month[0];
  const startYear = stratumData.year[0];
  const endMonth = stratumData.month[length - 1];
  const endYear = stratumData.year[length - 1];
  return {
    stratumId: stratumData.stratum_id,
    start: [startYear, startMonth],
    end: [endYear, endMonth],
    status,
    prod_days: stratumData.prod_days,
    inj_days: stratumData.inj_days,
    oil_prod: stratumData.oil_prod.map((v) => v && v / 1000),
    oil_rate: stratumData.oil_rate.map((v) => v && v / 1000),
    liquid_prod: stratumData.liquid_prod_m3.map((v) => v && v / 1000),
    liquid_prod_t: stratumData.liquid_prod.map((v) => v && v / 1000),
    water_prod: stratumData.water_prod.map((v) => v && v / 1000),
    apg_prod: stratumData.apg_prod.map((v) => v && v / 1_000_000),
    water_inj: stratumData.water_inj.map((v) => v && v / 1000),
  };
}

function nextDate(month: number, year: number): [month: number, year: number] {
  return month === 12 ? [1, year + 1] : [month + 1, year];
}

function identifyIntervalStatus(inj_time_ratio: number | null): "prod" | "inj" {
  return inj_time_ratio === 0 ? "prod" : "inj";
}

function extractStratumPeriodThresholds(stratumData: WellDataBackend["stratums"][number]): number[] {
  const { month, year, inj_time_ratio } = stratumData;
  const data = [month, year, inj_time_ratio];
  const length = year.length;
  console.assert(data.every((val) => val.length === length));

  if (length === 0) {
    return [];
  }
  const result: number[] = [];

  const datums = [...zipN(month, year, inj_time_ratio.map(identifyIntervalStatus))];
  let i = 0;
  for (const [[prevMonth, prevYear, prevStatus], [currMonth, currYear, currStatus]] of byPairs(datums)) {
    const [prevNextMonth, prevNextYear] = nextDate(prevMonth, prevYear);
    ++i;
    if (prevNextMonth === currMonth && prevNextYear === currYear && prevStatus === currStatus) {
      continue;
    }
    result.push(i);
  }
  return result;
}

export type { ProductionFields, StratumData, WellData };
export { getProduction, getProductions, PRODUCTION_FIELDS };
