import { memo, useEffect, useState } from "react";
import { DatePicker, Typography } from "antd";
import classNames from "classnames";
import { Dayjs } from "dayjs";

import { Select } from "elements/inputs/select";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { ModelContentComponentType } from "elements/modal/modal";
import { LoadableStoreResolver } from "models/loadableStore/loadableStoreResolver";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { ProducingObject } from "models/project/producingObject/producingObject";

import cn from "./periodForm.module.less";

const { RangePicker } = DatePicker;

type PeriodData = {
  well: number;
  range: [Dayjs, Dayjs];
  producingObject: number;
};

type PeriodInput = {
  data: PeriodData | null;
  disabledDate?: (selectedWellId: number) => (currentDate: Dayjs) => boolean;
  producingObjectIds?: (selectedWellId: number) => number[];
};

const PeriodForm: ModelContentComponentType<PeriodData, PeriodInput> = memo(({ dataRef, setValid, setOnAccept }) => {
  const fact = useFact()!;
  const fc = useForecast()!;

  const { from, to } = fact.forecastDateRange;

  const [result, setResult] = useState<{
    well?: number;
    range: [Dayjs | null, Dayjs | null];
    producingObject?: number;
  }>(dataRef.current.data ?? { range: [null, null] });

  const [prodObjectsResolver, setProdObjectsResolver] = useState<LoadableStoreResolver<ProducingObject> | null>(null);

  const onWellChange = (well: number) => {
    setResult((v) => ({ ...v, well }));
    const prodObjectsIds = dataRef.current.producingObjectIds?.(well) ?? [];
    setProdObjectsResolver(new LoadableStoreResolver(fact.producingObjects, prodObjectsIds));
  };
  const onRangeChange = (range: [Dayjs | null, Dayjs | null]) => setResult((v) => ({ ...v, range }));
  const onObjectChange = (producingObject: number) => setResult((v) => ({ ...v, producingObject }));

  useEffect(() => {
    if (typeof result.well === "number" && result.range?.[0] && result.range?.[1] && result.producingObject) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [result, setValid]);

  setOnAccept(async () => ({ result: true, data: result as PeriodData }));

  return (
    <div className={cn.modal}>
      <Typography.Text className={cn.span4}>Скважина</Typography.Text>
      <SelectStorable
        values={result.well ? [result.well, undefined] : undefined}
        onChange={onWellChange}
        className={cn.span4}
        variant="outlined"
        store={fc.wells}
        placeholder="Выбрать"
      />
      <Typography.Text>Дата начала</Typography.Text>
      <Typography.Text>Дата окончания</Typography.Text>
      <Typography.Text className={cn.span2}>Объект разработки</Typography.Text>
      <RangePicker
        value={result.range}
        onChange={onRangeChange}
        picker="month"
        allowEmpty
        minDate={from}
        maxDate={to}
        disabled={result.well === undefined}
        disabledDate={result.well !== undefined ? dataRef.current.disabledDate?.(result.well) : undefined}
        className={cn.spanInput}
      />
      {prodObjectsResolver ? (
        <SelectStorable
          disabled={result.well === undefined}
          onChange={onObjectChange}
          values={result.producingObject ? [result.producingObject, undefined] : undefined}
          className={classNames(cn.producing, cn.span2)}
          variant="outlined"
          store={prodObjectsResolver}
          placeholder="Выбрать"
        />
      ) : (
        <Select disabled className={classNames(cn.producing, cn.span2)} />
      )}
    </div>
  );
});

export { type PeriodData, PeriodForm, type PeriodInput };
