import { FC, ReactNode } from "react";

import { CustomOptionsSelect } from "elements/inputs/customOptionSelect/customOptionSelect";
import { Label } from "elements/inputs/label/label";

import { WellTypes } from "../../techForecastMock";
import { DropCurvesMethods, WellParametersForm } from "../../types";
import { getPropertyFromNestedObject } from "../../utils";
import { CustomInput } from "../customInput";
import { DropRateChildProps } from "../dropRate";

import cn from "./dropCurves.module.less";

type DoubleInputBlockProps = {
  title: string;
  path: string;
  techParameters: WellParametersForm;
  onMinChange: (value: number | null) => void;
  onMaxChange: (value: number | null) => void;
};

type DropCurvesDoubleInputs = "a" | "k";

type DropCurvesSelectOptions = {
  value: DropCurvesMethods;
  label: ReactNode;
};

type DropCurvesInputProps = {
  method: DropCurvesMethods;
  chosenType: WellTypes;
  techParameters: WellParametersForm;
  currentMethod: DropCurvesMethods;
  getOnDoubleInputChange: (id: DropCurvesDoubleInputs) => {
    onMinChange: (value: number | null) => void;
    onMaxChange: (value: number | null) => void;
  };
  getOnSingleInputChange: (id: DropCurvesDoubleInputs) => (value: number | null) => void;
};

const DoubleInputBlock: FC<DoubleInputBlockProps> = ({ path, title, techParameters, onMinChange, onMaxChange }) => {
  return (
    <Label title={title}>
      <div className={cn["input-block"]}>
        <div>
          min
          <CustomInput
            path={`${path},min`}
            className={cn.input}
            onSave={onMinChange}
            techParameters={techParameters}
            min={0}
            max={getPropertyFromNestedObject(techParameters, `${path},max`) ?? 999999}
            required
          />
        </div>
        <div>
          max
          <CustomInput
            path={`${path},max`}
            className={cn.input}
            onSave={onMaxChange}
            techParameters={techParameters}
            min={getPropertyFromNestedObject(techParameters, `${path},min`) ?? 0}
            max={999999}
            required
          />
        </div>
      </div>
    </Label>
  );
};

const DropCurvesInput: FC<DropCurvesInputProps> = ({
  chosenType,
  method,
  currentMethod,
  techParameters,
  getOnDoubleInputChange,
  getOnSingleInputChange,
}) => {
  const dropCurvesCoeffMap: Record<DropCurvesMethods, string[]> = {
    arps: ["a", "k"],
    duong: ["a", "m"],
    exponential: ["n", "τ"],
  };

  return currentMethod === method ? (
    chosenType !== "base" ? (
      <>
        <Label title={`Коэффициент ${dropCurvesCoeffMap[method][0]}`}>
          <CustomInput
            path={`oilDropRate,dropCurves,${method},a,min`}
            onSave={getOnSingleInputChange("a")}
            techParameters={techParameters}
            min={0}
            max={999999}
            required
          />
        </Label>
        <Label title={`Коэффициент ${dropCurvesCoeffMap[method][1]}`}>
          <CustomInput
            path={`oilDropRate,dropCurves,${method},k,min`}
            onSave={getOnSingleInputChange("k")}
            techParameters={techParameters}
            min={0}
            max={999999}
            required
          />
        </Label>
      </>
    ) : (
      <>
        <DoubleInputBlock
          title={`Коэффициент ${dropCurvesCoeffMap[method][0]}`}
          path={`oilDropRate,dropCurves,${method},a`}
          techParameters={techParameters}
          {...getOnDoubleInputChange("a")}
        />
        <DoubleInputBlock
          title={`Коэффициент ${dropCurvesCoeffMap[method][1]}`}
          path={`oilDropRate,dropCurves,${method},k`}
          techParameters={techParameters}
          {...getOnDoubleInputChange("k")}
        />
      </>
    )
  ) : null;
};

const DropCurves: FC<DropRateChildProps> = ({ techParameters, setTechParameters, chosenType }) => {
  const methodOptions: DropCurvesSelectOptions[] = [
    {
      value: "arps",
      label: "Арпса",
    },
    {
      value: "duong",
      label: "Дуонга",
    },
    {
      value: "exponential",
      label: "Растянутая экспоненц. кривая",
    },
  ];

  const onSelectChange = (value: string) => {
    setTechParameters.applyForAll(["oilDropRate", "dropCurves", "method"], value);
  };

  const { method } = techParameters[0].oilDropRate.dropCurves;

  const getOnDoubleInputChange = (id: DropCurvesDoubleInputs) => {
    return {
      onMinChange: (value: number | null) => setTechParameters.applyForAll(["oilDropRate", "dropCurves", method, id, "min"], value),
      onMaxChange: (value: number | null) => setTechParameters.applyForAll(["oilDropRate", "dropCurves", method, id, "max"], value),
    };
  };

  const getOnSingleInputChange = (id: DropCurvesDoubleInputs) => (value: number | null) =>
    setTechParameters.applyForAll(["oilDropRate", "dropCurves", method, id], { max: value, min: value });

  return (
    <>
      <Label title="Метод">
        <CustomOptionsSelect
          style={{ width: "100%", fontSize: "12px" }}
          onSelect={onSelectChange}
          options={methodOptions}
          optionclass={cn["select-option"]}
          value={method}
        />
      </Label>
      {
        <>
          <DropCurvesInput
            method="arps"
            currentMethod={method}
            chosenType={chosenType ?? "base"}
            techParameters={techParameters[0]}
            getOnDoubleInputChange={getOnDoubleInputChange}
            getOnSingleInputChange={getOnSingleInputChange}
          />
          <DropCurvesInput
            method="duong"
            currentMethod={method}
            chosenType={chosenType ?? "base"}
            techParameters={techParameters[0]}
            getOnDoubleInputChange={getOnDoubleInputChange}
            getOnSingleInputChange={getOnSingleInputChange}
          />
          <DropCurvesInput
            method="exponential"
            currentMethod={method}
            chosenType={chosenType ?? "base"}
            techParameters={techParameters[0]}
            getOnDoubleInputChange={getOnDoubleInputChange}
            getOnSingleInputChange={getOnSingleInputChange}
          />
        </>
      }
    </>
  );
};

export { DropCurves, type DropCurvesDoubleInputs };
