import { FC, ReactNode } from "react";

import { Icon } from "elements/icon/icon";

import cn from "./dropRate.module.less";

type DropRateProps = {
  title: string;
  children: ReactNode;
  icon?: ReactNode;
  header?: ReactNode;
};

const SettingContainer: FC<DropRateProps> = ({ children, title, icon, header }) => {
  return (
    <>
      <div className={cn.header}>
        <div className={cn.title}>
          {icon && <Icon className={cn.icon} content={icon} viewBox="0 0 12 16" />}
          {title}
        </div>
        {header}
      </div>
      {children}
    </>
  );
};

export { SettingContainer };
