import { FC, memo, useCallback, useMemo } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Switch, Tooltip } from "antd";

import { EllipsisTable as Ellipsis } from "elements/ellipsis/ellipsis";
import { Format } from "elements/format/format";
import { Icon } from "elements/icon/icon";
import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { global } from "models/global";
import { Column } from "models/table";
import styles from "theme/global";
import type { Range } from "utils/range";

import { ReactComponent as LockIcon } from "./lock.svg";
import type { ParamsRow } from "./paramsRow";
import { useEditRow } from "./useEditRow";

import cn from "./useMetricsColumns.module.less";

export type InputNumberProps = {
  value: number;
  id: number;
  setValue: (value: number | null, column: number) => void;
};

const InputOperatingNumber = memo(({ value, id, setValue }: InputNumberProps) => {
  const onUpdate = useCallback((v: number | null) => setValue(v, id), [id, setValue]);
  return <InputNumber value={value} onUpdate={onUpdate} min={0} className={cn.input} />;
});

export type InputBooleanProps = {
  labels?: [React.ReactNode, React.ReactNode];
  value: boolean | null;
  id: number;
  setValue: (value: boolean, column: number) => void;
};

const InputOperatingBoolean = memo(({ value, id, setValue, labels: [offLabel, onLabel] = ["Не работает", "Работает"] }: InputBooleanProps) => {
  const onUpdate = useCallback((v: boolean) => setValue(v, id), [id, setValue]);
  return <Switch unCheckedChildren={offLabel} checkedChildren={onLabel} checked={value ?? false} onChange={onUpdate} />;
});

const LockIconWgt = <Icon style={{ marginRight: 2, fontSize: 14 }} viewBox="0 0 12 15" content={<LockIcon />} />;

const EditRowButton: FC<{ row: any }> = ({ row }) => {
  const onEditRow = useEditRow(row);

  return (
    <Tooltip title="Ввод строки целиком">
      <Button onClick={onEditRow} type="text" icon={<EditOutlined />} />
    </Tooltip>
  );
};

const titleRender = (value: any, row: any) => {
  if (Array.isArray(row.children)) {
    return <Ellipsis value={value} row={row} />;
  }
  return (
    <>
      <Ellipsis value={value} row={row} />
      <EditRowButton row={row} />
    </>
  );
};

const SHARED_COLUMNS: Column[] = [
  {
    title: "No.пп",
    key: "row",
    dataIndex: "row",
  },
  {
    title: "Параметр",
    key: "title",
    dataIndex: "title",
    render: titleRender,
    onCell: () => ({
      className: cn.afterButton,
    }),
    width: 380,
  },
  {
    title: "Ед. измерения",
    key: "measure",
    dataIndex: "measure",
    width: 130,
  },
];

const useMetricsColumns = (
  model: { years: Range; stateYears?: Range | null } | null | undefined,
  hideSum: boolean = false,
  isEditable: boolean = true
) =>
  useMemo(
    () => {
      if (!model) {
        return [];
      }

      return [
        ...SHARED_COLUMNS,
        ...[...((global.isPickingFactYears && model.stateYears) || [])].map((year, id) => ({
          title: (
            <>
              {LockIconWgt}
              {year}
            </>
          ),
          className: cn["headerLock"],
          key: `year_${year}`,
          render: hideSum
            ? (_: undefined, { state, children }: { state: number[]; children: Array<unknown> | undefined }) =>
                children ? null : <Format>{state[id]}</Format>
            : (_: undefined, { state }: { state?: number[]; children: Array<unknown> | undefined }) =>
                state === undefined ? null : <Format>{state[id]}</Format>,
          onCell: hideSum
            ? ({ state, children }: { state: number[]; children: Array<unknown> | undefined }) => {
                return {
                  style: children ? {} : { background: styles["table-color-cell-lock"] },
                };
              }
            : ({ state, children }: { state?: number[]; children: Array<unknown> | undefined }) => {
                return {
                  style: children ? {} : { background: styles["table-color-cell-lock"] },
                };
              },
        })),
        ...[...(model?.years ?? [])].map((year, id) => ({
          title: `${year}`,
          key: `year_${year}`,
          render: (_: undefined, { values, setValue, inputFrom, inputTo, children, setBooleanValue, booleanLabels, tooltip }: ParamsRow) => {
            if (
              setValue && //
              isEditable &&
              (inputFrom === undefined || year >= inputFrom) &&
              (inputTo === undefined || year < inputTo)
            ) {
              return <InputOperatingNumber value={values![id] as number} id={id} setValue={setValue} />;
            }
            if (setBooleanValue && isEditable) {
              return <InputOperatingBoolean value={values![id] as boolean} id={id} labels={booleanLabels} setValue={setBooleanValue} />;
            }
            if (values === null) {
              return null;
            }
            if (children !== undefined && hideSum) {
              return null;
            }
            if (tooltip?.[id] !== undefined && tooltip?.[id] !== "") {
              return (
                <Tooltip title={tooltip?.[id]}>
                  <p style={{ margin: 0, display: "inline-block" }}>
                    <Format>{values?.[id]}</Format>
                  </p>
                </Tooltip>
              );
            }
            return <Format>{values?.[id]}</Format>;
          },
          onCell: ({ values, setValue, inputFrom, inputTo, children, setBooleanValue, backgroundColors }: ParamsRow) => {
            const customBG = backgroundColors?.[id];
            if (customBG !== undefined) {
              return { style: { background: customBG } };
            }
            if (setValue && isEditable && (inputFrom === undefined || year >= inputFrom) && (inputTo === undefined || year < inputTo)) {
              return {};
            }
            if (setBooleanValue && isEditable) {
              return {};
            }
            if (values === null) {
              return {
                style: children || !isEditable ? {} : { background: styles["table-color-cell-lock"] },
              };
            }
            if (children !== undefined && hideSum) {
              return {};
            }
            return {
              style: children || !isEditable ? {} : { background: styles["table-color-cell-lock"] },
            };
          },
        })),
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [model, hideSum, isEditable, global.isPickingFactYears]
  );

export { EditRowButton, SHARED_COLUMNS, useMetricsColumns };
