import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable, reaction, runInAction, when } from "mobx";

import { LoadableStoreResolver } from "models/loadableStore/loadableStoreResolver";
import { Intervention } from "models/project/fact/forecast/interventions/intervention";
import { Interventions } from "models/project/fact/forecast/interventions/interventions";
import { Production } from "models/project/fact/production/production";
import { StratumData } from "models/project/fact/production/stratumData";
import { Well } from "models/project/fact/well/well";
import { ProducingObject } from "models/project/producingObject/producingObject";
import { ProducingObjects } from "models/project/producingObject/producingObjects";
import { Stratum } from "models/project/stratum/stratum";
import { TreeRoot } from "models/tree/tree";
import { CompensationCoefficientsRaw } from "services/back/injectionPrediction/compensationCoefficients";
import { type InjectionInterval, savePeriods } from "services/back/injectionPrediction/savePeriods";
import { DateRange } from "utils/dateRange";

import { ProductionDataPack } from "../../production/productionData";
import { Forecast } from "../forecast";

type Period = Pick<StratumData, "stratumId" | "status" | "range">;

type DRow = {
  wellTitle: string;
  mineTitle?: string;
  eventTitle?: string;
  licenseRegionTitle?: string;
  start?: Dayjs;
  end?: Dayjs;
  periodStatus?: "prod" | "inj";
  producingObjectId?: number | null;
  stratumId?: number;
  compensationCoefficient?: number | null;

  producingObjectsResolver?: LoadableStoreResolver<ProducingObject>;
  isIntersected?: boolean;
  remove?: () => void;
};

function isIntersected(a: [Dayjs, Dayjs], b: [Dayjs, Dayjs]): boolean {
  const [s1, e1] = a;
  const [s2, e2] = b;
  return !(s1 > e2 || s2 > e1);
}

class PeriodNode extends TableNode<DRow> {
  asDRow = (): DRow => ({
    wellTitle: this.well.title,
    mineTitle: this.well.pad?.title,
    eventTitle: this.eventTitle,
    licenseRegionTitle: this.well.licenseRegion?.title,
    start: this.start,
    end: this.end,
    periodStatus: this.period.status,
    producingObjectId: this.producingObjectId,
    stratumId: this.stratumId,
    compensationCoefficient: this.compensationCoefficient,

    producingObjectsResolver: this.parent.producingObjects,
    isIntersected: this.isIntersected,
    remove: this.remove,
  });

  public producingObjectId: number | null;
  public stratumId: number;
  public start: Dayjs;
  public end: Dayjs;
  public isIntersected: boolean = false;

  constructor(
    private parent: WellNode,
    public readonly period: Period,
    public readonly gtm: Intervention | null = null
  ) {
    super(parent);
    this.childrenStore = null;
    makeObservable(this, {
      compensationCoefficient: computed,
      start: observable.ref,
      end: observable.ref,
      producingObjectId: observable,
      isIntersected: observable,
      isValid: computed,
      updateValue: action,
    });
    this.stratumId = this.period.stratumId;
    this.producingObjectId = this.initProducingObjectId();
    this.start = dayjs(this.period.range.from);
    this.end = dayjs(this.period.range.to);
    this.initCompensationCoefficient();
  }

  public get compensationCoefficient(): number | null {
    if (this.period.status === "inj" || this.producingObjectId === null) {
      return null;
    }
    return this.parent.compensationCoefficients[this.producingObjectId];
  }

  private initCompensationCoefficient() {
    if (this.period.status === "inj" || this.producingObjectId === null) {
      return;
    }
    const coeffs = this.parent.compensationCoefficients;
    if (coeffs === undefined) {
      console.error("Коэффициенты компенсации не заданы до загрузки таблицы");
      return;
    }
    if (coeffs[this.producingObjectId] === undefined) {
      coeffs[this.producingObjectId] = 115;
    }
  }

  public get isValid(): boolean {
    if (this.isIntersected) {
      return false;
    }
    return this.producingObjectId !== undefined;
  }

  public get eventTitle(): string | undefined {
    if (this.period.status === "inj") {
      return "";
    }
    if (this.gtm !== null) {
      return this.gtm.typeName ?? undefined;
    }
    if (this.well.fond === "New") {
      return "Эксплуатационное бурение";
    } else {
      return "Базовая добыча";
    }
  }

  private initProducingObjectId(): number | null {
    const stratums = this.parent.parent.forecast.fact.stratums;
    const stratum = stratums.at(this.period.stratumId);
    if (!stratum) {
      return null;
    }
    return stratums.getProducingObject(stratum)?.id ?? null;
  }

  private get well(): Well {
    return this.parent.well;
  }

  private remove = () => {
    if (this.index === undefined) {
      console.error("попытка удаления периода без индекса");
      return;
    }
    this.parent.removePeriod(this.index);
  };

  updateValue(key: keyof DRow, newValue: any): [prevValue: any, currValue: any] {
    if (!PeriodNode.isEditableField(key)) {
      console.error("редактирование немутабельного поля");
      return [undefined, undefined];
    }
    if (key === "producingObjectId") {
      const prev = this.producingObjectId;
      this.producingObjectId = newValue;
      return [prev, this.producingObjectId];
    }
    if (key === "start") {
      const prev = this.start.unix();
      const next = (newValue as Dayjs).unix();
      this.start = newValue;
      if (this.start.isAfter(this.end)) {
        this.mutationsManager?.updateWrapper("end", newValue);
      }
      this.parent.onUpdate();
      return [prev, next];
    }
    if (key === "end") {
      const prev = this.end.unix();
      const next = (newValue as Dayjs).unix();
      this.end = newValue;
      if (this.start.isAfter(this.end)) {
        this.mutationsManager?.updateWrapper("start", newValue);
      }
      this.parent.onUpdate();
      return [prev, next];
    }
    if (
      key === "compensationCoefficient" &&
      this.parent.compensationCoefficients !== undefined &&
      this.producingObjectId !== null
    ) {
      const prev = this.compensationCoefficient;
      this.parent.compensationCoefficients[this.producingObjectId] = newValue;
      return [prev, newValue];
    }
    // unreachable
    return [undefined, undefined];
  }

  static isEditableField(key: keyof DRow): key is "start" | "end" | "producingObjectId" | "compensationCoefficient" {
    return ["start", "end", "producingObjectId", "compensationCoefficient"].includes(key);
  }
}

class WellNode extends TableNode<DRow, PeriodNode> {
  asDRow = (): DRow => ({
    wellTitle: this.well.title,
  });

  public readonly interventions: Intervention[];
  public readonly producingObjects: LoadableStoreResolver<ProducingObject>;
  public readonly compensationCoefficients: CompensationCoefficientsRaw[number];

  constructor(public readonly parent: InputParams, public readonly well: Well) {
    super(parent, { isExpandedChildren: true });

    this.compensationCoefficients = this.initCompensationCoefficients();
    this.interventions = this.parent.interventions.getInterventionsByWellId(this.well.id);

    const stratums = new Set(
      this.periods
        .map((p) => parent.forecast.stratums.at(p[1].stratumId))
        .filter((s): s is Stratum => s !== null && s !== undefined)
    );
    const availableProducingObjects = new Set(
      [...stratums]
        .map((s) => parent.forecast.stratums.getProducingObject(s)?.id ?? null)
        .filter((id): id is number => id !== null)
    );

    this.producingObjects = new LoadableStoreResolver(this.parent.producingObjects, [
      ...new Set<number>(availableProducingObjects),
    ]);

    this.childrenStore = new ChildrenStoreArray(
      this,
      this.periods.map(([gtm, period]) => new PeriodNode(this, period, gtm))
    );

    this.onUpdate();
  }

  public onUpdate = () => {
    this.propagateIntersected();
    this.sortByStart();
  };

  public initCompensationCoefficients(): CompensationCoefficientsRaw[number] {
    const coeffs = this.parent.compensationCoefficients;
    if (coeffs === undefined) {
      console.error("Коэффициенты компенсации не заданы до загрузки таблицы");
      return {};
    }
    if (coeffs[this.well.id] === undefined) {
      coeffs[this.well.id] = {};
    }
    return coeffs[this.well.id];
  }

  public get injectionPeriods(): PeriodNode[] {
    return [...(this.childrenStore?.children ?? [])].filter((ch) => ch.period.status === "inj");
  }

  public addInjectionPeriod(stratumId: number, start: Dayjs, end: Dayjs) {
    this.childrenStore?.push(
      new PeriodNode(this, {
        stratumId,
        status: "inj",
        range: new DateRange(start, end),
      })
    );
    this.mutationsManager?.propagateMutation();
    this.onUpdate();
  }

  public removePeriod(childIdx: number) {
    this.childrenStore?.splice(childIdx, 1);
    this.propagateIntersected();
    this.onUpdate();
  }

  private get periods(): Array<[gtm: Intervention | null, period: Period]> {
    const productionDataToPeriod = (
      gtm: Intervention | null,
      data: ProductionDataPack
    ): Array<[gtm: Intervention | null, period: Period]> => {
      return data.all.map((stratumData) => [gtm, stratumData]);
    };
    const result: Array<[gtm: Intervention | null, period: Period]> = [];
    const wellData = this.parent.production.wellData(this.well.id);
    if (wellData !== undefined) {
      result.push(...productionDataToPeriod(null, wellData));
    }
    for (const intervention of this.interventions) {
      const interventionData = this.parent.production.interventionData(intervention.id);
      if (interventionData !== undefined) {
        result.push(...productionDataToPeriod(intervention, interventionData));
      }
    }
    return result;
  }

  private propagateIntersected() {
    const prodPeriods: PeriodNode[] = [];
    const injPeriods: PeriodNode[] = [];

    for (const child of this.childrenStore?.children ?? []) {
      child.isIntersected = false;
      if (child.period.status === "prod") {
        prodPeriods.push(child);
      } else {
        injPeriods.push(child);
      }
    }

    let someIntersects = false;

    for (const prod of prodPeriods) {
      const s1 = prod.start;
      const e1 = prod.end;
      for (const inj of injPeriods) {
        const s2 = inj.start;
        const e2 = inj.end;
        const intersects = isIntersected([s1, e1], [s2, e2]);
        someIntersects ||= intersects;
        runInAction(() => {
          prod.isIntersected = prod.isIntersected || intersects;
          inj.isIntersected = inj.isIntersected || intersects;
        });
      }
    }

    const n = injPeriods.length;
    for (let i = 0; i < n; ++i) {
      const inj1 = injPeriods[i];
      const s1 = inj1.start;
      const e1 = inj1.end;
      for (let j = i + 1; j < n; ++j) {
        const inj2 = injPeriods[j];
        const s2 = inj2.start;
        const e2 = inj2.end;
        const intersects = isIntersected([s1, e1], [s2, e2]);
        someIntersects ||= intersects;
        runInAction(() => {
          inj1.isIntersected = inj1.isIntersected || intersects;
          inj2.isIntersected = inj2.isIntersected || intersects;
        });
      }
    }
    if (someIntersects) {
      this.parent.invalidWells.add(this);
    } else {
      this.parent.invalidWells.delete(this);
    }
  }

  public sortByStart() {
    this.childrenStore?.sort((period1, period2) => period1.start.diff(period2.start, "month"));
  }

  public onlyIntersected() {
    this.childrenStore?.filter((ch) => ch.isIntersected);
  }

  public reset() {
    this.childrenStore?.reset();
    this.sortByStart();
  }

  public get childPeriods(): Array<[from: Dayjs, to: Dayjs]> {
    const result: Array<[from: Dayjs, to: Dayjs]> = [];
    for (const child of this.childrenStore?.children ?? []) {
      const { start, end } = child;
      result.push([start, end]);
    }
    return result;
  }
}

class InputParams extends TableNode<DRow, WellNode> {
  public readonly invalidWells = observable.set<WellNode>();
  public compensationCoefficients?: CompensationCoefficientsRaw;

  constructor(public readonly forecast: Forecast, private readonly tree: TreeRoot<Well>) {
    super();

    makeObservable(this, {
      compensationCoefficients: observable,
      isValid: computed,
    });

    when(
      () => this.forecast.compensationCoefficients.isLoading === false,
      () => this.init()
    );

    reaction(
      () => tree.selectedItems,
      (selected) => this.filterSelected(new Set(selected.map((node) => node.item!.id)))
    );
  }

  private init() {
    this.compensationCoefficients = this.forecast.compensationCoefficients.coeffs ?? {};
    this.childrenStore = new ChildrenStoreArray(
      this,
      this.wells.map((well) => new WellNode(this, well))
    );
  }

  public get isValid(): boolean {
    return this.invalidWells.size === 0;
  }

  public addPeriod(wellId: number, producingObjectId: number, start: Dayjs, end: Dayjs): void {
    const wellNode = [...(this.childrenStore?.children ?? [])].find((node) => node.well.id === wellId);
    const prodObj = this.forecast.fact.producingObjects.at(producingObjectId);
    if (!wellNode || !prodObj) {
      return;
    }
    wellNode.addInjectionPeriod(prodObj.data.mainStratumId, start, end);
  }

  public saveCompensation = async (): Promise<void> => {
    if (this.compensationCoefficients !== undefined) {
      return await this.forecast.compensationCoefficients.update(this.compensationCoefficients);
    } else {
      console.error("Коэффициенты компенсации не заданы на момент сохранения");
    }
  };

  public save = async (): Promise<void> => {
    await this.saveCompensation();
    const updatedWells: WellNode[] = [];
    const newPeriods: InjectionInterval[] = [...(this.mutatedChildren ?? [])].flatMap((wellNode) => {
      updatedWells.push(wellNode);
      const injectionPeriods = wellNode.injectionPeriods;
      if (injectionPeriods.length === 0) {
        return {
          wellId: wellNode.well.id,
          producingObjectId: wellNode.well.producingObject!.id,
          firstMonth: 0,
          firstYear: 0,
          monthsDuration: 0,
        };
      }

      return injectionPeriods
        .filter((periodNode) => {
          console.assert(periodNode.isValid);
          return periodNode.isValid && (periodNode.isUpdated || wellNode.addedChildren?.has(periodNode));
        })
        .map((periodNode): InjectionInterval => {
          const { start, end } = periodNode;
          const firstMonth = start.month() + 1;
          const firstYear = start.year();
          const monthsDuration = Math.max(end.diff(start, "month") + 1, 0);

          return {
            wellId: wellNode.well.id,
            producingObjectId: periodNode.producingObjectId!,
            firstMonth,
            firstYear,
            monthsDuration,
          };
        });
    });

    const isSaved = await savePeriods(this.forecast.id, newPeriods);
    if (isSaved) {
      const ids = updatedWells.map(({ well, mutationsManager }) => {
        mutationsManager?.dropMutations();
        mutationsManager?.propagateMutation();
        return { wellId: well.id };
      });
      this.forecast.production.update(ids);
    }
  };

  private filterSelected(selectedWellsIds: Set<number>) {
    this.childrenStore?.filter((node) => selectedWellsIds.has(node.well.id));
  }

  public get production(): Production {
    return this.forecast.production;
  }

  public get interventions(): Interventions {
    return this.forecast.interventions;
  }

  public get producingObjects(): ProducingObjects {
    return this.forecast.fact.producingObjects;
  }

  private get wells(): Well[] {
    return this.forecast.wells.allWells;
  }

  public disabledDate = (wellId: number) => {
    let wellNode: WellNode | undefined = undefined;
    for (const node of this.childrenStore?.children ?? []) {
      if (node.well.id === wellId) {
        wellNode = node;
      }
    }
    if (wellNode === undefined) {
      return () => true;
    }
    const periods = wellNode.childPeriods;
    return (date: Dayjs) => !periods.every(([from, to]) => date.isBefore(from, "month") || date.isAfter(to, "month"));
  };

  public producingObjectIds = (wellId: number): number[] => {
    let wellNode: WellNode | undefined = undefined;
    for (const node of this.childrenStore?.children ?? []) {
      if (node.well.id === wellId) {
        wellNode = node;
      }
    }
    if (wellNode === undefined) {
      return [];
    }
    return wellNode.producingObjects.ids;
  };

  public onlyIntersected() {
    this.invalidWells.forEach((w) => {
      w.onlyIntersected();
    });
    const selected = new Set(this.tree.selectedItems.map((node) => node.item!.id));
    this.childrenStore?.filter((ch) => selected.has(ch.well.id) && this.invalidWells.has(ch));
  }

  public resetFilters() {
    for (const child of this.childrenStore?.children ?? []) {
      child.childrenStore?.filter(() => true);
    }
    this.childrenStore?.filter(() => true);
    const selected = new Set(this.tree.selectedItems.map((node) => node.item!.id));
    this.filterSelected(selected);
  }
}

export type { DRow };
export { InputParams };
