import { TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable, reaction } from "mobx";

import { ModeSelectorModel } from "elements/modeSelector/modeSelectorModel";
import { ProducingObject } from "models/project/producingObject/producingObject";
import type { InterventionParsedRaw, InterventionRaw, InterventionUnsaved } from "services/back/interventions";

import { type DRow, WellInterventions } from "./wellInterventions";

type InterventionRawOptional = Omit<InterventionRaw, "id" | "wellId"> &
  Omit<InterventionParsedRaw, "id" | "wellId"> & {
    id: number | undefined;
    wellId: number | null;
  };

class WellIntervention extends TableNode<DRow> {
  public asDRow = (): DRow => ({
    ...this.data,
    fond: this.fond,
    well: [this.data.wellId, this.data.wellTitle ?? undefined],
    stratum: [this.data.stratumId, this.data.stratumTitle ?? undefined],
    producingObject: [this.data.producingObjectId, this.data.producingObjectTitle ?? undefined],
    gtmType: [this.data.gtmTypeId, this.data.gtmTypeTitle ?? undefined],
    isComplete: this.isComplete,

    remove: this.remove,
  });

  public readonly data: InterventionRawOptional;

  public get fond(): string | null {
    if (this.data.wellId === null) {
      return null;
    }
    const fond = this.parent.forecast.wells.at(this.data.wellId)?.fond;
    if (fond !== undefined) {
      return fond === "Base" ? "Базовый" : "Новый";
    }
    return null;
  }

  constructor(private parent: WellInterventions, data: Partial<InterventionRawOptional>) {
    super(parent);

    makeObservable<WellIntervention>(this, {
      data: observable,
      fond: computed,
      updateValue: action,
      isComplete: computed,
    });

    this.data = {
      wellId: data.wellId ?? null,
      wellTitle: data.wellTitle ?? null,

      gtmTypeId: data.gtmTypeId ?? null,
      gtmTypeTitle: data.gtmTypeTitle ?? null,

      stratumId: data.stratumId ?? null,
      stratumTitle: data.stratumTitle ?? null,

      producingObjectId: data.producingObjectId ?? null,
      producingObjectTitle: data.producingObjectTitle ?? null,

      id: data.id ?? undefined,
      scenarioId: parent.forecast.id,
      oilRate: data.oilRate ?? null,
      liquidRate: data.liquidRate ?? null,
      waterCut: data.waterCut ?? null,
      recoverableResources: data.recoverableResources ?? null,
      date: data.date ?? null,
    };
    this.onModeReset();
    reaction(
      () => [this.modeModel?.mode, this.producingObject],
      () => this.onModeReset()
    );
  }

  public get isComplete(): boolean {
    const { wellId, gtmTypeId, date, stratumId, recoverableResources } = this.data;
    const prodObj = this.producingObject;
    return !!prodObj && ![wellId, gtmTypeId, date, stratumId, recoverableResources].includes(null);
  }

  public get toInterventionUnsaved(): InterventionUnsaved | undefined {
    if (!this.isComplete) {
      return undefined;
    }
    return {
      id: this.data.id,
      wellId: this.data.wellId!,
      gtmTypeId: this.data.gtmTypeId!,
      date: this.data.date,
      stratumId: this.producingObject!.data.mainStratumId,
      oilRate: this.data.oilRate,
      liquidRate: this.data.liquidRate,
      waterCut: this.data.waterCut,
      recoverableResources: this.data.recoverableResources,
    };
  }

  public get producingObject(): ProducingObject | null | undefined {
    if (this.data.producingObjectId === null) {
      return null;
    }
    return this.parent.forecast.fact.producingObjects.at(this.data.producingObjectId);
  }

  public get modeModel(): ModeSelectorModel | null {
    return this.parent.modeModel ?? null;
  }

  private onModeReset() {
    if (this.modeModel === null || !this.producingObject) {
      return;
    }
    const mode = this.modeModel.mode;
    const args: Record<typeof mode, [number | null, number | null]> = {
      oilRate: [this.data.liquidRate, this.data.waterCut],
      liquidRate: [this.data.oilRate, this.data.waterCut],
      waterCut: [this.data.liquidRate, this.data.oilRate],
    };
    if (args[mode].includes(null)) {
      return;
    }
    const newValue = this.modeModel[`${mode}Calc`](
      ...(args[mode] as [number, number]),
      this.producingObject.oilRelativeDensity
    );
    this.mutationsManager?.updateWrapper(mode, newValue);
  }

  public updateValue(key: keyof DRow, newValue: any): [prevValue: any, currValue: any] {
    if (key === "remove" || key === "isComplete" || key === "fond") {
      console.error("attempt to update not editable field");
      return [undefined, undefined];
    }

    if (WellIntervention.isCompoundKey(key)) {
      const prev = this.data[`${key}Id`];
      const curr = (this.data[`${key}Id`] = newValue[0]);
      this.data[`${key}Title`] = newValue[1] ?? null;
      if (key === "producingObject") {
        this.data["stratumId"] = this.producingObject?.data.mainStratumId ?? null;
      }
      return [prev, curr];
    }

    const prev = this.data[key];
    this.data[key] = newValue;

    if ((key === "oilRate" || key === "liquidRate" || key === "waterCut") && this.producingObject) {
      this.modeModel
        ?.onValueChange(
          key,
          this.data["oilRate"],
          this.data["liquidRate"],
          this.data["waterCut"],
          this.producingObject.oilRelativeDensity
        )
        .forEach(({ key, value }) => {
          this.mutationsManager?.mutations.mutate(key, this.data[key], value);
          this.data[key] = value;
        });
    }

    return [prev, this.data[key]];
  }

  static isCompoundKey(key: keyof DRow): key is "well" | "stratum" | "producingObject" | "gtmType" {
    return ["well", "stratum", "producingObject", "gtmType"].includes(key);
  }

  public remove = () => {
    if (this.index === undefined) {
      console.error("attempt to remove intervention node w/o id");
      return;
    }
    this.parent.childrenStore?.splice(this.index, 1);
  };
}

export { type DRow, WellIntervention };
