import { FC } from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

import { Label } from "elements/inputs/label/label";
import { ValidationProps } from "elements/inputs/validationInput/validationInput";
import { conditionallyArr } from "utils/conditionally";

import { WellParametersForm } from "../../types";
import { CustomInput } from "../customInput";
import { DropRateChildProps } from "../dropRate";

import cn from "./wellsAnalog.module.less";

type DoubleInputProps = {
  title: string;
  id: WellsAnalogDoubleInputs;
};

type WellsAnalogDoubleInputs = "dropRatio" | "dropRatioFirstMonth";

type DoubleInputBlockProps = {
  title: string;
  path: string;
  techParameters: WellParametersForm;
  onValueChange: (value: number | null) => void;
  onDeviationChange: (value: number | null) => void;
  valueValidation: ValidationProps;
  deviationValidation: ValidationProps;
  isChecked: boolean;
  onCheck: (e: CheckboxChangeEvent) => void;
};

const DoubleInputBlock: FC<DoubleInputBlockProps> = ({
  techParameters,
  title,
  onValueChange,
  onDeviationChange,
  valueValidation,
  deviationValidation,
  path,
  isChecked,
  onCheck,
}) => {
  return (
    <Checkbox checked={isChecked} disabled onChange={onCheck}>
      <Label className={cn.whole} title={title}>
        <div className={cn["input-block"]}>
          <CustomInput
            disabled={!isChecked}
            suffix="%/мес"
            placeholder=""
            path={`${path},value`}
            className={cn.input}
            onSave={onValueChange}
            techParameters={techParameters}
            {...valueValidation}
          />
          <div className={cn.aside}>
            Отклонение
            <CustomInput
              disabled={!isChecked}
              path={`${path},deviation`}
              className={cn.deviation}
              onSave={onDeviationChange}
              techParameters={techParameters}
              suffix="%"
              placeholder=""
              {...deviationValidation}
            />
          </div>
        </div>
      </Label>
    </Checkbox>
  );
};

const WellsAnalog: FC<DropRateChildProps & { isDisplacement?: boolean }> = ({ propId, techParameters, setTechParameters, isDisplacement }) => {
  const doubleInputs: DoubleInputProps[] = [
    {
      title: "Учитывать темп падения дебита нефти",
      id: "dropRatio",
    },
    {
      title: "Учитывать темп падения дебита жидкости",
      id: "dropRatioFirstMonth",
    },
  ];

  const onChange = (value: number | null) => {
    if (isDisplacement) {
      setTechParameters.applyForAll([propId, "displacementCharacteristics", "wellsAnalog", "minDurOperation"], value);
    } else {
      setTechParameters.applyForAll([propId, "wellsAnalog", "minDurOperation"], value);
    }
  };

  const onTakenIntoAccount = (field: string, id?: WellsAnalogDoubleInputs) => (data: boolean | CheckboxChangeEvent) => {
    let checked = typeof data === "boolean" ? data : data.target.checked;
    if (isDisplacement) {
      setTechParameters.applyForAll(
        [propId, "displacementCharacteristics", "wellsAnalog", ...conditionallyArr(id !== undefined, id!), field],
        checked
      );
    } else {
      const path = [propId, "wellsAnalog", ...conditionallyArr(id !== undefined, id!), field];
      setTechParameters.applyForAll(path, checked);
    }
  };

  const isConstructionTypeTakeIntoAccount = isDisplacement
    ? techParameters[0][propId].displacementCharacteristics.wellsAnalog.constructionType
    : techParameters[0][propId].wellsAnalog.constructionType;

  const getOnDoubleInputChange = (id: WellsAnalogDoubleInputs) => {
    const onCheck = onTakenIntoAccount("takeIntoAccount", id);
    return {
      onValueChange: (value: number | null) => {
        onCheck(true);
        if (isDisplacement) {
          setTechParameters.applyForAll([propId, "displacementCharacteristics", "wellsAnalog", id, "value"], value);
        } else {
          setTechParameters.applyForAll([propId, "wellsAnalog", id, "value"], value);
        }
      },
      onDeviationChange: (value: number | null) => {
        onCheck(true);
        if (isDisplacement) {
          setTechParameters.applyForAll([propId, "displacementCharacteristics", "wellsAnalog", id, "deviation"], value);
        } else {
          setTechParameters.applyForAll([propId, "wellsAnalog", id, "deviation"], value);
        }
      },
      isChecked: isDisplacement
        ? techParameters[0][propId].displacementCharacteristics.wellsAnalog[id].takeIntoAccount
        : techParameters[0][propId].wellsAnalog[id].takeIntoAccount,
      onCheck,
    };
  };

  const calcMethodPath = (id: WellsAnalogDoubleInputs) =>
    isDisplacement ? `${propId},displacementCharacteristics,wellsAnalog` : `${propId},wellsAnalog,${id}`;

  return (
    <div className={cn.wgt}>
      <div className={cn["checkbox-container"]}></div>
      <Label className={cn.duration} title="Мин. длительность работы скважины на объекте разработки">
        <CustomInput
          techParameters={techParameters[0]}
          path={`${propId},wellsAnalog,minDurOperation`}
          className={cn.select}
          onSave={onChange}
          min={0}
          max={60}
          required
          integer
          placeholder=""
          suffix="мес"
        />
      </Label>
      {doubleInputs.map(({ title, id }) => (
        <DoubleInputBlock
          techParameters={techParameters[0]}
          key={id}
          title={title}
          path={calcMethodPath(id)}
          valueValidation={{ min: 0.0001, max: 1, required: true }}
          deviationValidation={{ min: 0, max: 100, required: true }}
          {...getOnDoubleInputChange(id)}
        />
      ))}
      <Checkbox onChange={onTakenIntoAccount("constructionType")} checked={isConstructionTypeTakeIntoAccount}>
        Учитывать тип конструкции скважины
      </Checkbox>
    </div>
  );
};

export { WellsAnalog };
