import { action, makeObservable, observable } from "mobx";

import { calcMock } from "services/back/calculate/calcMock";
import { CalculationExecNormalResult, loadCalculation, loadCalculationMeta } from "services/back/calculate/calculate";

import { Forecast } from "./forecast";
import { ForecastResult } from "./forecastResult";

type ForecastResultPack = {
  total: ForecastResult;
  [licenseRegionId: number]: ForecastResult;
};

function constructResultModels(
  forecast: Forecast,
  calcResult: CalculationExecNormalResult
): Map<number, ForecastResultPack> {
  const resultModels = new Map<number, ForecastResultPack>();
  const { calculated, ...rest } = calcResult;
  for (const [uscId, { total, ...byLZ }] of calculated.entries()) {
    const resultPack: ForecastResultPack = {
      total: new ForecastResult(forecast, { ...rest, calculated: total }),
    };
    for (const [lzId, lzResult] of Object.entries(byLZ)) {
      const licenseRegionId = lzId !== undefined ? parseInt(lzId) : forecast.licenseRegions.ids[0];
      resultPack[licenseRegionId] = new ForecastResult(forecast, { ...rest, calculated: lzResult });
    }

    resultModels.set(uscId, resultPack);
  }
  return resultModels;
}

class ForecastResultOperator {
  public saved: undefined | null | Map<number, ForecastResultPack>;
  public savedTS: undefined | null | Date;

  public calculated: null | undefined | Map<number, ForecastResultPack> = (() => {
    const mock = calcMock();
    if (mock === null) {
      return null;
    }
    // если подложить в папку services/back/calculate файл с моком то он будет использоваться сразу в конструкторе расчета
    return constructResultModels(this.fc, mock);
  })();

  private async initSaved() {
    const savedMeta = await loadCalculationMeta(this.fc);
    if (savedMeta === null) {
      this.saved = null;
      this.savedTS = null;
      return;
    }
    this.savedTS = savedMeta.ts;
    this.saved = constructResultModels(this.fc, (await loadCalculation(this.fc))!);

    //проверяем что сохраненный расчет действительно этого сценария
    const wellIds = (this.saved.values().next().value.total as ForecastResult).wells.tableItems.map(({ id }) => id);
    if (!wellIds.includes(this.fc.wells.wells[0].data.id)) {
      this.fc.calculate();
    }
  }

  constructor(private fc: Forecast) {
    this.initSaved();

    makeObservable<ForecastResultOperator, "initSaved">(this, {
      initSaved: action,
      saved: observable,
      savedTS: observable,
      calculated: observable,
    });
  }
}

export { constructResultModels, ForecastResultOperator, type ForecastResultPack };
