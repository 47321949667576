import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { Dayjs } from "dayjs";
import { computed, makeObservable, observable } from "mobx";

import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { Pipes as InfastructurePipes } from "models/project/fact/infrastructure/pipes";
import { SegmentType as SegmentCalculateType } from "services/back/infrastructure/calculate";
import { SegmentType } from "services/back/infrastructure/catalog";
import { Segment } from "services/back/infrastructure/types";

import { compareValues, findMatch } from "../../../utils";

type FormatPipesType<T> = { uuid: string; title: string; params: T[] };

type FormatSegmentCalculateType = { date: Dayjs; firstPressure: number; secondPressure: number } & SegmentCalculateType;

type DRow = {
  date: Dayjs | null;
  title: string;
  type: string;
  fluidRateM3: number;
  pressureDelta: number;
  firstPressure: number;
  secondPressure: number;
  limitingPressureGradient: number;
  limitingVelocity: number;
  pressureGradient: number;
  velocity: number;
  isProblem?: boolean;
};

class Pipe extends TableNode<DRow> {
  asDRow = (): any => ({
    ...this.data,
    type: this.type,
    isProblem: this.isProblem,
  });
  public data: FormatSegmentCalculateType;
  constructor(private parent: Pipes, data: FormatSegmentCalculateType, private infrastructure: Infrastructure) {
    super(parent);

    makeObservable(this, { data: observable, type: computed, isProblem: computed });

    this.data = data;
  }

  get type() {
    const pipeAttributes = InfastructurePipes.getPipesAttributes(this.data as Partial<SegmentType>);
    const pipes = this.infrastructure.catalog.pipes(this.data.segmentType) || [];
    const matchingStation = findMatch<SegmentType>(pipeAttributes, pipes);
    return matchingStation?.title || "";
  }

  get isProblem(): boolean {
    const pipe = this.data;
    return (
      compareValues(pipe.limitingPressureGradient ?? 0, pipe.pressureGradient) ||
      compareValues(pipe.limitingVelocity ?? 0, pipe.velocity) ||
      compareValues(pipe.limitingPressureGradient ?? 0, pipe.pressureDelta) ||
      compareValues(pipe.limitingPressureGradient ?? 0, pipe.firstPressure) ||
      compareValues(pipe.limitingPressureGradient ?? 0, pipe.secondPressure)
    );
  }
}

class Pipes extends TableNode<DRow, Pipe> {
  asDRow = () =>
    ({
      title: this.name,
    } as DRow);
  constructor(private parent: PipesModel, private name: string, private params: any[]) {
    super(parent, { isExpandedChildren: true });

    this.initChildren();
  }

  private initChildren() {
    this.childrenStore = new ChildrenStoreArray(
      this,
      this.params.map((pipe) => new Pipe(this, pipe, this.parent.infrastructure))
    );
  }
}

class PipesModel extends TableNode<DRow, Pipes> {
  constructor(public infrastructure: Infrastructure, private mode: "prod" | "inj") {
    super();

    this.initChildren();
  }

  private initChildren() {
    const calculations = this.infrastructure.calculateStore.hydraulicDataCollection || [];
    const segments: Map<string, FormatPipesType<Segment>> = new Map();
    for (const calculation of calculations) {
      if (calculation[this.mode]) {
        PipesModel.formatPipes(
          calculation[this.mode]!.segments,
          segments,
          calculation[this.mode]!.nodes,
          calculation.date
        );
      }
    }
    const pipes = ([...segments.values()] || []).map((pipe) => new Pipes(this, pipe.title, pipe.params));
    this.childrenStore = new ChildrenStoreArray(this, pipes);
  }

  static formatPipes = (data: Segment[], result: Map<string, any>, nodes: any[], date: Dayjs) => {
    for (const item of data) {
      const getPressure = (uuid: string) => nodes.find((el) => el.uuid === uuid)?.pressure;
      const newItem = {
        ...item,
        firstPressure: getPressure(item.firstNodeUuid),
        secondPressure: getPressure(item.secondNodeUuid),
        date,
      };
      if (result.has(item.uuid)) {
        result.get(item.uuid)?.params.push(newItem);
      } else {
        result.set(item.uuid, {
          uuid: item.uuid,
          title: item.title,
          params: [newItem],
        });
      }
    }
  };
}

export { type DRow as DRowPipes, PipesModel };
