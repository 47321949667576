import { useResultModel } from "routing/outlines/result/resultPage";

import { Results } from "./results";

const OperatingResults = () => {
  const result = useResultModel();

  return <Results model={result.operating} exportFileName="Операционная деятельность" />;
};

export { OperatingResults };
