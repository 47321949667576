import { useMemo } from "react";
import { ColumnRaw, TableModel } from "@okopok/components/Table";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";

import { Format } from "elements/format/format";
import { LazyInputNumber } from "elements/inputs/lazyInputNumber/lazyInputNumber";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { setupAccuracy } from "utils/round";

import "dayjs/locale/ru";

import { DRowMines } from "../infrastructureTableManager/mines";
import { WellPadsModel } from "../infrastructureTableManager/mines";

import cn from "../../infrastructureTable.module.less";

const useColumns = (): ColumnRaw<DRowMines>[] => {
  return useMemo(
    (): ColumnRaw<DRowMines>[] => [
      {
        title: "№",
        width: { min: 40, max: 80, competitiveness: 1 },
        key: "index",
        onCell: () => ({ className: cn.tableCell }),
        render: (_, mine) => mine.absoluteIndex,
      },
      {
        key: "expand",
        title: null,
        isSticky: true,
        width: { min: 30, max: 30, competitiveness: 1 },
        render: (_, { expand }) => (expand?.status !== undefined ? <ExpandButton expand={expand} /> : <></>),
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Кустовая площадка",
        width: { min: 240, max: 270, competitiveness: 1 },
        dataKey: "title",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Скважина",
        width: { min: 240, max: 270, competitiveness: 1 },
        dataKey: "well",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Дата ввода",
        width: { min: 240, max: 270, competitiveness: 1 },
        dataKey: "startedAt",
        onCell: () => ({ className: cn.tableCell }),
        render: (_, mine) => (mine.value?.startedAt ? mine.value?.startedAt.format("MM.YYYY") : ""),
      },
      {
        title: "Координаты, м",
        width: { min: 240, max: 270, competitiveness: 1 },
        dataKey: "coords",
        onCell: () => ({ className: cn.tableCell }),
        render: (_, mine) => {
          if (mine.value?.coords === undefined) {
            return <></>;
          }
          return (
            <span>
              X: <Format>{setupAccuracy(mine.value?.coords.x, "round")}</Format> Y: <Format>{setupAccuracy(mine.value?.coords.y, "round")}</Format>
            </span>
          );
        },
      },
      {
        title: "Альтитуда, м",
        width: { min: 240, max: 270, competitiveness: 1 },
        dataKey: "altitude",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, tableItem) => {
          if (tableItem.expand?.status === undefined) {
            return <></>;
          }
          return <LazyInputNumber variant="borderless" value={value} onUpdate={(altitude) => tableItem.update?.("altitude", altitude)} />;
        },
      },
      {
        title: "Стоимость, тыс руб / скв",
        width: { min: 240, max: 270, competitiveness: 1 },
        dataKey: "cost",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
    ],
    []
  );
};

const useInfrastructureMinesTableModel = () => {
  const infrastructure = useInfrastructure();

  const columns = useColumns();
  const store = useMemo(() => new WellPadsModel(infrastructure), [infrastructure]);

  const model = useMemo(
    () =>
      new TableModel(columns, store, {
        onRow: ({ indexPath, expand }) => ({
          className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowPlain,
        }),
      }),
    [columns, store]
  );

  return { model, store };
};

export { useInfrastructureMinesTableModel };
