import { global } from "models/global";
import { LoadableStore } from "models/loadableStore/loadableStore";
import { useSession } from "models/session";
import { deleteProject, getProjects } from "services/back/project";

import { Project } from "./project";

class Projects extends LoadableStore<Project> {
  constructor() {
    super(() =>
      getProjects().then((projects) => {
        global.logger.init(projects.map(({ id }) => id));
        return projects.map(Project.fromRawData);
      })
    );
  }
  public deleteProject = async (id: number) => {
    const res = await deleteProject(id);
    if (res.id === id) {
      await this.reset();
    }
  };
}

const useProjects = () => useSession().projects;

export { Projects, useProjects };
