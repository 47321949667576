import { FC, ReactNode, useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import { useSearchParamsStorage } from "elements/useSearchParamsStorage";
import { ProjectData, ProjectDataContext } from "models/project/context/projectContext";
import { Forecast, useForecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import { useProject } from "models/project/project";
import { TreeNode, TreeRoot } from "models/tree/tree";

import { usePagePostfix } from "./secondaryMenu/scenarioMenu";

const processTreeSelect = (node: TreeNode<any> | TreeRoot, selectedIds: Set<number>) => {
  if ("item" in node && node.item instanceof Well) {
    if (selectedIds.has(node.item.id)) {
      node.asTableItem.select?.onSelect();
    } else {
      node.asTableItem.select?.onDeselect();
    }
  }
  if ("children" in node && node.children) {
    for (const c of node.children) {
      processTreeSelect(c, selectedIds);
    }
  }
};

const ProjectContextProvider: FC<{ children: ReactNode }> = observer(({ children }) => {
  const searchParamsStorage = useSearchParamsStorage();
  const project = useProject();
  const forecast = useForecast();
  const pagePostfix = usePagePostfix();
  const pageIdentifier = pagePostfix?.[0];

  const projectData = useMemo(() => {
    console.assert(project !== null && project !== undefined, "Project is null or undefined");
    return new ProjectData(project!, searchParamsStorage);
  }, [project, searchParamsStorage]);

  useEffect(() => {
    const wellIdsRaw = projectData.searchParamsStorage.getItem("_wells");
    if (wellIdsRaw && Array.isArray(wellIdsRaw)) {
      const wellIds = new Set(wellIdsRaw);
      processTreeSelect(projectData.wellsTree, wellIds);
    }
  }, [projectData, projectData.searchParamsStorage, projectData.wellsTree]);

  useEffect(() => {
    console.assert(forecast !== undefined, "Forecast is undefined");
    projectData.forecast = forecast as null | Forecast;
  }, [forecast, projectData]);

  useEffect(() => {
    if (projectData.wellsTree.length !== 0) {
      const wellIds = projectData.wellsTree.selectedItems
        .filter((e) => e.item != null && e.item instanceof Well)
        .map((e) => e.item!.id)
        .sort();

      projectData.searchParamsStorage.setItem("_wells", wellIds);
    }
  }, [projectData, projectData.searchParamsStorage, projectData.wellsTree.selectedItems, pageIdentifier]);

  return <ProjectDataContext.Provider value={projectData}>{children}</ProjectDataContext.Provider>;
});

export { ProjectContextProvider };
