import { FC, MouseEventHandler, ReactNode, useState } from "react";
import { Button, Popconfirm, PopconfirmProps, Tooltip } from "antd";

import { WizardSteps } from "./wizardSteps";

import cn from "./wizard.module.less";

type WizardProps = {
  steps: Step[];
  sidebar?: ReactNode;
  pageWidth?: string;
  reject: () => void;
  step?: {
    number: number;
    status: StatusType;
  };
};

type Step = {
  title: string;
  icon: (className: string) => ReactNode;
  page: ReactNode;
  footerButtons?: FooterButton[];
};

type FooterButton = {
  title: string;
  type?: "primary" | "default";
  disabled?: boolean;
  onClick: MouseEventHandler;
  tooltip?: string;
  confirm?: PopconfirmProps;
};

export const Status = {
  Wait: "wait",
  Loading: "loading",
  Finish: "finish",
} as const;

export type StatusType = (typeof Status)[keyof typeof Status];

const Wizard: FC<WizardProps> = ({ step, steps, sidebar, reject, pageWidth }) => {
  type StepStatus = {
    number: number;
    status?: StatusType;
  };

  const [currentStepState, setStep] = useState<StepStatus>(step ?? { number: 0, status: Status.Finish });

  const currentStep = step ?? currentStepState;

  const incrementStep =
    (status: StatusType = Status.Finish) =>
    () => {
      if (currentStep.number >= steps.length - 1) {
        return;
      }
      setStep({ number: currentStep.number + 1, status });
    };

  const decrementStep =
    (status: StatusType = Status.Finish) =>
    () => {
      if (currentStep.number <= 0) {
        return;
      }
      setStep({ number: currentStep.number - 1, status });
    };

  const preparedSteps = (steps: Step[], currentStep: StepStatus): Omit<Step, "page">[] => {
    const res = steps.map((step, stepNumber) => ({ status: stepNumber > currentStep.number ? "wait" : "finish", ...step }));
    res[currentStep.number].status = currentStep.status ?? Status.Wait;
    return res;
  };

  return (
    <>
      <div className={cn.wizard}>
        <div className={cn.body}>
          <div className={cn["sidebar-container"]}>{sidebar}</div>
          <div style={{ width: pageWidth ?? "auto" }} className={cn["page-container"]}>
            {steps[currentStep.number].page}
          </div>
        </div>
      </div>
      <WizardSteps steps={preparedSteps(steps, currentStep)} />
      <div className={cn.footer}>
        {steps[currentStep.number].footerButtons ? (
          <>
            {steps[currentStep.number].footerButtons?.map(({ title, onClick, type, disabled = false, tooltip = "", confirm }, index) =>
              confirm ? (
                <Popconfirm key={index} {...confirm}>
                  <Tooltip title={tooltip}>
                    <Button key={title} className={cn.button} type={type ?? "primary"} onClick={onClick} disabled={disabled}>
                      {title}
                    </Button>
                  </Tooltip>
                </Popconfirm>
              ) : (
                <Tooltip key={index} title={tooltip}>
                  <Button key={title} className={cn.button} type={type ?? "primary"} onClick={onClick} disabled={disabled}>
                    {title}
                  </Button>
                </Tooltip>
              )
            )}
          </>
        ) : (
          <>
            <Button className={cn.button} onClick={incrementStep()}>
              Далее
            </Button>
            <Button className={cn.button} onClick={decrementStep()}>
              Назад
            </Button>
          </>
        )}
        <Button className={cn.button} onClick={reject}>
          Отменить
        </Button>
      </div>
    </>
  );
};

export { type Step, Wizard };
