import { global } from "models/global";
import { reqCamel } from "utils/request";

type Role = {
  title: string;
  id: number;
  userIds: number[];
  projectIds: number[];
};

type ProjectRole = Omit<Role, "projectIds">;

type AssignRole = {
  roleId: number;
  projectId: number;
  userId: number;
};

//Администратор системы - 1, Руководитель проекта - 2
const ADMIN_ROLES_IDS = [1, 2];

const canManageRoles = async (projectId: number) => {
  const currentUserId = global.user?.id;
  if (!currentUserId) {
    return false;
  }
  const adminProjectRoles = (await getProjectRoles(projectId)).filter(({ id }) => ADMIN_ROLES_IDS.includes(id));
  for (const { userIds } of adminProjectRoles) {
    if (userIds.includes(currentUserId)) {
      return true;
    }
  }
  return false;
};

const getRoles = async (): Promise<Role[]> => reqCamel.get<Role[]>("roles");

const getProjectRoles = async (projectId: number): Promise<ProjectRole[]> =>
  reqCamel.get<ProjectRole[]>(`roles/projects/${projectId}`);

const assignRole = async (roleId: number, projectId: number, userId: number) => {
  if (await canManageRoles(projectId)) {
    return await reqCamel.post<AssignRole>("roles/assign_role", { roleId, projectId, userId });
  }
  console.warn("Недостаточно прав для назначения ролей");
};

const deleteRole = async (roleId: number, projectId: number, userId: number) => {
  if (await canManageRoles(projectId)) {
    return await reqCamel.delete<AssignRole>("roles/users", { roleId, projectId, userId });
  }
  console.warn("Недостаточно прав для удаления ролей");
};

const deleteUser = (roleIds: number[], projectId: number, userId: number) =>
  Promise.all(roleIds.map((roleId) => deleteRole(roleId, projectId, userId)));

export { ADMIN_ROLES_IDS, assignRole, deleteRole, deleteUser, getProjectRoles, getRoles };
export type { ProjectRole };
