import { useMemo } from "react";
import { ColumnRaw, TableModel } from "@okopok/components/Table";
import { Input } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Format } from "elements/format/format";
import { LazyInputNumber } from "elements/inputs/lazyInputNumber/lazyInputNumber";
import { MonthPicker } from "elements/inputs/monthPicker";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { setupAccuracy } from "utils/round";

import "dayjs/locale/ru";

import { DrainSources, DRowDrainSources } from "../infrastructureTableManager/drainSources";

import cn from "../../infrastructureTable.module.less";

const useColumns = (): ColumnRaw<DRowDrainSources>[] => {
  const infratructure = useInfrastructure();
  return useMemo(
    (): ColumnRaw<DRowDrainSources>[] => [
      {
        title: "№",
        width: { min: 40, max: 80, competitiveness: 1 },
        key: "index",
        onCell: () => ({ className: cn.tableCell }),
        render: (_, tableItem) => tableItem.absoluteIndex,
      },
      {
        title: "Название",
        width: { min: 300, max: 400, competitiveness: 1 },
        dataKey: "title",
        onCell: () => ({ className: cn.tableCell }),
        render: (value, tableItem) => (
          <Input variant="borderless" className={cn.title} value={value} onChange={({ target }) => tableItem.update?.("title", target.value)} />
        ),
      },
      {
        title: "Дата ввода",
        width: { min: 250, max: 400, competitiveness: 1 },
        dataKey: "startedAt",
        render: (value, tableItem) => {
          return (
            <div className={cn.date}>
              <MonthPicker
                onChange={(date) => tableItem.update?.("startedAt", date ?? null)}
                value={value ? value : undefined}
                placeholder="Выбрать..."
                variant="borderless"
                locale={locale}
                start={dayjs(infratructure.range.from.toString())}
                end={dayjs(infratructure.range.to.toString())}
              />
            </div>
          );
        },
      },
      {
        title: "Координаты, м",
        width: { min: 300, max: 400, competitiveness: 1 },
        dataKey: "coords",
        onCell: () => ({ className: cn.tableCell }),
        render: (_, tableItem) => {
          if (tableItem.value?.coords === undefined) {
            return <></>;
          }
          return (
            <span>
              X: <Format>{setupAccuracy(tableItem.value?.coords.x, "round")}</Format> Y:{" "}
              <Format>{setupAccuracy(tableItem.value?.coords.y, "round")}</Format>
            </span>
          );
        },
      },
      {
        title: "Альтитуда, м",
        width: { min: 250, max: 400, competitiveness: 1 },
        dataKey: "altitude",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, tableItem) => (
          <LazyInputNumber variant="borderless" value={value} onUpdate={(altitude) => tableItem.update?.("altitude", altitude)} />
        ),
      },
      {
        title: <p />,
        key: "remove",
        width: 32,
        onCell: () => ({ className: cn.remove }),
        render: (_, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return <DeleteButton onClick={pipe.value?.remove} />;
        },
      },
    ],
    [infratructure]
  );
};

const useInfrastructureDrainSourcesTableModel = (mode: "drain" | "source") => {
  const infrastructure = useInfrastructure();

  const columns = useColumns();
  const store = useMemo(() => new DrainSources(infrastructure, mode), [infrastructure, mode]);
  const model = useMemo(() => new TableModel(columns, store), [columns, store]);

  return { model, store };
};

export { useInfrastructureDrainSourcesTableModel };
