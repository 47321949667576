import { PageFrame } from "routing/pageFrame/pageFrame";

import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { Preloader } from "features/preloader/preloader";
import { Debet as DebetFeature } from "features/techPrediction/debet";

import { DEBIT_PRELOADER } from "./preloads";

const Debet = () => (
  <ForecastSelector>
    <Preloader hooks={DEBIT_PRELOADER}>
      <PageFrame title="Прогноз технологических показателей">
        <DebetFeature />
      </PageFrame>
    </Preloader>
  </ForecastSelector>
);

export { Debet };
