import { FC } from "react";
import { Virtuoso } from "react-virtuoso";
import { Button } from "antd";

import { Icon } from "elements/icon/icon";
import { Label } from "elements/inputs/label/label";

import { ReactComponent as DownloadIcon } from "../../icons/download.svg";
import { CustomInput } from "../customInput";
import { DropRateChildProps } from "../dropRate";

import cn from "./oilDebitDropRate.module.less";

const OilDebitDropRate: FC<DropRateChildProps> = ({ propId, techParameters, setTechParameters, wellsNameMap }) => {
  const onChange = (value: number | null) => {
    setTechParameters.applyForAll([propId, "dropRatio", "chart"], { x: [0], y: [value] });
  };

  const getOnChange = (id: number, gId?: number | null) => (value: number | null) => {
    setTechParameters.applyForId([propId, "dropRatio", "chart"], { x: [0], y: [value] }, id, gId);
  };

  const techParametersById = (id: number, gId?: number | null) => techParameters.find(({ wellId, gtmId }) => wellId === id && gId === gtmId)!;

  const isDifferent = !techParameters.every(
    (techParameter) => techParameter[propId].dropRatio.chart.y[0] === techParameters[0][propId].dropRatio.chart.y[0]
  );

  return (
    <div className={cn.container}>
      <Label title={`Темп падения дебита ${propId === "oilDropRate" ? "нефти" : "жидкости"}, %/месяц`}>
        <CustomInput
          onSave={onChange}
          disabled={isDifferent}
          placeholder={isDifferent ? "Разные" : "Задать"}
          path={`${propId},dropRatio,chart,y,0`}
          techParameters={techParameters[0]}
          min={0.0001}
          max={5}
          required
          forceValueUpdate
          generalInput
          percent
        />
        <Button className={cn["upload"]} icon={<Icon content={<DownloadIcon />} width="16" height="16" viewBox="0 0 16 16" />} disabled>
          Загрузить график
        </Button>
      </Label>
      {techParameters.length > 1 ? (
        <Virtuoso
          style={{ height: "calc(100% - 50px)" }}
          data={techParameters}
          itemContent={(_, { wellId, gtmId }) => {
            return (
              <Label title={wellsNameMap ? wellsNameMap[wellId] : ""} key={`${wellId}${gtmId || ""}`}>
                <CustomInput
                  className={cn["ratio-input"]}
                  onSave={getOnChange(wellId, gtmId)}
                  placeholder={"Задать"}
                  path={`${propId},dropRatio,chart,y,0`}
                  techParameters={techParametersById(wellId, gtmId)}
                  min={0.0001}
                  max={5}
                  required
                  forceValueUpdate
                  percent
                />
              </Label>
            );
          }}
        />
      ) : null}
    </div>
  );
};

export { OilDebitDropRate };
