import { HTMLAttributes, useMemo } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import { Button, Checkbox, Tooltip } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useResultModel } from "routing/outlines/result/resultPage";
import { ResultPageTitlePortal } from "routing/outlines/result/resultPageTitlePortal";

import { CsvSaver } from "elements/csvSaver/csvSaver";
import { EllipsisTable as Ellipsis } from "elements/ellipsis/ellipsis";
import { Format, NUMBER_FORMAT } from "elements/format/format";
import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { OptimizerModal } from "models/project/fact/forecast/optimizerModal";

import { useOptimizerModal } from "./useOptimizerModal";
import { WellsResultStore } from "./wellsResultStore";

import cn from "./wells.module.less";

const SHARED_COLUMNS: ColumnRaw<any>[] = [
  {
    key: "hideExpand",
    title: null,
    isSticky: true,
    width: { min: 34, max: 34, competitiveness: 1 },
    render: (v, tableItem) => !v && <ExpandButton expand={tableItem.expand} />,
    onCell: (tableItem) => {
      if (tableItem.value.optimizerModel) {
        const model: OptimizerModal = tableItem.value.optimizerModel;
        return { className: classNames(cn.tableCellAlignRight, model.getCellClassName(tableItem)) };
      }
      return {};
    },
    isExported: false,
  },
  {
    dataKey: "title",
    title: "Параметр",
    isSticky: true,
    width: { min: 380, max: 380, competitiveness: 1 },
    render: (value, tableItem) => <Ellipsis value={value} row={{ level: tableItem.indexPath.length }} />,
    onCell: (tableItem) => {
      const attributes: HTMLAttributes<any> = { style: { paddingLeft: tableItem.indexPath.length * 15 } };
      if (tableItem.value.optimizerModel) {
        const model: OptimizerModal = tableItem.value.optimizerModel;
        attributes.className = classNames(cn.tableCellAlignRight, model.getCellClassName(tableItem));
      }
      return attributes;
    },
    renderToString: (value) => value,
  },
  {
    dataKey: "measure",
    title: "Ед. измерения",
    isSticky: true,
    width: { min: 130, max: 130, competitiveness: 1 },
    onCell: (tableItem) => {
      if (tableItem.value.optimizerModel) {
        const model: OptimizerModal = tableItem.value.optimizerModel;
        return { className: classNames(cn.tableCellAlignRight, model.getCellClassName(tableItem)) };
      }
      return {};
    },
  },
];

const EXCEPT_COLUMNS: ColumnRaw<any>[] = [
  {
    key: "exclude",
    title: "",
    isSticky: true,
    width: { min: 52, max: 52, competitiveness: 1 },
    render: (_, { expand, value }) => {
      const model: OptimizerModal = value.optimizerModel;
      return (
        expand &&
        !value.hideExpand &&
        value.fond !== "Base" &&
        value.title !== "Новый фонд" && (
          <div className={cn.close}>
            <Checkbox
              className={cn["exclude-checkbox"]}
              checked={!!(model.manualExcludedWells[value.key] ? model.manualExcludedWells[value.key].checked : model.excludedWells[value.key])}
              onChange={() => model.setManualExcludedWell(value.key)}
            />
            <Tooltip title="Пометить скважину на исключение">
              <CloseOutlined />
            </Tooltip>
          </div>
        )
      );
    },
    onCell: (tableItem) => {
      if (tableItem.value.optimizerModel) {
        const model: OptimizerModal = tableItem.value.optimizerModel;
        return { className: classNames(cn.tableCellAlignRight, model.getCellClassName(tableItem)) };
      }
      return {};
    },
  },
];

const getSummaryColumn = (year: number): ColumnRaw<any>[] => [
  {
    dataKey: year,
    title: "Итого",
    width: { min: 130, max: 130, competitiveness: 1 },
    render: (value, tableItem) => {
      if (!tableItem.value.measure || tableItem.value.title !== "NPV") {
        return null;
      }
      return <Format>{value ?? null}</Format>;
    },
    onCell: (tableItem) => {
      if (tableItem.value.optimizerModel) {
        const model: OptimizerModal = tableItem.value.optimizerModel;
        return { className: classNames(cn.tableCellAlignRight, model.getCellClassName(tableItem, year, tableItem.value.title !== "NPV")) };
      }
      return { className: classNames(cn.tableCellAlignRight) };
    },
    renderToString: (value) => (typeof value === "number" ? NUMBER_FORMAT.real_4.format(value) : value),
  },
];

const useColumns = (forModal = false, startYear?: number): ColumnRaw<any>[] => {
  const fact = useFact()!;
  const forecast = useForecast() ?? null;
  const range = !!forecast ? (global.isPickingFactYears ? forecast.wholeRange : forecast.range) : fact.factRange;
  return useMemo(
    (): ColumnRaw<any>[] =>
      [
        {
          key: "index",
          title: "No.пп",
          isSticky: true,
          width: { min: 52, max: 52, competitiveness: 1 },
          render: (_, { absoluteIndex }) => <div style={{ width: "100%", textAlign: "right" }}>{absoluteIndex ?? 0}</div>,
          onCell: (tableItem) => {
            if (tableItem.value.optimizerModel) {
              const model: OptimizerModal = tableItem.value.optimizerModel;
              return { className: classNames(cn.tableCellAlignRight, model.getCellClassName(tableItem)) };
            }
            return {};
          },
        },
        ...(forModal ? EXCEPT_COLUMNS : []),
        ...SHARED_COLUMNS,
        ...(forModal ? getSummaryColumn(range.to - 1) : []),
        ...[...range]
          .filter((year) => !startYear || year >= startYear)
          .map(
            (year) =>
              ({
                dataKey: year,
                title: `${year}`,
                width: { min: 130, max: 130, competitiveness: 1 },
                render: (value, tableItem) => {
                  if (!tableItem.value.measure) {
                    return null;
                  }
                  return <Format>{value ?? null}</Format>;
                },
                onCell: (tableItem) => {
                  if (tableItem.value.optimizerModel) {
                    const model: OptimizerModal = tableItem.value.optimizerModel;
                    return { className: classNames(cn.tableCellAlignRight, model.getCellClassName(tableItem, year)) };
                  }
                  return { className: classNames(cn.tableCellAlignRight) };
                },
                renderToString: (value) => (typeof value === "number" ? NUMBER_FORMAT.real_4.format(value) : value),
              } as ColumnRaw<any>)
          ),
      ] as ColumnRaw<any>[],
    [forModal, range, startYear]
  );
};

const WellsResult = observer(() => {
  const result = useResultModel();
  const openOptimizerModal = useOptimizerModal(result);
  const columns = useColumns();
  const store = useMemo(() => new WellsResultStore(result), [result]);
  const model = useMemo(
    () =>
      new TableModel(
        columns,
        store,
        {
          onRow: ({ indexPath, expand }) => ({
            className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
          }),
        },
        {
          headerHeight: 39,
          rowHeight: 33,
          borderColor: "#f0f0f0",
        }
      ),
    [store, columns]
  );

  return (
    <>
      <ResultPageTitlePortal>
        <Button onClick={openOptimizerModal}>Настройки оптимизации</Button>
      </ResultPageTitlePortal>
      <TableContextProvider value={model}>
        <CsvSaver exportArray={() => model.export()} filename="Поскважинный расчет">
          <Widget headerClassName={cn.tableHeader} />
        </CsvSaver>
      </TableContextProvider>
    </>
  );
});

export { useColumns, WellsResult };
