import { FC, ReactNode, useMemo } from "react";
import FullScreen from "react-fullscreen-crossbrowser";
import { ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons";
import { ChartContext, Domain, Lines } from "@okopok/axes_context";
import { Button, Typography } from "antd";
import { observer } from "mobx-react";

import { useScaleFromLines } from "elements/charts/lines/useScaleFromLines";
import { PointerLine } from "elements/charts/pointerLine/pointerLine";
import { ModeSelectorModel } from "elements/modeSelector/modeSelectorModel";
import TooltipDiv from "features/plot/Tooltip/TooltipDiv";
import { TooltipContext } from "features/plot/Tooltip/useTooltipDataManager";
import { LegendContentState } from "features/techForecastResultChart/LegendContent/LegendContentState";
import { TechForecastChartControls } from "features/techForecastResultChart/techForecastChartControls/techForecastChartControls";
import { useBooleanState } from "utils/useBooleanState";

import { TechFactChartModel } from "./techFactChartModel";

import cn from "./techFactResultChart.module.less";

const YEAR = 31536000000 / 2;

type TechFactResultAxisInfo = {
  axisTitle: ReactNode;
  curveFactData?: ChartData | undefined;
  curveFactForecastData?: ChartData | undefined;
  curveForecastData?: ChartData;
};

type ChartData = {
  y: Array<d3.NumberValue | null>;
  x: Array<d3.NumberValue> | Domain;
};

type TechFactResultChartProps = {
  oil: TechFactResultAxisInfo;
  liquid: TechFactResultAxisInfo;
  waterCut: TechFactResultAxisInfo;
};

const TechFactResultChart: FC<TechFactResultChartProps> = observer(({ liquid, oil, waterCut }) => {
  const legendContent = useMemo(() => new LegendContentState(), []);
  const [isFullscreen, openFullscreen, closeFullscreen, switchFullscreen] = useBooleanState();
  const modeModel = useMemo(() => new ModeSelectorModel(), []);
  const techFactModel = useMemo(() => new TechFactChartModel(oil, liquid, waterCut), [liquid, oil, waterCut]);
  return (
    <div className={cn.layout}>
      <FullScreen enabled={isFullscreen} onClose={closeFullscreen} onOpen={openFullscreen}>
        <div className={cn.titleContainer}>
          <div className={cn.header}>
            <Typography.Title level={4} className={cn.title}>
              Прогноз технологических показателей
            </Typography.Title>
            <TechForecastChartControls legendContent={legendContent} modeModel={modeModel} />
          </div>
          <div className={cn.buttonsGroup}>
            <Button onClick={switchFullscreen} icon={isFullscreen ? <ShrinkOutlined /> : <ArrowsAltOutlined />} />
          </div>
        </div>
        <div className={cn.wrap}>
          <ChartContext
            axes={useScaleFromLines(
              [techFactModel.curveFactLiquid, techFactModel.curveFactOil, techFactModel.curveFactWatering],
              YEAR,
              new Map<string, ReactNode>(
                Object.entries({
                  yOil: oil.axisTitle,
                  yLiquid: liquid.axisTitle,
                  yWatering: "Обводненность (об.), %",
                })
              ),
              undefined,
              undefined,
              undefined,
              new Map<string, Domain>(
                Object.entries({
                  yWatering: { min: 0, max: 100 },
                })
              )
            )}
            className={cn.chart}
          >
            <TooltipContext.Provider value={techFactModel.tooltipManager}>
              {legendContent.getLiquid && <Lines data={[techFactModel.curveFactLiquid]} />}
              {legendContent.getOil && <Lines data={[techFactModel.curveFactOil]} />}
              {legendContent.getWatering && <Lines data={[techFactModel.curveFactWatering]} />}
              <PointerLine />
            </TooltipContext.Provider>
          </ChartContext>
          <TooltipDiv manager={techFactModel.tooltipManager} />
        </div>
      </FullScreen>
    </div>
  );
});

export type { ChartData, TechFactResultAxisInfo, TechFactResultChartProps };
export { TechFactResultChart };
