import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Dropdown, MenuProps, Tooltip } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";
import { useForecast } from "models/project/fact/forecast/forecast";

import { ReactComponent as ArrowDownIcon } from "./arrowDown.svg";

import cn from "./calculateButton.module.less";

const calculateItems = [
  { key: "hydraulic", label: "Гидравлический расчет" },
  { key: "economic", label: "Экономический расчет" },
];

const CalculateButton: FC<{ fullWidth?: boolean }> = observer(({ fullWidth }) => {
  const [errors, setErrors] = useState("");
  const forecast = useForecast()!;
  const { pipes, nodes, currentDate, drainSourceBoundaryConditions } = forecast.infrastructure;
  const { isCalculation, calculateHydraulic, calculateEconomic } = forecast.infrastructure.calculateStore;
  const loading = forecast.production.isLoading || isCalculation;

  const location = useLocation();
  const path = location.pathname.split("/").pop();

  const checkError = useCallback(
    (isMulti?: boolean) => {
      const errors = [];

      if (!currentDate && !isMulti) {
        errors.push("Не выбрана дата расчета");
      }

      if ((pipes.data ?? []).length === 0) {
        errors.push("Не задан ни один трубопровод");
      }

      const isEmptyPipes = (pipes.data ?? []).some((el) => el.category === null);
      if (isEmptyPipes) {
        errors.push("Невозможно выполнить расчет, не все трубы в обвязке");
      }

      if (pipes.oilPipes.length && !nodes.drains.length) {
        errors.push("Не задан сток");
      }

      if (pipes.oilPipes.length && nodes.drains.length) {
        const notConnectedDrains = nodes.drains.filter((drain) => !pipes.oilPipes.some((pipe) => pipe.from === drain.uuid || pipe.to === drain.uuid));
        if (notConnectedDrains.length) {
          errors.push("Не все стоки подключены к трубопроводам");
        }
      }

      if (pipes.waterPipes.length && !nodes.sources.length) {
        errors.push("Не задан источник");
      }

      if (pipes.waterPipes.length && nodes.sources.length) {
        const notConnectedSources = nodes.sources.filter(
          (source) => !pipes.waterPipes.some((pipe) => pipe.from === source.uuid || pipe.to === source.uuid)
        );
        if (notConnectedSources.length) {
          errors.push("Не все источники подключены к трубопроводам");
        }
      }

      if (nodes.drains.length || nodes.sources) {
        const notPressureDrainSources = [...nodes.drains, ...nodes.sources].filter(
          (el) => !drainSourceBoundaryConditions.some((item) => el.uuid === item.nodeUuid)
        );
        if (notPressureDrainSources.length) {
          errors.push("Отсутствуют граничные условия давления для Стока или Источника");
        }
      }

      setErrors(errors.join(", "));
    },
    [currentDate, nodes.drains, nodes.sources, pipes.data, pipes.oilPipes, pipes.waterPipes, drainSourceBoundaryConditions]
  );

  useEffect(() => {
    checkError(path !== "map");
  }, [checkError, isCalculation, forecast.infrastructure.isUpdated, path]);

  const tooltip = useMemo(() => {
    if (forecast.production.isLoading) {
      return "Подготовка добычи";
    } else if (errors) {
      return errors;
    } else if (isCalculation) {
      return "Идет расчет";
    }
  }, [forecast.production.isLoading, errors, isCalculation]);

  const onCalculate: MenuProps["onClick"] = (e) => {
    if (e.key === "hydraulic") {
      calculateHydraulic();
    } else {
      calculateEconomic();
    }
  };

  if (path === "table") {
    return (
      <Tooltip title={tooltip}>
        <Button
          onClick={() => calculateHydraulic(true)}
          loading={loading}
          disabled={!!errors || loading}
          type="primary"
          className={classNames(cn.calculateBtn, fullWidth && cn.fullWidth)}
        >
          Рассчитать
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltip}>
      <div>
        <Dropdown disabled={!!errors || loading} menu={{ items: !!errors || loading ? [] : calculateItems, onClick: onCalculate }}>
          <Button loading={loading} type="primary" className={classNames(cn.calculateBtn, fullWidth && cn.fullWidth)}>
            Рассчитать <Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ArrowDownIcon />} />
          </Button>
        </Dropdown>
      </div>
    </Tooltip>
  );
});

export { CalculateButton };
