import { FC, ReactNode } from "react";
import { Radio, Tooltip } from "antd";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";
import { ReactComponent as LiquidIcon } from "elements/modeSelector/liquidIcon.svg";
import { ReactComponent as OilIcon } from "elements/modeSelector/oilIcon.svg";

import { VoronoiModeSelectorModel, VoronoiModeSelectorOptions } from "./voronoiModeSelectorModel";

import cn from "./voronoiModeSelector.module.less";

type ModeSelectorProps = {
  modeModel: VoronoiModeSelectorModel | null;
};

const tooltipTitle = (tooltip: string): ReactNode => (
  <>
    Выбрать <b>{tooltip}</b> вычисляемым параметром
  </>
);

const MODE_OPTIONS: { label: ReactNode; value: VoronoiModeSelectorOptions; tooltip: string }[] = [
  { value: "oilRate", label: <Icon viewBox="0 0 16 16" content={<OilIcon />} />, tooltip: "добычу нефти" },
  { value: "liquidRate", label: <Icon viewBox="0 0 16 16" content={<LiquidIcon />} />, tooltip: "добычу жидкости" },
];

const VoronoiModeSelector: FC<ModeSelectorProps> = observer(({ modeModel }) => {
  return (
    <Radio.Group onChange={modeModel?.onChangeMode} value={modeModel?.mode}>
      {MODE_OPTIONS.map((option, index) => (
        <Tooltip overlayClassName={cn.tooltip} key={index} title={tooltipTitle(option.tooltip)}>
          <Radio.Button value={option.value}>{option.label}</Radio.Button>
        </Tooltip>
      ))}
    </Radio.Group>
  );
});

export { VoronoiModeSelector };
