import { createContext, useContext } from "react";
import { makeAutoObservable, reaction } from "mobx";

import { SearchParamsStorage } from "elements/useSearchParamsStorage";
import { Forecast } from "models/project/fact/forecast/forecast";
import { TreeRoot } from "models/tree/tree";

import { Project } from "../project";

import * as wellsTree from "./wellsTree";

class ProjectData {
  public forecast: Forecast | null = null;
  public wellsTree: TreeRoot = wellsTree.generateEmptyWellTree();
  constructor(public project: Project, public searchParamsStorage: SearchParamsStorage) {
    makeAutoObservable(this);

    wellsTree.onUpdateForecast(this, null);

    reaction(
      () => this.forecast,
      () => {
        wellsTree.onUpdateForecast(this, this.forecast);
      }
    );
  }
}

const ProjectDataContext = createContext<ProjectData | null>(null);

const useProjectContext = (): ProjectData => {
  const projectData = useContext(ProjectDataContext);
  console.assert(projectData != null, "Запрошен projectData вне контекста ProjectDataContext");
  return projectData!;
};

export { ProjectData, ProjectDataContext, useProjectContext };
