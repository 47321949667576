import { makeObservable, observable } from "mobx";

import { UOM } from "elements/uom";
import { global } from "models/global";
import { Table } from "models/table";
import { Metric, parseRawMock, riseMetrics } from "services/finance/utils";
import { numerateTree } from "utils/tree";

import type { Forecast } from "./forecast";
import { ForecastResult } from "./forecastResult";
import type { Forecasts } from "./forecasts";

type ComparisonParams = Record<string, any>;

const RENAME = new Map([
  ["NPV", "NPV 2"],
  ["NPV за прогнозный период", "NPV 1"],
  ["PI", "PI 2"],
  ["PI за прогнозный период", "PI 1"],
  ["Проходка", "prohodka"],
  ["Добыча нефти", "Товарная нефть"],
  ["Инвестиционные расходы", "Денежный поток от инвестиционной деятельности"],
]);

const TRANSFORMS = new Map([["Инвестиционные расходы", (v: number) => -v]]);

const REPORT_METRICS = parseRawMock(`
Добыча нефти
Проходка
NPV
NPV за прогнозный период
PI
PI за прогнозный период
Выручка
  Нефть (включая льготы от ЭП)
  Нефтяной газ
Налоги
  НДПИ нефть
  НДД
  Налог на имущество
  Налог на прибыль
Операционные расходы (с инфляцией)
Инвестиционные расходы
  Капитальные вложения
  Затраты на геолого-разведочные работы (без развед. бурения)
`);

const SUM = (prev: number | null, cur: null | number) => (prev ?? 0) + (cur ?? 0);

class Comparison extends Table<ComparisonParams> {
  private favoriteValue: number | undefined;

  get favorite(): number {
    return this.favoriteValue || this.data.first!.id;
  }

  set favorite(val: number) {
    this.favoriteValue = val;
  }

  constructor(private data: Forecasts) {
    super("");
    makeObservable<Comparison, "favoriteValue">(this, {
      ...Table.inherited,
      favoriteValue: observable,
    });
  }

  public npv(forecast: Forecast | null) {
    return Math.random() * 100;
  }

  get isUpdated(): boolean {
    return false;
  }

  get isLoading(): boolean {
    return this.data.isLoading;
  }

  get isCompleted(): boolean {
    return true;
  }

  prohodka(forecast: Forecast): Metric {
    return {
      key: 1e8,
      title: "Проходка",
      parent: null,
      unit: {
        quantity: 1,
        measure: 1,
      },
      values: [forecast.wells.wells?.reduce((sum, { length }) => sum + (length ?? 0), 0) ?? null],
    };
  }

  extraction(forecast: Forecast): Metric {
    return {
      key: 2e8,
      title: "Добыча нефти",
      parent: null,
      unit: {
        quantity: 1,
        measure: 1,
      },
      values: [forecast.wells.wells?.reduce((sum, { length }) => sum + (length ?? 0), 0) ?? null],
    };
  }

  getMetricByTitle(metric: string, forecast: Forecast): Metric | null {
    const [result] = forecast.result!.values(); // TODO: fix
    const key = RENAME.get(metric) ?? metric;
    if (key in this) {
      return (this[key as keyof Comparison] as any)(forecast) as Metric;
    }
    return result.total?.getMetricByTitle?.(key) ?? null;
  }

  resultMetric(metricName: string): Record<string, any> {
    const metrics = this.data.map(
      (forecast: Forecast) =>
        [
          forecast.id,
          forecast.result instanceof ForecastResult ? this.getMetricByTitle(metricName, forecast) : null,
        ] as [number, Metric]
    )!;
    const valuedMetric = metrics.find(([_, v]) => v !== null)?.[1];
    if (valuedMetric === undefined) {
      return {
        title: metricName,
        measure: null,
        ...Object.fromEntries(metrics),
      };
    }
    return {
      title: metricName,
      measure: valuedMetric.unit
        ? new UOM(valuedMetric.unit.quantity ?? 0, valuedMetric.unit.measure ?? 0, global.uomResolver).unit
        : "",
      ...Object.fromEntries(
        this.comparate(
          metrics.map(([key, metric]) => {
            let sum = metric?.values?.reduce(SUM, null) ?? null;
            if (TRANSFORMS.has(metricName) && sum !== null) {
              sum = TRANSFORMS.get(metricName)!(sum);
            }
            return [key, sum];
          })
        )
      ),
    };
  }

  comparate(data: [number, number | null][]): [number, { val: number; diff: number } | null][] {
    const favoriteValue = data.find(([key]) => key === this.favorite)?.[1];
    return data.map(([key, val]) => [
      key,
      val !== null
        ? {
            diff: typeof favoriteValue === "number" ? val - favoriteValue : 0,
            val,
          }
        : null,
    ]);
  }

  get tableItems(): ComparisonParams[] {
    const tree = riseMetrics(
      REPORT_METRICS.map((metric) => ({
        ...metric,
        ...this.resultMetric(metric.title),
      }))
    );
    numerateTree(tree);
    return tree;
  }

  get isValid(): boolean {
    return true;
  }
}

export { Comparison };
