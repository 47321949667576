import { Forecast } from "models/project/fact/forecast/forecast";
import { WellData } from "models/project/fact/well/well";

import { WellParameters, WellParametersForm } from "./types";

export type WellTypes = "base" | "new" | "gtm";

export type WellInfo = {
  bush: string;
  deposit: string;
  licenseRegion: string;
  construction: string;
  stratum: string;
  stratumId: number;
};

export type WellNodePart = {
  well: WellData;
  wellInfo: WellInfo;
  techParameters: WellParameters | null;
  gtmId?: number | null;
  typeName?: string | null;
  forecast: Forecast | null;
};

export const emptyTechParameters = (
  wellId: number,
  stratumId: number | null,
  gtmId?: number | null
): WellParametersForm => ({
  wellId,
  stratumId,
  gtmId,
  liquidDropRate: {
    calculationMethod: "dropRatio",
    wellsAnalog: {
      constructionType: true,
      minDurOperation: null,
      dropRatio: {
        value: null,
        deviation: null,
        takeIntoAccount: false,
      },
      dropRatioFirstMonth: {
        value: null,
        deviation: null,
        takeIntoAccount: false,
      },
    },
    dropRatio: {
      chart: {
        x: [null],
        y: [null],
      },
    },
    displacementCharacteristics: {
      method: "ln_vnf",
      calculationMethod: "chart",
      chart: null,
      wellsAnalog: {
        constructionType: true,
        minDurOperation: null,
        dropRatio: {
          value: null,
          deviation: null,
          takeIntoAccount: false,
        },
        dropRatioFirstMonth: {
          value: null,
          deviation: null,
          takeIntoAccount: false,
        },
      },
    },
  },
  oilDropRate: {
    calculationMethod: "dropCurves",
    wellsAnalog: {
      constructionType: true,
      minDurOperation: null,
      dropRatio: {
        value: null,
        deviation: null,
        takeIntoAccount: false,
      },
      dropRatioFirstMonth: {
        value: null,
        deviation: null,
        takeIntoAccount: false,
      },
    },
    dropRatio: {
      chart: {
        x: [null],
        y: [null],
      },
    },
    displacementCharacteristics: {
      method: "ln_vnf",
      calculationMethod: "chart",
      chart: null,
      wellsAnalog: {
        constructionType: true,
        minDurOperation: null,
        dropRatio: {
          value: null,
          deviation: null,
          takeIntoAccount: false,
        },
        dropRatioFirstMonth: {
          value: null,
          deviation: null,
          takeIntoAccount: false,
        },
      },
    },
    dropCurves: {
      method: "arps",
      arps: {
        a: {
          min: 0,
          max: 10,
        },
        k: {
          min: 0,
          max: 1,
        },
      },
      duong: {
        a: {
          min: 0,
          max: 10,
        },
        k: {
          min: 1,
          max: 10,
        },
      },
      exponential: {
        a: {
          min: 0,
          max: 10,
        },
        k: {
          min: 0.1,
          max: 10,
        },
      },
    },
  },
  stopCriterion: {
    waterCut: 99,
    oilFlowRate: null,
    periodOilWorkingOff: null,
  },
  operationCoefficient: 1,
});
