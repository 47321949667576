import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { Dayjs } from "dayjs";
import { computed, makeObservable, reaction, runInAction, transaction } from "mobx";

import { WellParameters } from "features/forecast/technologyForecastModal/types";
import { TECH_PREDICTION_DEBET_FILTER_MAP, TECH_PREDICTION_DEBET_STATIC_FILTERS } from "features/techPrediction/debet";
import { FilterManager } from "features/techPrediction/filters/manager";
import { StratumData } from "models/project/fact/production/stratumData";
import { ProducingObject } from "models/project/producingObject/producingObject";
import { Stratum } from "models/project/stratum/stratum";
import { TreeRoot } from "models/tree/tree";

import { aggregateByDate, DateDataSeries } from "../../production/aggregate";
import { sumUp } from "../../production/aggregateFunctions";
import { ProductionDatum } from "../../production/production";
import { Well } from "../../well/well";
import { Forecast } from "../forecast";
import { Intervention } from "../interventions/intervention";

import { TechParameters } from "./techParameters";

type FilterPredicate<T> = (child: T) => boolean;
function compileFilters<T>(filters: Array<FilterPredicate<T>>): FilterPredicate<T> {
  return (child) => {
    for (const filter of filters) {
      if (!filter(child)) {
        return false;
      }
    }
    return true;
  };
}

type ByStratums = {
  oilRate?: number | null;
  liquidRate?: number | null;
  waterCut?: number | null;
  recoverableResources?: number | null;
};

type StopCriterion = {
  label: string;
  value: number;
  measure: string;
};

type DRow = {
  wellTitle: string;
  eventTitle?: string;
  date?: Dayjs | null;
  wellStatus?: string;
  wellType?: string;
  wellPad?: string;
  wellDate?: Dayjs | null | undefined;
  fond?: string;
  licenseRegion?: string;
  producingObject?: string;
  stratum?: string;

  operationCoef?: number | null;

  liquidRate?: number | null;
  oilRate?: number | null;
  waterCut?: number | null;

  accumLiquid?: number | null;
  accumOil?: number | null;

  recoverableResourcesStart?: number | null;
  recoverableResourcesEnd?: number | null;
  recoverableResourcesRatio?: number | null;

  liquidDebitMethod?: string | null;
  oilDebitMethod?: string | null;

  stopCriterion?: StopCriterion;

  absoluteIndex?: number;
};

class Debet extends TableNode<DRow, WellNode> {
  public readonly filterManager = new FilterManager(
    TECH_PREDICTION_DEBET_FILTER_MAP,
    TECH_PREDICTION_DEBET_STATIC_FILTERS
  );

  constructor(public readonly forecast: Forecast, private readonly tree: TreeRoot<Well>) {
    super(null, { mutable: false });
    makeObservable(this, {
      selectedEvents: computed,
    });

    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        forecast.wells.allWells
          .map((well) => [well, forecast.interventions.getInterventionsByWellId(well.id)] as const)
          .filter(([well, interventions]) => well.data.stratumId !== null || interventions.length > 0)
          .map(([well, interventions]) => new WellNode(this, forecast, well, interventions))
      );
    });

    reaction(
      () => tree.selectedItems,
      (selected) => this.filterSelected(new Set(selected.map((node) => node.item!.id)))
    );
  }

  private filterSelected(selectedWellsIds: Set<number>) {
    this.childrenStore?.filter((node) => selectedWellsIds.has(node.well.id));
  }

  public get selectedEvents(): EventNode[] {
    return [...TableNode.selectedNodes(this)].filter((node) => node.children === null) as EventNode[];
  }

  public applyFilters = () => {
    const predicate = compileFilters<EventNode>(this.filterManager.predicates);
    transaction(() => {
      for (const child of this.childrenStore?.children ?? []) {
        child.childrenStore?.filter(predicate);
      }
      this.childrenStore?.filter((node) => node.childrenStore?.visibleChildrenLength !== 0);
    });
  };
}

class WellNode extends TableNode<DRow, EventNode> {
  public asDRow(): DRow {
    return {
      wellTitle: this.well.title,
      wellDate: this.well.date,
    };
  }

  constructor(parentNode: Debet, forecast: Forecast, public readonly well: Well, interventions: Intervention[]) {
    super(parentNode, { mutable: false, isExpandedChildren: true });

    const wellEvents =
      well.fond === "New"
        ? [new EventNode(this, forecast, well, null)]
        : well.data.stratumIds.map((stratumId) => new EventNode(this, forecast, well, null, stratumId));
    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(this, [
        ...wellEvents,
        ...interventions.map((intervention) => new EventNode(this, forecast, well, intervention)),
      ]);
    });
  }
}

class EventNode extends TableNode<DRow> {
  public asDRow(): DRow {
    const prod = this.production?.map((d) => d.byYear());

    let accumOil: number | null = null;
    let accumLiquid: number | null = null;
    if (prod) {
      let byYear: DateDataSeries<ProductionDatum>;
      if (prod.length === 1) {
        byYear = prod[0];
      } else {
        byYear = aggregateByDate(sumUp, prod);
      }

      for (const [, datum] of byYear) {
        accumOil = (accumOil ?? 0) + (datum.oil_prod ?? 0);
        accumLiquid = (accumLiquid ?? 0) + (datum.liquid_prod ?? 0);
      }
    }

    const recoverableResourcesStart = this.byStratums.recoverableResources ?? null;
    let recoverableResourcesEnd: number | null = null;
    let recoverableResourcesRatio: number | null = null;
    if (recoverableResourcesStart !== null && accumOil !== null) {
      recoverableResourcesEnd = recoverableResourcesStart - accumOil;
      recoverableResourcesRatio = (accumOil / recoverableResourcesStart) * 100; // в %
    }

    const techParams = this.techParameters;

    const { oilDebitMethod = null, liquidDebitMethod = null } =
      techParams !== null ? TechParameters.calcMethods(techParams) : {};

    return {
      wellTitle: this.well.title,
      eventTitle:
        this.intervention?.typeName ?? (this.well.fond === "New" ? "Эксплуатационное бурение" : "Базовая добыча"),
      date: (this.intervention ?? this.well).date,
      wellStatus: this.wellStatus,
      wellType: this.well.type ?? undefined,
      wellPad: this.well.pad?.title,
      wellDate: this.intervention?.date,
      fond: this.well.fond === "Base" ? "Базовый" : "Новый",
      licenseRegion: this.well.licenseRegion?.title,
      stratum: this.stratum?.title,
      producingObject: this.producingObject?.title,
      operationCoef: techParams?.operationCoefficient ?? null,
      stopCriterion: undefined,

      oilRate: this.byStratums.oilRate ?? null,
      liquidRate: this.byStratums.liquidRate ?? null,
      waterCut: this.byStratums.waterCut ?? null,

      accumLiquid,
      accumOil,

      recoverableResourcesStart,
      recoverableResourcesEnd,
      recoverableResourcesRatio,

      liquidDebitMethod,
      oilDebitMethod,
    };
  }

  public get techParameters(): WellParameters | null {
    if (this.intervention !== null) {
      return this.forecast.techParameters.interventionsParameters.get(this.intervention.id) ?? null;
    }
    if (this.stratumId === null) {
      return null;
    }
    return this.forecast.techParameters.wellsParameters.get(this.well.id)?.[this.stratumId] ?? null;
  }

  public get production(): StratumData[] | undefined {
    if (!this.stratumId) {
      return undefined;
    }
    let forecastData;
    if (this.intervention === null) {
      forecastData = this.forecast.production.wellData(this.well.id)?.dataByStratums.get(this.stratumId);
    } else {
      forecastData = this.forecast.production
        .interventionData(this.intervention.id)
        ?.dataByStratums.get(this.stratumId);
    }
    if (!forecastData) {
      return undefined;
    }
    let factData;
    if (this.intervention === null) {
      factData = this.forecast.fact.production.wellData(this.well.id)?.dataByStratums.get(this.stratumId);
    } else {
      factData = this.forecast.fact.production
        .interventionData(this.intervention.id)
        ?.dataByStratums.get(this.stratumId);
    }

    return [...(factData ?? []), ...forecastData];
  }

  public get data() {
    return (this.intervention ?? this.well).data;
  }

  public get wellStatus(): string {
    const prod = this.forecast.production.wellStatus(this.well.id);
    if (prod === undefined) {
      return "Нет истории добычи";
    }
    if (prod.isInjecting) {
      return "Нагнетательная";
    }
    if (prod.isMining) {
      return "Добывающая";
    }
    return "Прочего назначения";
  }

  public readonly stratumId: number | null = null;

  constructor(
    parentNode: WellNode,
    public readonly forecast: Forecast,
    public readonly well: Well,
    public readonly intervention: Intervention | null,
    wellStratumId?: number // if base fond well, stratumId for recoverableResources
  ) {
    super(parentNode, { mutable: false });

    if (well.fond === "Base" && intervention === null) {
      console.assert(
        wellStratumId !== undefined,
        "wellStratumId should be provided only for base production event",
        well,
        intervention
      );
    }

    if (intervention !== null) {
      this.stratumId = intervention.data.stratumId;
    } else if (well.fond === "New") {
      this.stratumId = well.data.stratumId;
    } else if (wellStratumId !== undefined) {
      console.assert(
        intervention === null,
        "wellStratumId should be provided only for base production event (no intervention)"
      );
      this.stratumId = wellStratumId;
    }

    makeObservable(this, {
      techParameters: computed,
      production: computed,
      wellStatus: computed,
      data: computed,
      stratum: computed,
      producingObject: computed,
      byStratums: computed,
    });

    runInAction(() => (this.childrenStore = null));
  }

  public get stratum(): Stratum | null | undefined {
    if (this.stratumId === null) {
      return null;
    }
    return this.forecast.stratums.at(this.stratumId);
  }

  public get producingObject(): ProducingObject | null | undefined {
    const stratum = this.stratum;
    if (!stratum) {
      return stratum;
    }
    return this.forecast.stratums.getProducingObject(stratum);
  }

  public get byStratums(): ByStratums {
    const getter = ((): ByStratums => {
      if (this.intervention !== null) {
        return this.intervention.data;
      }
      if (this.well.fond === "New") {
        return this.well.data;
      }
      if (this.stratumId === null) {
        return {};
      }
      return this.well.data.byStratums[this.stratumId];
    })();
    const { oilRate, liquidRate, waterCut, recoverableResources } = getter;
    return {
      oilRate,
      liquidRate,
      waterCut,
      recoverableResources,
    };
  }
}

export type { DRow };
export { Debet, EventNode };
