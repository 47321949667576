import dayjs, { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable } from "mobx";

import { calculateDistance } from "features/infrastructure/infrastructureMap/utils";
import { SegmentType } from "services/back/infrastructure/catalog";
import { PipeSystem } from "services/back/infrastructure/types";
import { convertPressure } from "utils/convertePressure";
import { getRandomUid } from "utils/random";

import { Infrastructure } from "./infrastructure";

const { barToAtmosphere } = convertPressure;

type PipeType = {
  uuid: string;
  from: string;
  startedAt: Dayjs;
  to: string;
  isSelected?: boolean;
  type: "pipe";
  segmentType: "prod" | "inj";
  title: string;
  velocity?: number;
  diameter?: number;
  diameterOuter?: number;
  finishedAt?: Dayjs | null;
  roughness?: number;
  thickness?: number;
  construction?: SegmentType["construction"];
  reconstruction?: SegmentType["reconstruction"];
  limitingPressureGradient?: number;
  limitingVelocity?: number;
  category?: string | null;
  length: number;
  frictionCorrectionFactor: number;
};

class Pipes {
  data?: PipeType[];

  constructor(private parent: Infrastructure) {
    makeObservable(this, {
      data: observable,
      init: action,
      isLoading: computed,
      length: computed,
      update: action,
      pushPipe: action,
      remove: action,
    });
  }

  public init = (data: PipeType[]) => {
    this.data = data;
  };

  get isLoading() {
    return this.data === undefined;
  }

  get length() {
    return this.data?.length;
  }

  get waterPipes() {
    return (this.data ?? []).filter(({ segmentType }) => segmentType === "inj");
  }
  get oilPipes() {
    return (this.data ?? []).filter(({ segmentType }) => segmentType === "prod");
  }

  update = (pipe: Partial<PipeType>) => {
    const pipeIndex = this.data!.findIndex(({ uuid }) => uuid === pipe.uuid);
    this.data![pipeIndex] = {
      ...this.data![pipeIndex],
      ...pipe,
    };
    this.parent.markUpdated();
  };

  pushPipe = (pipe: PipeType) => {
    this.data?.push(pipe);
    this.parent.markUpdated();
  };

  remove = (ids: string[]) => {
    this.data = (this.data ?? []).filter(({ uuid }) => !ids.includes(uuid));
    this.parent.markUpdated();
  };

  static createPipe = (
    from: string,
    to: string,
    index: number,
    mode: "oil" | "water",
    infrastructure: Infrastructure
  ): PipeType => {
    const [nodeFrom, nodeTo] = [infrastructure.nodes.at(from)!, infrastructure.nodes.at(to)!];
    return {
      uuid: getRandomUid(),
      startedAt: dayjs(),
      title: `${nodeFrom.title} - ${nodeTo.title}`,
      from,
      to,
      segmentType: mode === "oil" ? "prod" : ("inj" as PipeType["segmentType"]),
      type: "pipe",
      category: null,
      length: calculateDistance({ from: infrastructure.nodes.at(from)!, to: infrastructure.nodes.at(to)!, unit: "km" }),
      frictionCorrectionFactor: 1,
    };
  };

  static pipesFromPipeSystem(pipeSystem: PipeSystem, infrastructure: Infrastructure): PipeType[] {
    return pipeSystem.segments.map((segment, i) => ({
      ...segment,
      title: segment.title || `Труба ${i + 1}`,
      from: segment.firstNodeUuid,
      to: segment.secondNodeUuid,
      startedAt: dayjs(segment.startedAt),
      diameterOuter: segment.diameterOuter ?? 50,
      diameter: segment.diameterOuter - segment.thickness,
      limitingPressureGradient: barToAtmosphere(segment.limitingPressureGradient),
      finishedAt: segment.finishedAt ? dayjs(segment.finishedAt) : null,
      type: "pipe",
      category: "Новый объект",
      frictionCorrectionFactor: segment.frictionCorrectionFactor ?? 1,
      length: calculateDistance({
        from: infrastructure.nodes.at(segment.firstNodeUuid)!,
        to: infrastructure.nodes.at(segment.secondNodeUuid)!,
        unit: "km",
      }),
    }));
  }

  static getPipesAttributes(data?: Partial<PipeType>) {
    return {
      diameterOuter: data ? data?.diameterOuter : undefined,
      diameter: data ? data.diameter : undefined,
      limitingPressureGradient: data ? data.limitingPressureGradient : undefined,
      limitingVelocity: data ? data.limitingVelocity : undefined,
      roughness: data ? data.roughness : undefined,
      thickness: data ? data.thickness : undefined,
      construction: data ? data.construction : undefined,
      reconstruction: data ? data.reconstruction : undefined,
    } as Partial<SegmentType>;
  }
}

export { Pipes, type PipeType };
