import { FC } from "react";

import { CustomOptionsSelect } from "elements/inputs/customOptionSelect/customOptionSelect";

import { DisplacementCharacteristicsTypeOption } from "../../types";
import { DropRateChildProps } from "../dropRate";

import { UploadChart } from "./uploadChart";
import { WellsAnalog } from "./wellsAnalog";

import cn from "./displacementCharacteristic.module.less";

type SelectOption = {
  value: DisplacementCharacteristicsTypeOption;
  label: string;
  disabled?: boolean;
};

const DisplacementCharacteristics: FC<DropRateChildProps> = ({ propId, techParameters, setTechParameters, chosenType, wellsNameMap }) => {
  const optionsMap: SelectOption[] = [
    {
      value: "ln_vnf",
      label: `ln(ВНФ) - ${chosenType === "base" ? "Накопленная добыча" : "Отбор от НИЗ"}`,
    },
    {
      value: "wc",
      label: "Wc - Отбор от НИЗ",
    },
  ];

  return (
    <>
      <div className={cn.header}>
        <CustomOptionsSelect
          className={cn.selector}
          options={optionsMap}
          defaultValue={optionsMap[0]}
          onChange={(v) => setTechParameters.applyForAll([propId, "displacementCharacteristics", "method"], v)}
          optionclass={cn["select-option"]}
        />
      </div>
      {chosenType === "base" ? null : techParameters[0][propId].displacementCharacteristics.calculationMethod === "wellsAnalog" ? (
        <WellsAnalog propId={propId} techParameters={techParameters} setTechParameters={setTechParameters} isDisplacement chosenType={chosenType} />
      ) : (
        <>
          {techParameters.length > 1 ? (
            <UploadChart
              title="Загрузить для всех"
              onSave={(chart) => setTechParameters.applyForAll([propId, "displacementCharacteristics", "chart"], chart)}
            />
          ) : null}
          {techParameters.map(({ wellId, gtmId }) => (
            <UploadChart
              title={wellsNameMap ? wellsNameMap[wellId] : ""}
              onSave={(chart) => setTechParameters.applyForId([propId, "displacementCharacteristics", "chart"], chart, wellId, gtmId)}
            />
          ))}
        </>
      )}
    </>
  );
};

export { DisplacementCharacteristics };
