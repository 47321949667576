import { ColumnRaw, TableItem, TableModel } from "@okopok/components/Table";
import { makeAutoObservable, reaction } from "mobx";

import { WellsResultStore } from "features/results/wellsResultStore";

import { ForecastResult } from "./forecastResult";

import cn from "./optimizerModal.module.less";

type ExcludedWells = Record<number, { year: number; wellId?: number } | null>;
type OptimizeReq = {
  scenarioId: number;
  needRemove: number[];
  needStop: { wellId: number; year: number }[];
};

const onRow = ({ value, expand, indexPath }: TableItem<any>) => {
  if (expand) {
    return { className: !value.hideExpand ? (indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary) : "" };
  }
  return {};
};

class OptimizerModal {
  public readonly scenarioId: number;
  public readonly store: WellsResultStore;
  public readonly table: TableModel<any>;

  public currentNpv: number | null = null;

  public currentCf: number | null = null;

  public accumulatedNpv: number | null = null;

  public manualExcludedWells: Record<number, { checked: boolean; wellId?: number }> = {};

  public filterStop = false;
  public filterRemove = false;

  constructor(result: ForecastResult | null | undefined, columns: ColumnRaw<any>[], private startYear: number) {
    this.scenarioId = result?.forecast.id!;
    this.store = new WellsResultStore(result!, this, this.startYear);
    this.table = new TableModel(
      columns,
      this.store,
      {
        onRow,
      },
      {
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }
    );
    makeAutoObservable(this);
    reaction(
      () => [this.filterRemove, this.filterStop, this.summary],
      ([fRemove, fStop, summary]) => {
        for (const children of this.table.store.childrenStore?.children!) {
          children.childrenStore.filter((value: any) => {
            if (fRemove && (summary as OptimizeReq).needRemove.includes(value.wellInfo.id)) {
              return true;
            }
            if (fStop && (summary as OptimizeReq).needStop.find(({ wellId }) => value.wellInfo.id === wellId)) {
              return true;
            }
            if (!(fRemove && this.deleteWells) && !(fStop && this.stopWells)) {
              return true;
            }
            return false;
          });
        }
      }
    );
  }
  setCurrentNpv = (v: number | null) => {
    this.currentNpv = v;
  };

  setCurrentCf = (v: number | null) => {
    this.currentCf = v;
  };

  setAccumulatedNpv = (v: number | null) => {
    this.accumulatedNpv = v;
  };

  changeFilterStop = () => {
    this.filterStop = !this.filterStop;
  };

  changeFilterRemove = () => {
    this.filterRemove = !this.filterRemove;
  };

  getCellClassName = ({ value, expand }: TableItem<any>, year?: number, noValue = false) => {
    const excludedInfo = this.excludedWells[value.key];

    if (year && excludedInfo && excludedInfo.year !== 0 && excludedInfo.year <= year && !(expand && !value.hideExpand) && !noValue) {
      return cn["excluded-cell"];
    }
    return (this.manualExcludedWells[value.key] ? this.manualExcludedWells[value.key].checked : this.excludedWells[value.key])
      ? expand && !value.hideExpand
        ? cn["excluded-well"]
        : cn["excluded-row"]
      : "";
  };

  setManualExcludedWell = (key: number, wellId?: number) => {
    if (!this.manualExcludedWells[key]?.checked) {
      this.manualExcludedWells[key] = { checked: true, wellId };
      this.manualExcludedWells[key + 2] = { checked: true, wellId };
      this.manualExcludedWells[key + 4] = { checked: true, wellId };
    } else {
      this.manualExcludedWells[key] = { checked: false, wellId };
      this.manualExcludedWells[key + 2] = { checked: false, wellId };
      this.manualExcludedWells[key + 4] = { checked: false, wellId };
    }
  };

  get selectedDelete(): number {
    return this.summary.needRemove.length;
  }

  get selectedStop(): number {
    return this.summary.needStop.length;
  }

  get deleteWells(): boolean {
    return this.accumulatedNpv !== null;
  }

  get stopWells(): boolean {
    return this.currentNpv !== null || this.currentCf !== null;
  }

  get currentCfCheck(): boolean {
    return this.currentCf !== null;
  }

  get currentNpvCheck(): boolean {
    return this.currentNpv !== null;
  }

  get excludedWells(): ExcludedWells {
    const result: ExcludedWells = {};
    for (const children of this.store.childrenStore?.children ?? []) {
      for (const well of children.childrenStore?.children ?? []) {
        let forDelete = this.deleteWells;
        const key = Number(well.wellInfo.key);
        const id = well.wellInfo.id;
        for (const parameter of well.children ?? []) {
          const { title } = parameter.wellInfo;
          const { values } = parameter;
          const parameterKey = Number(parameter.wellInfo.key);
          let isPrevValueGreaterNpv = false;
          let isPrevValueGreaterCf = false;
          let firstNotNull = true;
          let stopYear = 0;
          const valuesArr = Object.entries(values);
          if (title === "NPV" && valuesArr.every(([_, value]) => value === null)) {
            forDelete = false;
          }
          for (const [year, value] of valuesArr) {
            if (typeof value === "number") {
              const currentYear = Number(year);
              if (title === "CF") {
                if (value < (this.currentCf ?? 0) && firstNotNull) {
                  stopYear = currentYear;
                }
                if (value > (this.currentCf ?? 0)) {
                  isPrevValueGreaterCf = true;
                }
                if (value < (this.currentCf ?? 0) && isPrevValueGreaterCf) {
                  stopYear = currentYear;
                  isPrevValueGreaterCf = false;
                }
              }
              if (title === "NPV") {
                if (value < (this.currentNpv ?? 0) && firstNotNull) {
                  stopYear = currentYear;
                }
                if (value > (this.currentNpv ?? 0)) {
                  isPrevValueGreaterNpv = true;
                }
                if (value >= (this.accumulatedNpv ?? 0)) {
                  forDelete = false;
                }
                if (value < (this.currentNpv ?? 0) && isPrevValueGreaterNpv) {
                  stopYear = currentYear;
                  isPrevValueGreaterNpv = false;
                }
              }
              firstNotNull = false;
            }
          }
          stopYear = isPrevValueGreaterNpv || isPrevValueGreaterCf ? 0 : stopYear;
          if (stopYear !== 0 && this.stopWells && (title === "CF" ? this.currentCfCheck : this.currentNpvCheck)) {
            result[parameterKey] = { year: stopYear, wellId: id };
            result[key] = { year: stopYear, wellId: id };
          }
        }
        if (forDelete) {
          result[key] = { year: 0, wellId: id };
          result[key + 2] = { year: 0, wellId: id };
          result[key + 4] = { year: 0, wellId: id };
        }
      }
    }

    return result;
  }

  get summary(): OptimizeReq {
    const needRemove: Set<number> = new Set([]);
    const needStop: { wellId: number; year: number }[] = [];
    for (const excluded of Object.values(this.excludedWells)) {
      if (excluded) {
        const { year, wellId } = excluded;
        if (year === 0) {
          needRemove.add(wellId!);
        } else if (!needStop.some(({ wellId: wId }) => wId === wellId)) {
          needStop.push({ year, wellId: wellId! });
        }
      }
    }
    for (const { checked, wellId } of Object.values(this.manualExcludedWells)) {
      if (checked) {
        needRemove.add(wellId!);
      } else {
        needRemove.delete(wellId!);
      }
    }
    return {
      scenarioId: this.scenarioId,
      needRemove: Array.from(needRemove.values()),
      needStop,
    };
  }
}

export { OptimizerModal };
export type { OptimizeReq };
