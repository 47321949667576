import { FC, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Button, Tabs, Tooltip } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useMainRouterParams } from "routing/authorizedRouter";

import { Icon } from "elements/icon/icon";
import { global } from "models/global";

import { COMPARE, GENERAL, MANAGEMENT, ProjectFolder, REPORTS, SCENARIO } from "../../assets/routing";

import cn from "./modeSwitch.module.less";

const useCurrentFolder = (): ProjectFolder => {
  const { scenario } = useMainRouterParams();
  const { pathname } = useLocation();
  let expectedKey = pathname.split("/")[2 + (scenario ? 1 : 0)];
  return (
    GROUPS.flat().find(
      (item) =>
        // если ключ группы совпадает
        item.key === expectedKey ||
        // или если среди детей есть соответствующий ключ
        ("children" in item && item.children.find(({ key }) => key === expectedKey))
    ) ??
    // иначе берём дефолтную
    GENERAL
  );
};

const MenuNarrowButton: FC = observer(() => {
  const current = useCurrentFolder();
  return (
    <div className={cn.menuToggler}>
      <Tooltip placement="right" title="Свернуть/показать подменю">
        <Button
          disabled={!("children" in current) && current.key !== "infrastructure"}
          onClick={global.toggleNarrowMenu}
          type="text"
          size="large"
          icon={global.isNarrowMenu ? <RightCircleOutlined /> : <LeftCircleOutlined />}
        />
      </Tooltip>
    </div>
  );
});

MenuNarrowButton.displayName = "narrowMenu";

const GROUPS = [[GENERAL, MANAGEMENT], SCENARIO, [COMPARE, REPORTS]];

const isEndOfGroup = (key: string): boolean => GROUPS.find((group) => group.at(-1)!.key === key) !== undefined;

const MenuIcon: FC<{ index: string; title: React.ReactNode; icon: React.ReactNode }> = ({ title, icon, index }) => (
  <Tooltip className={classNames(isEndOfGroup(index) && cn.lastOfGroup)} placement="right" title={title}>
    <span>
      <Icon content={icon} />
    </span>
  </Tooltip>
);

const TABS = GROUPS.flat().map(({ key, title, icon, ...content }) => ({
  key,
  label: <MenuIcon index={key} title={title} icon={icon} />,
  disabled: "element" in content && content.element === null,
}));

const useOnNavigate = () => {
  const navigate = useNavigate();
  const { project, scenario } = useMainRouterParams();

  return useCallback(
    (key: string) => {
      navigate(["", project, scenario, key].filter((v) => v !== undefined).join("/"));
    },
    [navigate, project, scenario]
  );
};

const ModeSwitch: FC<{ className?: string }> = observer(({ className }) => {
  const current = useCurrentFolder();

  const onNavigate = useOnNavigate();

  return (
    <>
      <MenuNarrowButton />
      <Tabs activeKey={current.key} className={classNames(cn.tabs, className)} onTabClick={onNavigate} tabPosition="left" items={TABS} />
    </>
  );
});

export { ModeSwitch, useCurrentFolder };
