import { TableNode } from "@okopok/components/Table";
import { action, computed, makeObservable, observable } from "mobx";

import { zip } from "utils/itertools";

import { FilterDetails, FilterInfo, FilterType, StaticFilter } from "./types";

class FilterManager<DRow, TNode extends TableNode<DRow, any>> {
  #filters: Array<Partial<FilterInfo<any>> | undefined> = observable.array([undefined]);
  public staticFiltersChecked: boolean[];

  constructor(
    public readonly filterMap: Record<string, FilterDetails<any, DRow, TNode>>,
    public readonly staticFilters: StaticFilter<DRow, TNode>[]
  ) {
    this.staticFiltersChecked = new Array(staticFilters.length).fill(false);

    makeObservable(this, {
      staticFiltersChecked: observable,
      filters: computed,
      predicates: computed,
      purePredicates: computed,
      removeDisabled: computed,
      insertEmpty: action,
      delete: action,
      change: action,
    });
  }

  public get predicates(): ReturnType<FilterDetails<any, DRow, TNode>["predicateFactory"]>[] {
    return [
      ...[...zip(this.staticFiltersChecked, this.staticFilters)]
        .filter(([checked]) => checked)
        .map(([_, filter]) => filter.predicate),
      ...this.purePredicates,
    ];
  }

  public get purePredicates(): ReturnType<FilterDetails<any, any, any>["predicateFactory"]>[] {
    return this.#filters
      .filter((filter) => {
        if (filter === undefined) {
          return false;
        }
        const { column, condition } = filter;
        if ([column, condition].some((v) => v === undefined)) {
          return false;
        }
        return true;
      })
      .map((filter) => {
        const { column, condition, value } = filter as FilterInfo<any>;
        const factory = this.filterMap[column].predicateFactory;
        return factory(condition, value);
      });
  }

  public get filters() {
    return this.#filters;
  }

  public insertEmpty(index: number) {
    // if undefined -> delete previous undefined
    this.#filters.splice(index, 0, undefined);
  }

  public change<T extends FilterType>(filter: Partial<FilterInfo<T>>, index: number) {
    this.#filters[index] = filter;
  }

  public delete(index: number) {
    this.#filters.splice(index, 1);
    if (this.#filters.length === 0) {
      this.#filters.push(undefined);
    }
  }

  public get removeDisabled(): boolean {
    return this.#filters.length === 1 && this.#filters[0] === undefined;
  }
}

export { FilterManager };
