import { parseRawMock, riseMetrics } from "services/finance/utils";

const COMPARISON_TABLE_LAYOUT_MAIN_METRICS = `
NPV;2;6
IRR;3;2
PBP;4;2
dPBP;4;2
PI;3;1
Накопленный денежный поток;2;6
`;

function duplicateMainMetrics(line: string): string[] {
  const [title, qty, msr] = line.split(";");
  const titles = [title, `${title} за прогнозный период`];
  return titles.map((t) => `${t};${qty};${msr}\n`);
}

// {'  ' * level}{title};{quantity};{measure}
const COMPARISON_TABLE_LAYOUT = `
${COMPARISON_TABLE_LAYOUT_MAIN_METRICS.trim().split("\n").flatMap(duplicateMainMetrics).join("\n")}
Добыча;0;0
  Добыча жидкости;5;4
  Добыча УВ всего;5;6
    Добыча нефти;5;4
    Использование нефтяного газа;7;5
Выручка всего (без налогов и транспорта);2;3
  Нефть (включая льготы от ЭП);2;3
    Льготы от ЭП;2;3
  Нефтяной газ;2;3
  Прочая выручка;2;3
  Затраты на внешний транспорт;2;3
Операционные расходы (с инфляцией);2;3
Налоги;0;0
  Налог на имущество;2;3
  Налог на прибыль;2;3
  НДД;2;3
  НДПИ нефть;2;3
Проходка;1;2
Денежный поток от инвестиций в оборотный капитал;2;3
Денежный поток от инвестиционной деятельности;2;3
  Капитальные вложения;2;3
  Затраты на геолого-разведочные работы (без развед. бурения);2;3
  Затраты на приобретение лицензий и геолог. информации;2;3
  Прочие инвестиционные расходы;2;3
`;

const COMPARISON_TABLE_METRICS = riseMetrics(parseRawMock(COMPARISON_TABLE_LAYOUT));

type MetricsSources = {
  [metricTitle: string]: number | undefined; // title -> maxLevel in metric tree
};

const OPERATING_METRICS_SOURCES: MetricsSources = {
  Добыча: undefined, // full tree
  "Выручка всего (без налогов и транспорта)": undefined, // full tree
  "Операционные расходы (с инфляцией)": 1, // only root
  Налоги: 2, // self and children level
};

const INVEST_METRICS_SOURCES: MetricsSources = {
  "Денежный поток от инвестиций в оборотный капитал": 1,
  "Денежный поток от инвестиционной деятельности": 2,
  Проходка: 1,
};

export type { MetricsSources };
export { COMPARISON_TABLE_METRICS, INVEST_METRICS_SOURCES, OPERATING_METRICS_SOURCES };
