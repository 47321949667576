import dayjs from "dayjs";

import { getRandomUid } from "utils/random";

type Well = {
  x: number;
  y: number;
  mineId: number;
  mineTitle: string;
};

const getWellPadPoints = async (wells: Well[]) => {
  const mines = new Map<number, Well[]>();
  for (const well of wells) {
    const mineId = well.mineId;
    if (!mines.has(mineId)) {
      mines.set(mineId, []);
    }
    mines.get(mineId)!.push(well);
  }
  return {
    points: [...mines.entries()].map(([mineId, mineSet]) => {
      let sx = 0;
      let sy = 0;
      for (const { x, y } of mineSet) {
        sx += x;
        sy += y;
      }
      return {
        x: sx / mineSet.length,
        y: (sy /= mineSet.length),
        mineId,
        startedAt: dayjs(),
        uuid: getRandomUid(),
        pressure: 0,
        type: "mine" as const,
        title: `Куст ${mineSet[0].mineTitle}`,
        altitude: 266,
        isDisabled: false,
      };
    }),
    ordered: [],
  };
};

export { getWellPadPoints };
