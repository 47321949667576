import { FC } from "react";
import { Tooltip } from "antd";
import classNames from "classnames";

import { compareValues } from "../utils";

import cn from "./problemIndicator.module.less";

type Props = {
  children: number;
  limit: number;
};

const ProblemIndicator: FC<Props> = ({ children, limit }) => {
  const value = Number(Math.abs(children).toPrecision(5));
  const isWarn = compareValues(limit, value);
  return (
    <Tooltip title={isWarn ? `Превышен лимит. Лимит:${limit}` : undefined}>
      <div className={classNames(cn.indicatorWrap, isWarn && cn.warning)}>{value}</div>
    </Tooltip>
  );
};

export { ProblemIndicator };
