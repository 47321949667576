import { RadioChangeEvent } from "antd";
import { NumberValue } from "d3";
import { action, makeObservable, observable } from "mobx";

import { setupAccuracy } from "utils/round";

class ModeSelectorModel {
  public mode: "oilRate" | "liquidRate" | "waterCut";

  constructor() {
    makeObservable(this, {
      mode: observable,
      onChangeMode: action,
      onValueChange: action,
    });
    this.mode = "waterCut";
  }

  public onChangeMode = ({ target: { value } }: RadioChangeEvent) => {
    this.mode = value;
  };

  public onValueChange = (
    updatedKey: "oilRate" | "liquidRate" | "waterCut",
    oilRate: number | null,
    liquidRate: number | null,
    waterCut: number | null,
    rho: number
  ): { key: "oilRate" | "liquidRate" | "waterCut"; value: number | null }[] => {
    console.assert(rho > 0 && rho < 1, `плотность должна быть из диапазона (0, 1), передано ${rho}`);
    return {
      liquidRate: () => {
        const updates: { key: "oilRate" | "liquidRate" | "waterCut"; value: number | null }[] = [];
        if (this.mode === "waterCut") {
          if (!(liquidRate !== null && isFinite(liquidRate) && oilRate !== null && isFinite(oilRate))) {
            return updates;
          }
          let newOilRate = oilRate;
          if (oilRate / rho > liquidRate) {
            newOilRate = liquidRate * rho;
            updates.push({
              key: "oilRate",
              value: setupAccuracy(newOilRate, "floor"),
            });
          }
          updates.push({
            key: "waterCut",
            value:
              this.waterCutCalc(liquidRate, newOilRate, rho) !== null
                ? this.waterCutCalc(liquidRate, newOilRate, rho)
                : null,
          });
        }
        if (this.mode === "oilRate") {
          if (!(liquidRate !== null && isFinite(liquidRate) && waterCut !== null && isFinite(waterCut))) {
            return updates;
          }
          updates.push({
            key: "oilRate",
            value:
              this.oilRateCalc(liquidRate, waterCut, rho) !== null
                ? setupAccuracy(this.oilRateCalc(liquidRate, waterCut, rho)!, "floor")
                : null,
          });
        }
        return updates;
      },
      oilRate: () => {
        const updates: { key: "oilRate" | "liquidRate" | "waterCut"; value: number | null }[] = [];
        if (this.mode === "waterCut") {
          if (!(liquidRate !== null && isFinite(liquidRate) && oilRate !== null && isFinite(oilRate))) {
            return updates;
          }
          let newLiquidRate = liquidRate;
          if (oilRate / rho > liquidRate) {
            newLiquidRate = oilRate / rho;
            updates.push({
              key: "liquidRate",
              value: setupAccuracy(newLiquidRate, "ceil"),
            });
          }
          updates.push({
            key: "waterCut",
            value:
              this.waterCutCalc(newLiquidRate, oilRate, rho) !== null
                ? this.waterCutCalc(newLiquidRate, oilRate, rho)
                : null,
          });
        }
        if (this.mode === "liquidRate") {
          if (!(oilRate !== null && isFinite(oilRate) && waterCut !== null && isFinite(waterCut))) {
            return updates;
          }
          updates.push({
            key: "liquidRate",
            value:
              this.liquidRateCalc(oilRate, waterCut, rho) !== null
                ? setupAccuracy(this.liquidRateCalc(oilRate, waterCut, rho)!, "ceil")
                : null,
          });
        }
        return updates;
      },
      waterCut: () => {
        const updates: { key: "oilRate" | "liquidRate" | "waterCut"; value: number | null }[] = [];
        if (this.mode === "oilRate") {
          if (!(liquidRate !== null && isFinite(liquidRate) && waterCut !== null && isFinite(waterCut))) {
            return updates;
          }
          updates.push({
            key: "oilRate",
            value:
              this.oilRateCalc(liquidRate, waterCut, rho) !== null
                ? setupAccuracy(this.oilRateCalc(liquidRate, waterCut, rho)!, "floor")
                : null,
          });
        }
        if (this.mode === "liquidRate") {
          if (!(oilRate !== null && isFinite(oilRate) && waterCut !== null && isFinite(waterCut))) {
            return updates;
          }
          updates.push({
            key: "liquidRate",
            value:
              this.liquidRateCalc(oilRate, waterCut, rho) !== null
                ? setupAccuracy(this.liquidRateCalc(oilRate, waterCut, rho)!, "ceil")
                : null,
          });
        }
        return updates;
      },
    }[updatedKey]();
  };

  public waterCutCalc = (liquid: NumberValue, oil: NumberValue, rho: number): number | null => {
    if (+liquid === 0) {
      return null;
    }

    const value = ((+liquid - +oil / rho) / +liquid) * 100;

    return setupAccuracy(Math.min(value, 100), "round");
  };
  public liquidRateCalc = (oil: NumberValue, waterCut: NumberValue, rho: number) => {
    if (+waterCut === 100) {
      return null;
    }
    return +oil / rho / (1 - +waterCut / 100);
  };
  public oilRateCalc = (liquid: NumberValue, waterCut: NumberValue, rho: number) => {
    if (+waterCut === 100) {
      return null;
    }
    return +liquid * (1 - +waterCut / 100) * rho;
  };

  static convertLiquid(liquid: number[] | null, oil: number[] | null, rho: number) {
    let result = null;
    if (liquid && oil) {
      result = Array.from({ length: liquid.length }).map((_, i) => Math.max(liquid[i] / rho, oil[i]));
    }
    return result;
  }
}
export { ModeSelectorModel };
