import { PageFrame } from "routing/pageFrame/pageFrame";

import { Preloader } from "features/preloader/preloader";
import { Summary as SummaryFeature } from "features/techPrediction/summary/summary";

import { SUMMARY_PRELOADER } from "./preloads";

const Summary = () => (
  <Preloader hooks={SUMMARY_PRELOADER}>
    <PageFrame title="Сводка по прогнозу технологических показателей">
      <SummaryFeature />
    </PageFrame>
  </Preloader>
);

export { Summary };
