import { FC, useMemo } from "react";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { DatePicker, Empty, Tag } from "antd";
import { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Format } from "elements/format/format";
import { FullScreen } from "elements/fullScreen/fullScreen";
import { LinkButton } from "elements/linkButton/linkButton";
import { useForecast } from "models/project/fact/forecast/forecast";
import { type DRow, Events as EventsModel } from "models/project/fact/forecast/ranking/results/eventsTable";
import { useProject } from "models/project/project";
import { useRankingResults } from "pages/ranking/rankingResultsContext";

import { getRangColor } from "../ranking";

import cn from "./results.module.less";

const COLUMNS_SHARED: ColumnRaw<DRow>[] = [
  {
    key: "index",
    title: "No.пп",
    isSticky: true,
    width: 54,
    render: (_, { absoluteIndex }) => <div style={{ width: "100%", textAlign: "right" }}>{absoluteIndex ?? 0}</div>,
  },
  {
    dataKey: "wellTitle",
    title: "Скважина",
    isSticky: true,
    width: { min: 200, max: 600, competitiveness: 2 },
  },
  {
    dataKey: "mineCode",
    title: "Куст",
    width: { min: 60, max: 600, competitiveness: 1 },
  },
  {
    dataKey: "eventType",
    title: "Мероприятие",
    width: { min: 250, max: 600, competitiveness: 2 },
  },
  {
    dataKey: "crewType",
    title: "Тип бригады",
    width: { min: 120, max: 600, competitiveness: 1 },
  },
  {
    dataKey: "rank",
    title: "Ранг",
    width: 52,
    render: (rank: number | undefined) => {
      if (rank === undefined) {
        return <div />;
      }
      return (
        <Tag className={cn.rank} style={{ borderRadius: "100px" }} color={getRangColor(rank)}>
          {Math.round(rank)}
        </Tag>
      );
    },
  },
  {
    dataKey: "oilProdPeriod",
    title: "Накопл. добыча нефти за период, тыс т",
    width: { min: 180, max: 600, competitiveness: 0 },
    render: () => null,
  },
  {
    dataKey: "liquidProdPeriod",
    title: "Накопл. добыча жид-ти за период, тыс м³",
    width: { min: 190, max: 600, competitiveness: 0 },
    render: () => null,
  },
];

const COLUMNS_INCLUDED_GTMS: ColumnRaw<DRow>[] = [
  {
    dataKey: "oilProdYear",
    title: "Накопл. добыча нефти за год, тыс т",
    width: { min: 180, max: 600, competitiveness: 0 },
    render: () => null,
  },
  {
    dataKey: "liquidProdYear",
    title: "Накопл. добыча жид-ти за год, тыс м³",
    width: { min: 190, max: 600, competitiveness: 0 },
    render: () => null,
  },
  {
    dataKey: "start",
    title: "Начало",
    width: 140,
    render: (value: Dayjs) => <DatePicker value={value} format="DD.MM.YYYY" variant="borderless" disabled />,
  },
  {
    dataKey: "end",
    title: "Окончание",
    width: 140,
    render: (value: Dayjs) => <DatePicker value={value} format="DD.MM.YYYY" variant="borderless" disabled />,
  },
  {
    dataKey: "price",
    title: "Затраты на проведение, млн руб",
    width: 260,
    render: (value: number) => <Format>{value}</Format>,
    onCell: () => ({ style: { justifyContent: "end" } }),
  },
];

const Events: FC<{ gtmsList: "included" | "notIncluded" }> = observer(({ gtmsList }) => {
  const project = useProject();
  const forecast = useForecast()!;
  const results = useRankingResults();
  const store = useMemo(() => new EventsModel(forecast, gtmsList), [forecast, gtmsList]);
  const model = useMemo(() => {
    const columns = gtmsList === "notIncluded" ? COLUMNS_SHARED : [...COLUMNS_SHARED, ...COLUMNS_INCLUDED_GTMS];
    return new TableModel(columns, store, undefined, {
      headerHeight: 39,
      rowHeight: 33,
      borderColor: "#f0f0f0",
    });
  }, [store, gtmsList]);

  if (store.isLoading) {
    return (
      <FullScreen>
        <Empty description={<>Необходимо провести планирование</>}>
          <LinkButton to={`/${project?.id}/${forecast.id}/settings`}>Настройки планирования</LinkButton>
        </Empty>
      </FullScreen>
    );
  }
  return (
    <TableContextProvider value={model}>
      <PageFrameTitlePortal model={results} submitting={results.isSaving} onSave={results.save} />
      <Widget headerClassName={cn.tableHeader} />
    </TableContextProvider>
  );
});

export { Events };
