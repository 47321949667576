import { Outlet, useParams } from "react-router-dom";
import { useMainRouterParams } from "routing/authorizedRouter";

import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { useForecast } from "models/project/fact/forecast/forecast";

const ForecastOutline = () => {
  const forecast = useForecast();
  const params = useParams();
  const paramsParsed = useMainRouterParams();
  if (forecast === null) {
    if (paramsParsed.scenario === undefined) {
      return <FullScreenEmpty>Страница {params.scenario} не найдена</FullScreenEmpty>;
    }
    return <FullScreenEmpty>Не удалось найти сценарий с ключом {params.scenario}</FullScreenEmpty>;
  }
  return <Outlet />;
};

export { ForecastOutline };
