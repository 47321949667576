import { observer } from "mobx-react-lite";

import { CsvSaver } from "elements/csvSaver/csvSaver";
import { Table, TableContextProvider } from "elements/table/table";
import type { ResultsTable } from "models/project/fact/forecast/forecastResult";

import { useMetricsColumns } from "../useMetrics/useMetricsColumns";

import cn from "./results.module.less";

const Results = observer(({ model, hideSum = false, exportFileName }: { exportFileName: string; model: ResultsTable; hideSum?: boolean }) => {
  const columns = useMetricsColumns(model, hideSum, false);

  return (
    <div className={cn.generic}>
      <TableContextProvider value={{ model, columns }}>
        <CsvSaver filename={exportFileName} exportArray={() => model.export()}>
          <div className={cn.tableWrap}>
            <Table className={cn.table} />
          </div>
        </CsvSaver>
      </TableContextProvider>
    </div>
  );
});

export { Results };
