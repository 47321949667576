import { useResultModel } from "routing/outlines/result/resultPage";

import { Results } from "./results";

const InvestResults = () => {
  const result = useResultModel();

  return <Results model={result.invest} exportFileName="Инвестиционная деятельность" />;
};

export { InvestResults };
