import { START_YEAR } from "services/field";
import { Metric } from "services/finance/utils";
import { expandArr } from "utils/expandArray";
import { Range } from "utils/range";

export type Schema = Omit<Metric, "key" | "parent"> & {
  id: number;
  parent_id: null | number;
  code_title: string;
};

export type ParamsResponse<T extends string> = {
  [key in T]: {
    schema: Schema[];
  };
};

const formatSchema = (schema: Schema[], range?: Range): Metric[] =>
  schema.map((el) => ({
    key: el.id,
    title: el.title,
    parent: el.parent_id,
    unit: el.unit,
    code_title: el.code_title,
    values: el.values && range ? expandArr(el.values, START_YEAR, range) : el.values,
  }));

export { formatSchema };
