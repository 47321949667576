import { FC } from "react";
import { Tooltip, Typography } from "antd";

import { Icon } from "elements/icon/icon";

import { ReactComponent as FlagIcon } from "../icons/flag.svg";
import { ReactComponent as InfoIcon } from "../icons/info.svg";
import { ReactComponent as RootIcon } from "../icons/root.svg";
import { TechModalState } from "../techForecast";
import { WellTypes } from "../techForecastMock";

import { WellDataForTooltip } from "./wellsGroup";
import { WellTooltip } from "./wellTooltip";

import cn from "./wellItem.module.less";

type WellItemProps = {
  isSelected: boolean;
  onClick: () => void;
  well: WellDataForTooltip;
  type: WellTypes;
  isExistChart: boolean;
  activeRef: React.MutableRefObject<null>;
  forecastState: TechModalState;
  isSelectedGroup: boolean;
};

const WellItem: FC<WellItemProps> = ({ well, type, isExistChart, onClick, isSelected, activeRef, forecastState, isSelectedGroup }) => {
  return (
    <div
      className={
        (isSelected && forecastState === "results") || (isSelectedGroup && forecastState === "settings") ? cn["selected-container"] : cn.container
      }
      onClick={onClick}
      ref={isSelected ? activeRef : undefined}
    >
      <div className={cn["title-container"]}>
        <div className={cn["icon-container"]}>
          <Icon content={<FlagIcon />} viewBox="0 0 12 14" className={cn["flag-icon"]} />
        </div>
        <Typography.Text style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontSize: "12px" }}>
          {`${well.title}/${type === "gtm" ? `${well.typeName ?? "Базовая добыча"}` : well.construction}`}
        </Typography.Text>
      </div>
      <div className={cn["info-container"]}>
        {isExistChart ? (
          <div className={cn["icon-container"]}>
            <Icon content={<RootIcon />} viewBox="0 0 14 12" className={cn["root-icon"]} />
          </div>
        ) : null}
        {type === "gtm" ? null : (
          <Tooltip overlayStyle={{ maxWidth: "350px" }} color="#FFF" title={<WellTooltip well={well} />}>
            <div className={cn["icon-container"]}>
              <Icon content={<InfoIcon />} viewBox="0 0 16 16" className={cn["info-icon"]} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export { WellItem };
