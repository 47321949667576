import { useInfrastructureDrainSourcesTableModel } from "../infrastructureDrainSources/useInfrastructureDrainSourcesTableModel";
import { useInfrastructureMinesTableModel } from "../infrastructureMinesTable/useInfrastructureMinesTableModel";
import { useInfrastructurePipesTableModel } from "../infrastructurePipesTable/useInfrastructurePipesTableModel";
import { useInfrastructureStationsTableModel } from "../infrastructureStationsTable/useInfrastructureStationsTableModel";

const useInfrastructureTableModel = (mode: string) => {
  const { model: minesModel, store: minesStore } = useInfrastructureMinesTableModel();
  const { model: stationsModel, store: stationsStore } = useInfrastructureStationsTableModel();
  const { model: oilModel, store: oilStore } = useInfrastructurePipesTableModel("prod");
  const { model: waterModel, store: waterStore } = useInfrastructurePipesTableModel("inj");
  const { model: drainsModel, store: drainsStore } = useInfrastructureDrainSourcesTableModel("drain");
  const { model: sourcesModel, store: sourcesStore } = useInfrastructureDrainSourcesTableModel("source");

  if (mode === "mines") {
    return { model: minesModel, store: minesStore };
  }
  if (mode === "stations") {
    return { model: stationsModel, store: stationsStore };
  }
  if (mode === "drain") {
    return { model: drainsModel, store: drainsStore };
  }
  if (mode === "source") {
    return { model: sourcesModel, store: sourcesStore };
  }
  if (mode === "prod") {
    return { model: oilModel, store: oilStore };
  }

  return { model: waterModel, store: waterStore };
};

export { useInfrastructureTableModel };
