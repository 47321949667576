const convertPressureFn = (): {
  barToAtmosphere: (bar?: number | null) => number | undefined;
  atmosphereToBar: (atm?: number | null) => number | undefined;
} => {
  const BAR_TO_ATM = 0.986923; // 1 бар = 0.986923 атмосфер
  const ATM_TO_BAR = 1.01325; // 1 атм = 1.01325 бар

  const barToAtmosphere = (bar?: number | null) => {
    if (!bar) return;
    if (bar === 0) return 0;
    return Number((bar * BAR_TO_ATM).toFixed(4));
  };
  const atmosphereToBar = (atm?: number | null) => {
    if (!atm) return;
    if (atm === 0) return 0;
    return Number((atm * ATM_TO_BAR).toFixed(4));
  };

  return { barToAtmosphere, atmosphereToBar };
};

const convertPressure = convertPressureFn();

export { convertPressure };
