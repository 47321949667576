import { convertPressure } from "utils/convertePressure";
import { reqCamel } from "utils/request";

import { reqLogged } from "../logger";

import { PipeBoundaryCondition, PipeSystem } from "./types";

const { barToAtmosphere } = convertPressure;

async function getPipeSystem(scenarioId: number): Promise<PipeSystem | null> {
  try {
    const response = await reqCamel.get<PipeSystem>(`pipe/${scenarioId}`);
    if (typeof response === "object" && response !== null && "detail" in response) {
      throw Error(response.detail as string);
    }
    return response;
  } catch (error) {
    return null;
  }
}

type BoundaryCondition = { boundaryCondition: { pressure: number } } & Omit<PipeBoundaryCondition, "boundaryCondition">;

async function getPipeBoundaryCondition(scenarioId: number): Promise<BoundaryCondition[]> {
  const response = await reqCamel.get<BoundaryCondition[]>(`pipe_boundary_condition/${scenarioId}`);
  return response.map((el) => ({
    ...el,
    boundaryCondition: { pressure: barToAtmosphere(el.boundaryCondition.pressure) ?? 0 },
  }));
}

async function savePipeSystem(scenarioId: number, pipeSystem: PipeSystem): Promise<void> {
  await reqCamel.post("pipe/", { ...pipeSystem, scenarioId }).catch(() => {});
}

async function updatePipeBoundaryCondition(scenarioId: number, params: PipeBoundaryCondition[]) {
  await reqLogged.post(`pipe_boundary_condition/${scenarioId}`, params);
}

async function deletePipeSystem(scenarioId: number): Promise<void> {
  await reqCamel.delete(`pipe/${scenarioId}`).catch(() => {});
}

export { deletePipeSystem, getPipeBoundaryCondition, getPipeSystem, savePipeSystem, updatePipeBoundaryCondition };
