import { LineDataModel } from "@okopok/axes_context";
import dayjs from "dayjs";
import { computed, makeObservable } from "mobx";

import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { SummaryModel } from "models/summary";
import { colorCarousel } from "services/colorCarousel";

class ExpensesChartModel {
  constructor(public summaryModel: SummaryModel) {
    makeObservable(this, {
      tooltipManager: computed,
      lines: computed,
      capexStickerValue: computed,
    });
  }

  get tooltipManager() {
    return new TooltipDataManager(this.lines);
  }

  get capexStickerValue() {
    return this.summaryModel.CAPEX.data
      .filter(
        (value) =>
          value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
          value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
      )
      .reduce((prev, curr) => prev + curr.y, 0);
  }

  get opexStickerValue() {
    return this.summaryModel.OPEX.data
      .filter(
        (value) =>
          value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
          value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
      )
      .reduce((prev, curr) => prev + curr.y, 0);
  }

  get lines() {
    return [
      new LineDataModel({
        y: this.summaryModel.CAPEX.data
          .filter(
            (value) =>
              value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
              value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
          )
          .map((value) => value.y),
        x: this.summaryModel.CAPEX.data
          .filter(
            (value) =>
              value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
              value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
          )
          .map((value) => value.x),
        axisKey: "capex",
        key: "capex",
        color: colorCarousel(6),
        title: `CAPEX, млн ₽`,
      }),
      new LineDataModel({
        y: this.summaryModel.OPEX.data
          .filter(
            (value) =>
              value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
              value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
          )
          .map((value) => value.y),
        x: this.summaryModel.OPEX.data
          .filter(
            (value) =>
              value.x <= dayjs(`${this.summaryModel.currentRange.to}`) &&
              value.x >= dayjs(`${this.summaryModel.currentRange.from}`)
          )
          .map((value) => value.x),
        axisKey: "opex",
        key: "opex",
        color: colorCarousel(7),
        title: `OPEX, млн ₽`,
      }),
    ];
  }
}

export { ExpensesChartModel };
