import { Select as AntdSelect, type SelectProps } from "antd";

type CustomOptionsSelectProps = SelectProps & { optionclass?: string };

const CustomOptionsSelect = (props: CustomOptionsSelectProps) => {
  const customOptions = props.options?.map((option) => {
    const cloneOption = JSON.parse(JSON.stringify(option));
    cloneOption.label = <div className={props.optionclass}>{cloneOption.label}</div>;
    return cloneOption;
  });
  return <AntdSelect {...props} options={customOptions} />;
};

export { CustomOptionsSelect };
