import { useRef, useState } from "react";

import { type ModelContentComponentType, useModal } from "elements/modal/modal";
import { EventNode } from "models/project/fact/forecast/techPrediction/techPrediction";
import { WellCharts } from "services/techParameters";

import { TechnologyResults } from "./results/results";
import { WellsList } from "./wellsList/wellsList";
import { convertWellNodes, getWellNodeParts } from "./utils";

import cn from "./technologyResults.module.less";

type ModalProps = {
  forecast: WellCharts[];
  wellNodes: EventNode[];
};

const ModalContent: ModelContentComponentType<WellCharts[] | null, ModalProps | undefined> = ({ dataRef, reject, setOnAccept }) => {
  const [currentWell, setCurrentWell] = useState(0);
  const [techForecast, setTechForecast] = useState<WellCharts[]>(dataRef.current ? dataRef.current.forecast : []);
  if (dataRef.current === undefined) {
    reject();
    return null;
  }

  const wellNodeParts = getWellNodeParts(dataRef.current.wellNodes);
  const wellsIdArray = wellNodeParts.map(({ well: { id }, gtmId }) => ({ wellId: id, gtmId: gtmId ?? null }));
  const onCurrentChange = (wellId: number, gtmId?: number | null) => {
    const wellNumber = wellsIdArray.findIndex(({ wellId: wId, gtmId: gId }) => (gtmId ?? null) === gId && wellId === wId)!;
    setCurrentWell(wellNumber);
  };

  const wellNodesMap = convertWellNodes(wellNodeParts);

  const onFinish = async () => {
    return {
      result: true,
      data: techForecast,
    };
  };

  setOnAccept(onFinish);

  return (
    <div className={cn.container}>
      <div className={cn["wells-list"]}>
        <WellsList
          chosenType="base"
          setChosenType={(t) => ""}
          wells={wellNodesMap}
          current={wellsIdArray[currentWell]}
          onCurrentChange={onCurrentChange}
          result={dataRef.current.forecast}
          forecastState="results"
        />
      </div>
      <TechnologyResults
        className={cn["chart-container"]}
        techForecast={techForecast}
        setTechForecast={setTechForecast}
        current={currentWell}
        onCurrentChange={setCurrentWell}
      />
    </div>
  );
};

const useCreateTechnologyResultsModal = (): ((wellNodes: ModalProps) => Promise<WellCharts[] | null>) => {
  const showModal = useModal<WellCharts[] | null, ModalProps | undefined>();
  const wellNodesRef = useRef<ModalProps>();

  return async (wellNodes) => {
    wellNodesRef.current = wellNodes;

    return showModal(ModalContent, wellNodesRef, "Результаты расчета технологических параметров", "Применить", undefined, "fit-content", undefined, {
      padding: 0,
      fontSize: "12px",
    })
      .then((data) => data)
      .catch(() => null);
  };
};

export { useCreateTechnologyResultsModal };
