import { makeObservable, observable, runInAction, transaction } from "mobx";

import type { WellParameters, WellTechForecast } from "features/forecast/technologyForecastModal/types";
import {
  deleteTechParameters,
  getTechParameters,
  ParamsByStratum,
  updateForecast,
  updateTechParameters,
  WellCharts,
} from "services/techParameters";

import { Forecast } from "../forecast";

const CALCULATION_METHODS = {
  wellsAnalog: "Скважины-аналоги",
  dropRatio: "Коэффициент падения дебита",
  displacementCharacteristics: "Характеристики вытеснения",
  dropCurves: "Кривые падения",
} as const;

class TechParameters {
  public readonly wellsParameters = observable.map<number, ParamsByStratum>();
  public readonly interventionsParameters = observable.map<number, WellParameters>();

  private isLoadingTechParameters: boolean = true;

  constructor(private forecast: Forecast) {
    makeObservable<TechParameters, "isLoadingTechParameters">(this, {
      isLoadingTechParameters: observable,
    });

    this.isLoadingTechParameters = true;

    getTechParameters(forecast.id).then((data) => {
      transaction(() => {
        data.forEach(({ data, wellId, gtmId }) => {
          if (gtmId !== null) {
            this.interventionsParameters.set(gtmId, data);
          } else {
            this.wellsParameters.set(wellId, data);
          }
        });
        this.isLoadingTechParameters = false;
      });
    });
  }

  static calcMethods(params: WellParameters): { oilDebitMethod: string; liquidDebitMethod: string } {
    let oilMethod = CALCULATION_METHODS[params.oilDropRate.calculationMethod];
    let liquidMethod = CALCULATION_METHODS[params.liquidDropRate.calculationMethod];
    if (oilMethod === CALCULATION_METHODS["dropRatio"]) {
      oilMethod += " нефти";
    }
    if (liquidMethod === CALCULATION_METHODS["dropRatio"]) {
      liquidMethod += " жидкости";
    }

    return {
      oilDebitMethod: oilMethod,
      liquidDebitMethod: liquidMethod,
    };
  }

  public get isLoading(): boolean {
    return this.isLoadingTechParameters;
  }

  public async delete(wellIdsInfo: { wellId: number; gtmId?: number | null; stratumId: number | null }[]) {
    const wellsForDelete = wellIdsInfo.map(({ wellId, gtmId }) => ({
      wellId,
      gtmId: gtmId ?? null,
      scenarioId: this.forecast.id,
    }));
    const emptyForecast: WellCharts[] = wellIdsInfo.map(({ wellId, gtmId, stratumId }) => ({
      wellId,
      gtmId: gtmId ?? null,
      scenarioId: this.forecast.id,
      stratumId: stratumId,
      forecast: {
        month: [],
        year: [],
        oilProd: [],
        oilRate: [],
        liquidProd: [],
        liquidRate: [],
        prodDays: [],
      },
      fact: null,
      forecastInterval: null,
      oilRelativeDensity: 0.75,
      waterRelativeDensity: 1,
      appliedStopCriterion: null,
    }));
    await this.deleteTechParameters(wellsForDelete);
    await this.updateForecast(emptyForecast);
  }

  public async deleteTechParameters(wellsInfo: { wellId: number; gtmId: number | null; scenarioId: number }[]) {
    await deleteTechParameters(wellsInfo);
    wellsInfo.forEach(({ wellId, gtmId }) => {
      if (gtmId !== null && gtmId !== undefined) {
        this.interventionsParameters.delete(gtmId);
      } else {
        this.wellsParameters.delete(wellId);
      }
    });
  }

  public async updateTechParameters(techParameters: WellParameters[]) {
    const wellParamsMap = new Map<number, ParamsByStratum>();
    const gtmParams: { wellId: number; gtmId: number; data: WellParameters }[] = [];
    for (const param of techParameters) {
      if (typeof param.gtmId === "number") {
        gtmParams.push({
          wellId: param.wellId,
          gtmId: param.gtmId,
          data: param,
        });
        continue;
      }
      if (wellParamsMap.has(param.wellId)) {
        wellParamsMap.get(param.wellId)![param.stratumId] = param;
      } else {
        wellParamsMap.set(param.wellId, { [param.stratumId]: param });
      }
    }
    const wellParams: { wellId: number; gtmId: null; data: ParamsByStratum }[] = [];
    wellParamsMap.forEach((params, wellId) => {
      wellParams.push({
        wellId,
        gtmId: null,
        data: {
          ...(this.wellsParameters.get(wellId) ?? {}),
          ...params,
        },
      });
    });

    const newTechParameters = await updateTechParameters([...gtmParams, ...wellParams], this.forecast.id);
    runInAction(() => {
      newTechParameters.forEach(({ data, wellId, gtmId }) => {
        if (gtmId !== null) {
          this.interventionsParameters.set(gtmId, data);
        } else {
          this.wellsParameters.set(wellId, data);
        }
      });
    });
  }

  public async updateForecast(forecast: WellCharts[]) {
    await updateForecast(forecast, this.forecast.id);
    await this.forecast.production.update(forecast.map(({ wellId, gtmId }) => ({ wellId, gtmId: gtmId ?? undefined })));
  }

  public async update(params: WellTechForecast[]) {
    await this.updateTechParameters(params.map(({ techParameters }) => techParameters));

    const forecast = params.filter(({ forecast }) => forecast !== undefined).map(({ forecast }) => forecast!);
    await this.updateForecast(forecast);
  }
}

export { TechParameters };
