import { Delaunay } from "d3";
import { makeAutoObservable } from "mobx";

import { Forecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import type { WellItem } from "services/back/injectionPrediction/voronoi";
import { viewport } from "utils/pointsViewport";

type WellPadPoint = {
  id: number;
  x: number;
  y: number;
  balance: number;
  polygon: Delaunay.Polygon;
  well: Well | null;
};

type TooltipInfoProps = {
  header: string;
  value: number;
};

type TooltipInfoType = TooltipInfoProps & { left: number; top: number };

class GridMapDataManager {
  wells: WellPadPoint[] | undefined;

  constructor(wells: WellItem[], fc: Forecast) {
    makeAutoObservable(this);
    this.wells = wells.map(({ x, y, points, id }) => {
      return {
        x,
        y,
        id,
        polygon: points.map(({ x, y }) => [x, y]),
        balance: 0,
        well: fc.wells.at(id) ?? null,
      };
    });
  }

  get viewPort(): string {
    return viewport(this.wells?.filter(({ x, y }) => x + y !== 0) ?? []).join(" ");
  }
}

export { GridMapDataManager };
export type { TooltipInfoProps, TooltipInfoType, WellPadPoint };
