import { action, computed, makeObservable } from "mobx";

import { LoadableStore } from "models/loadableStore/loadableStore";
import { ecySource } from "services/finance/ecy";
import { checkSystemECY, ECY_BASE_ID } from "services/finance/ecyPatcher";
import { Range } from "utils/range";

import { ECY } from "../ecy";
import { Fact } from "../fact";

import cn from "./ecyStore.module.less";

class ECYStore extends LoadableStore<ECY> {
  constructor(private years: Range, private state: Fact, private origin?: ECYStore) {
    const [getData, setData] = ecySource({ fact: state.projectId });

    super(
      async () => {
        const data = await getData(years);
        return data.map((raw) => new ECY(raw.id, raw.usc, raw.title, raw.discount, years, state));
      },
      async (data: ECY[]) => {
        setData(
          data.map((ecy) => ({
            id: ecy.id,
            title: ecy.title,
            usc: ecy.asRaw,
            discount: ecy.discount,
          }))
        );
        return data;
      }
    );

    makeObservable(this, {
      createNewECY: action,
      deleteEcy: action,
      update: action,

      isUpdated: computed,
      isCompleated: computed,
      isValid: computed,
      clone: computed,
      listSystemECY: computed,
      listECY: computed,
      selectItems: computed,
    });
  }

  get selectItems(): {
    label: JSX.Element;
    title: string;
    options: {
      label: JSX.Element;
      value: number;
    }[];
  }[] {
    return [
      {
        label: <span className={cn["select-group-title"]}>Системные</span>,
        title: "system",
        options: this.listSystemECY.map((ecy) => {
          return {
            label: <span className={cn["select-system-title"]}>{ecy.title}</span>,
            value: ecy.id,
          };
        }),
      },
      ...(this.listECY.length > 0
        ? [
            {
              label: <span className={cn["select-group-title"]}>Пользовательские</span>,
              title: "engineer",
              options: this.listECY.map((ecy) => {
                return {
                  label: <span>{ecy.title}</span>,
                  value: ecy.id,
                };
              }),
            },
          ]
        : []),
    ];
  }

  get listSystemECY() {
    return Array.from(this.values ?? []).filter((ecy) => checkSystemECY(ecy));
  }

  get listECY() {
    return Array.from(this.values ?? []).filter((ecy) => !checkSystemECY(ecy));
  }

  public isSystemECY(ecy: ECY): boolean {
    return checkSystemECY(ecy)!; // shouldnt return undefined when ecy is not undefined;
  }

  // check if not submited ecy models are presented
  public get isUpdated() {
    if (!this.origin) {
      return false;
    }
    if (this.isLoading || this.origin.isLoading) {
      return false;
    }
    if (this.length !== this.origin.length) {
      return true;
    }
    const discounts = new Map(Array.from(this.values!, (o) => [o.id, o.discount]));
    const discountsOrigin = new Map(Array.from(this.origin.values!, (o) => [o.id, o.discount]));

    if (![...discounts.entries()].every(([id, disc]) => discountsOrigin.get(id) === disc)) {
      return true;
    }
    return false;
  }

  public get isCompleated() {
    return true;
  }

  public get isValid() {
    return true;
  }

  public async update(obj: ECYStore) {
    if (this.isLoading || obj.isLoading) {
      console.warn("Попытка отправить изменения ЕСУ до загрузки");
      return undefined;
    }
    return this.reset(Array.from(obj.values!));
  }

  public createNewECY(options: { title?: string | undefined | null; baseId?: number | undefined | null }) {
    const { title = null } = options ? options : {};

    const baseId = ECY_BASE_ID;

    const baseECY = this.at(baseId);
    if (!baseECY) {
      console.error("Не возможно создать новый ЕСУ, по какойто причине базовый ЕСУ отсутсвует");
      return null;
    }
    const newEcy = baseECY.clone();
    if (title != null) {
      newEcy.title = title;
    }

    this.add(newEcy);
    // this.sendChanges();
    return newEcy.id;
  }

  public deleteEcy(ecyId: number) {
    if (checkSystemECY(ecyId)) {
      console.error("Попытка удалить системные ЕСУ");
      return false;
    }
    const result = this.delete(ecyId);
    // this.sendChanges();
    return result;
  }

  public get clone(): ECYStore {
    if (this.isLoading) {
      console.error("Попытка клонировать изменения ЕСУ до загрузки");
      // return undefined;
    }

    const instance = new ECYStore(this.years, this.state, this);

    // Тут вообще интересная чтука получается при клонированииии LoadableStore, получаетсся так что идет запрос еще один на бекенд, и это скорее не копия текущего, а "более актуальная копия"
    // instance.reset(Array.from(this.values!));
    return instance;
  }
}
export { ECYStore };
