import { FC, useEffect, useMemo } from "react";
import { useMapContext } from "@okopok/axes_context";
import { observer } from "mobx-react";

import { useInfrastructureMapContext } from "../InfrastructureMapManager/InfrastructureMapManager";
import { calculateSnapPoint } from "../utils";

import cn from "./pipes.module.less";

function calculateRadius(zoom: number) {
  const a = 10;
  const b = -0.6;
  return a * Math.pow(zoom, b);
}

const Line: FC = observer(() => {
  const { scale, zoom } = useMapContext();
  const manager = useInfrastructureMapContext();

  const radius = useMemo(() => calculateRadius(zoom), [zoom]);
  const strokeWidth = (zoom >= 4 ? 1 : 4) / Math.min(zoom, 1);
  const [from, to] = [manager.newPipe?.start!, manager.lineEnd];

  useEffect(() => {
    const position = manager.zoomedCursorPosition ?? { x: 0, y: 0 };
    const snapNode = calculateSnapPoint(manager.nodes, position, scale, zoom);
    manager.setLineEnd(snapNode ?? position);
  }, [manager, manager.zoomedCursorPosition, scale, zoom]);

  if (!(from && to)) {
    return <></>;
  }
  return (
    <>
      <circle cx={scale.x(to.x)} cy={scale.y(to.y)} r={radius} className={cn.circleNewLine} />
      <line x1={scale.x(from.x)} y1={scale.y(from.y)} x2={scale.x(to.x)} y2={scale.y(to.y)} className={cn.newLine} strokeWidth={strokeWidth} />
    </>
  );
});

export default Line;
