import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import dayjs from "dayjs";
import { makeAutoObservable, reaction } from "mobx";

import { User } from "models/user";
import { BackendStorageMock } from "services/back/backendStorage";

import { global } from "./global";

type LogNote = {
  date: string;
  user: User;
  title: string;
};

const getDayjs = (stringDate: string) => {
  let date = dayjs(stringDate);
  if (!date.isValid()) {
    date = dayjs(stringDate, "DD/MM/YYYY HH:mm");
  }
  return date;
};

class Logger {
  private logger?: BackendStorageMock<void, LogNote[]>;
  private logs: LogNote[] = [];
  private globalLogs: Record<number, LogNote[]> = {};
  private projectId?: number;
  public isLoadingLog = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.projectId,
      async (newProjectId) => {
        this.logger = new BackendStorageMock<void, LogNote[]>("log", newProjectId!, undefined, false);
        if (this.globalLogs[newProjectId!]) {
          this.logs = this.globalLogs[newProjectId!];
        } else {
          this.logs = (await this.logger.getItem()) ?? [];
          this.isLoadingLog = false;
        }
      }
    );
  }

  init = (projectIds: number[]) => {
    for (const projectId of projectIds) {
      new BackendStorageMock<void, LogNote[]>("log", projectId, undefined, false).getItem().then((logs) => {
        this.globalLogs[projectId] = logs ?? [];
      });
    }
  };

  addNote = async (key: string) => {
    if (this.logger && global.user) {
      const logs = (await this.logger.getItem()) ?? [];
      const newLogs = await this.logger.setItem([
        ...logs,
        { date: new Date().toString(), title: key, user: global.user },
      ]);
      this.logs = newLogs ?? [];
    } else {
      console.assert("Логирование может быть произведено только в рамках проекта");
    }
  };

  setProjectId = (newProjectId: number) => {
    this.projectId = newProjectId;
    if (!this.globalLogs[newProjectId]) {
      this.isLoadingLog = true;
    }
  };

  lastNoteByKey = (key: string) => this.logs.find(({ title }) => title === key);

  notesByKey = (key: string) => this.logs.filter(({ title }) => title === key);

  get currentProjectLog(): LogNote[] {
    return this.logs;
  }

  get isLoading() {
    return !!this.projectId && this.isLoadingLog;
  }

  lastNodeByProject = (id: number) => {
    if (!this.globalLogs[id]) {
      return undefined;
    } else if (this.globalLogs[id].length === 0) {
      return null;
    } else {
      const lastNode = this.globalLogs[id][this.globalLogs[id].length - 1];
      return {
        ...lastNode,
        date: getDayjs(lastNode.date).format(),
      };
    }
  };
}

class LoggerStore extends TableNode<LogNote, LogNode> {
  constructor(private logs: LogNote[]) {
    super();
    this.childrenStore = new ChildrenStoreArray(
      this,
      logs.map((log) => new LogNode(this, log))
    );
  }

  public get getLogs() {
    return this.logs;
  }
}

class LogNode extends TableNode<LogNote> {
  public asDRow(): LogNote {
    return {
      title: this.logNote.title,
      user: this.logNote.user,
      date: this.logNote.date,
    };
  }
  constructor(parentNode: LoggerStore, private logNote: LogNote) {
    super(parentNode);
  }
}

export { Logger, LoggerStore };
export type { LogNote };
