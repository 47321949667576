import { useMemo } from "react";
import { ColumnRaw, TableModel } from "@okopok/components/Table";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import dayjs from "dayjs";

import { Format } from "elements/format/format";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";

import { DRowMines, WellPadsModel } from "./tableManager/mines";

import cn from "../infrastructureTable.module.less";

const useColumns = (): ColumnRaw<DRowMines>[] => {
  return useMemo(
    (): ColumnRaw<DRowMines>[] => [
      {
        title: "№",
        width: { min: 40, max: 80, competitiveness: 1 },
        key: "index",
        isSticky: true,
        onCell: () => ({ className: cn.tableCell }),
        render: (_, pipe) => pipe.absoluteIndex,
      },
      {
        key: "expand",
        title: null,
        isSticky: true,
        width: { min: 30, max: 30, competitiveness: 1 },
        render: (_, { expand }) => (expand?.status !== undefined ? <ExpandButton expand={expand} /> : <></>),
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Куст",
        width: { min: 250, max: 300, competitiveness: 1 },
        isSticky: true,
        dataKey: "title",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Дата",
        width: { min: 250, max: 300, competitiveness: 1 },
        isSticky: true,
        dataKey: "date",
        onCell: () => ({ className: cn.tableCell }),
        render: (value, { expand }) => {
          if (expand?.status !== undefined) {
            return <></>;
          }
          return dayjs(value).format("MM.YYYY");
        },
      },
      {
        title: "Дебит жидкости, т/сут",
        width: { min: 250, max: 300, competitiveness: 1 },
        dataKey: "fluidRateT",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, { expand }) => {
          if (expand?.status !== undefined) {
            return <></>;
          }
          return <Format>{value}</Format>;
        },
      },
      {
        title: "Дебит нефти, т/сут",
        width: { min: 250, max: 300, competitiveness: 1 },
        dataKey: "oilRateT",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, { expand }) => {
          if (expand?.status !== undefined) {
            return <></>;
          }
          return <Format>{value}</Format>;
        },
      },
      {
        title: "Дебит воды, т/сут",
        width: { min: 250, max: 300, competitiveness: 1 },
        dataKey: "waterRateT",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, { expand }) => {
          if (expand?.status !== undefined) {
            return <></>;
          }
          return <Format>{value}</Format>;
        },
      },
      {
        title: "Приемистость, т/сут",
        width: { min: 250, max: 300, competitiveness: 1 },
        dataKey: "injRateT",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, { expand }) => {
          if (expand?.status !== undefined) {
            return <></>;
          }
          return <Format>{value}</Format>;
        },
      },
    ],
    []
  );
};

const useMinesTableModel = () => {
  const infrastructure = useInfrastructure()!;
  const { isCalculation } = infrastructure.calculateStore;

  const columns = useColumns(); // eslint-disable-next-line react-hooks/exhaustive-deps
  const store = useMemo(() => new WellPadsModel(infrastructure), [infrastructure, isCalculation]);

  const model = useMemo(
    () =>
      new TableModel(columns, store, {
        onRow: ({ indexPath, expand }) => ({
          className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? `${cn.tableRowPrimary}` : cn.tableRowPlain,
        }),
      }),
    [columns, store]
  );

  return { model, store };
};

export { useMinesTableModel };
