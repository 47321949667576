import { FC, useEffect, useRef, useState } from "react";
import { Input as InputAntd, type InputProps as AntdInputProps, Tooltip } from "antd";

import { formatNumber, NUMBER_FORMAT } from "elements/format/format";

type ValidationInputProps = AntdInputProps & {
  value?: number | null;
  onSave: (value: number | null) => void;
  integer?: boolean;
  required?: boolean;
  min: number;
  max: number;
  onSuccessValidation?: () => void;
  onFailedValidation?: () => void;
  accuracy?: number;
  maximumFractionDigits?: number;
  forceValueUpdate?: boolean;
  generalInput?: boolean;
};

type ValidationProps = Pick<ValidationInputProps, "min" | "max" | "accuracy" | "maximumFractionDigits" | "integer" | "required">;

const prepareValue = (value?: number | null, accuracy?: number, maximumFractionDigits?: number): string => {
  if (!value) {
    return "";
  }
  let res = formatNumber(value);
  if (accuracy !== undefined) {
    res = formatNumber(Math.round(Math.pow(10, accuracy) * value) / Math.pow(10, accuracy));
  }
  if (maximumFractionDigits !== undefined) {
    NUMBER_FORMAT[`real_${maximumFractionDigits}`] = new Intl.NumberFormat("ru-RU", { maximumFractionDigits });
    res = formatNumber(value, `real_${maximumFractionDigits}`);
  }
  return res.replace(",", ".");
};

const ValidationInput: FC<ValidationInputProps> = ({
  className,
  value,
  placeholder,
  required,
  onSave,
  integer,
  min,
  max,
  accuracy,
  maximumFractionDigits,
  onFailedValidation,
  onSuccessValidation,
  disabled,
  forceValueUpdate,
  generalInput,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState<string>(value === 0 ? "0" : prepareValue(value, accuracy, maximumFractionDigits));
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipText, setTooltipText] = useState<string>("");
  const [inputFocus, setInputFocus] = useState(false);

  const prevLimits = useRef({ min, max });

  useEffect(() => {
    if (forceValueUpdate) {
      if (value === undefined) {
        setCurrentValue("");
      } else if (value && currentValue !== value?.toString()) {
        validateValue(value.toString(), false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceValueUpdate, value]);

  useEffect(() => {
    if (prevLimits.current.min !== min || prevLimits.current.max !== max) {
      validateValue(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max]);

  const onValidation = (tooltip: string) => {
    if (tooltip === "" && onSuccessValidation) {
      onSuccessValidation();
    } else if (onFailedValidation) {
      onFailedValidation();
    }
    setTooltipText(tooltip);
  };

  const validateValue = (value: string, needSave = true) => {
    setCurrentValue(value);
    if (value === "") {
      if (required && !generalInput) {
        onValidation("Это поле обязательно для ввода");
      } else {
        onValidation("");
      }
      if (needSave && !generalInput) {
        onSave(null);
      }
      return;
    }
    const numberValue = Number(value.replace(",", "."));

    if (isNaN(numberValue)) {
      onValidation("Неверный формат вводимых данных");
      if (needSave && !generalInput) {
        onSave(null);
      }
    } else if (numberValue > max || numberValue < min) {
      onValidation(`Значение должно быть в пределах от ${min} до ${max}`);
      if (needSave && !generalInput) {
        onSave(null);
      }
    } else if (!Number.isInteger(numberValue) && integer) {
      onValidation("Вводимое значение должно быть целым");
      if (needSave && !generalInput) {
        onSave(null);
      }
    } else {
      onValidation("");
      if (needSave) {
        onSave(numberValue);
      }
    }
  };

  return (
    <Tooltip title={tooltipText} trigger={inputFocus ? "focus" : "hover"} open={(inputFocus || isHovered) && tooltipText !== ""}>
      <InputAntd
        {...props}
        placeholder={placeholder}
        status={tooltipText !== "" && !disabled ? "error" : ""}
        disabled={disabled}
        className={className}
        value={currentValue}
        onChange={(e) => {
          validateValue(e.target.value);
        }}
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
    </Tooltip>
  );
};

export { ValidationInput, type ValidationInputProps, type ValidationProps };
