import { LineDataModel } from "@okopok/axes_context";
import { NumberValue } from "d3";
import { action, makeObservable, observable, reaction } from "mobx";

import { ModeSelectorModel } from "elements/modeSelector/modeSelectorModel";
import { GetOnChangeType } from "features/forecast/technologyForecastModal/results/results";
import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { colorCarousel } from "services/colorCarousel";

import { TechForecastResultAxisInfo } from "./techForecastResultChart";

import cn from "./techForecastResultChart.module.less";

const EPS = 1e-10;

class TechForecastChartModel {
  rho: number;
  modeModel: ModeSelectorModel;
  tooltipManager: TooltipDataManager;

  curveFactOil: LineDataModel;
  curveFactForecastOil: LineDataModel;
  curveForecastOil: LineDataModel;
  connectOilCurve: LineDataModel;
  onChangeCurveForecastOil: (newCHart: (NumberValue | null)[]) => void;

  curveFactLiquid: LineDataModel;
  curveFactForecastLiquid: LineDataModel;
  curveForecastLiquid: LineDataModel;
  connectLiquidCurve: LineDataModel;
  onChangeCurveForecastLiquid: (newCHart: (NumberValue | null)[]) => void;

  curveFactWatering: LineDataModel;
  curveFactForecastWatering: LineDataModel;
  curveForecastWatering: LineDataModel;
  connectWateringCurve: LineDataModel;

  constructor(
    oil: TechForecastResultAxisInfo,
    liquid: TechForecastResultAxisInfo,
    rho: number,
    modeModel: ModeSelectorModel,
    getOnChangeCurves?: GetOnChangeType
  ) {
    makeObservable(this, {
      curveFactOil: observable,
      curveFactForecastOil: observable,
      curveForecastOil: observable,
      connectOilCurve: observable,

      curveFactLiquid: observable,
      curveFactForecastLiquid: observable,
      curveForecastLiquid: observable,
      connectLiquidCurve: observable,

      curveFactWatering: observable,
      curveFactForecastWatering: observable,
      curveForecastWatering: observable,
      connectWateringCurve: observable,

      tooltipManager: observable,
      modeModel: observable,

      changeShowPoints: action,
      connectLines: action,
    });

    this.onChangeCurveForecastOil = getOnChangeCurves ? getOnChangeCurves({ liquid: "oil", period: "forecast" }) : (t) => null;
    this.onChangeCurveForecastLiquid = getOnChangeCurves ? getOnChangeCurves({ liquid: "liquid", period: "forecast" }) : (t) => null;

    this.rho = rho;
    this.modeModel = modeModel;

    this.curveFactLiquid = new LineDataModel({
      y: liquid.curveFactData?.y ?? [],
      x: liquid.curveFactData?.x ?? [],
      axisKey: "yLiquid",
      key: "curveFactLiquid",
      color: colorCarousel(2),
      title: "Дебит жидкости, м³/сут",
    });
    this.curveFactForecastLiquid = new LineDataModel({
      y: liquid.curveFactForecastData?.y ?? [],
      x: liquid.curveFactForecastData?.x ?? [],
      axisKey: "yLiquid",
      key: "curveFactForecastLiquid",
      color: colorCarousel(2),
      title: "Дебит жидкости, м³/сут",
    });
    this.curveForecastLiquid = new LineDataModel({
      y: liquid.curveForecastData?.y ?? [],
      x: liquid.curveForecastData?.x ?? [],
      axisKey: "yLiquid",
      key: "curveForecastLiquid",
      color: colorCarousel(2),
      className: cn.forecast,
      title: "Дебит жидкости, м³/сут",
      onChange: (newChart) => this.onChangeCurveForecastLiquid(newChart),
    });
    this.connectLiquidCurve = new LineDataModel({
      y: [],
      x: [],
      axisKey: "yLiquid",
      key: "connectLiquidCurve",
      color: colorCarousel(2),
      className: cn.forecast,
      title: "Дебит жидкости, м³/сут",
    });

    this.curveFactOil = new LineDataModel({
      y: oil.curveFactData?.y ?? [],
      x: oil.curveFactData?.x ?? [],
      axisKey: "yOil",
      key: "curveFactOil",
      color: colorCarousel(4),
      title: "Дебит нефти, т/сут",
    });
    this.curveFactForecastOil = new LineDataModel({
      y: oil.curveFactForecastData?.y ?? [],
      x: oil.curveFactForecastData?.x ?? [],
      axisKey: "yOil",
      key: "curveFactForecastOil",
      color: colorCarousel(4),
      title: "Дебит нефти, т/сут",
    });
    this.curveForecastOil = new LineDataModel({
      y: oil.curveForecastData?.y ?? [],
      x: oil.curveForecastData?.x ?? [],
      axisKey: "yOil",
      key: "curveForecastOil",
      color: colorCarousel(4),
      className: cn.forecast,
      title: "Дебит нефти, т/сут",
      limitMin: 0,
      onChange: (newChart) => this.onChangeCurveForecastOil(newChart),
    });
    this.connectOilCurve = new LineDataModel({
      y: [],
      x: [],
      axisKey: "yOil",
      key: "connectOilCurve",
      color: colorCarousel(4),
      className: cn.forecast,
      title: "Дебит нефти, т/сут",
    });

    this.curveFactWatering = new LineDataModel({
      y: new Array(Math.min(this.curveFactOil.y.length, this.curveFactLiquid.y.length))
        .fill(null)
        .map((value, index) => this.modeModel.waterCutCalc(this.curveFactLiquid.y[index], this.curveFactOil.y[index], this.rho)),
      x: this.curveFactOil.x,
      axisKey: "yWatering",
      key: "curveFactWatering",
      color: colorCarousel(1),
      title: "Обводненность (об.), %",
      limitMin: 0,
      limitMax: 100,
    });
    this.curveFactForecastWatering = new LineDataModel({
      y: new Array(Math.min(this.curveFactForecastOil.y.length, this.curveFactForecastLiquid.y.length))
        .fill(null)
        .map((value, index) => this.modeModel.waterCutCalc(this.curveFactForecastLiquid.y[index], this.curveFactForecastOil.y[index], this.rho)),
      x: this.curveFactForecastOil.x,
      axisKey: "yWatering",
      key: "curveFactForecastWatering",
      color: colorCarousel(1),
      title: "Обводненность (об.), %",
      limitMin: 0,
      limitMax: 100,
    });
    this.curveForecastWatering = new LineDataModel({
      y: new Array(Math.min(this.curveForecastOil.y.length, this.curveForecastLiquid.y.length))
        .fill(null)
        .map((value, index) => this.modeModel.waterCutCalc(this.curveForecastLiquid.y[index], this.curveForecastOil.y[index], this.rho)),
      x: this.curveForecastOil.x,
      axisKey: "yWatering",
      key: "curveForecastWatering",
      color: colorCarousel(1),
      className: cn.forecast,
      title: "Обводненность (об.), %",
      limitMin: 0,
      limitMax: 100,
    });
    this.connectWateringCurve = new LineDataModel({
      y: [],
      x: [],
      axisKey: "yWatering",
      key: "connectWateringCurve",
      color: colorCarousel(1),
      className: cn.forecast,
      title: "Обводненность (об.), %",
      limitMin: 0,
      limitMax: 100,
    });
    this.connectLines();
    this.changeShowPoints(modeModel.mode);

    this.correctOilValues();

    this.tooltipManager = new TooltipDataManager([
      this.curveFactForecastLiquid,
      this.curveFactForecastOil,
      this.curveFactLiquid,
      this.curveFactOil,
      this.curveForecastLiquid,
      this.curveForecastOil,
      this.curveFactForecastWatering,
      this.curveFactWatering,
      this.curveForecastWatering,
    ]);

    reaction(
      () => this.modeModel.mode,
      (mode) => {
        this.changeShowPoints(mode);
      }
    );

    reaction(
      () => [this.curveForecastOil.y],
      () => {
        if (this.tooltipManager.curveDragging === "curveForecastOil") {
          const newLiquid: (NumberValue | null)[] = [];
          const newWater: (NumberValue | null)[] = [];
          let updateLiquid = false;
          let updateWater = false;
          for (let i = 0; i < Math.min(this.curveForecastOil.y.length, this.curveForecastWatering.y.length, this.curveForecastLiquid.y.length); ++i) {
            newLiquid.push(+this.curveForecastLiquid.y[i]);
            newWater.push(+this.curveForecastWatering.y[i]);
            const updates = this.modeModel.onValueChange(
              "oilRate",
              +this.curveForecastOil.y[i],
              +this.curveForecastLiquid.y[i],
              +this.curveForecastWatering.y[i],
              this.rho
            );
            for (let update of updates) {
              if (update.key === "liquidRate") {
                newLiquid[i] = update.value;
                updateLiquid = true;
              }
              if (update.key === "waterCut") {
                newWater[i] = update.value;
                updateWater = true;
              }
            }
          }
          if (updateLiquid) {
            this.curveForecastLiquid.setPoints(newLiquid);
          }
          if (updateWater) {
            this.curveForecastWatering.setPoints(newWater);
          }
        }
      }
    );

    reaction(
      () => [this.curveForecastLiquid.y],
      () => {
        if (this.tooltipManager.curveDragging === "curveForecastLiquid") {
          const newOil: (NumberValue | null)[] = [];
          const newWater: (NumberValue | null)[] = [];
          let updateOil = false;
          let updateWater = false;
          for (let i = 0; i < Math.min(this.curveForecastOil.y.length, this.curveForecastWatering.y.length, this.curveForecastLiquid.y.length); ++i) {
            newOil.push(+this.curveForecastOil.y[i]);
            newWater.push(+this.curveForecastWatering.y[i]);
            const updates = this.modeModel.onValueChange(
              "liquidRate",
              +this.curveForecastOil.y[i],
              +this.curveForecastLiquid.y[i],
              +this.curveForecastWatering.y[i],
              this.rho
            );
            for (let update of updates) {
              if (update.key === "oilRate") {
                newOil[i] = update.value;
                updateOil = true;
              }
              if (update.key === "waterCut") {
                newWater[i] = update.value;
                updateWater = true;
              }
            }
          }
          if (updateOil) {
            this.curveForecastOil.setPoints(newOil);
          }
          if (updateWater) {
            this.curveForecastWatering.setPoints(newWater);
          }
        }
      }
    );

    reaction(
      () => [this.curveForecastWatering.y],
      () => {
        if (this.tooltipManager.curveDragging === "curveForecastWatering") {
          const newOil: (NumberValue | null)[] = [];
          const newLiquid: (NumberValue | null)[] = [];
          let updateOil = false;
          let updateLiquid = false;
          for (let i = 0; i < Math.min(this.curveForecastOil.y.length, this.curveForecastWatering.y.length, this.curveForecastLiquid.y.length); ++i) {
            newOil.push(+this.curveForecastOil.y[i]);
            newLiquid.push(+this.curveForecastLiquid.y[i]);
            const updates = this.modeModel.onValueChange(
              "waterCut",
              +this.curveForecastOil.y[i],
              +this.curveForecastLiquid.y[i],
              +this.curveForecastWatering.y[i],
              this.rho
            );
            for (let update of updates) {
              if (update.key === "oilRate") {
                newOil[i] = update.value;
                updateOil = true;
              }
              if (update.key === "liquidRate") {
                newLiquid[i] = update.value;
                updateLiquid = true;
              }
            }
          }
          if (updateOil) {
            this.curveForecastOil.setPoints(newOil);
          }
          if (updateLiquid) {
            this.curveForecastLiquid.setPoints(newLiquid);
          }
        }
      }
    );
  }

  private correctOilValues = () => {
    if (this.curveForecastOil.onChange && this.curveForecastOil.y.find((value, index) => +value > +this.curveForecastLiquid.y[index] * this.rho)) {
      this.curveForecastOil.onChange(
        this.curveForecastOil.y.map((value, index) => Math.max(0, Math.min(+value, +this.curveForecastLiquid.y[index] * this.rho - EPS)))
      );
    }
  };

  public connectLines = () => {
    if (this.curveFactOil.y.length > 0 && this.curveForecastOil.y.length > 0 && this.curveFactOil.lastX !== this.curveForecastOil.firstX) {
      this.connectOilCurve.setPoints([this.curveFactOil.y.at(-1)!, this.curveForecastOil.y[0]]);
      this.connectOilCurve.x = [this.curveFactOil.lastX, this.curveForecastOil.firstX];
    }

    if (
      this.curveFactLiquid.y.length > 0 &&
      this.curveForecastLiquid.y.length > 0 &&
      this.curveFactLiquid.lastX !== this.curveForecastLiquid.firstX
    ) {
      this.connectLiquidCurve.setPoints([this.curveFactLiquid.y.at(-1)!, this.curveForecastLiquid.y[0]]);
      this.connectLiquidCurve.x = [this.curveFactLiquid.lastX, this.curveForecastLiquid.firstX];
    }

    if (
      this.curveFactWatering.y.length > 0 &&
      this.curveForecastWatering.y.length > 0 &&
      this.curveFactWatering.lastX !== this.curveForecastWatering.firstX
    ) {
      this.connectWateringCurve.setPoints([this.curveFactWatering.y.at(-1)!, this.curveForecastWatering.y[0]]);
      this.connectWateringCurve.x = [this.curveFactWatering.lastX, this.curveForecastWatering.firstX];
    }
  };

  public changeShowPoints = (value: string) => {
    this.curveForecastWatering.setMaxLimit(100);
    if (value === "oilRate") {
      this.curveForecastOil.onChangeShowPoints(false);
      this.curveForecastLiquid.onChangeShowPoints(true);
      this.curveForecastWatering.onChangeShowPoints(true);
    }
    if (value === "liquidRate") {
      this.curveForecastOil.onChangeShowPoints(true);
      this.curveForecastLiquid.onChangeShowPoints(false);
      this.curveForecastWatering.onChangeShowPoints(true);

      this.curveForecastWatering.setMaxLimit(99);
    }
    if (value === "waterCut") {
      this.curveForecastOil.onChangeShowPoints(true);
      this.curveForecastLiquid.onChangeShowPoints(true);
      this.curveForecastWatering.onChangeShowPoints(false);
    }
  };
}

export { TechForecastChartModel };
