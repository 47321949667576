import { FC, MouseEvent, useCallback, useEffect } from "react";
import { GradientLine, useMapContext } from "@okopok/axes_context";
import classNames from "classnames";
import { observer } from "mobx-react";

import { useStatedRef } from "utils/useInteractiveRef";

import { NodeType, PipeColorMode, useInfrastructureMapContext } from "../InfrastructureMapManager/InfrastructureMapManager";
import { Label } from "../label";
import { getSelectedText, getZoomParameters, tooltipText } from "../utils";

import { EmptyLine } from "./emptyLine";

import cn from "./pipes.module.less";

const getValue = (mode: PipeColorMode, isCalculated: boolean, pipe: PipeProps["pipe"], range: [number, number]): { v1: number; v2: number } | {} => {
  if (mode === "velocity" && isCalculated) {
    return { v1: Math.abs(pipe.velocity), v2: Math.abs(pipe.velocity) };
  }
  if (mode === "pressure") {
    return { v1: pipe.from.pressure, v2: pipe.to.pressure };
  }
  return {};
};

type PipeProps = {
  pipe: { title: string; from: NodeType; to: NodeType; uuid: string; isEmpty?: boolean; isSelected?: boolean; velocity: number };
  type: "water" | "oil";
};

const Pipe: FC<PipeProps> = observer(({ pipe, type }) => {
  const manager = useInfrastructureMapContext();
  const { zoom, scale } = useMapContext();

  const selectedText = getSelectedText(manager.selection.selectedNodeIds.length, manager.selection.selectedPipeIds.length);
  const isCalculated = !!manager.calculateStore.hydraulicData?.[type === "oil" ? "prod" : "inj"]?.segments?.length;
  const { zoomScaleFactor, zoomScale } = getZoomParameters(zoom);

  useEffect(() => {
    if (manager.isSelection) {
      if (pipe.isSelected) {
        manager.setTooltip(selectedText);
      } else {
        manager.setTooltip(tooltipText.clickToSelect);
      }
    } else {
      manager.setTooltip("");
    }
  }, [manager, manager.isSelection, pipe, selectedText]);

  const handlePipesSelected = (event: MouseEvent, id: string) => {
    event.stopPropagation();
    manager.selection.setSelectedPipeIds(id, !!(event.ctrlKey || event.metaKey || event.shiftKey));
  };

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (manager.isSelection) {
        if (pipe.isSelected) {
          manager.setTooltip(selectedText);
        } else {
          manager.setTooltip(tooltipText.clickToSelect);
        }
      }
    },
    [manager, pipe.isSelected, selectedText]
  );

  const angleRef = useStatedRef<number>(0);

  const value = getValue(manager.pipeColorMode, isCalculated, pipe, manager.range);
  const isReverse = "v1" in value && manager.pipeColorMode === "pressure" ? value.v1 < value.v2 : pipe.velocity < 0;
  const noMovement =
    (manager.pipeColorMode === "pressure" && "v1" in value && value.v1 === value.v2) || (manager.pipeColorMode === "velocity" && pipe.velocity === 0);
  return (
    <g
      className={classNames(manager.isDrawing && cn.isDisabled)}
      onClick={(event) => (manager.isSelection ? handlePipesSelected(event, pipe.uuid) : undefined)}
      onMouseMove={handleMouseMove}
    >
      {pipe.isEmpty && (
        <EmptyLine
          scale={scale}
          from={pipe.from}
          to={pipe.to}
          strokeWidth={zoomScaleFactor}
          className={classNames({
            [cn.pipeWater]: !pipe.isSelected && !isCalculated && type === "water",
            [cn.pipeOil]: !pipe.isSelected && !isCalculated && type === "oil",
            [cn.pipeSelected]: pipe.isSelected,
          })}
        >
          {zoom > 1 && (
            <g transform={`scale(${zoomScale}), translate(20, 0), rotate(${-angleRef.current})`}>
              {manager.showMapText && <Label>{pipe.title}</Label>}
            </g>
          )}
        </EmptyLine>
      )}
      {!pipe.isEmpty && (
        <GradientLine
          from={pipe.from}
          to={pipe.to}
          {...value}
          strokeWidth={zoomScaleFactor}
          className={classNames({
            [cn.pipeWater]: !pipe.isSelected && !isCalculated && type === "water",
            [cn.pipeOil]: !pipe.isSelected && !isCalculated && type === "oil",
            [cn.pipeSelected]: pipe.isSelected,
          })}
          childrenPos={0.5}
          angleRef={angleRef}
        >
          <g transform={`scale(${zoomScale})`}>
            {isCalculated &&
              (noMovement ? (
                <g transform="translate(-8,-8)">
                  <rect x="3.33" y="3.33" className={cn.squareIcon} />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.33 3.33V12.67H12.67V3.33H3.33ZM3 2C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2H3Z"
                    fill="white"
                  />
                </g>
              ) : (
                <path
                  d="M7.11289 14.4964L1.31306 1.56097L15.6238 5.98551L10.2511 9.27651L7.11289 14.4964Z"
                  className={cn.arrowIcon}
                  transform={`rotate(${isReverse ? 180 : 0}), translate(10,0), rotate(138)`}
                />
              ))}

            {zoom > 1 && <g transform={`translate(20, 0), rotate(${-angleRef.current})`}>{manager.showMapText && <Label>{pipe.title}</Label>}</g>}
          </g>
        </GradientLine>
      )}
    </g>
  );
});

export { Pipe };
