import { createContext, Dispatch, type FC, type RefObject, SetStateAction, useContext, useEffect } from "react";
import { createPortal } from "react-dom";
import { useSecureTableContext } from "@okopok/components/Table";
import { Button, Dropdown, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import { SubmitButtonProps } from "routing/outlines/submitButton/submitButton";

import { ColumnsSettings } from "elements/icons/columnsSettings";
import { Filter } from "elements/icons/filter";
import { Search } from "elements/icons/search";
import { ColumnsSettingsWidget } from "features/techPrediction/columnsWidget/columnsWidget";
import { TechFilterWidget } from "features/techPrediction/filters/filterWidget";
import type { FilterManager } from "features/techPrediction/filters/manager";

import cn from "./pageFrameTitlePortal.module.less";

const CONTEXT = createContext<{
  toolboxRef: RefObject<HTMLElement>;
  setSubmitButtonProps: Dispatch<SetStateAction<SubmitButtonProps | null>>;
} | null>(null);

const ToolboxPortalContextProvider = CONTEXT.Provider;

type PageFrameTitlePortalProps = {
  children?: React.ReactNode;
  filter?: FilterManager<any, any>;
  onFilterChange?: () => void;
  onSave?: () => void;
  submitCustomTooltip?: SubmitButtonProps["customTooltip"];
  model?: {
    isUpdated?: boolean;
    isCompleted?: boolean;
    isValid?: boolean;
  };
  submitting?: boolean;
};

const PageFrameTitlePortal: FC<PageFrameTitlePortalProps> = observer(
  ({ submitting, filter, children, onFilterChange, onSave, model, submitCustomTooltip }) => {
    const { toolboxRef, setSubmitButtonProps } = useContext(CONTEXT)!;

    useEffect(
      () =>
        setSubmitButtonProps(
          onSave
            ? {
                onSubmit: onSave,
                customTooltip: submitCustomTooltip,
                model,
              }
            : null
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onSave, model?.isUpdated, model?.isCompleted, model?.isValid, setSubmitButtonProps]
    );

    useEffect(() => {
      setSubmitButtonProps((v) => (v === null ? null : { ...v, loading: submitting }));
    }, [submitting, setSubmitButtonProps]);

    const tableContext = useSecureTableContext();

    if (toolboxRef.current === null) {
      return null;
    }

    return createPortal(
      <div className={cn["pane-buttons-search"]}>
        {tableContext && "search" in tableContext && <Button type="text" disabled icon={<Search width="20" height="20" />} />}
        {filter && onFilterChange && (
          <Dropdown dropdownRender={() => <TechFilterWidget filterManager={filter} applyFilters={onFilterChange} />} trigger={["click"]}>
            <Tooltip title="Фильтры">
              <Button type="text" icon={<Filter width="20" height="20" />} />
            </Tooltip>
          </Dropdown>
        )}
        {tableContext && (
          <Dropdown dropdownRender={ColumnsSettingsWidget} trigger={["click"]}>
            <Tooltip title="Колонки">
              <Button type="text" icon={<ColumnsSettings width="20" height="20" />} />
            </Tooltip>
          </Dropdown>
        )}
        {children}
      </div>,
      toolboxRef.current
    );
  }
);

export { PageFrameTitlePortal, ToolboxPortalContextProvider };
