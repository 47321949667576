import React, {
  CSSProperties,
  type FC,
  type MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { DraggableData, DraggableEvent } from "react-draggable";
import Draggable from "react-draggable";
import { Modal } from "antd";

import { useBooleanState } from "utils/useBooleanState";

import cn from "./modal.module.less";

type ContentTypeProps<Resolve, InputDataType = undefined> = {
  setValid: (v: boolean) => void;
  accept: () => void;
  reject: () => void;
  setLoading: (v: boolean) => void;
  // если undefined то модалка остаётся, если false то промис реджектится датой, если true то резолвится
  setOnAccept: (callback: () => Promise<{ result: boolean; data?: Resolve } | undefined>) => void;
  dataRef: MutableRefObject<InputDataType>;
};

type ContentType<Resolve, InputDataType = undefined> = FC<ContentTypeProps<Resolve, InputDataType>>;

type OnShow<Resolve, InputDataType> = (
  content: ContentType<Resolve, InputDataType>,

  data?: MutableRefObject<InputDataType> | null,
  title?: ReactNode | string,
  okText?: ReactNode | string,
  cancelText?: ReactNode | string,
  width?: number | string,
  footer?: ReactNode,
  bodyStyle?: CSSProperties
) => Promise<Resolve>;

const ModalContext = React.createContext<OnShow<unknown, unknown>>(() => {
  console.error("Do not use modal outside the modal context provider");
  return Promise.reject();
});

const hideButtonProps = { style: { display: "none" } };

const ModalContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [open, showModal, hideModal] = useBooleanState(false);
  // Чтобы при скрытии-показывании сбрасывалось состояние виджетов внутри модалки
  const [widgetKey, setWidgetKey] = useState(0);
  const changeWidgetKey = useCallback(() => setWidgetKey((v) => v + 1), []);
  const [disabled, setDisabled] = useState(true);
  const [isValid, setValid] = useState<boolean>(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const [isLoading, setLoading] = useState<boolean>(false);
  const draggleRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<ContentType<unknown, unknown>>(() => null);
  const titleRef = useRef<ReactNode>(null);
  const widthRef = useRef<number | string | null | undefined>(null);
  const acceptButtonTextRef = useRef<ReactNode>(null);
  const rejectButtonTextRef = useRef<ReactNode>(null);
  const dataRef = useRef<unknown | null>(null);
  const onAccept = useRef<Parameters<ContentTypeProps<unknown, unknown>["setOnAccept"]>[0]>(() => Promise.resolve({ result: true }));
  const setOnAccept: ContentTypeProps<unknown, unknown>["setOnAccept"] = useCallback((callback) => (onAccept.current = callback), []);
  const footerRef = useRef<ReactNode>(null);
  const bodyStyleRef = useRef<CSSProperties>({});

  useEffect(() => {
    if (!open) {
      setLoading(false);
    }
  }, [open]);

  const modalPromiseCallbacks = useRef({ resolve: (_: any) => {}, reject: () => {} });

  const modalPublisher: OnShow<unknown, unknown> = (
    content,
    data,
    title = "title",
    okText = "Ok",
    cancelText = "Отмена",
    width,
    footer,
    bodyStyle = {}
  ) => {
    contentRef.current = content;
    titleRef.current = title;
    widthRef.current = width;
    acceptButtonTextRef.current = okText;
    rejectButtonTextRef.current = cancelText;
    footerRef.current = footer;
    bodyStyleRef.current = bodyStyle;
    if (data?.current) {
      dataRef.current = data.current;
    }
    changeWidgetKey();
    showModal();
    return new Promise<any>((resolve, reject) => {
      modalPromiseCallbacks.current = { resolve, reject };
    });
  };

  const handleOk = async () => {
    if (isLoading) {
      return;
    }
    const acceptDecision = await onAccept.current();
    if (acceptDecision === undefined) {
      return;
    }
    modalPromiseCallbacks.current[acceptDecision.result ? "resolve" : "reject"](acceptDecision.data);
    hideModal();
  };

  const handleCancel = () => {
    modalPromiseCallbacks.current.reject();
    hideModal();
  };

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const ContentComponent: ContentType<unknown, unknown> = contentRef.current;
  const okProps = useMemo(() => ({ disabled: !isValid, loading: isLoading }), [isValid, isLoading]);

  const width = widthRef.current ? { width: widthRef.current.toString() } : undefined;
  return (
    <>
      <Modal
        styles={{ body: bodyStyleRef.current }}
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            {titleRef.current}
          </div>
        }
        open={open}
        okButtonProps={okProps}
        cancelButtonProps={rejectButtonTextRef.current === null ? hideButtonProps : undefined}
        okText={acceptButtonTextRef.current}
        cancelText={rejectButtonTextRef.current}
        onOk={handleOk}
        footer={footerRef.current}
        onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}>
            <div className={cn.wrap} ref={draggleRef}>
              {modal}
            </div>
          </Draggable>
        )}
        {...width}
      >
        {open && (
          <ContentComponent
            key={widgetKey}
            dataRef={dataRef}
            setOnAccept={setOnAccept}
            setValid={setValid}
            setLoading={setLoading}
            reject={handleCancel}
            accept={handleOk}
          />
        )}
      </Modal>
      <ModalContext.Provider value={modalPublisher}>{children}</ModalContext.Provider>
    </>
  );
};

const useModal = <Resolve, Data = undefined>() => useContext(ModalContext) as OnShow<Resolve, Data>;

export { ModalContextProvider, type ContentType as ModelContentComponentType, useModal };
