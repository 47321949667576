import { useMemo } from "react";
import { ColumnRaw, TableModel } from "@okopok/components/Table";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import { Input, Switch } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";

import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Format } from "elements/format/format";
import { MonthPicker } from "elements/inputs/monthPicker";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";
import { setupAccuracy } from "utils/round";

import "dayjs/locale/ru";

import { DRow as DRowPipes, PipesModel } from "../infrastructureTableManager/pipes";

import cn from "../../infrastructureTable.module.less";
import localStyles from "./useInfrastructurePipesTable.module.less";

const useColumns = (mode: "prod" | "inj"): ColumnRaw<DRowPipes>[] => {
  const { catalog, range } = useInfrastructure();
  return useMemo(
    (): ColumnRaw<DRowPipes>[] => [
      {
        title: "№",
        width: { min: 40, max: 80, competitiveness: 1 },
        key: "index",
        isSticky: true,
        onCell: () => ({ className: cn.tableCell }),
        render: (_, pipe) => pipe.absoluteIndex,
      },
      {
        key: "expand",
        title: null,
        isSticky: true,
        width: { min: 30, max: 30, competitiveness: 1 },
        render: (_, { expand }) => (expand?.status !== undefined ? <ExpandButton expand={expand} /> : <></>),
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Название участка",
        width: { min: 154, max: 200, competitiveness: 1 },
        isSticky: true,
        dataKey: "title",
        onCell: () => ({ className: cn.tableCell }),
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return value;
          }
          return <Input variant="borderless" className={cn.title} value={value} onChange={({ target }) => pipe.update?.("title", target.value)} />;
        },
      },
      {
        title: "Вид трубопровода",
        width: { min: 200, max: 250, competitiveness: 1 },
        dataKey: "kind",
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return (
            <div className={cn.catalogType}>
              <SelectStorable
                popupClassName={localStyles.kindPopup}
                values={value}
                placeholder="Нет выбранного трубопровода"
                store={catalog[mode === "inj" ? "waterPipesSelector" : "oilPipesSelector"]!}
                setValues={(id, title) => pipe.update?.("kind", [id, title])}
              />
            </div>
          );
        },
      },
      {
        title: "Категория",
        width: { min: 132, max: 160, competitiveness: 1 },
        dataKey: "category",
        onCell: () => ({ className: cn.tableCell }),
        render: (value) => (value ? value : ""),
      },
      {
        title: "Внешний диаметр, мм",
        width: { min: 120, max: 150, competitiveness: 1 },
        dataKey: "diameterOuter",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Толщина стенки, мм",
        width: { min: 100, max: 120, competitiveness: 1 },
        dataKey: "thickness",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Шерохов-ть стенки, мм",
        width: { min: 110, max: 120, competitiveness: 1 },
        dataKey: "roughness",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: "Дата ввода",
        width: { min: 120, max: 120, competitiveness: 1 },
        dataKey: "startedAt",
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return (
            <div className={cn.date}>
              <MonthPicker
                onChange={(date) => pipe.update?.("startedAt", date ?? null)}
                value={value ? value : undefined}
                placeholder="Выбрать..."
                variant="borderless"
                locale={locale}
                start={dayjs(range.from.toString())}
                end={dayjs(range.to.toString())}
              />
            </div>
          );
        },
      },
      {
        title: "Дата выбытия",
        width: { min: 120, max: 120, competitiveness: 1 },
        dataKey: "finishedAt",
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return (
            <div className={cn.date}>
              <MonthPicker
                onChange={(date) => pipe.update?.("finishedAt", date ?? null)}
                value={value ? value : undefined}
                placeholder=""
                variant="borderless"
                locale={locale}
                start={pipe.value?.startedAt}
                end={dayjs(range.to.toString())}
              />
            </div>
          );
        },
      },
      {
        title: "Узел начала",
        width: { min: 102, max: 120, competitiveness: 1 },
        key: "firstNodeTitle",
        onCell: () => ({ className: cn.tableCell }),
        render: (_, pipe) => pipe.value?.firstNode?.title,
      },
      {
        title: "Координаты узла начала, м",
        width: { min: 158, max: 170, competitiveness: 1 },
        key: "firstNodeCoords",
        onCell: () => ({ className: cn.tableCell }),
        render: (_, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return (
            <span>
              X: <Format>{setupAccuracy(pipe.value?.firstNode?.coords.x!, "round")}</Format> Y:{" "}
              <Format>{setupAccuracy(pipe.value?.firstNode?.coords.y!, "round")}</Format>
            </span>
          );
        },
      },
      {
        title: "Альтитуда узла начала, м",
        width: { min: 120, max: 150, competitiveness: 1 },
        key: "firstNodeAltitude",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (_, pipe) => pipe.value?.firstNode?.altitude,
      },
      {
        title: "Узел конца",
        width: { min: 102, max: 120, competitiveness: 1 },
        key: "secondNodeTitle",
        onCell: () => ({ className: cn.tableCell }),
        render: (_, pipe) => pipe.value?.secondNode?.title,
      },
      {
        title: "Координаты узла конца, м",
        width: { min: 158, max: 170, competitiveness: 1 },
        key: "secondNodeCoords",
        onCell: () => ({ className: cn.tableCell }),
        render: (_, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return (
            <span>
              X: <Format>{setupAccuracy(pipe.value?.secondNode?.coords.x!, "round")}</Format> Y:{" "}
              <Format>{setupAccuracy(pipe.value?.secondNode?.coords.y!, "round")}</Format>
            </span>
          );
        },
      },
      {
        title: "Альтитуда узла конца, м",
        width: { min: 120, max: 150, competitiveness: 1 },
        key: "secondNodeAltitude",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (_, pipe) => pipe.value?.secondNode?.altitude,
      },
      {
        title: "Протяженность, км",
        width: { min: 130, max: 150, competitiveness: 1 },
        dataKey: "length",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return <Format>{value}</Format>;
        },
      },
      {
        title: "Коррект. коэф-т на трение",
        width: { min: 134, max: 150, competitiveness: 1 },
        dataKey: "frictionCorrectionFactor",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return <Format>{value}</Format>;
        },
      },
      {
        title: "Уд. стоимость строительства, тыс руб / км",
        width: { min: 240, max: 250, competitiveness: 1 },
        dataKey: "unitConstructionCost",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return <Format>{value}</Format>;
        },
      },
      {
        title: "Стоимость строительства, тыс руб",
        width: { min: 220, max: 250, competitiveness: 1 },
        dataKey: "cost",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return <Format>{value}</Format>;
        },
      },
      {
        title: "Учет реконструкции",
        width: { min: 130, max: 150, competitiveness: 1 },
        dataKey: "isReconstruction",
        onCell: () => ({ className: cn.tableCell }),
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return (
            <Switch
              onChange={(el) => pipe.update?.("isReconstruction", el ?? null)}
              className={localStyles.isReconstruction}
              checkedChildren="Да"
              unCheckedChildren="Нет"
              value={value}
            />
          );
        },
      },
      {
        title: "Уд. стоимость реконструкции, тыс руб /км",
        width: { min: 240, max: 250, competitiveness: 1 },
        dataKey: "unitReconstructionCost",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return <Format>{value}</Format>;
        },
      },
      {
        title: "Стоимость реконструкции, тыс руб",
        width: { min: 220, max: 250, competitiveness: 1 },
        dataKey: "costReconstruction",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return <Format>{value}</Format>;
        },
      },
      {
        title: "Период реконструкции, года",
        width: { min: 190, max: 220, competitiveness: 1 },
        dataKey: "periodReconstruction",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
      },
      {
        title: <p />,
        key: "remove",
        width: 32,
        onCell: () => ({ className: cn.remove }),
        render: (_, pipe) => {
          if (pipe.expand?.status !== undefined) {
            return <></>;
          }
          return <DeleteButton onClick={pipe.value?.remove} />;
        },
      },
    ],
    [range, catalog, mode]
  );
};

const useInfrastructurePipesTableModel = (mode: "prod" | "inj") => {
  const infrastructure = useInfrastructure();

  const columns = useColumns(mode);
  const store = useMemo(() => new PipesModel(infrastructure, mode), [infrastructure, mode]);

  const model = useMemo(
    () =>
      new TableModel(columns, store, {
        onRow: ({ indexPath, expand }) => ({
          className:
            expand === undefined
              ? cn.tableRowPlain
              : indexPath.length === 1
              ? `${cn.tableRowPrimary} ${localStyles.rowBorderNone}`
              : cn.tableRowPlain,
        }),
      }),
    [columns, store]
  );

  return { model, store };
};

export { useInfrastructurePipesTableModel };
