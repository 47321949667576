import { memo, useEffect, useRef } from "react";
import * as d3 from "d3";

import cn from "./WellPad.module.less";

type WellPadMode = "injection" | "mining";
type WellPadProps = {
  showLabel: boolean;
  title?: string;
  zoom: number;
} & React.PropsWithChildren;

const WellPad = memo<WellPadProps>(({ zoom, showLabel, title, children }) => {
  const textRef = useRef<SVGTextElement>(null);
  const backgroundRef = useRef<SVGRectElement>(null);

  useEffect(() => {
    const textBox = d3.select(textRef.current).node()?.getBBox();
    const rectWidth = (textBox?.width ?? 0) + 10;
    const rectHeight = (textBox?.height ?? 0) + 10;
    d3.select(backgroundRef.current)
      .attr("x", -rectWidth / 2)
      .attr("y", -20 - rectHeight / 2)
      .attr("height", rectHeight)
      .attr("width", rectWidth);
  }, [showLabel]);
  return (
    <g className={cn.wrap} style={{ transform: `scale(${zoom})` }}>
      {showLabel && <rect ref={backgroundRef} rx={5} style={{ fill: "rgba(255, 255, 255, 0.8)" }} />}
      {showLabel && title && (
        <text style={{ textAnchor: "middle" }} ref={textRef} y={-15}>
          {title}
        </text>
      )}
      {children}
    </g>
  );
});

export { WellPad };
export type { WellPadMode };
