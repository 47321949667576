import { useMemo } from "react";
import { ColumnRaw, TableContextProvider, TableItem, TableModel, Widget } from "@okopok/components/Table";
import { observer } from "mobx-react";

import { EllipsisTable as Ellipsis } from "elements/ellipsis/ellipsis";
import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { useForecast } from "models/project/fact/forecast/forecast";

import { useOperatingGTMContext } from "../operatingGTM";

import { DRow } from "./decreaseCoeffsTableModel";

import cn from "./decreaseCoeffsTable.module.less";

const useColumns = (): ColumnRaw<DRow>[] => {
  const forecast = useForecast();

  return useMemo(
    (): ColumnRaw<DRow>[] => [
      {
        key: "index",
        title: "No.пп",
        isSticky: true,
        width: { min: 52, max: 52, competitiveness: 1 },
        render: (_, { absoluteIndex }) => <div style={{ width: "100%", textAlign: "right" }}>{absoluteIndex ?? 0}</div>,
      },
      {
        dataKey: "title",
        title: "Параметр",
        isSticky: true,
        width: { min: 380, max: 380, competitiveness: 1 },
        render: (value, tableItem) => <Ellipsis value={value} row={{ level: tableItem.indexPath.length }} />,
        onCell: ({ indexPath }) => ({ style: { paddingLeft: indexPath.length * 15 } }),
      },

      ...[...forecast!.range].map((year, index) => ({
        dataKey: year,
        title: year,
        width: { min: 130, max: 300, competitiveness: 1 },
        render: (value: number | undefined, tableItem: TableItem<DRow>) =>
          tableItem.expand === undefined ? (
            <InputNumber variant="borderless" min={0} value={value} onUpdate={(value) => tableItem.update?.(year, value)} />
          ) : null,
      })),
    ],
    [forecast]
  );
};
const DecreaseCoeffsTable = observer(() => {
  const store = useOperatingGTMContext()!.DecreaseCoeffsTableModel;
  const columns = useColumns();

  const model = useMemo(() => {
    const model = new TableModel(
      columns,
      store,
      {
        onRow: ({ indexPath, expand }) => ({
          className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
        }),
      },
      {
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }
    );
    return model;
  }, [store, columns]);

  return (
    <TableContextProvider value={model}>
      <Widget headerClassName={cn.tableHeader} />
    </TableContextProvider>
  );
});

export { DecreaseCoeffsTable };
