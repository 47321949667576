import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { runInAction, transaction } from "mobx";

import { Mine, Ranking, Well } from "services/back/ranking";

import { RankingDrilling, RankingDRow } from "./rankingDrilling";
import { RankingReconstruction } from "./rankingReconstruction";

type RowNodeType = {
  //TODO: сделать какой-нибудь общий тип
  gtmId?: number;
  gtmTypeId?: number;
  gtmTypeTitle?: string;
  stratumTitle?: string;
} & Well;

class WellsRow extends TableNode<RankingDRow, RowNode> {
  public asDRow = (): RankingDRow => ({
    rank: this.rank,
    mineCode: this.mine.mineCode,
    accumOilProd: this.mine.accumOilProd,
    pvCapexProd: this.mine.economics.pvCapexProd,
    pvCapex: this.mine.economics.pvCapex,
    pi: this.mine.economics.pi,
    npv: this.mine.economics.npv,
    irr: this.mine.economics.irr,
    pbp: this.mine.economics.pbp,
    dpbp: this.mine.economics.dpbp,
    move: this.move,
  });

  constructor(private parent: RankingDrilling, public readonly mine: Mine, public readonly wells: Ranking["wells"]) {
    super(parent, { isExpandedChildren: true, selectable: false, mutable: false });

    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(
        this,
        wells.map((well) => new RowNode(this, well))
      );
    });
  }

  get absoluteIndex(): number {
    return super.absoluteIndex - (this.index ?? 0) - 1;
  }

  get order() {
    return this.parent.order;
  }

  get rank(): number {
    return this.mine.ranks[this.order];
  }

  set isMoved(value: boolean) {
    this.parent.isMoved = value;
  }

  private move = (direction: "up" | "down") => {
    if (this.index === undefined) {
      console.error("attempt to move row w/o index");
      return;
    }
    const delta = direction === "up" ? -1 : 1;
    const resultIndex = this.index + delta;
    if (resultIndex < 0 || resultIndex > (this.parent.childrenStore?.size ?? 0) - 1) {
      return;
    }
    transaction(() => {
      this.parentNode!.childrenStore?.splice(this.index!, 1);
      this.parentNode!.childrenStore?.splice(resultIndex, 0, this);
    });
    this.parent.isMoved = true;
  };
}

class RowNode extends TableNode<RankingDRow> {
  public asDRow = (): RankingDRow => ({
    rank: this.rank,
    mineCode: this.row.mineCode,
    wellTitle: this.row.wellTitle,
    accumOilProd: this.row.accumOilProd,
    pvCapexProd: this.row.economics.pvCapexProd,
    pvCapex: this.row.economics.pvCapex,
    pi: this.row.economics.pi,
    npv: this.row.economics.npv,
    irr: this.row.economics.irr,
    pbp: this.row.economics.pbp,
    dpbp: this.row.economics.dpbp,
    gtm: this.row.gtmTypeTitle,
    stratumTitle: this.row.stratumTitle,
    move: this.move,
  });

  constructor(private parent: WellsRow | RankingReconstruction, public readonly row: RowNodeType) {
    super(parent, { selectable: false, mutable: false });
    runInAction(() => (this.childrenStore = null));
  }

  get wellId(): number {
    return this.row.wellId;
  }

  get rank(): number {
    return this.row.ranks[this.parent.order];
  }

  private move = (direction: "up" | "down") => {
    if (this.index === undefined) {
      console.error("attempt to move row w/o index");
      return;
    }
    const delta = direction === "up" ? -1 : 1;
    const resultIndex = this.index + delta;
    if (resultIndex < 0 || resultIndex > (this.parent.childrenStore?.size ?? 0) - 1) {
      return;
    }
    transaction(() => {
      this.parentNode!.childrenStore?.splice(this.index!, 1);
      this.parentNode!.childrenStore?.splice(resultIndex, 0, this);
    });
    this.parent.isMoved = true;
  };
}

export { RowNode, WellsRow };
