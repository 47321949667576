import { useMemo, useState } from "react";
import { TableContextProvider, Widget } from "@okopok/components/Table";
import { Radio, RadioChangeEvent } from "antd";
import { observer } from "mobx-react";
import { SubmitButton } from "routing/outlines/submitButton/submitButton";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { FullScreenEmpty } from "elements/fullScreen/fullScreen";
import { Select } from "elements/inputs/select";
import { Loader } from "elements/loader";
import { useForecast } from "models/project/fact/forecast/forecast";

import "dayjs/locale/ru";

import { CalculateButton } from "../calculateButton/calculateButton";
import { useInfrastructure } from "../useInfrastructure";

import { useDinamicTableManager } from "./dinamicTable/tableManager/useDinamicTableManager";
import { useInfrastructureTableModel } from "./staticTable/infrastructureTableManager/useInfrastructureTableModel";

import cn from "./infrastructureTable.module.less";

const usePipeTypeOptions = (regime: "dinamic" | "static") =>
  useMemo(
    () => [
      { label: "Нефтепровод", value: "prod" },
      { label: "Водовод", value: "inj" },
      { label: "Кустовые площадки", value: "mines" },
      { label: "НС/УПН/УПСВ/УКПГ", value: "stations", disabled: regime === "dinamic" },
      { label: "Стоки", value: "drain" },
      { label: "Источники", value: "source" },
    ],
    [regime]
  );

const regimeOptions = [
  { label: "Cтатика", value: "static" },
  { label: "Динамика", value: "dinamic" },
];

const InfrastructureTable = observer(() => {
  const [mode, setMode] = useState("prod");
  const [regime, setRegime] = useState<"static" | "dinamic">("static");

  const infrastructure = useInfrastructure();
  const forecast = useForecast();
  const { store: staticStore, model: staticModel } = useInfrastructureTableModel(mode);
  const { store: dinamicStore, model: dinamicModel } = useDinamicTableManager(mode);
  const pipeTypeOptions = usePipeTypeOptions(regime);

  const [store, model] = regime === "static" ? [staticStore, staticModel] : [dinamicStore, dinamicModel];

  const handleSelect = (value: string) => setMode(value);

  const handleRegime = (event: RadioChangeEvent) => setRegime(event?.target.value);

  if (store.isLoading) {
    return <Loader />;
  }

  return (
    <TableContextProvider value={model}>
      <PageFrameTitlePortal model={model}>
        <div className={cn.actionButtons}>
          <Select value={mode} options={pipeTypeOptions} onChange={handleSelect} />
          <Radio.Group value={regime} options={regimeOptions} buttonStyle="solid" optionType="button" onChange={handleRegime} className={cn.regime} />
          {forecast && <CalculateButton fullWidth />}
          {regime === "static" && <SubmitButton model={undefined} onSubmit={infrastructure.save} />}
        </div>
      </PageFrameTitlePortal>
      {store.childrenStore && store.childrenStore.size === 0 ? (
        <FullScreenEmpty>Нет данных для отображения</FullScreenEmpty>
      ) : (
        <Widget headerClassName={cn.tableHeader} />
      )}
    </TableContextProvider>
  );
});

export { InfrastructureTable };
