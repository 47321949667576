import { TableNode } from "@okopok/components/Table";

import { UOM } from "elements/uom";
import { global } from "models/global";
import { zip } from "utils/itertools";

import { DRow } from "./tech";

const skipNulls =
  (f: (a: number, b: number) => number | null, swap: boolean = false) =>
  (a: null | number, b: null | number) => {
    if (swap) {
      [a, b] = [b, a];
    }
    if (a === null || b === null || b === 0) {
      return null;
    }
    if (swap) {
      [a, b] = [b, a];
    }
    return f(a, b);
  };

type ProdInfo = [prod: number | null, days: number | null];

// this formula is not relevant, but it seems worth saving. IPRM-621
// eslint-disable-next-line
const debitDiffOld = ([pPrev, dPrev]: ProdInfo, [pCurr, dCurr]: ProdInfo): number | null => {
  if ([pCurr, dCurr, pPrev, dPrev].find((x) => x === null || x === 0) !== undefined) {
    return null;
  }
  return (((pCurr! / dCurr! / pPrev!) * dPrev! ** 2) / 365 - 1) * 100;
};

const debitDiff = ([pPrev, dPrev]: ProdInfo, [pCurr, dCurr]: ProdInfo): number | null => {
  if ([pCurr, pPrev].find((x) => x === null || x === 0) !== undefined) {
    return null;
  }
  return (pCurr! / pPrev! - 1) * 100;
};

const debitMean = skipNulls((debet: number, mining: number) => (debet / mining) * 1000);

const wellPerformance = (mining: null | number) => (mining === null ? null : mining / 365);

class TechDummyRow extends TableNode<DRow, any> {
  public asDRow = () => ({
    name: this.name,
    measure: this.measure,
    tooltip: this.tooltip?.(),
    ...Object.fromEntries(
      this.years !== undefined ? zip(this.years, (this.data?.() as any) ?? []) : (this.data?.() as any) ?? []
    ),
  });

  protected measure: DRow["measure"];

  constructor(
    parentNode: TableNode<DRow, any>,
    protected years: number[] | undefined,
    protected name: string,
    measure: [quantity: number, unitId: number] | null = null,
    protected data: () => DRow[number][] | Array<[year: number, value: DRow[number]]> | undefined,
    protected tooltip?: () => DRow["tooltip"]
  ) {
    super(parentNode, { selectable: false, mutable: false });
    this.childrenStore = null;
    this.measure = measure && new UOM(...measure, global.uomResolver).unit;
  }
}

export { debitDiff, debitMean, wellPerformance };
export { TechDummyRow };
