import { FC } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import cn from "./loaderPage.module.less";

const LoaderPage: FC = () => {
  return (
    <div className={cn.container}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
    </div>
  );
};

export { LoaderPage };
