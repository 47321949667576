import { FC, useCallback, useEffect, useState } from "react";
import { useChartContext } from "@okopok/axes_context";
import { useDrag } from "@okopok/axes_context";
import { NumberValue } from "d3";
import { observer } from "mobx-react";

import cn from "./borderLine.module.less";

type BorderLineProps = {
  x: NumberValue;
  limitLeft?: NumberValue;
  limitRight?: NumberValue;
  setOnDrag?: (value: number) => void;
};

const BorderLine: FC<BorderLineProps> = observer(({ x, setOnDrag, limitLeft, limitRight }) => {
  const axes = useChartContext();

  const [dx, setDx] = useState(0);
  const onDrag = useCallback((x: number, dy: number) => {
    setDx(x);
  }, []);
  const [drag, isDrag] = useDrag(onDrag);
  const coord = (x: number) => {
    if (limitLeft && limitRight) {
      if (x < xScale(limitLeft)) return xScale(limitLeft);
      if (x > xScale(limitRight)) return xScale(limitRight);
      return x;
    }
    if (limitLeft) {
      return Math.max(xScale(limitLeft), x);
    }
    if (limitRight) {
      return Math.min(xScale(limitRight), x);
    }

    return x;
  };
  useEffect(() => {
    if (!isDrag && !axes.isNotReady && setOnDrag) {
      setOnDrag(xScale.invert(coord(xScale(x) + dx)));
      setDx(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrag]);
  if (axes.isNotReady) {
    return null;
  }
  const xScale = axes.axisX.scale;

  return (
    <g onMouseDownCapture={setOnDrag === undefined ? undefined : drag} style={{ cursor: setOnDrag === undefined ? "default" : "col-resize" }}>
      <line className={cn.line} x1={coord(xScale(x) + dx)} x2={coord(xScale(x) + dx)} y1={0} y2={axes.height}></line>
      <line className={cn.transparentLine} x1={coord(xScale(x) + dx)} x2={coord(xScale(x) + dx)} y1={0} y2={axes.height}></line>
    </g>
  );
});

export { BorderLine };
