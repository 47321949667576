import { LineDataModel } from "@okopok/axes_context";
import dayjs from "dayjs";
import { computed, makeObservable, when } from "mobx";

import { TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { Forecast } from "models/project/fact/forecast/forecast";
import { aggregateByDate } from "models/project/fact/production/aggregate";
import { sumUp } from "models/project/fact/production/aggregateFunctions";
import { colorCarousel } from "services/colorCarousel";

class MiningValueChartModel {
  lines: LineDataModel[] = [];
  tooltipManager: TooltipDataManager | null = null;
  constructor(private forecast: Forecast) {
    makeObservable(this, {
      isValid: computed,
      basedMining: computed,
    });
    when(
      () => forecast.operatingGTM.isLoading === false,
      () => {
        this.init();
      }
    );
  }

  private init() {
    const params = ["optimization", "rir", "grp", "perforation", "otherOPZ", "otherGTM", "physic"];

    const dataSets: { data: Map<number, number | null>; title: string; color: string }[] = [];

    const dataForTooltip: { data: Map<number, number | null>; title: string; color: string }[] = [];
    dataForTooltip.push({
      data: this.forecast.operatingGTM.basicMining,
      title: "Базисная добыча",
      color: colorCarousel(19),
    });
    this.forecast.operatingGTM.params.forEach((parentParam) => {
      if (params.includes(parentParam.dataKey)) {
        if (parentParam.dataKey === "otherGTM") {
          for (let [, param] of parentParam.children!.get("otherGTM-i")!.children!) {
            if (param.title === "Доп. добыча за год проведения ГТМ") {
              dataForTooltip.push({
                data: param.data!,
                title: parentParam.title,
                color: colorCarousel(dataForTooltip.length),
              });
            }
          }
        } else {
          if (parentParam.dataKey === "physic") {
            for (let [, param] of parentParam.children!) {
              if (param.title === "Доп. добыча за год проведения ФХ МУН") {
                dataForTooltip.push({
                  data: param.data!,
                  title: parentParam.title,
                  color: colorCarousel(dataForTooltip.length),
                });
              }
            }
          } else {
            for (let [, param] of parentParam.children!) {
              if (param.title === "Доп. добыча за год проведения ГТМ") {
                dataForTooltip.push({
                  data: param.data!,
                  title: parentParam.title,
                  color: colorCarousel(dataForTooltip.length),
                });
              }
            }
          }
        }
      }
    });

    dataSets.push({
      data: this.forecast.operatingGTM.basicMining,
      title: "Базисная добыча",
      color: dataForTooltip.find((data) => data.title === "Базисная добыча")!.color,
    });

    this.forecast.operatingGTM.params.forEach((parentParam) => {
      if (params.includes(parentParam.dataKey)) {
        if (parentParam.dataKey === "otherGTM") {
          for (let [, param] of parentParam.children!.get("otherGTM-i")!.children!) {
            if (param.title === "Доп. добыча за год проведения ГТМ") {
              const newValue = {
                data: new Map(dataSets.at(-1)!.data),
                title: parentParam.title,
                color: dataForTooltip.find((data) => data.title === parentParam.title)!.color,
              };

              for (let [year, value] of dataSets.at(-1)!.data) {
                if (value !== null && param.data?.get(year) != null) {
                  newValue.data.set(year, value + param.data?.get(year)!);
                }
              }
              dataSets.push(newValue);
            }
          }
        } else {
          if (parentParam.dataKey === "physic") {
            for (let [, param] of parentParam.children!) {
              if (param.title === "Доп. добыча за год проведения ФХ МУН") {
                dataSets.push({
                  data: new Map(dataSets.at(-1)!.data),
                  title: parentParam.title,
                  color: dataForTooltip.find((data) => data.title === parentParam.title)!.color,
                });
                for (let [year, value] of dataSets.at(-1)!.data) {
                  if (value !== null && param.data?.get(year) !== null) {
                    dataSets.at(-1)?.data.set(year, value + param.data?.get(year)!);
                  }
                }
              }
            }
          } else {
            for (let [, param] of parentParam.children!) {
              if (param.title === "Доп. добыча за год проведения ГТМ") {
                dataSets.push({
                  data: new Map(dataSets.at(-1)!.data),
                  title: parentParam.title,
                  color: dataForTooltip.find((data) => data.title === parentParam.title)!.color,
                });
                for (let [year, value] of dataSets.at(-1)!.data) {
                  if (value !== null && param.data?.get(year) !== null) {
                    dataSets.at(-1)?.data.set(year, value + param.data?.get(year)!);
                  }
                }
              }
            }
          }
        }
      }
    });

    dataSets.reverse().forEach((data, index) => {
      this.lines.push(
        new LineDataModel({
          y: Array.from(data.data.values()).slice(0, this.forecast.range.length),
          x: Array.from(data.data.keys())
            .map((year) => dayjs(`${year}`))
            .slice(0, this.forecast.range.length),
          axisKey: "y",
          title: data.title,
          key: `line-${index}`,
          color: data.color,
          shadow: true,
        })
      );
    });
    this.tooltipManager = new TooltipDataManager(
      dataForTooltip.map(
        (data, index) =>
          new LineDataModel({
            y: Array.from(data.data.values()).slice(0, this.forecast.range.length),
            x: Array.from(data.data.keys())
              .map((year) => dayjs(`${year}`))
              .slice(0, this.forecast.range.length),
            axisKey: "y",
            title: data.title,
            key: `line-${index}`,
            color: data.color,
            shadow: true,
          })
      )
    );
  }

  get basedMining() {
    const production = this.forecast?.production;
    const toAggregate = this.forecast.wells.allWells.map(({ id }) => production?.wholeWellData(id) ?? []);

    const sumByYear = aggregateByDate(sumUp, toAggregate);
    const result = Array.from(sumByYear, ([year, datum]) => ({ year: year.year(), value: datum.oil_prod })).filter(
      (record) => record.year >= this.forecast.range.from
    );
    return result;
  }

  get isValid() {
    return this.lines.length > 0;
  }
}

export { MiningValueChartModel };
