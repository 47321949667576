import type { WellData } from "services/back/production";

import { aggregateByDate, DateDataRow, DateDataSeries } from "./aggregate";
import { sumUp } from "./aggregateFunctions";
import { ProductionDatum } from "./production";
import { StratumData } from "./stratumData";

class ProductionDataPack {
  public readonly wellId: number;
  public readonly dataByStratums = new Map<number, StratumData[]>();

  public readonly isMining: boolean = false;
  public readonly isInjecting: boolean = false;

  constructor(wellData: WellData) {
    this.wellId = wellData.wellId;

    for (const stratumData of wellData.stratums) {
      const stratumId = stratumData.stratumId;
      if (stratumData.status === "prod") {
        this.isMining = true;
      }
      if (stratumData.status === "inj") {
        this.isInjecting = true;
      }
      const data = new StratumData(stratumData);
      this.dataByStratums.get(stratumId)?.push(data) || this.dataByStratums.set(stratumId, [data]);
    }
  }

  public *byMonth(): DateDataSeries<ProductionDatum> {
    const iterators = this.all.map((d) => d.byMonth());
    yield* aggregateByDate(sumUp, iterators);
  }

  public *byYear(): DateDataSeries<ProductionDatum> {
    const iterators = this.all.map((d) => d.byYear());
    yield* aggregateByDate(sumUp, iterators);
  }

  public get lastMonth(): DateDataRow<ProductionDatum> | null {
    if (this.dataByStratums.size === 0) {
      return null;
    }
    const [first, ...rest] = this.all.map((d) => d.lastMonth);
    let lastDate = first[0];
    let datums: ProductionDatum[] = [first[1]];
    for (const [date, datum] of rest) {
      if (date.isBefore(lastDate)) {
        continue;
      }
      if (date.isAfter(lastDate)) {
        lastDate = date;
        datums.length = 0;
      }
      datums.push(datum);
    }
    return [lastDate, sumUp(datums)];
  }

  public getForMonth(year: number, month: number): ProductionDatum | undefined {
    if (this.dataByStratums.size === 0) {
      return undefined;
    }
    const datums = this.all.map((d) => d.getForMonth(year, month)).filter((d): d is ProductionDatum => d !== undefined);
    return sumUp(datums);
  }

  public get all(): StratumData[] {
    return [...this.dataByStratums.values()].flat();
  }
}

export { ProductionDataPack };
