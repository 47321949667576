import { FC } from "react";
import { Input } from "antd";

import { Icon } from "elements/icon/icon";

import { ReactComponent as PlusIcon } from "../icons/plus.svg";

import { WellDataForTooltip } from "./wellsGroup";

import cn from "./wellTooltip.module.less";

type TooltipString = {
  title: string;
  key: keyof WellDataForTooltip;
};

type TooltipStringProps = {
  title: string;
  value: string;
};

const WellTooltip: FC<{ well: WellDataForTooltip }> = ({ well }) => {
  const wellPropsMap: TooltipString[] = [
    {
      title: "Актив",
      key: "title",
    },
    {
      title: "Месторождение",
      key: "deposit",
    },
    {
      title: "ЛУ",
      key: "licenseRegion",
    },
    {
      title: "Куст",
      key: "bush",
    },
    {
      title: "Тип скважины",
      key: "title",
    },
    {
      title: "Конструкция",
      key: "construction",
    },
    {
      title: "Дата ввода",
      key: "title",
    },
    {
      title: "Плановая остановка",
      key: "title",
    },
    {
      title: "НИЗ",
      key: "recoverableResources",
    },
    {
      title: "Процент отбора от НИЗ",
      key: "title",
    },
    {
      title: "Эксплуатируемый пласт",
      key: "stratum",
    },
  ];

  const TooltipString: FC<TooltipStringProps> = ({ title, value }) => {
    return (
      <div className={cn["string-container"]}>
        <div className={cn["string-title"]}>{title}</div>
        <div className={cn["string-value"]}>{value}</div>
      </div>
    );
  };

  return (
    <div className={cn.container}>
      <div className={cn["props-container"]}>
        {wellPropsMap.map(({ title, key }, index) => (
          <TooltipString key={index} title={title} value={(well[key] ?? "").toString()} />
        ))}
      </div>
      <div className={cn["tags-container"]}>
        <Input
          prefix={<Icon content={<PlusIcon />} viewBox="0 0 10 10" width="12px" height="12px" style={{ paddingRight: "4px" }} />}
          placeholder="Добавить тег"
        />
      </div>
    </div>
  );
};

export { WellTooltip };
