import { FC, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { message, Select, Tabs } from "antd";
import { NumberValue } from "d3";
import { observer } from "mobx-react-lite";
import { useMainRouterParams } from "routing/authorizedRouter";

import { Icon } from "elements/icon/icon";
import { Status, Step, Wizard } from "elements/wizard/wizard";
import { TechFactResultChart, TechFactResultChartProps } from "features/techFactResultChart/techFactResultChart";
import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { ProductionDataPack } from "models/project/fact/production/productionData";
import { getForecast, WellCharts } from "services/techParameters";

import { ReactComponent as CriteriaIcon } from "./icons/criteria.svg";
import { ReactComponent as RootIcon } from "./icons/root.svg";
import { ReactComponent as TimeCircleIcon } from "./icons/timeCircle.svg";
import { TechnologyResults } from "./results/results";
import { LoaderPage } from "./settings/loaderPage";
import { TechnologySettings } from "./settings/settings";
import { WellsList, WellTypes } from "./wellsList/wellsList";
import { emptyTechParameters, WellNodePart } from "./techForecastMock";
import { WellParameters, WellParametersForm, WellTechForecast } from "./types";
import { changeProp, convertWellNodes, filterOnlyNew, getTooltip, validateForm } from "./utils";

import cn from "./techForecast.module.less";
export type TechModalState = "settings" | "loading" | "results";

type TechForecastProps = {
  accept: () => void;
  reject: () => void;
  wells: WellNodePart[];
  setOnAccept: (callback: () => Promise<{ result: boolean; data?: WellTechForecast[] | null } | undefined>) => void;
};

const getFactChartData = (wellData: ProductionDataPack | undefined): TechFactResultChartProps => {
  const oilRate: [NumberValue, number | null][] = [];
  const liquidRate: [NumberValue, number | null][] = [];
  const waterCut: [NumberValue, number | null][] = [];
  for (const [date, { liquid_prod, oil_prod, prod_days }] of wellData?.byMonth()!) {
    if (oil_prod && prod_days) {
      oilRate.push([date, oil_prod / prod_days]);
    }
    if (liquid_prod && prod_days) {
      liquidRate.push([date, liquid_prod / prod_days]);
    }
    if (liquid_prod && oil_prod) {
      waterCut.push([date, (1 - oil_prod / liquid_prod) * 100]);
    }
  }

  return {
    oil: {
      axisTitle: (
        <g className={cn.axisTitle}>
          <text x={-20} className={cn.axisTitle}>
            q
          </text>
          <text x={-14} baselineShift="sub" className={cn.axisTitle} style={{ fontSize: "10px" }}>
            н
          </text>
          <text x={24} className={cn.axisTitle}>
            , т/сут
          </text>
        </g>
      ),
      curveFactData: { y: oilRate.map((pair) => pair[1]), x: oilRate.map((pair) => pair[0]) },
    },
    liquid: {
      axisTitle: (
        <g>
          <text x={-20} className={cn.axisTitle}>
            q
          </text>
          <text x={-12} baselineShift="sub" className={cn.axisTitle} style={{ fontSize: "10px" }}>
            ж
          </text>
          <text x={4} className={cn.axisTitle}>
            , м
          </text>
          <text x={9} baselineShift="super" className={cn.axisTitle} style={{ fontSize: "8px" }}>
            3
          </text>
          <text x={32} className={cn.axisTitle}>
            /сут
          </text>
        </g>
      ),
      curveFactData: { y: liquidRate.map((pair) => pair[1]), x: liquidRate.map((pair) => pair[0]) },
    },
    waterCut: {
      axisTitle: <g>Обводненность (обв.), %</g>,
      curveFactData: { y: waterCut.map((pair) => pair[1]), x: waterCut.map((pair) => pair[0]) },
    },
  } as const;
};

function wellSelector(
  baseWells: WellParametersForm[],
  newWells: WellParametersForm[],
  gtmWells: WellParametersForm[],
  wellsNameMap: Record<number, string>
) {
  return [
    {
      label: <span className={cn["select-group-title"]}>Базовый</span>,
      title: "base",
      options: baseWells.map((well) => {
        return {
          label: <span className={cn["select-system-title"]}>{wellsNameMap[well.wellId]}</span>,
          value: well.wellId,
        };
      }),
    },
    ...(newWells.length > 0
      ? [
          {
            label: <span className={cn["select-group-title"]}>Новый</span>,
            title: "new",
            options: newWells.map((well) => {
              return {
                label: <span>{wellsNameMap[well.wellId]}</span>,
                value: well.wellId,
              };
            }),
          },
        ]
      : []),
    ...(gtmWells.length > 0
      ? [
          {
            label: <span className={cn["select-group-title"]}>ГТМ</span>,
            title: "gtm",
            options: gtmWells.map((well) => {
              return {
                label: <span>{wellsNameMap[well.wellId]}</span>,
                value: well.wellId,
              };
            }),
          },
        ]
      : []),
  ];
}

const TechForecast: FC<TechForecastProps> = observer(({ accept, setOnAccept, reject, wells }) => {
  const scenarioId = useMainRouterParams().scenario!;
  const notCalculatedWells = wells.filter(({ techParameters }) => techParameters === null).map(({ well: { id }, gtmId }) => ({ wellId: id, gtmId }));
  const [wellIdSelected, setWellIdSelected] = useState<number>();
  const onWellIdSelect = (id: number) => {
    setWellIdSelected(id);
  };
  const wellNodesMap = convertWellNodes(wells);
  const wellsNameMap: Record<number, string> = {};
  const fact = useFact()!;

  wells.forEach(({ well: { id, title }, wellInfo: { construction } }) => {
    wellsNameMap[id] = `${title}/${construction}`;
  });
  const getTechParameters = (wellType: WellTypes) => {
    const wellNodes = wellNodesMap[wellType];
    if (wellNodes.length === 0) {
      return [];
    }

    return wellNodes.map(({ techParameters, well: { id, stratumId }, gtmId }) => techParameters ?? emptyTechParameters(id, stratumId, gtmId));
  };

  const getApplyForAll = (wellType: WellTypes) => (path: string[], value: any) =>
    setParametersMap[wellType](techParametersMap[wellType].map((techParameters) => changeProp(techParameters, path, value)));
  const getApplyForId = (wellType: WellTypes) => (path: string[], value: any, id: number, gtmId?: number | null) =>
    setParametersMap[wellType](techParametersMap[wellType].map((techParameters) => changeProp(techParameters, path, value, id, gtmId)));

  const [techModalState, setTechModalState] = useState<TechModalState>("settings");

  const [baseTechParameters, setBaseTechParameters] = useState<WellParametersForm[]>(getTechParameters("base"));
  const [newTechParameters, setNewTechParameters] = useState<WellParametersForm[]>(getTechParameters("new"));
  const [gtmTechParameters, setGtmTechParameters] = useState<WellParametersForm[]>(getTechParameters("gtm"));

  const [chosenType, setChosenType] = useState<WellTypes>(wellNodesMap["base"].length ? "base" : wellNodesMap["new"].length ? "new" : "gtm");

  const [techForecast, setTechForecast] = useState<WellCharts[] | null>(null);
  const [currentWell, setCurrentWell] = useState<number>(0);
  const [notCalculatedOnly, setNotCalculatedOnly] = useState<boolean>(false);

  const [allTechParameters, setAllTechParameters] = useState<WellParameters[]>([]);

  const setParametersMap: Record<WellTypes, (wellParameters: WellParameters[]) => void> = {
    base: setBaseTechParameters,
    new: setNewTechParameters,
    gtm: setGtmTechParameters,
  };

  const techParametersMap: Record<WellTypes, WellParametersForm[]> = {
    base: baseTechParameters,
    new: newTechParameters,
    gtm: gtmTechParameters,
  };

  const onFinish = async () => {
    return {
      result: true,
      data: allTechParameters
        .map((s, index) => ({ ...s, stratumId: wells[index].wellInfo.stratumId }))
        .map((techParameters) => ({
          techParameters,
          forecast: techForecast?.find(
            ({ wellId, gtmId }) => (techParameters.gtmId === gtmId || (gtmId === null && !techParameters.gtmId)) && techParameters.wellId === wellId
          ),
        })),
    };
  };

  setOnAccept(onFinish);
  const [messageApi, contextHolder] = message.useMessage();

  const [messageText, scheduleMessage] = useState<string | null>(null);

  useEffect(() => {
    if (messageText) {
      try {
        const json = JSON.parse(messageText);
        messageApi.info(<ReactJson name="Тело ответа" quotesOnKeys={false} collapsed displayDataTypes={false} src={json} />);
      } catch {
        messageApi.info(messageText);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageText]);

  const getConfirmClick =
    (onlyNew = false) =>
    async () => {
      const allTechParameters: WellParameters[] = [...baseTechParameters, ...newTechParameters, ...gtmTechParameters] as WellParameters[];
      if (onlyNew) {
        allTechParameters.filter(({ wellId, gtmId }) => notCalculatedWells.find(({ wellId: wId, gtmId: gId }) => wellId === wId && gtmId === gId));
      }
      if (allTechParameters.length === 0) {
        return;
      }
      setTechModalState("loading");
      try {
        const forecast = await getForecast(
          allTechParameters.map((s, index) => ({ ...s, stratumId: wells[index].wellInfo.stratumId })),
          scenarioId
        );
        setNotCalculatedOnly(onlyNew);
        setAllTechParameters(allTechParameters);
        setTechModalState("results");
        setTechForecast(forecast.map((forecast) => ({ ...forecast, forecast: { ...forecast.forecast, oil_prod: [], liquid_prod: [] } })));
      } catch (err: any) {
        setTechModalState("settings");
        if (global.IS_DEBUG_ZONE) {
          const json = await err?.json?.();
          scheduleMessage(JSON.stringify(json));
        } else {
          scheduleMessage("При попытке выполнить расчет произошла ошибка. При подготовке параметров расчета допущена ошибка");
        }
        console.error(err);
      }
    };

  const showConfirm = wells.length !== 1 && wells.length !== notCalculatedWells.length;

  const steps: Step[] = [
    {
      title: "Настройки расчета",
      page: (
        <Tabs
          className={cn.tabs}
          items={[
            {
              key: "1",
              label: "Настройки",
              children: (
                <>
                  {contextHolder}
                  <TechnologySettings
                    setTechParameters={{
                      applyForAll: getApplyForAll("base"),
                      applyForId: getApplyForId("base"),
                    }}
                    techParameters={techParametersMap["base"]}
                    chosenType={chosenType}
                    type="base"
                    wellsNameMap={wellsNameMap}
                  />
                  <TechnologySettings
                    setTechParameters={{
                      applyForAll: getApplyForAll("new"),
                      applyForId: getApplyForId("new"),
                    }}
                    techParameters={techParametersMap["new"]}
                    chosenType={chosenType}
                    type="new"
                    wellsNameMap={wellsNameMap}
                  />
                  <TechnologySettings
                    setTechParameters={{
                      applyForAll: getApplyForAll("gtm"),
                      applyForId: getApplyForId("gtm"),
                    }}
                    techParameters={techParametersMap["gtm"]}
                    chosenType={chosenType}
                    type="gtm"
                    wellsNameMap={wellsNameMap}
                  />
                </>
              ),
            },
            {
              key: "2",
              label: (
                <div className={cn.tabTitle}>
                  График{" "}
                  <Select
                    variant="filled"
                    className={cn.select}
                    value={wellIdSelected}
                    onChange={onWellIdSelect}
                    options={wellSelector(baseTechParameters, newTechParameters, gtmTechParameters, wellsNameMap)}
                  />
                </div>
              ),
              children: (
                <div className={cn.chart}>
                  {wellIdSelected !== undefined ? (
                    <TechFactResultChart {...getFactChartData(fact?.production.wellData(wellIdSelected))} />
                  ) : (
                    <>{`Выберите скважину`}</>
                  )}
                </div>
              ),
            },
          ]}
        />
      ),
      icon: (className: string = "") => <Icon className={className} content={<CriteriaIcon />} viewBox="0 0 14 16" />,
      footerButtons: [
        {
          title: "Подтвердить настройки",
          onClick: showConfirm ? () => {} : getConfirmClick(),
          disabled: !!validateForm([
            ...baseTechParameters.map((techP) => ({ ...techP, wellType: "base" })),
            ...newTechParameters,
            ...gtmTechParameters,
          ]),
          tooltip: getTooltip(chosenType, techParametersMap),
          confirm:
            techModalState === "settings" && showConfirm
              ? {
                  placement: "topRight",
                  title: (
                    <>
                      Среди выбранных скважин/ГТМ часть уже рассчитана. <br />
                      Выберите, для каких именно производить расчет.
                    </>
                  ),
                  okText: `Нерасчитанные (${notCalculatedWells.length})`,
                  okButtonProps: {
                    disabled: notCalculatedWells.length === 0,
                  },
                  cancelText: `Рассчитать все (${wells.length})`,
                  onCancel: getConfirmClick(),
                  onConfirm: getConfirmClick(true),
                }
              : undefined,
        },
      ],
    },
    {
      title: "Расчет",
      page: <LoaderPage />,
      icon: (className: string = "") => <Icon className={className} content={<RootIcon />} viewBox="0 0 14 12" />,
      footerButtons: [
        {
          title: "Подтвердить настройки",
          onClick: () => {},
          disabled: true,
          confirm: undefined,
        },
      ],
    },
    {
      title: "Просмотр результатов",
      page: techForecast ? (
        <TechnologyResults
          current={currentWell}
          onCurrentChange={setCurrentWell}
          techForecast={techForecast}
          setTechForecast={setTechForecast}
          disabledCoefficients={allTechParameters[0].oilDropRate.calculationMethod !== "dropCurves"}
          disableVerticalLines={!["dropCurves", "displacementCharacteristics"].includes(allTechParameters[0].oilDropRate.calculationMethod)}
        />
      ) : (
        "Прогнозные данные не получены"
      ),
      icon: (className: string = "") => <Icon className={className} content={<TimeCircleIcon />} viewBox="0 0 16 16" />,
      footerButtons: [
        {
          title: "Применить",
          onClick: accept,
        },
        {
          title: "Вернуться к настройкам",
          onClick: () => {
            setTechModalState("settings");
          },
          type: "default" as "default",
        },
      ],
    },
  ];

  const stepMap = {
    settings: { number: 0, status: Status.Finish },
    loading: { number: 1, status: Status.Loading },
    results: { number: 2, status: Status.Finish },
  };

  return (
    <Wizard
      steps={steps}
      reject={reject}
      sidebar={
        <WellsList
          forecastState={techModalState}
          wells={notCalculatedOnly ? filterOnlyNew(wellNodesMap, notCalculatedWells) : wellNodesMap}
          chosenType={chosenType}
          setChosenType={setChosenType}
          result={techForecast}
          current={{
            wellId: techForecast ? techForecast[currentWell].wellId : 0,
            gtmId: techForecast ? techForecast[currentWell].gtmId : 0,
          }}
          onCurrentChange={(id, gId) => {
            if (!techForecast) {
              return;
            }
            const gtmId = gId || null;

            const wellIndex = techForecast.findIndex((forecast) => forecast.wellId === id && forecast.gtmId === gtmId)!;
            setCurrentWell(wellIndex);
          }}
        />
      }
      step={stepMap[techModalState]}
      pageWidth="938px"
    />
  );
});

export { TechForecast };
