import { FC, memo, ReactNode, useMemo } from "react";
import FullScreen from "react-fullscreen-crossbrowser";
import { ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import * as d3 from "d3";
import dayJS from "dayjs";

import { Loader } from "elements/loader";
import { ModeSelectorModel } from "elements/modeSelector/modeSelectorModel";
import { GetOnChangeType } from "features/forecast/technologyForecastModal/results/results";
import TooltipDiv from "features/plot/Tooltip/TooltipDiv";
import { Domain } from "utils/boundDomain";
import { useBooleanState } from "utils/useBooleanState";

import { ChartInput } from "./chartInput/chartInput";
import { LegendContentState } from "./LegendContent/LegendContentState";
import { TechForecastChartControls } from "./techForecastChartControls/techForecastChartControls";
import { TechForecastChartModel } from "./techForecastChartModel";
import { TechForecastResultChartFeature } from "./techForecastResultChartFeature";

import cn from "./techForecastResultChart.module.less";

type TechForecastResultAxisInfo = {
  axisTitle: ReactNode;
  curveFactData?: ChartData | undefined;
  curveFactForecastData?: ChartData | undefined;
  curveForecastData?: ChartData;
};

type ChartData = {
  y: Array<d3.NumberValue>;
  x: Array<d3.NumberValue> | Domain;
};

type TechForecastResultChartProps = {
  oil: TechForecastResultAxisInfo;
  liquid: TechForecastResultAxisInfo;
  rho: number;
  start: d3.NumberValue;
  end: d3.NumberValue;
  onChangeStart?: React.Dispatch<React.SetStateAction<dayJS.Dayjs>>;
  onChangeEnd?: React.Dispatch<React.SetStateAction<dayJS.Dayjs>>;
  onChangeA?: (value: number | undefined) => void;
  onChangeK?: (value: number | undefined) => void;
  a?: number;
  k?: number;
  getOnChangeCurves?: GetOnChangeType;
  disabledCoefficients?: boolean;
  disableVerticalLines?: boolean;
};

const TechForecastResultChart: FC<TechForecastResultChartProps> = memo(
  ({
    liquid,
    oil,
    rho,
    start,
    end,
    onChangeStart,
    onChangeEnd,
    a,
    k,
    disabledCoefficients = false,
    onChangeA,
    onChangeK,
    disableVerticalLines = false,
    getOnChangeCurves,
  }) => {
    const legendContent = useMemo(() => new LegendContentState(), []);
    const [isFullscreen, openFullscreen, closeFullscreen, switchFullscreen] = useBooleanState();
    const modeModel = useMemo(() => new ModeSelectorModel(), []);
    const techForecastModel = useMemo(
      () => new TechForecastChartModel(oil, liquid, rho, modeModel, getOnChangeCurves),
      [getOnChangeCurves, liquid, modeModel, oil, rho]
    );

    const changeStart = useMemo(
      () => (value: number) => {
        onChangeStart?.(dayJS(Math.round(value)));
      },
      [onChangeStart]
    );

    const changeEnd = useMemo(
      () => (value: number) => {
        onChangeEnd?.(dayJS(Math.round(value)));
      },
      [onChangeEnd]
    );

    return (
      <div className={cn.layout}>
        <FullScreen enabled={isFullscreen} onClose={closeFullscreen} onOpen={openFullscreen}>
          <div className={cn.titleContainer}>
            <div className={cn.header}>
              <Typography.Title level={4} className={cn.title}>
                Прогноз технологических показателей
              </Typography.Title>
              <TechForecastChartControls legendContent={legendContent} modeModel={modeModel} />

              {(a === undefined || k === undefined) && <Loader />}
            </div>
            <div className={cn.buttonsGroup}>
              {!disabledCoefficients && (
                <>
                  <ChartInput label="a" onChange={onChangeA} value={a} />
                  <ChartInput label="k" onChange={onChangeK} value={k} />
                </>
              )}
              <Button onClick={switchFullscreen} icon={isFullscreen ? <ShrinkOutlined /> : <ArrowsAltOutlined />} />
            </div>
          </div>
          <div className={cn.wrap}>
            <TechForecastResultChartFeature
              curveFactForecastLiquid={techForecastModel.curveFactForecastLiquid}
              curveFactForecastOil={techForecastModel.curveFactForecastOil}
              curveFactForecastWatering={techForecastModel.curveFactForecastWatering}
              curveFactLiquid={techForecastModel.curveFactLiquid}
              curveFactOil={techForecastModel.curveFactOil}
              curveFactWatering={techForecastModel.curveFactWatering}
              curveForecastLiquid={techForecastModel.curveForecastLiquid}
              curveForecastOil={techForecastModel.curveForecastOil}
              curveForecastWatering={techForecastModel.curveForecastWatering}
              connectOilCurve={techForecastModel.connectOilCurve}
              connectLiquidCurve={techForecastModel.connectLiquidCurve}
              connectWateringCurve={techForecastModel.connectWateringCurve}
              start={start}
              end={end}
              changeStart={changeStart}
              changeEnd={changeEnd}
              tooltipManager={techForecastModel.tooltipManager}
              oilTitle={oil.axisTitle}
              liquidTitle={liquid.axisTitle}
              wateringTitle="Обводненность (об.), %"
              legendContent={legendContent}
              disableVerticalLines={disableVerticalLines}
            />
            <TooltipDiv manager={techForecastModel.tooltipManager} />
          </div>
        </FullScreen>
      </div>
    );
  }
);

export type { ChartData, TechForecastResultAxisInfo, TechForecastResultChartProps };
export { TechForecastResultChart };
