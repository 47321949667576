import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { Dayjs } from "dayjs";
import { action, computed, makeObservable, observable } from "mobx";

import { Infrastructure } from "models/project/fact/infrastructure/infrastructure";
import { InfrastructureCatalog } from "models/project/fact/infrastructure/infrastructureCatalog";
import { Nodes, NodeType } from "models/project/fact/infrastructure/nodes";
import { PipeType } from "models/project/fact/infrastructure/pipes";
import { StationType } from "services/back/infrastructure/catalog";

import { findMatch } from "../../../utils";

type DRow = {
  title: string;
  stationType?: string;
  kind: [id: number | null, title?: string];
  category?: string;
  power?: number;
  deltaPressure?: number | null;
  startedAt: Dayjs;
  finishedAt?: Dayjs | null;
  relatedPipes?: string[];
  coords?: { x: number; y: number };
  altitude?: number;
  cost?: number;
  costReconstruction?: number;
  isReconstruction?: boolean;
  remove?: () => void;
};

type StationLocalType = { kind: [id: number | null, title?: string]; isReconstruction: boolean } & NodeType;

class Station extends TableNode<DRow> {
  asDRow = (): DRow => ({
    ...this.data,
    category: this.parent.name,
    relatedPipes: this.relatedPipes.map((el) => el.title),
    coords: this.coords,
    cost: this.cost,
    costReconstruction: this.costReconstruction,

    remove: this.remove,
  });
  public data: StationLocalType;
  constructor(private parent: Stations, data: NodeType, private infrastructure: Infrastructure) {
    super(parent);

    this.data = { ...data, kind: this.findKind(data), isReconstruction: !!data.reconstruction?.isActive };
    makeObservable(this, {
      coords: computed,
      relatedPipes: computed,
      data: observable,
      updateValue: action,
    });
  }

  get coords() {
    const { x, y } = this.data;
    return { x, y };
  }

  get relatedPipes() {
    const pipes: PipeType[] = [];
    for (const pipe of this.infrastructure.pipes.data || []) {
      if (pipe.from === this.data.uuid || pipe.to === this.data.uuid) {
        pipes.push(pipe);
      }
    }
    return pipes;
  }

  get cost(): number {
    return this.data.construction?.totalCostPerUnit || 0;
  }
  get costReconstruction(): number {
    return this.data.reconstruction?.totalCostPerUnit || 0;
  }

  findKind(data: NodeType): [id: number | null, title?: string | undefined] {
    const { stations } = this.parent.catalog;
    if (!stations || !data) {
      return [null, undefined];
    }
    let stationAttributes = Nodes.getStationAttributes(data);
    if (data.stationType === "НС") {
      stationAttributes = { ...stationAttributes, deltaPressure: data.deltaPressure };
    }
    const matchingStation = findMatch<StationType>(stationAttributes, stations);
    return matchingStation ? [matchingStation.id, undefined] : [null, undefined];
  }

  updateValue(key: string, newValue: any): [prevValue: any, currValue: any] {
    const [k, value] = [key as keyof StationLocalType, newValue as never];
    const { stationsSelector } = this.parent.catalog;
    const updateData = (data: any) => this.infrastructure.nodes.update({ ...this.data, ...data });
    if (key === "isReconstruction") {
      value !== null && updateData({ reconstruction: { ...this.data.reconstruction!, isActive: value } });
    }
    if ((key === "startedAt" || key === "title" || key === "altitude") && value) {
      updateData({ [key]: value });
    }
    if (key === "finishedAt") {
      updateData({ [key]: value });
    }
    if (
      key === "isReconstruction" ||
      key === "startedAt" ||
      key === "finishedAt" ||
      key === "title" ||
      key === "altitude"
    ) {
      const prev = this.data[k];
      this.data[k] = value;
      return [prev, value];
    }
    if (key === "kind") {
      const attributes = Nodes.getStationAttributes(stationsSelector?.at(value[0]) ?? undefined);
      value[0] && updateData({ ...attributes });
      this.data = { ...this.data, [k]: value, ...attributes };
      return [stationsSelector?.at(value[0])?.id, undefined];
    }
    return [undefined, undefined];
  }

  public remove = () => {
    if (this.index === undefined) {
      console.error("attempt to remove infrastructure node without id");
      return;
    }
    this.infrastructure.nodes.remove([this.data.uuid]);
    this.parent.childrenStore?.splice(this.index, 1);
  };
}

class Stations extends TableNode<DRow, Station> {
  asDRow = () =>
    ({
      title: this.name,
    } as DRow);
  constructor(
    private parent: StationsModel,
    public name: string,
    private stations: NodeType[],
    private infrastructure: Infrastructure,
    public catalog: InfrastructureCatalog
  ) {
    super(parent, { isExpandedChildren: true });

    this.initChildren();
  }

  initChildren() {
    this.childrenStore = new ChildrenStoreArray(
      this,
      this.stations.map((data) => new Station(this, data, this.infrastructure))
    );
  }
}

class StationsModel extends TableNode<DRow, Stations> {
  constructor(public infrastructure: Infrastructure) {
    super();
    this.initChildren();
  }

  private initChildren() {
    const nodes = this.infrastructure.nodes.data || [];
    const stations = nodes.filter((el) => el.type === "pumping");
    this.childrenStore = new ChildrenStoreArray(this, [
      new Stations(this, "Новый объект", stations, this.infrastructure, this.infrastructure.catalog),
    ]);
  }
}

export { type DRow, Stations, StationsModel };
