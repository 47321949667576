import { FC } from "react";
import { observer } from "mobx-react";

import { useForecast } from "models/project/fact/forecast/forecast";
import { Well } from "models/project/fact/well/well";
import { TreeRoot } from "models/tree/tree";

import { Chart } from "./chart";
import { Table } from "./table";
import { aggregate } from "./utils";

const View: FC<{ tree: TreeRoot<Well>; className?: string }> = observer(({ tree, className }) => {
  const forecast = useForecast()!;
  const { years, data } = aggregate(
    tree.selectedItems.map((item) => item.item!),
    forecast.production
  );
  return data.length ? (
    <div className={className}>
      <Chart years={years} data={data} />
      <Table years={years} data={data} />
    </div>
  ) : null;
});

export { View };
