import { Button } from "antd";
import { observer } from "mobx-react-lite";

import { CompleteIcon } from "elements/icons/complete/complete";
import { global } from "models/global";

const FactYearsToggler = observer(() => (
  <Button onClick={global.togglePickingFactYears}>
    <CompleteIcon gray completed={global.isPickingFactYears} />
    Отображать фактические данные в режиме прогноза
  </Button>
));

export { FactYearsToggler };
