import { useMemo } from "react";
import { ColumnRaw, TableModel } from "@okopok/components/Table";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import dayjs from "dayjs";

import { Format } from "elements/format/format";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";

import { DrainSourcesModel, DRowDrainSource } from "./tableManager/drainSourse";

import cn from "../infrastructureTable.module.less";

const useColumns = (type: string): ColumnRaw<DRowDrainSource>[] => {
  return useMemo(
    (): ColumnRaw<DRowDrainSource>[] => [
      {
        title: "№",
        width: { min: 40, max: 80, competitiveness: 1 },
        key: "index",
        isSticky: true,
        onCell: () => ({ className: cn.tableCell }),
        render: (_, pipe) => pipe.absoluteIndex,
      },
      {
        key: "expand",
        title: null,
        isSticky: true,
        width: { min: 30, max: 30, competitiveness: 1 },
        render: (_, { expand }) => (expand?.status !== undefined ? <ExpandButton expand={expand} /> : <></>),
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: type === "drain" ? "Название стока" : "Название источника",
        width: { min: 410, max: 500, competitiveness: 1 },
        isSticky: true,
        dataKey: "title",
        onCell: () => ({ className: cn.tableCell }),
      },
      {
        title: "Дата",
        width: { min: 430, max: 500, competitiveness: 1 },
        isSticky: true,
        dataKey: "date",
        onCell: () => ({ className: cn.tableCell }),
        render: (value, { expand }) => {
          if (expand?.status !== undefined) {
            return <></>;
          }
          return dayjs(value).format("MM.YYYY");
        },
      },
      {
        title: type === "drain" ? "Давление приема, бар" : "Давление нагнетания, бар",
        width: { min: 430, max: 500, competitiveness: 1 },
        dataKey: "pressure",
        onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        onCell: () => ({ className: cn.tableCellAlignRight }),
        render: (value, { expand }) => {
          if (expand?.status !== undefined) {
            return <></>;
          }
          return <Format>{value}</Format>;
        },
      },
    ],
    [type]
  );
};

const useDrainSoucesTableModel = (type: string) => {
  const infrastructure = useInfrastructure()!;
  const { isCalculation } = infrastructure.calculateStore;

  const columns = useColumns(type); // eslint-disable-next-line react-hooks/exhaustive-deps
  const store = useMemo(() => new DrainSourcesModel(infrastructure, type as "drain"), [infrastructure, type, isCalculation]);

  const model = useMemo(
    () =>
      new TableModel(columns, store, {
        onRow: ({ indexPath, expand }) => ({
          className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? `${cn.tableRowPrimary}` : cn.tableRowPlain,
        }),
      }),
    [columns, store]
  );

  return { model, store };
};

export { useDrainSoucesTableModel };
