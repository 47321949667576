import { ChildrenStoreArray, TableNode } from "@okopok/components/Table";
import { computed, makeObservable, runInAction, when } from "mobx";

import { byPairs, zip } from "utils/itertools";
import { Range } from "utils/range";

import { Production, ProductionDatum } from "../../production/production";
import { Well } from "../well";
import { Wells } from "../wells";

import { debitDiff, debitMean, TechDummyRow, wellPerformance } from "./helpers";
import { DRow, TechWells } from "./tech";

class MiningWell extends TableNode<DRow, TechDummyRow> {
  public asDRow = (): DRow => ({
    name: this.well.title,
    measure: null,
  });

  public get years(): number[] {
    return this.data.map(([year]) => year);
  }

  public data: [year: number, datum: ProductionDatum][];

  constructor(
    private parent: MiningWellsNode,
    public readonly well: Well,
    public readonly productionStore: Production
  ) {
    super(parent, { selectable: false, mutable: false, isExpandedChildren: true });

    makeObservable<MiningWell>(this, {
      isMining: computed,
      debitMean: computed,
      debitDiff: computed,
      wellPerformance: computed,
    });

    let data;
    if (this.productionStore.forecast === null) {
      data = [...(this.productionStore.wellData(this.well.id)?.byYear() ?? [])];
    } else {
      data = [...this.productionStore.wholeWellData(this.well.id)];
    }
    this.data = data.map(([date, datum]) => [date.year(), datum]);

    runInAction(() => {
      this.childrenStore = new ChildrenStoreArray(this, [
        new TechDummyRow(this, this.years, "Состояние скважины", null, () =>
          this.isMining.map((v) => (v ? "Работает" : ("Не работает" as any)))
        ),
        new TechDummyRow(this, this.years, "Число дней работы скважины", [4, 1], () => this.miningDays),
        new TechDummyRow(this, this.years, "Добыча нефти", [5, 4], () => this.oilDebit),
        new TechDummyRow(this, this.years, "Добыча жидкости", [5, 4], () => this.liquidDebit),
        new TechDummyRow(this, this.years, "Нефтяной газ", [7, 4], () => this.apgProd),
        new TechDummyRow(this, this.years, "Среднесуточный дебит нефти скважины", [6, 1], () => this.debitMean),
        new TechDummyRow(this, this.years, "Процент изменения добычи нефти", [3, 2], () => this.debitDiff),
        new TechDummyRow(this, this.years, "Коэффициент эксплуатации скважины", [3, 1], () => this.wellPerformance),
      ]);
    });
  }

  public get range(): Range {
    return this.parent.range;
  }

  public get miningDays() {
    return this.data.map(([, e]) => e.prod_days);
  }

  public get oilDebit() {
    return this.data.map(([, e]) => e.oil_prod);
  }

  public get liquidDebit() {
    return this.data.map(([, e]) => e.liquid_prod_t);
  }

  public get apgProd() {
    return this.data.map(([, e]) => e.apg_prod);
  }

  get isMining(): boolean[] {
    return this.miningDays.map((days) => days !== null && isFinite(days) && days > 0);
  }

  get debitMean(): Array<number | null> {
    return Array.from(zip(this.oilDebit, this.miningDays), (pair) => debitMean(...pair));
  }

  get debitDiff(): Array<number | null> {
    const result = Array.from(byPairs([...zip(this.oilDebit, this.miningDays)]), (pair) => debitDiff(...pair));
    return [null, ...result.slice(0, -1)];
  }

  get wellPerformance(): Array<number | null> {
    return this.miningDays.map(wellPerformance);
  }
}

class MiningWellsNode extends TableNode<DRow, MiningWell> {
  public asDRow = (): DRow => ({
    name: "Данные по добывающим скважинам",
    measure: null,
  });

  constructor(src: Wells, private parent: TechWells) {
    super(parent, { selectable: false, mutable: false });
    when(
      () => src.miningWells !== undefined,
      () =>
        runInAction(() => {
          this.childrenStore = new ChildrenStoreArray(
            this,
            src.miningWells!.map((well) => new MiningWell(this, well, this.parent.source.production))
          );
          this.parent.childrenStore?.onSplice(this.index!, this.childrenStore.fullLength);
        })
    );
  }

  public get range(): Range {
    return this.parent.range;
  }
}

export { MiningWell, MiningWellsNode, debitMean as oilDebetMean };
