import { FC, ReactNode } from "react";

import { CustomOptionsSelect } from "elements/inputs/customOptionSelect/customOptionSelect";
import { conditionallyArr } from "utils/conditionally";

import { DropRateCalculationMethods } from "../types";

import { DisplacementCharacteristics } from "./calculationsMethod/displacementCharacteristics";
import { DropCurves } from "./calculationsMethod/dropCurves";
import { OilDebitDropRate } from "./calculationsMethod/oilDebitDropRate";
import { WellsAnalog } from "./calculationsMethod/wellsAnalog";
import { SettingContainer } from "./settingContainer";
import { TechnologySettingsProps } from "./settings";

import cn from "./dropRate.module.less";

type DropRateSelectOption = {
  value: DropRateCalculationMethods;
  label: string;
  disabled?: boolean;
};

type DropRateChildProps = TechnologySettingsProps & {
  propId: DropRatePropsId;
};

type CalculationsMethod = {
  option: DropRateSelectOption;
  element: FC<DropRateChildProps>;
};

type DropRatePropsId = "liquidDropRate" | "oilDropRate";

type DropRateProps = TechnologySettingsProps & {
  propId: DropRatePropsId;
  title: string;
  icon: ReactNode;
};

const DropRate: FC<DropRateProps> = ({ propId, techParameters, setTechParameters, title, icon, chosenType, wellsNameMap }) => {
  const handleChange = (value: string) => {
    const option = calculationsMethod.find((calcMethod) => calcMethod.option.value === value);
    const optionValue = option?.option.value;

    setTechParameters.applyForAll([propId, "calculationMethod"], optionValue);
  };

  const displacementCharacteristicsStatus =
    techParameters[0][propId === "liquidDropRate" ? "oilDropRate" : "liquidDropRate"].calculationMethod === "displacementCharacteristics";

  const calculationsMethod: CalculationsMethod[] = [
    ...conditionallyArr(propId === "oilDropRate", {
      option: {
        value: "wellsAnalog",
        label: "Скважины-аналоги",
      },
      element: WellsAnalog,
    } as const),
    {
      option: {
        value: "dropRatio",
        label: `Коэффициент падения дебита ${propId === "oilDropRate" ? "нефти" : "жидкости"}`,
      },
      element: OilDebitDropRate,
    },
    {
      option: {
        value: "displacementCharacteristics",
        label: "Характеристики вытеснения",
        disabled: displacementCharacteristicsStatus,
      },
      element: DisplacementCharacteristics,
    },
    ...conditionallyArr(propId === "oilDropRate", {
      option: {
        value: "dropCurves",
        label: "Кривые падения",
      },
      element: DropCurves,
    } as const),
  ];

  const calculationMethod = calculationsMethod.find((method) => method.option.value === techParameters[0][propId].calculationMethod)!;

  const MethodElement = calculationMethod.element;

  const getOptions = () => calculationsMethod.map((calcMethod) => calcMethod.option);

  return (
    <SettingContainer
      title={title}
      icon={icon}
      header={
        <div className={cn["calculation-way"]}>
          <div className={cn["calculation-title"]}>Способы расчета</div>
          <CustomOptionsSelect
            className={cn["calculation-select"]}
            onChange={handleChange}
            options={getOptions()}
            value={calculationMethod?.option.value}
            optionclass={cn["select-option"]}
          />
        </div>
      }
    >
      <MethodElement
        propId={propId}
        techParameters={techParameters}
        setTechParameters={setTechParameters}
        chosenType={chosenType}
        wellsNameMap={wellsNameMap}
      />
    </SettingContainer>
  );
};

export { type CalculationsMethod, DropRate, type DropRateChildProps, type DropRatePropsId, type DropRateSelectOption };
