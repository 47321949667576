import { useDrainSoucesTableModel } from "../useDrainSoucesTableModel";
import { useMinesTableModel } from "../useMinesTableModel";
import { usePipesTableModel } from "../usePipesTableModel";

const useDinamicTableManager = (mode: string) => {
  const { model: oilModel, store: oilStore } = usePipesTableModel("prod");
  const { model: waterModel, store: waterStore } = usePipesTableModel("inj");
  const { model: minesModel, store: minesStore } = useMinesTableModel();
  const { model: drainsModel, store: drainsStore } = useDrainSoucesTableModel("drain");
  const { model: sourcesModel, store: sourcesStore } = useDrainSoucesTableModel("source");

  if (mode === "prod") {
    return { model: oilModel, store: oilStore };
  }
  if (mode === "mines") {
    return { model: minesModel, store: minesStore };
  }
  if (mode === "drain") {
    return { model: drainsModel, store: drainsStore };
  }
  if (mode === "source") {
    return { model: sourcesModel, store: sourcesStore };
  }
  return { model: waterModel, store: waterStore };
};

export { useDinamicTableManager };
