import { FC } from "react";
import { Select, SelectProps } from "antd";
import { observer } from "mobx-react";

import { useTreeContext } from "models/tree/context";

import cn from "./nestingSelector.module.less";

const NestingSelector: FC = observer(() => {
  const treeContext = useTreeContext();
  const nestingOptions: SelectProps["options"] = treeContext.nestingFields.map(({ key, title }) => ({ value: key, label: title }));

  return (
    <Select
      value={treeContext.selectValue}
      className={cn.selector}
      mode="multiple"
      maxTagCount={1}
      optionFilterProp="label"
      placeholder="Please select"
      onChange={treeContext.onChange}
      options={nestingOptions}
    />
  );
});

export { NestingSelector };
