import { FC, useMemo } from "react";
import { Divider } from "antd";
import { observer } from "mobx-react";

import { Format } from "elements/format/format";
import { useInfrastructure } from "features/infrastructure/useInfrastructure";

import { useInfrastructureMapContext } from "../../InfrastructureMapManager/InfrastructureMapManager";
import { Container } from "../ui/container";
import { FieldParams } from "../ui/fieldParams";
import { Label } from "../ui/label";
import { ParamsTabs } from "../ui/paramsTabs/paramsTabs";

import cn from "./generalInspector.module.less";

function sumParams(data: any[]): EconomicParamsType {
  return data.reduce((acc, el) => {
    for (const type of ["construction", "reconstruction"]) {
      if (el[type]) {
        if (!acc[type]) {
          acc[type] = {
            dsw: 0,
            ciw: 0,
            equipment: 0,
            other: 0,
            totalCostPerUnit: 0,
          };
        }
        for (const key in el[type]) {
          if (el[type].hasOwnProperty(key)) {
            acc[type][key] += el[type][key];
          }
        }
      }
    }

    return acc;
  }, {});
}

type Construction = {
  dsw: number;
  ciw: number;
  equipment: number;
  other: number;
  totalCostPerUnit: number;
};

type EconomicParamsType = { construction: Construction; reconstruction: Construction };

type TechnologyParamsProps = { oilLength?: number; waterLength?: number; minesCount?: number; wellsCount?: number };

const TechnologyParams: FC<TechnologyParamsProps> = ({ oilLength, waterLength, minesCount, wellsCount }) => (
  <div className={cn.wrapper}>
    <div className={cn.paramsWrapper}>
      {!!(oilLength || waterLength) && (
        <>
          <Label>Протяженность трубопровода</Label>
          {!!oilLength && (
            <FieldParams title="Нефтепровод">
              <Format>{oilLength}</Format> км
            </FieldParams>
          )}
          {!!waterLength && (
            <FieldParams title="Водопровод">
              <Format>{waterLength}</Format> км
            </FieldParams>
          )}
          <Divider className={cn.divider} />
        </>
      )}
      {!!minesCount && (
        <>
          <Label>Кустовые площадки и скважины</Label>
          <FieldParams title="Кол-во кустов">{minesCount}</FieldParams>
          {!!wellsCount && <FieldParams title="Кол-во скважин">{wellsCount}</FieldParams>}
        </>
      )}
    </div>
  </div>
);

const GeneralInspector: FC = observer(() => {
  const infrastructure = useInfrastructure();
  const { inspector } = useInfrastructureMapContext();
  const [oilLength, waterLength] = inspector.segmentsLength;

  const { economicData } = infrastructure.calculateStore;

  const { mines, segments, stations } = useMemo(() => (economicData ? economicData : { mines: [], segments: [], stations: [] }), [economicData]);

  const technologyParams = useMemo(
    () => ({
      oilLength,
      waterLength,
      minesCount: inspector.minesCount,
      wellsCount: inspector.wellsCount,
    }),
    [oilLength, waterLength, inspector]
  );

  const economicParams = sumParams([sumParams(segments ?? []), sumParams(mines ?? []), sumParams(stations ?? [])]);

  return (
    <Container title="Инспектор">
      <ParamsTabs economicParams={{ ...economicParams, reconstruction: { ...economicParams.reconstruction, period: undefined } }}>
        <TechnologyParams {...technologyParams} />
      </ParamsTabs>
    </Container>
  );
});

export { GeneralInspector };
