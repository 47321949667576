import { req } from "utils/request";

type EntityWithYear = {
  id: number;
  date: string;
};

function updateGtmsStartYears(gtms: EntityWithYear[]) {
  return Promise.all(gtms.map(({ id, date }) => req.patch(`gtms/${id}`, { date })));
}

function updateWellsStartYears(wells: EntityWithYear[]) {
  return Promise.all(wells.map(({ id, date }) => req.patch(`wells/${id}`, { date })));
}

async function updateStartYears(wells: EntityWithYear[], gtms: EntityWithYear[]) {
  return Promise.all([updateWellsStartYears(wells), updateGtmsStartYears(gtms)]);
}

export { updateStartYears };
