import { FC, useEffect, useRef, useState } from "react";
import { GroupedVirtuoso } from "react-virtuoso";
import { Typography } from "antd";

import { Icon } from "elements/icon/icon";
import { WellCharts } from "services/techParameters";

import { ReactComponent as ArrowIcon } from "../icons/arrow.svg";
import { TechModalState } from "../techForecast";
import { WellNodePart, WellTypes } from "../techForecastMock";

import { WellItem } from "./wellItem";
import { WellDataForTooltip } from "./wellsGroup";

import cn from "./wellsList.module.less";

type CurrentId = {
  wellId: number;
  gtmId: number | null;
};

type WellListProps = {
  current: CurrentId;
  onCurrentChange: (id: number, gtmId?: number | null) => void;
  chosenType: WellTypes;
  setChosenType: (wellType: WellTypes) => void;
  wells: Record<WellTypes, WellNodePart[]>;
  result: WellCharts[] | null;
  forecastState: TechModalState;
};

const fixIndex = (rawIndex: number, wells: WellListProps["wells"]): number => {
  let index = rawIndex;
  index += wells.base.length === 0 ? 1 : 0;
  if (index > 0) {
    index += wells.new.length === 0 ? 1 : 0;
  }
  return index;
};

const WellsList: FC<WellListProps> = ({ wells, chosenType, setChosenType, result, current, onCurrentChange, forecastState }) => {
  const active = useRef(null);
  useEffect(() => {
    if (current.gtmId !== 0 && current.wellId !== 0 && active.current) {
      const element = active.current as any;
      if (element.scrollIntoViewIfNeeded) {
        element.scrollIntoViewIfNeeded();
      } else {
        const contRect = element.parentElement.parentElement.getBoundingClientRect();
        const elemRect = element.getBoundingClientRect();

        if (elemRect.right > contRect.right || elemRect.left < contRect.left) {
          element.scrollIntoView();
        }
      }
    }
  }, [current]);

  const isExistChart = (wellId: number, gtmId?: number | null) =>
    !!result?.findIndex(({ wellId: wId, gtmId: gId }) => wId === wellId && gId === gtmId);

  const groupArray = [wells["base"].length, wells["new"].length, wells["gtm"].length].filter((value) => value > 0);

  const wellsList: WellDataForTooltip[] = [
    ...wells["base"].map(({ well, wellInfo, gtmId }) => ({ ...well, ...wellInfo, gtmId })),
    ...wells["new"].map(({ well, wellInfo, gtmId }) => ({ ...well, ...wellInfo, gtmId })),
    ...wells["gtm"].map(({ well, wellInfo, gtmId, typeName }) => ({ ...well, ...wellInfo, gtmId, typeName })),
  ];

  const [groupCounts, setGroupCounts] = useState<number[]>(groupArray);

  const getIndex = (index: number, groupIndex: number) => {
    let resIndex = index;
    if (groupIndex !== 0) {
      resIndex += groupCounts[0] !== groupArray[0] ? groupArray[0] : 0;
    }
    if (groupIndex === 2) {
      resIndex += groupCounts[1] !== groupArray[1] ? groupArray[1] : 1;
    }
    return resIndex;
  };

  const wellGroups: WellTypes[] = ["base", "new", "gtm"];
  const titleMap: Record<WellTypes, string> = {
    base: "Базовый",
    new: "Новый",
    gtm: "ГТМ",
  };

  return (
    <div className={cn.container}>
      <div className={cn.title}>
        <Typography style={{ fontSize: "10px" }}>СКВАЖИНЫ</Typography>
      </div>
      <GroupedVirtuoso
        style={{ height: "calc(100% - 50px)" }}
        groupCounts={groupCounts}
        groupContent={(rawIndex) => {
          const index = fixIndex(rawIndex, wells);
          return groupArray[index] === 0 ? null : (
            <div className={wellGroups[index] === chosenType && forecastState === "settings" ? cn["selected-group"] : cn["group"]}>
              <div className={cn["title-container"]}>
                <div
                  className={cn["group-title"]}
                  onClick={() => {
                    const newList = JSON.parse(JSON.stringify(groupCounts));
                    newList[index] = newList[index] > 0 ? 0 : groupArray[index];
                    setGroupCounts(newList);
                  }}
                >
                  <div className={cn["icon-container"]}>
                    <Icon content={<ArrowIcon />} className={groupCounts[index] > 0 ? cn.icon : cn["transformed-icon"]} viewBox="0 0 8 4" />
                  </div>
                  {titleMap[wellGroups[index]]}
                </div>
                {forecastState === "settings" ? (
                  <div className={cn["select-button"]} onClick={() => setChosenType(wellGroups[index])}>
                    Выбрать
                  </div>
                ) : null}
              </div>
            </div>
          );
        }}
        itemContent={(index, groupIndex) => {
          const well = wellsList[getIndex(index, groupIndex)];

          return well ? (
            <WellItem
              forecastState={forecastState}
              well={well}
              key={`${well.id}${well.gtmId}`}
              type={wellGroups[groupIndex]}
              isExistChart={isExistChart(well.id, well.gtmId)}
              isSelectedGroup={wellGroups[groupIndex] === chosenType}
              isSelected={current.wellId === well.id && current.gtmId === (well.gtmId || null)}
              onClick={() => onCurrentChange(well.id, well.gtmId)}
              activeRef={active}
            />
          ) : null;
        }}
      />
    </div>
  );
};

export { type CurrentId, WellsList, type WellTypes };
