import { FC } from "react";
import { Tabs, TabsProps } from "antd";
import { NumberValue } from "d3";
import dayJS from "dayjs";

import { Player } from "elements/Player/player";
import { ChartData, TechForecastResultAxisInfo } from "features/techForecastResultChart/techForecastResultChart";
import { ForecastChart, WellCharts } from "services/techParameters";

import { ResultTab } from "./resultTab";

import cn from "./results.module.less";

type TechnologyResultsProps = {
  current: number;
  onCurrentChange: (index: number) => void;
  techForecast: WellCharts[];
  setTechForecast: (techForecastResponse: WellCharts[]) => void;
  disabledCoefficients?: boolean;
  disableVerticalLines?: boolean;
  className?: string;
};

type ResultChartsType = {
  start: number;
  end: number;
  oil: TechForecastResultAxisInfo;
  liquid: TechForecastResultAxisInfo;
  oilRelativeDensity: number;
};

type ChartType = {
  liquid: "oil" | "liquid";
  period: "fact" | "forecast";
};

type GetOnChangeType = (chartType: ChartType) => (newCHart: (NumberValue | null)[]) => void;
const getCharts = (forecastChart: ForecastChart | null) => {
  if (forecastChart === null || forecastChart.month.length === 0) {
    return {
      oil: undefined,
      liquid: undefined,
    };
  }
  const { month, year, oilRate, liquidRate } = forecastChart;
  const oil: ChartData = {
    y: oilRate ?? [],
    x: year.map((year, index) => dayJS(new Date(year, month[index] - 1))),
  };

  const liquid: ChartData = {
    y: liquidRate ?? [],
    x: year.map((year, index) => dayJS(new Date(year, month[index] - 1))),
  };

  return {
    oil,
    liquid,
  };
};
const TechnologyResults: FC<TechnologyResultsProps> = ({
  techForecast,
  disabledCoefficients,
  disableVerticalLines,
  current,
  onCurrentChange,
  setTechForecast,
  className,
}) => {
  const wellsCount = techForecast.length;

  const getOnChartChange: GetOnChangeType =
    ({ liquid, period }) =>
    (newChart) => {
      const techForecastClone = JSON.parse(JSON.stringify(techForecast)) as WellCharts[];
      techForecastClone[current][period]![`${liquid}Rate`] = newChart.map((numberValue) => {
        return Number(numberValue);
      });
      setTechForecast(techForecastClone);
    };

  const resultCharts: ResultChartsType[] = techForecast.map(({ fact, forecast, forecastInterval, oilRelativeDensity }) => {
    let factStart: number = +dayJS(fact ? new Date(fact.year[0], fact.month[0] - 1) : new Date());
    let factEnd: number = +dayJS(fact ? new Date(fact.year[fact.year.length - 1], fact.month[fact.month.length - 1] - 1) : new Date());

    if (forecastInterval) {
      factStart = +dayJS(new Date(forecastInterval.startYear, forecastInterval.startMonth - 1));
      factEnd = +dayJS(new Date(forecastInterval.endYear, forecastInterval.endMonth - 1));
    }
    const factCharts = getCharts(fact);
    const forecastCharts = getCharts(forecast);

    return {
      start: factStart,
      end: factEnd,
      oil: {
        axisTitle: (
          <g>
            <text x={-20} className={cn.axisTitle}>
              q
            </text>
            <text x={-14} baselineShift="sub" className={cn.axisTitle} style={{ fontSize: "10px" }}>
              н
            </text>
            <text x={24} className={cn.axisTitle}>
              , т/сут
            </text>
          </g>
        ),
        curveFactData: factCharts.oil,
        curveForecastData: forecastCharts.oil!,
        curveFactForecastData: undefined,
      },
      liquid: {
        axisTitle: (
          <g>
            <text x={-20} className={cn.axisTitle}>
              q
            </text>
            <text x={-12} baselineShift="sub" className={cn.axisTitle} style={{ fontSize: "10px" }}>
              ж
            </text>
            <text x={4} className={cn.axisTitle}>
              , м
            </text>
            <text x={9} baselineShift="super" className={cn.axisTitle} style={{ fontSize: "8px" }}>
              3
            </text>
            <text x={32} className={cn.axisTitle}>
              /сут
            </text>
          </g>
        ),
        curveFactData: factCharts.liquid,
        curveForecastData: forecastCharts.liquid!,
        curveFactForecastData: undefined,
      },
      oilRelativeDensity,
    } as const;
  });

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Результаты",
      children: (
        <ResultTab
          {...resultCharts[current]}
          disabledCoefficients={disabledCoefficients}
          disableVerticalLines={disableVerticalLines}
          getOnChartChange={getOnChartChange}
        />
      ),
    },
  ];

  return (
    <div className={`${cn.container} ${className ?? ""}`}>
      <Player count={wellsCount} onCurrentChange={onCurrentChange} current={current} />
      <div className={cn["tabs-container"]}>
        <Tabs style={{ flex: 1 }} items={items} />
      </div>
    </div>
  );
};

export { getCharts, TechnologyResults };
export type { GetOnChangeType, ResultChartsType };
