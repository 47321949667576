import { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from "react";
import TimeAgo from "react-timeago-i18n";
import { Button, Select, Typography } from "antd";
import { observer } from "mobx-react";

import { Card } from "elements/card/card";
import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { Loader } from "elements/loader";
import { ForecastSelector } from "features/forecastSelector/forecastSelector";
import { Preloader } from "features/preloader/preloader";
import { useECYStore } from "features/useMetrics/useECYStore";
import { ForecastResultPack, useForecast } from "models/project/fact/forecast/forecast";
import { ForecastResult } from "models/project/fact/forecast/forecastResult";
import { useInteractiveRef } from "utils/useInteractiveRef";

import { CalcParamsCheck } from "./calcParamsCheck";
import { CalculateButton } from "./calculateButton";
import { ResultPageToolboxPortalContextProvider } from "./resultPageTitlePortal";
import { ResultTitle } from "./resultTitle";

import cn from "./resultPage.module.less";

const ResultContext = createContext<ForecastResult | null>(null);

const useResultModel = (): ForecastResult => {
  const result = useContext(ResultContext);
  console.assert(result !== null, "Запрошен объект параметров вне контекста");
  return result!;
};

type ResultPageComponentProps = PropsWithChildren<{ title?: string; scroll?: boolean; needLicense?: boolean }>;

const LicenseRegionSelector: FC<{ selected: number | null; setSelected: (id: number | null) => void }> = observer(({ selected, setSelected }) => {
  const { licenseRegions } = useForecast()!;
  const options = [
    ...licenseRegions.selector,
    {
      value: null,
      label: "Суммарно по всем ЛУ",
    },
  ];
  return <Select value={selected} options={options} onSelect={(id: number | null) => setSelected(id)} />;
});

const Outline: FC<{ pack?: Map<number, ForecastResultPack> } & Pick<ResultPageComponentProps, "title" | "children" | "needLicense">> = ({
  pack,
  title,
  children,
  needLicense = false,
}) => {
  const forecast = useForecast()!;
  const toolboxRef = useInteractiveRef<HTMLDivElement>();
  const toolboxContext = useMemo(
    () => ({
      toolboxRef: toolboxRef,
    }),
    [toolboxRef]
  );
  const ecyStore = useECYStore()!;
  const [selectedEcyId, setEcyId] = useState<number>(ecyStore.first!.id);
  const [selectedLicenseRegionId, setLicenseRegionId] = useState<number | null>(null);
  const forecastResult = pack?.get(selectedEcyId)?.[selectedLicenseRegionId ?? "total"];
  return (
    <div className={cn.wrap}>
      <div className={cn.pane}>
        <ResultTitle date={forecast.calculatedResult ? null : forecast.savedResultTS}>{title}</ResultTitle>
        <div className={cn.buttons}>
          <div ref={toolboxRef} />
          <Select value={selectedEcyId} options={ecyStore.selector} onSelect={(id) => setEcyId(id)} />
          {needLicense && <LicenseRegionSelector selected={selectedLicenseRegionId} setSelected={setLicenseRegionId} />}
          <CalculateButton>Пересчитать</CalculateButton>
          <Button onClick={forecast.puskachDownload}>Скачать файл настроек расчета</Button>
        </div>
      </div>
      <div className={cn.body}>
        <ResultPageToolboxPortalContextProvider value={toolboxContext}>
          <ResultContext.Provider value={forecastResult ?? null}>{children}</ResultContext.Provider>
        </ResultPageToolboxPortalContextProvider>
      </div>
    </div>
  );
};

const ForecastSelectedResultPage: FC<ResultPageComponentProps> = observer(({ title, children, needLicense }) => {
  const forecast = useForecast()!;

  if (forecast.calculatedResult === undefined) {
    return <FullScreenLoader>Выполнение расчета</FullScreenLoader>;
  }

  if (forecast.calculatedResult !== null) {
    return (
      <Outline pack={forecast.calculatedResult} title={title} needLicense={needLicense}>
        {children}
      </Outline>
    );
  }

  // если расчет не заказывался то разбираемся с тем, что сохранено
  if (forecast.savedResultTS === undefined) {
    return <FullScreenLoader>Проверка наличия сохранённых расчетов</FullScreenLoader>;
  }

  if (forecast.savedResultTS !== null && forecast.savedResult === undefined) {
    return (
      <Outline title={title} needLicense={needLicense}>
        <Card
          title={
            <>
              Для прогноза "{forecast.title}" <TimeAgo date={forecast.savedResultTS} /> проводился экономический расчет
            </>
          }
        >
          <div className={cn.loader}>
            <Loader />
            <Typography.Text>Идёт загрузка полученного ранее результата</Typography.Text>
          </div>
        </Card>
      </Outline>
    );
  }

  if (forecast.savedResult !== null) {
    return (
      <Outline pack={forecast.savedResult} title={title} needLicense={needLicense}>
        {children}
      </Outline>
    );
  }

  // нету ни сохранённого ни выполненного расчета
  return (
    <Outline title={title} needLicense={needLicense}>
      <CalcParamsCheck />
    </Outline>
  );
});

const ResultPage: FC<ResultPageComponentProps> = (props) => (
  <ForecastSelector>
    <Preloader hooks={[useECYStore]}>
      <ForecastSelectedResultPage {...props} />
    </Preloader>
  </ForecastSelector>
);

export { ResultPage, useResultModel };
