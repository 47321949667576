import { FC, useMemo } from "react";
import { ChildrenStoreArray, ColumnRaw, TableContextProvider, TableModel, TableNode, Widget } from "@okopok/components/Table";
import { Dayjs } from "dayjs";

import { CsvSaver } from "elements/csvSaver/csvSaver";
import { Format, NUMBER_FORMAT } from "elements/format/format";
import { zip } from "utils/itertools";

import { Param, PARAMS, YearDatum } from "./constants";

import cn from "./summary.module.less";

type DRow = {
  title: string;
  measure: string | null;
  [year: number]: number;
};

class ParamsStore extends TableNode<DRow, ParamRow> {
  constructor(public readonly years: Dayjs[], public readonly data: YearDatum[]) {
    super(null);
    this.childrenStore = new ChildrenStoreArray(
      this,
      PARAMS.map((p) => new ParamRow(this, p))
    );
  }
}

class ParamRow extends TableNode<DRow> {
  asDRow = (): DRow => ({
    title: this.param.title,
    measure: this.param.measure.unit,
    ...Object.fromEntries(
      zip(
        (this.parent.years ?? []).map((y) => y.year()),
        (this.parent.data ?? []).map((v) => v[this.param.key])
      )
    ),
  });

  constructor(private parent: ParamsStore, private param: Param) {
    super(parent);
  }
}

const BASE_COLUMNS: ColumnRaw<DRow>[] = [
  {
    key: "index",
    title: "No.пп",
    width: 50,
    isSticky: true,
    render: (_, { absoluteIndex }) => absoluteIndex,
    isExported: false,
  },
  {
    dataKey: "title",
    title: "Параметр",
    width: 300,
    isSticky: true,
  },
  {
    dataKey: "measure",
    title: "Ед. измерения",
    width: 100,
    isSticky: true,
  },
];

type TableProps = {
  years: Dayjs[];
  data: YearDatum[];
};

const Table: FC<TableProps> = ({ years, data }) => {
  const store = new ParamsStore(years, data);
  const columns: ColumnRaw<DRow>[] = useMemo(
    () =>
      [
        ...BASE_COLUMNS,
        ...(years.map(
          (y): ColumnRaw<DRow> => ({
            dataKey: y.year(),
            title: y.year(),
            width: 100,
            render: (v) => <Format>{v}</Format>,
            renderToString: (v) => NUMBER_FORMAT.real_3.format(v),
          })
        ) ?? []),
      ].map((col) => ({
        ...col,
        onHeaderCell: () => ({ className: cn.tableHeader }),
      })),
    [years]
  );
  const tableModel = new TableModel(columns, store, undefined, {
    headerHeight: 39,
    rowHeight: 33,
    borderColor: "#e5e7eb",
  });
  return (
    <TableContextProvider value={tableModel}>
      <CsvSaver className={cn.table} filename="Сводка по прогнозу технологических показателей" exportArray={() => tableModel.export()}>
        <Widget />
      </CsvSaver>
    </TableContextProvider>
  );
};

export { Table };
