import { FC, useMemo, useState } from "react";
import { Map as MapContext, useMapContext } from "@okopok/axes_context";
import { MapManager } from "@okopok/axes_context";
import { Button, Empty, Typography } from "antd";
import { observer } from "mobx-react";

import { useForecast } from "models/project/fact/forecast/forecast";
import { save } from "services/back/calculate/utils";
import { VoronoiGrid as VoronoiGridData, WellItem } from "services/back/injectionPrediction/voronoi";
import { viewport } from "utils/pointsViewport";

import { Cells } from "./Cells/Cells";
import { LegendMap } from "./legendMap/legendMap";
import { VoronoiModeSelector } from "./voronoiModeSelector/voronoiModeSelector";
import { VoronoiModeSelectorModel } from "./voronoiModeSelector/voronoiModeSelectorModel";
import { GridMapDataManager } from "./GridMapDataManager";
import { HoverManager, HoverManagerContext } from "./hoverManager";

import cn from "./GridMap.module.less";

type LayoutProps = {
  wells: WellItem[];
  mode: "injection" | "mining";
};

const VoronoiGrid: FC<LayoutProps> = ({ wells, mode }) => {
  const { scale, zoom } = useMapContext();
  const fc = useForecast()!;

  const manager = useMemo(() => new GridMapDataManager(wells, fc), [wells, fc]);

  return <Cells mode={mode} active dataManager={manager} scale={scale} zoom={zoom} />;
};

type GridMapProps = {
  data: VoronoiGridData;
};

const GridMap: FC<GridMapProps> = observer(({ data }) => {
  const [manager, setManager] = useState<MapManager | null>(null);
  const modeModel = useMemo(() => new VoronoiModeSelectorModel(), []);
  const hoverManager = useMemo(() => new HoverManager(data, modeModel), [data, modeModel]);
  const onDownload = () => {
    save(data);
  };

  if (data.injWells.length === 0 && data.prodWells.length === 0) {
    return (
      <div className={cn.empty}>
        <Empty description={<Typography.Text>Нет данных для отображения</Typography.Text>} />
      </div>
    );
  }
  return (
    <div className={cn.layout}>
      <LegendMap />

      <div className={cn.toolbar}>
        <div className={cn.buttons}>
          <Button onClick={manager?.setInitialScale}>1:1</Button>
          <Button onClick={onDownload}>Скачать расчет</Button>
          <VoronoiModeSelector modeModel={modeModel} />
        </div>
      </div>
      <div className={cn.mapNDate}>
        <MapContext manager={setManager} viewPort={viewport([...data.injWells, ...data.prodWells]).join(" ")} className={cn.map}>
          <HoverManagerContext.Provider value={hoverManager}>
            <VoronoiGrid
              wells={modeModel.mode === "oilRate" ? data.injWells : data.prodWells}
              mode={modeModel.mode === "oilRate" ? "injection" : "mining"}
            />
            <VoronoiGrid
              wells={modeModel.mode === "oilRate" ? data.prodWells : data.injWells}
              mode={modeModel.mode === "oilRate" ? "mining" : "injection"}
            />
          </HoverManagerContext.Provider>
        </MapContext>
      </div>
    </div>
  );
});

export { GridMap };
