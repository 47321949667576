import { useMemo } from "react";
import { TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import type { ColumnRaw } from "@okopok/components/Table/models/columns/store";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { CsvSaver } from "elements/csvSaver/csvSaver";
import { EllipsisTable as Ellipsis } from "elements/ellipsis/ellipsis";
import { Format } from "elements/format/format";
import { FilterDetails } from "features/techPrediction/filters/types";
import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { InjectWell } from "models/project/fact/well/tech/inject";
import { MiningWell } from "models/project/fact/well/tech/mining";
import { DRow, TechWells } from "models/project/fact/well/tech/tech";
import { Range } from "utils/range";

import cn from "./wellsTech.module.less";

export type InputBooleanProps = {
  labels?: [React.ReactNode, React.ReactNode];
  value: boolean | null;
  setValue: (value: boolean) => void;
};

const TECH_PARAMS_FILTER_MAP = {
  name: {
    type: "stringer",
    predicateFactory: (condition, value) => (wellNode) => {
      value = value.toLowerCase();
      const contains = wellNode.well.title.toLowerCase().includes(value);
      return condition === "contains" ? contains : !contains;
    },
  } as FilterDetails<"stringer", DRow, MiningWell | InjectWell, string>,
};

const SHARED_COLUMNS: ColumnRaw<DRow>[] = [
  {
    key: "index",
    title: "No.пп",
    isSticky: true,
    isExported: false,
    width: { min: 52, max: 52, competitiveness: 1 },
    render: (_, { absoluteIndex }) => <div style={{ width: "100%", textAlign: "right" }}>{absoluteIndex ?? 0}</div>,
  },
  {
    key: "expand",
    title: <div />,
    isSticky: true,
    isExported: false,
    width: { min: 34, max: 34, competitiveness: 1 },
    render: (_, tableItem) => <ExpandButton expand={tableItem.expand} />,
  },
  {
    dataKey: "name",
    title: "Параметр",
    isSticky: true,
    width: { min: 380, max: 380, competitiveness: 1 },
    render: (value, tableItem) => <Ellipsis value={value} row={{ level: tableItem.indexPath.length }} />,
    renderToString: (value) => {
      return value;
    },
    onCell: ({ indexPath }) => ({ style: { paddingLeft: indexPath.length * 15 } }),
  },
  {
    dataKey: "measure",
    title: "Ед. измерения",
    isSticky: true,
    renderToString: (value) => value,
    width: { min: 130, max: 130, competitiveness: 1 },
  },
];

const WellsTech = observer(() => {
  const fact = useFact()!;
  const forecast = useForecast();

  const store = useMemo(() => new TechWells(fact, forecast), [fact, forecast]);
  const factRange = new Range(fact.factRange.from, fact.lastDescribedYear + 1);

  const columns: ColumnRaw<DRow>[] = useMemo(
    () => [
      ...SHARED_COLUMNS,
      /** Fact? columns */
      ...[...(!!forecast ? (global.isPickingFactYears ? forecast.wholeRange : forecast.range) : factRange)].map(
        (year) =>
          ({
            dataKey: year,
            title: `${year}`,
            width: { min: 130, max: 130, competitiveness: 1 },
            render: (value, tableItem) => {
              if (tableItem.expand !== undefined) {
                return null;
              }
              if (typeof value === "boolean" && tableItem.update !== undefined) {
                return value ? "Работает" : "Не работает";
              }
              return <Format>{value ?? null}</Format>;
            },
            renderToString: (value) => value,
            onCell: ({ value, expand }) => ({
              className: classNames(cn.tableCellAlignRight, {
                [cn.oilDiffCellPositive]: value?.name.startsWith("Процент изменения") && ((value[year] ?? 0) as number) > 0,
                [cn.oilDiffCellNegative]: value?.name.startsWith("Процент изменения") && ((value[year] ?? 0) as number) < 0,
              }),
            }),
          } as ColumnRaw<DRow>)
      ),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [global.isPickingFactYears]
  );

  const model = useMemo(
    () =>
      new TableModel(
        columns,
        store,
        {
          onRow: ({ indexPath, expand }) => ({
            className: expand === undefined ? cn.tableRowPlain : indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary,
          }),
        },
        {
          headerHeight: 39,
          rowHeight: 33,
          borderColor: "#f0f0f0",
        }
      ),
    [columns, store]
  );

  return (
    <TableContextProvider value={model}>
      <CsvSaver
        tooltip="Экспорт может проходить более минуты"
        filename="Технологические параметры"
        exportArray={() => {
          return model.export();
        }}
      >
        <PageFrameTitlePortal />
        <Widget headerClassName={cn.tableHeader} />
      </CsvSaver>
    </TableContextProvider>
  );
});

export { TECH_PARAMS_FILTER_MAP, WellsTech };
