import { action, computed, makeObservable, observable } from "mobx";

import { LoadableStore } from "models/loadableStore/loadableStore";
import {
  CatalogType,
  getInfrastructureCatalog,
  MineType,
  saveCatalog,
  SegmentType,
  StationType,
} from "services/back/infrastructure/catalog";

class InfrastructureCatalog {
  public data?: CatalogType;

  constructor() {
    makeObservable(this, {
      data: observable,
      oilPipes: computed,
      waterPipes: computed,
      mines: computed,
      stations: computed,
      oilPipesSelector: computed,
      waterPipesSelector: computed,
      minesSelector: computed,
      stationsSelector: computed,
      isLoading: computed,
      initChildren: action,
      update: action,
      push: action,
      remove: action,
    });

    this.initChildren();
  }

  initChildren() {
    getInfrastructureCatalog().then((data) => (this.data = data));
  }

  get oilPipes() {
    return this.data?.segments.filter(({ segmentType }) => segmentType === "prod") || [];
  }
  get waterPipes() {
    return this.data?.segments.filter(({ segmentType }) => segmentType === "inj") || [];
  }
  get mines() {
    return this.data?.mines || [];
  }
  get stations() {
    if (!this.data) {
      return [];
    }
    return this.data.stations || [];
  }
  get isLoading(): boolean {
    return this.data === undefined;
  }
  get oilPipesSelector() {
    return new LoadableStore(() => Promise.resolve(this.oilPipes));
  }
  get waterPipesSelector() {
    return new LoadableStore(() => Promise.resolve(this.waterPipes));
  }
  get minesSelector() {
    return new LoadableStore(() => Promise.resolve(this.data!.mines));
  }
  get stationsSelector() {
    return new LoadableStore(() => Promise.resolve(this.stations));
  }

  public update = (item: Partial<SegmentType | MineType | StationType>, type: "pipe" | "mine" | "station") => {
    const array = type === "pipe" ? "segments" : type === "mine" ? "mines" : "stations";
    const index = this.data![array].findIndex(({ uuid }) => uuid === item.uuid);
    this.data![array][index] = { ...this.data![array][index], ...item };
  };

  public push = (item: SegmentType | MineType | StationType, type: "pipe" | "mine" | "station") => {
    const array = type === "pipe" ? "segments" : type === "mine" ? "mines" : "stations";
    this.data![array].push(item as any);
  };

  pipes = (type: "prod" | "inj") => (type === "prod" ? this.oilPipes : this.waterPipes);
  stationsAt = (type: string) => this.stations.filter((el) => el.stationType === type);

  remove = (uuid: string, type: "pipe" | "mine" | "station") => {
    if (!this.data) {
      return;
    }
    const array = type === "pipe" ? "segments" : type === "mine" ? "mines" : "stations";
    const index = this.data[array].findIndex((el) => el.uuid === uuid);
    this.data[array].splice(index, 1);
  };

  save = async () => {
    await saveCatalog(this.data!);
  };
}

export { InfrastructureCatalog };
