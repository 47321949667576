import { action, makeObservable, observable } from "mobx";

import {
  getRankingBack,
  getRankingQueue,
  isChangedInvestCost,
  provideRequest,
  saveInvestCost,
  saveRankingQueue,
  TableType,
} from "services/back/ranking";

import { type Forecast } from "../forecast";

import { RankingDrilling } from "./rankingDrilling";
import { RankingReconstruction } from "./rankingReconstruction";

class Ranking {
  drilling = new RankingDrilling();
  reconstruction = new RankingReconstruction();
  customTooltip?: string;

  constructor(private type: TableType, private fc: Forecast, preloader: Promise<unknown>) {
    preloader.then(this.execRanking);

    makeObservable(this, {
      customTooltip: observable,
      isLoading: observable,
      execRanking: action,
      setCustomTooltip: action,
    });
  }

  isLoading = true;

  execRanking = async () => {
    this.drilling.drop();
    this.reconstruction.drop();
    this.isLoading = true;
    const { fact: projectId, forecast: scenarioId } = this.fc.storageKey;
    const res =
      this.type === "sequential"
        ? (await getRankingQueue(projectId, scenarioId!)) ?? (await getRankingBack(this.fc))
        : await getRankingBack(this.fc);
    if (this.type === "ranking") {
      const isDifference = await isChangedInvestCost(this.fc, projectId, scenarioId!);
      if (isDifference) {
        this.setCustomTooltip(
          "Параметры ранжирования были обновлены. Для получения нового ранжирования требуется выполнить перерасчет"
        );
      }
    }
    const { result, drilling, reconstruction } = res;
    const { wells, mines, gtms } = result;

    this.drilling.initChildren(wells, mines);
    this.drilling.setOrder(drilling);
    this.reconstruction.initChildren(gtms);
    this.reconstruction.setOrder(reconstruction);
    this.isLoading = false;
  };

  public submit = async () => {
    const drillingOrder = this.drilling.getOrder();
    const reconstructionOrder = this.reconstruction.getOrder();
    const result = { ...drillingOrder.result, ...reconstructionOrder.result };
    const ranking = {
      result,
      drilling: drillingOrder.drilling,
      reconstruction: reconstructionOrder.reconstruction,
    };
    const { fact: projectId, forecast: scenarioId } = this.fc.storageKey;

    if (this.type === "ranking") {
      await saveInvestCost(provideRequest(this.fc), projectId, scenarioId!);
    }
    await saveRankingQueue(ranking, projectId, scenarioId!);
  };

  setCustomTooltip(tooltip: string) {
    this.customTooltip = tooltip;
  }

  get currentResult() {
    const drillingOrder = this.drilling.getOrder();
    const reconstructionOrder = this.reconstruction.getOrder();
    return { ...drillingOrder.result, ...reconstructionOrder.result };
  }

  get ranks(): { wells: Map<number, number>; gtms: Map<number, number> } {
    const wells = new Map<number, number>();
    const gtms = new Map<number, number>();
    for (const mine of this.drilling.children ?? []) {
      for (const well of mine.children ?? []) {
        wells.set(well.wellId, well.rank);
      }
    }
    for (const gtm of this.reconstruction.children ?? []) {
      if (gtm.row.gtmId !== undefined) {
        gtms.set(gtm.row.gtmId, gtm.rank);
      }
    }

    return { wells, gtms };
  }
}

export { Ranking };
