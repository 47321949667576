import { FC, ReactNode } from "react";
import { ChartContext } from "@okopok/axes_context";
import { LineDataModel } from "@okopok/axes_context";
import { Lines } from "@okopok/axes_context";
import { NumberValue } from "d3";
import { observer } from "mobx-react";

import { BorderLine } from "elements/charts/borderLine/borderLine";
import { useScaleFromLines } from "elements/charts/lines/useScaleFromLines";
import { PointerLine } from "elements/charts/pointerLine/pointerLine";
import { TooltipContext, TooltipDataManager } from "features/plot/Tooltip/useTooltipDataManager";
import { Domain } from "utils/boundDomain";

import { LegendContentState } from "./LegendContent/LegendContentState";

import cn from "./techForecastResultChart.module.less";

const YEAR = 31536000000 / 2;

type TechForecastResultChartFeatureProps = {
  curveFactForecastLiquid: LineDataModel;
  curveFactForecastOil: LineDataModel;
  curveFactLiquid: LineDataModel;
  curveFactOil: LineDataModel;
  curveForecastLiquid: LineDataModel;
  curveForecastOil: LineDataModel;
  curveFactForecastWatering: LineDataModel;
  curveForecastWatering: LineDataModel;
  curveFactWatering: LineDataModel;

  connectOilCurve: LineDataModel;
  connectLiquidCurve: LineDataModel;
  connectWateringCurve: LineDataModel;

  start: NumberValue;
  end: NumberValue;

  changeStart: (value: number) => void;
  changeEnd: (value: number) => void;

  oilTitle: ReactNode;
  liquidTitle: ReactNode;
  wateringTitle: ReactNode;

  tooltipManager: TooltipDataManager;
  legendContent: LegendContentState;

  disableVerticalLines: boolean;
};

const findRightLimit = (curveForecastOil: LineDataModel | null, curveForecastLiquid: LineDataModel | null) => {
  if (curveForecastLiquid) {
    if (Array.isArray(curveForecastLiquid.x)) {
      return curveForecastLiquid.x[0];
    } else {
      return curveForecastLiquid.x.min;
    }
  }
  if (curveForecastOil) {
    if (Array.isArray(curveForecastOil.x)) {
      return curveForecastOil.x[0];
    } else {
      return curveForecastOil.x.min;
    }
  }
  return undefined;
};

const findLeftLimit = (curveFactOil: LineDataModel | null, curveFactLiquid: LineDataModel | null) => {
  if (curveFactOil) {
    if (Array.isArray(curveFactOil.x)) {
      return curveFactOil.x[0];
    } else {
      return curveFactOil.x.min;
    }
  }
  if (curveFactLiquid) {
    if (Array.isArray(curveFactLiquid.x)) {
      return curveFactLiquid.x[0];
    } else {
      return curveFactLiquid.x.min;
    }
  }
  return undefined;
};

const TechForecastResultChartFeature: FC<TechForecastResultChartFeatureProps> = observer(
  ({
    curveFactForecastLiquid,
    curveFactForecastOil,
    curveFactLiquid,
    curveFactOil,
    curveForecastLiquid,
    curveForecastOil,
    curveFactForecastWatering,
    curveFactWatering,
    curveForecastWatering,
    connectOilCurve,
    connectLiquidCurve,
    connectWateringCurve,
    start,
    end,
    changeStart,
    changeEnd,
    oilTitle,
    liquidTitle,
    wateringTitle,
    tooltipManager,
    legendContent,
    disableVerticalLines,
  }) => {
    return (
      <ChartContext
        axes={useScaleFromLines(
          [
            curveFactForecastLiquid,
            curveFactForecastOil,
            curveFactLiquid,
            curveFactOil,
            curveForecastLiquid,
            curveForecastOil,
            curveFactForecastWatering,
            curveFactWatering,
            curveForecastWatering,
          ],
          YEAR,
          new Map<string, ReactNode>(
            Object.entries({
              yOil: oilTitle,
              yLiquid: liquidTitle,
              yWatering: wateringTitle,
            })
          ),
          undefined,
          undefined,
          undefined,
          new Map<string, Domain>(
            Object.entries({
              yWatering: { min: 0, max: 100 },
            })
          )
        )}
        className={cn.chart}
      >
        <TooltipContext.Provider value={tooltipManager}>
          {legendContent.getLiquid && <Lines data={[curveFactForecastLiquid, curveForecastLiquid, curveFactLiquid, connectLiquidCurve]} />}
          {legendContent.getOil && <Lines data={[curveFactOil, curveFactForecastOil, curveForecastOil, connectOilCurve]} />}
          {legendContent.getWatering && <Lines data={[curveFactForecastWatering, curveFactWatering, curveForecastWatering, connectWateringCurve]} />}
          <PointerLine />
        </TooltipContext.Provider>

        {isFinite(+end) && !disableVerticalLines && (
          <BorderLine
            x={end}
            setOnDrag={curveFactForecastOil !== null || curveFactForecastLiquid !== null ? changeEnd : undefined}
            limitLeft={start}
            limitRight={findRightLimit(curveForecastLiquid, curveForecastOil)}
          />
        )}
        {isFinite(+start) && !disableVerticalLines && (
          <BorderLine
            x={start}
            setOnDrag={curveFactForecastOil !== null || curveFactForecastLiquid !== null ? changeStart : undefined}
            limitRight={end}
            limitLeft={findLeftLimit(curveFactLiquid, curveFactOil)}
          />
        )}
      </ChartContext>
    );
  }
);

export { TechForecastResultChartFeature };
