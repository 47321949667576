import { FC } from "react";

import { CustomInput } from "./customInput";
import { SettingContainer } from "./settingContainer";
import { TechnologySettingsProps } from "./settings";

const OperatingCoefficient: FC<TechnologySettingsProps> = ({ techParameters, setTechParameters }) => {
  const onChange = (value: number | null) => {
    setTechParameters.applyForAll(["operationCoefficient"], value);
  };

  return (
    <SettingContainer title="Коэффициент эксплуатации, д.ед.">
      <CustomInput path="operationCoefficient" onSave={onChange} techParameters={techParameters[0]} min={0} max={10} required />
    </SettingContainer>
  );
};

export { OperatingCoefficient };
