import { FC, useMemo } from "react";
import { ColumnRaw, TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import { ExpandButton } from "@okopok/components/Table/widgets/ExpandButton/ExpandButton";
import { observer } from "mobx-react-lite";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { Format } from "elements/format/format";
import { Forecast, useForecast } from "models/project/fact/forecast/forecast";
import { type DRow, Summary as SummaryModel } from "models/project/fact/forecast/ranking/results/summary";
import { useRankingResults } from "pages/ranking/rankingResultsContext";

import cn from "./results.module.less";

const useColumns = (forecast: Forecast) =>
  useMemo<ColumnRaw<DRow>[]>(
    () => [
      {
        key: "index",
        title: "No.пп",
        isSticky: true,
        width: { min: 54, max: 54, competitiveness: 1 },
        render: (_, { absoluteIndex }) => absoluteIndex ?? <div />,
        onCell: () => ({ style: { justifyContent: "right" } }),
      },
      {
        key: "expand",
        title: null,
        isSticky: true,
        width: { min: 32, max: 32, competitiveness: 1 },
        render: (_, tableItem) => <ExpandButton expand={tableItem.expand} />,
      },
      {
        dataKey: "param",
        title: "Параметр",
        isSticky: true,
        width: { min: 300, max: 600, competitiveness: 2 },
      },
      {
        dataKey: "measure",
        title: "Ед. измерения",
        isSticky: true,
        width: 130,
      },
      ...[...forecast.range].map(
        (year): ColumnRaw<DRow> => ({
          dataKey: year,
          title: year,
          width: 150,
          render: (value: number | undefined) => (typeof value === "number" ? <Format>{value}</Format> : null),
          onCell: () => ({ className: cn.tableCellAlignRight }),
          onHeaderCell: () => ({ className: cn.tableCellAlignRight }),
        })
      ),
    ],
    [forecast]
  );

const Summary: FC = observer(() => {
  const forecast = useForecast()!;
  const results = useRankingResults();
  const store = useMemo(() => new SummaryModel(forecast), [forecast]);
  const columns = useColumns(forecast);
  const model = useMemo(() => {
    return new TableModel(
      columns,
      store,
      {
        onRow: ({ expand, indexPath }) => ({ className: expand && (indexPath.length === 1 ? cn.tableRowPrimary : cn.tableRowSecondary) }),
      },
      {
        headerHeight: 39,
        rowHeight: 33,
        borderColor: "#f0f0f0",
      }
    );
  }, [store, columns]);
  return (
    <TableContextProvider value={model}>
      <PageFrameTitlePortal model={results} submitting={results.isSaving} onSave={results.save} />
      <Widget headerClassName={cn.tableHeader} />
    </TableContextProvider>
  );
});

export { Summary };
