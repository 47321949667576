import { useMemo, useState } from "react";
import { TableContextProvider, TableModel, Widget } from "@okopok/components/Table";
import type { ColumnRaw } from "@okopok/components/Table/models/columns/store";
import { Button, Checkbox, Tooltip, Typography } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import { UploadFile } from "antd/es/upload";
import classNames from "classnames";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { CsvSaver } from "elements/csvSaver/csvSaver";
import { DeleteButton } from "elements/deleteButton/deleteButton";
import { Ellipsis } from "elements/ellipsis/ellipsis";
import { EmptyScreen } from "elements/emptyScreen";
import { Format } from "elements/format/format";
import { DATE_FORMAT, formatDate, NUMBER_FORMAT } from "elements/format/format";
import { FullScreenLoader } from "elements/fullScreen/fullScreen";
import { Icon } from "elements/icon/icon";
import { Plus } from "elements/icons/plus";
import { useFallBack } from "elements/importModalContent/useFallBack";
import { EditableFallbackDisplay } from "elements/inputs/editableFallbackDisplay/editableFallbackDisplay";
import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { MonthPicker } from "elements/inputs/monthPicker";
import { SelectStorable } from "elements/inputs/selectStorable/selectStorable";
import { ModeSelector } from "elements/modeSelector/modeSelector";
import { ModeSelectorModel } from "elements/modeSelector/modeSelectorModel";
import { global } from "models/global";
import { useFact } from "models/project/fact/fact";
import { useForecast } from "models/project/fact/forecast/forecast";
import { DRow as DRowInterventions } from "models/project/fact/forecast/wellInterventions/wellIntervention";
import { WellInterventions as WellInterventionsModel } from "models/project/fact/forecast/wellInterventions/wellInterventions";
import { conditionallyArr } from "utils/conditionally";

import "dayjs/locale/ru";

import { ReactComponent as GTMIcon } from "./icons/gtmIcon.svg";
import { ReactComponent as UploadIcon } from "./icons/uploadIcon.svg";
import { ReactComponent as WarningIcon } from "./icons/warningIcon.svg";
import { useImportInterventionsModal } from "./importInterventionsModal";

import "@okopok/components/style.css";
import localStyles from "./wellInterventions.module.less";

const useColumns = (isPreviewColumns: boolean = false, modeModel: ModeSelectorModel | null = null): ColumnRaw<DRowInterventions>[] => {
  const fact = useFact()!;
  const forecast = useForecast()!;
  const getFallback = useFallBack(forecast, fact);

  return useMemo(
    (): ColumnRaw<DRowInterventions>[] => [
      {
        title: <div></div>,
        key: "checked",
        width: { min: 30, max: 40, competitiveness: 1 },
        isExported: false,
        onHeaderCell: () => ({ className: localStyles.tableHeader }),
        render: (_) => <Checkbox />,
      },
      {
        title: "No.пп",
        key: "index",
        width: 50,
        onHeaderCell: () => ({ className: localStyles.tableHeader }),
        render: (_, { indexPath, value }: any) => (
          <div className={localStyles.indexList}>
            {indexPath[0] + 1}
            {!value.isComplete && isPreviewColumns && (
              <Tooltip title="Данные по этому ГТМ отсутствуют либо некорректны.">
                <Icon content={<WarningIcon />} className={localStyles.importIcon} viewBox="0 0 16 16" />
                &nbsp;
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        title: "Скважина",
        dataKey: "well",
        width: { min: 120, max: 370, competitiveness: 1 },
        onCell: () => ({ className: localStyles.tableCell }),
        onHeaderCell: () => ({ className: localStyles.tableHeader }),
        render: (value, { update }) => (
          <div className={localStyles.stratum}>
            <EditableFallbackDisplay {...getFallback(value[0] || value[1], "wellId")} isImport={isPreviewColumns} isWrapper={!value[0]}>
              <SelectStorable values={value} store={forecast.wells} autoFocus={!value[0]} setValues={(id, title) => update?.("well", [id, title])} />
            </EditableFallbackDisplay>
          </div>
        ),
        renderToString: (value) => {
          if (!value) return null;

          const [id] = value;

          return id !== null ? forecast.wells.at(id)?.title ?? null : null;
        },
      },
      {
        title: "Фонд",
        width: { min: 80, max: 148, competitiveness: 1 },
        dataKey: "fond",
        onHeaderCell: () => ({ className: localStyles.tableHeader }),
        render: (value) => <Format>{value}</Format>,
      },
      {
        title: "Стартовый дебит жид-ти, м³/сут",
        width: { min: 128, max: 148, competitiveness: 1 },
        dataKey: "liquidRate",
        onCell: () => ({ className: classNames(localStyles.tableCell, localStyles.tableCol) }),
        onHeaderCell: () => ({ className: classNames(localStyles.tableHeader, localStyles.tableCol) }),
        render: (liquidRate, { update, value }) => (
          <EditableFallbackDisplay isWrapper={!liquidRate} isImport={isPreviewColumns} {...getFallback(liquidRate)}>
            <InputNumber
              disabled={"liquidRate" === modeModel?.mode || typeof value?.producingObject?.[0] !== "number"}
              variant="borderless"
              min={0}
              value={liquidRate}
              autoFocus={!liquidRate}
              onUpdate={(value) => update?.("liquidRate", value)}
              placeholder="Задать..."
            />
          </EditableFallbackDisplay>
        ),
        renderToString: (liquidRate) => {
          if (liquidRate === undefined) return null;

          return NUMBER_FORMAT.real_2.format(liquidRate);
        },
      },
      {
        title: "Стартовый дебит нефти, т/сут",
        width: { min: 128, max: 148, competitiveness: 1 },
        dataKey: "oilRate",
        onCell: () => ({ className: classNames(localStyles.tableCell, localStyles.tableCol) }),
        onHeaderCell: () => ({ className: classNames(localStyles.tableHeader, localStyles.tableCol) }),
        render: (oilRate, { update, value }) => (
          <EditableFallbackDisplay isWrapper={!oilRate} isImport={isPreviewColumns} {...getFallback(oilRate)}>
            <InputNumber
              disabled={"oilRate" === modeModel?.mode || typeof value?.producingObject?.[0] !== "number"}
              variant="borderless"
              min={0}
              value={oilRate}
              autoFocus={!oilRate}
              onUpdate={(value) => update?.("oilRate", value)}
              placeholder="Задать..."
            />
          </EditableFallbackDisplay>
        ),
        renderToString: (oilRate) => {
          if (oilRate === undefined) return null;

          return NUMBER_FORMAT.real_2.format(oilRate);
        },
      },
      {
        title: "Извлекаемые запасы, тыс т",
        width: { min: 108, max: 148, competitiveness: 1 },
        dataKey: "recoverableResources",
        onCell: () => ({ className: classNames(localStyles.tableCell, localStyles.tableCol) }),
        onHeaderCell: () => ({ className: classNames(localStyles.tableHeader, localStyles.tableCol) }),
        render: (value, { update }) => (
          <EditableFallbackDisplay isWrapper={!value} isImport={isPreviewColumns} {...getFallback(value)}>
            <InputNumber
              variant="borderless"
              min={0}
              autoFocus={!value}
              value={value}
              onUpdate={(value) => update?.("recoverableResources", value)}
              placeholder="Задать..."
            />
          </EditableFallbackDisplay>
        ),
        renderToString: (value) => {
          if (value === undefined) return null;

          return NUMBER_FORMAT.real_3.format(value);
        },
      },
      ...conditionallyArr<ColumnRaw<DRowInterventions>>(!isPreviewColumns, {
        title: "Обводненность, %",
        width: { min: 138, max: 148, competitiveness: 1 },
        dataKey: "waterCut",
        onCell: () => ({ className: classNames(localStyles.tableCell, localStyles.tableCol) }),
        onHeaderCell: () => ({ className: classNames(localStyles.tableHeader, localStyles.tableCol) }),
        render: (waterCut, { update, value }) => (
          <EditableFallbackDisplay isImport={isPreviewColumns} isWrapper={!waterCut} {...getFallback(waterCut, "waterCut")}>
            <InputNumber
              disabled={"waterCut" === modeModel?.mode || typeof value?.producingObject?.[0] !== "number"}
              variant="borderless"
              min={0}
              max={100}
              value={waterCut}
              autoFocus={!waterCut}
              onUpdate={(value) => update?.("waterCut", value)}
              placeholder="Задать..."
            />
          </EditableFallbackDisplay>
        ),
        renderToString: (waterCut) => {
          if (waterCut === undefined) return null;

          return NUMBER_FORMAT.real_2.format(waterCut);
        },
      }),
      {
        title: "Тип ГТМ",
        dataKey: "gtmType",
        onCell: () => ({ className: localStyles.tableCell }),
        width: { min: 140, max: 500, competitiveness: 1 },
        onHeaderCell: () => ({ className: localStyles.tableHeader }),
        render: (value, { update }) => (
          <EditableFallbackDisplay {...getFallback(value[0] || value[1], "gtmType")} isWrapper={!value[0]} isImport={isPreviewColumns}>
            <SelectStorable
              values={value}
              store={global.interventionsTypes}
              autoFocus={!value[0]}
              setValues={(id, title) => update?.("gtmType", [id, title])}
            />
          </EditableFallbackDisplay>
        ),
        renderToString: (value) => {
          if (!value) return null;

          const [id] = value;

          return id !== null ? global.interventionsTypes.at(id)?.title ?? null : null;
        },
      },
      {
        title: "Дата проведения ГТМ",
        width: { min: 140, max: 160, competitiveness: 1 },
        dataKey: "date",
        onCell: () => ({ className: localStyles.tableCell }),
        onHeaderCell: () => ({ className: localStyles.tableHeader }),
        render: (value, { update }) => {
          const fallback = {
            fallbackText: value ? dayjs(value).format("MM.YYYY") : "",
            value: value ? dayjs(value).format("MM.YYYY") : value,
            tooltip: getFallback(value, "date").tooltip,
          };
          return (
            <EditableFallbackDisplay isImport={isPreviewColumns} {...fallback} isWrapper={false}>
              <MonthPicker
                onChange={(date) => update?.("date", date?.format("YYYY-MM-DD") ?? null)}
                value={value ? dayjs(value) : undefined}
                placeholder="Выбрать..."
                variant="borderless"
                locale={locale}
                start={dayjs(forecast.range.from.toString())}
                end={dayjs(forecast.range.to.toString())}
                format="MM.YYYY"
              />
            </EditableFallbackDisplay>
          );
        },
        renderToString: (value) => {
          if (!value) return null;

          return formatDate(dayjs(value), DATE_FORMAT.monthYearNumeric);
        },
      },
      {
        title: "Объект разработки",
        dataKey: "producingObject",
        width: { min: 160, max: 440, competitiveness: 1 },
        onCell: () => ({ className: localStyles.tableCell }),
        onHeaderCell: () => ({ className: localStyles.tableHeader }),
        render: (value, { update }) => (
          <EditableFallbackDisplay isWrapper={!value[0]} isImport={isPreviewColumns} {...getFallback(value[0] || value[1], "producingObject")}>
            <SelectStorable
              values={value}
              store={fact.producingObjects}
              autoFocus={!value[0]}
              setValues={(id, title) => update?.("producingObject", [id, title])}
            />
          </EditableFallbackDisplay>
        ),
        renderToString: (value) => {
          if (!value) return null;

          const [id] = value;

          return id !== null ? fact.producingObjects.at(id)?.title ?? null : null;
        },
      },
      {
        title: "Залежь",
        dataKey: "stratum",
        width: { min: 200, max: 300, competitiveness: 1 },
        onHeaderCell: () => ({ className: localStyles.tableHeader }),
        render: (value: [number | null, string?] | undefined, { update, expand }) => {
          if (value === undefined) {
            return null;
          }
          const [id, title] = value;
          const stratum = id ? fact.stratums.at(id) : null;
          if (isPreviewColumns) {
            return (
              <Ellipsis limit={30} position="mid">
                <Format>{id !== null ? stratum?.title : null}</Format>
              </Ellipsis>
            );
          }
          return (
            <SelectStorable
              disabled
              values={[id, title]}
              store={fact.stratums}
              setValues={(id: number | null, title?: string) => update?.("stratum", [id, title])}
            />
          );
        },
        renderToString: (value: [number | null, string?] | undefined) => {
          if (!value) return null;

          const [id] = value;

          return id !== null ? fact.stratums.at(id)?.title ?? null : null;
        },
      },
      ...conditionallyArr(!isPreviewColumns, {
        title: <p />,
        key: "remove",
        width: 32,
        isExported: false,
        onCell: () => ({ className: localStyles.remove }),
        onHeaderCell: () => ({ className: localStyles.tableHeader }),
        render: (_, { value }) => <DeleteButton onClick={value?.remove} />,
      } as ColumnRaw<DRowInterventions>),
    ],
    [fact, forecast, isPreviewColumns, getFallback, modeModel]
  );
};

const WellInterventions = observer(() => {
  const [fileName, setFileName] = useState("");

  const importInterventionsModal = useImportInterventionsModal();

  const forecast = useForecast()!;
  const modeModel = useMemo(() => new ModeSelectorModel(), []);
  const store = useMemo(() => new WellInterventionsModel(forecast, undefined, modeModel), [forecast, modeModel]);
  const columns = useColumns(false, store.modeModel);

  const model = useMemo(() => new TableModel(columns, store), [columns, store]);

  const handleUpload = (file: UploadFile) => {
    importInterventionsModal(file).then((data) => {
      data?.file?.name && setFileName(data.file.name);
      if (data?.newRows && data.replace !== undefined) {
        store.importInterventions(data.newRows, data.replace);
      }
    });
  };

  const addExcelData = () => {
    importInterventionsModal(null).then((data) => {
      data?.file?.name && setFileName(data.file.name);
      if (data?.newRows && data.replace !== undefined) {
        store.importInterventions(data.newRows, data.replace);
      }
    });
  };

  if (store.isLoading) {
    return <FullScreenLoader>Загрузка информации о ГТМ</FullScreenLoader>;
  }

  return (
    <TableContextProvider value={model}>
      <CsvSaver
        filename="Программа ГТМ"
        exportArray={() => {
          return model.export();
        }}
      >
        <PageFrameTitlePortal onSave={store.submit} model={model}>
          {fileName && <Typography.Text>Программа ГТМ: {fileName}</Typography.Text>}
          {store.modeModel && <ModeSelector modeModel={store.modeModel ?? null} />}

          <Tooltip title="Добавить ГТМ">
            <Button type="text" icon={<Plus />} onClick={store.addEmpty} />
          </Tooltip>
          <Tooltip title="Импортировать из excel-файла">
            <Button
              type="text"
              loading={model.isLoading}
              onClick={addExcelData}
              icon={<Icon width="24" height="24" content={<UploadIcon />} viewBox="0 0 16 16" />}
            />
          </Tooltip>
        </PageFrameTitlePortal>
        {!store.childrenStore?.length ? (
          <EmptyScreen
            text="Загрузите программу ГТМ"
            icon={<Icon className={localStyles.gtmIcon} content={<GTMIcon />} viewBox="0 0 36 42" />}
            onUpload={handleUpload}
          />
        ) : (
          <Widget />
        )}
      </CsvSaver>
    </TableContextProvider>
  );
});

export { useColumns, WellInterventions };
