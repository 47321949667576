import { ModeSelectorModel } from "elements/modeSelector/modeSelectorModel";
import {
  CurveDecline,
  DisplacementCharacteristicsReq,
  DisplacementCharacteristicsType,
  DropCurvesType,
  RatioDecline,
  WellDropRatio,
  WellParameters,
  WellRequest,
  WellsAnalogReq,
  WellsAnalogType,
} from "features/forecast/technologyForecastModal/types";
import { global } from "models/global";
import { conditionally } from "utils/conditionally";
import { reqCamel } from "utils/request";

import { reqLogged } from "./back/logger";

type TechParametersRequest = {
  wellId: number;
  scenarioId: number;
} & (
  | {
      data: WellParameters;
      gtmId: number;
    }
  | {
      data: ParamsByStratum;
      gtmId: null;
    }
);

type ParamsByStratum = {
  [stratumId: number]: WellParameters;
};

type ForecastInterval = {
  startMonth: number;
  startYear: number;
  endMonth: number;
  endYear: number;
};

type ForecastChart = {
  month: number[];
  year: number[];
  oilRate: number[] | null;
  liquidRate: number[] | null;
  oilProd: number[] | null;
  liquidProd: number[] | null;
  prodDays: number[] | null;
};

type StopCriterion = {
  waterCut: number | null;
  oilRate: number | null;
  oilProdMonthDuration: number | null;
};

type WellCharts = {
  fact: ForecastChart | null;
  forecast: ForecastChart;
  forecastInterval: ForecastInterval | null;
  gtmId: number | null;
  stratumId: number | null;
  wellId: number;
  oilRelativeDensity: number;
  waterRelativeDensity: number;
  appliedStopCriterion: StopCriterion | null;
};

const convertChart = (
  { oilProd, oilRate, liquidProd, liquidRate, ...rest }: ForecastChart,
  rho: number
): ForecastChart => ({
  oilRate,
  liquidRate: global.IS_DEBUG_ZONE ? liquidRate : ModeSelectorModel.convertLiquid(liquidRate, oilRate, rho),
  oilProd,
  liquidProd: global.IS_DEBUG_ZONE ? liquidProd : ModeSelectorModel.convertLiquid(liquidProd, oilProd, rho),
  ...rest,
});

export const convertToTechRequest = (
  {
    wellId,
    stratumId,
    gtmId,
    liquidDropRate,
    oilDropRate,
    stopCriterion: { waterCut, oilFlowRate, periodOilWorkingOff },
    operationCoefficient,
  }: WellParameters,
  scenarioId: number
): WellRequest => {
  const wellsAnalogToReq = ({
    constructionType,
    minDurOperation,
    dropRatio,
    dropRatioFirstMonth,
  }: WellsAnalogType): WellsAnalogReq => ({
    allow_well_construction: constructionType,
    min_month_duration: minDurOperation,
    ...conditionally(dropRatio.takeIntoAccount, {
      ratio: dropRatio.value,
      ratio_div: dropRatio.deviation,
    }),
    ...conditionally(dropRatioFirstMonth.takeIntoAccount, {
      ratio_first_month: dropRatioFirstMonth.value,
      ratio_first_month_div: dropRatioFirstMonth.deviation,
    }),
  });

  const dropRatioToReq = ({ chart: { x, y } }: WellDropRatio): RatioDecline => ({ x, y });

  const displacementCharacteristicsToReq = ({
    method,
    chart,
  }: DisplacementCharacteristicsType): DisplacementCharacteristicsReq => ({
    data: { ...(chart ?? { x: [], y: [] }), method },
  });

  const dropCurvesToReq = (dropCurves: DropCurvesType): CurveDecline => {
    const { method } = dropCurves;
    const { a, k } = dropCurves[method];

    return {
      method,
      a: {
        min_value: a.min,
        max_value: a.max,
      },
      k: {
        min_value: k.min,
        max_value: k.max,
      },
    };
  };

  let oil_decline: RatioDecline | WellsAnalogReq | DisplacementCharacteristicsReq | CurveDecline;
  let liquid_decline: RatioDecline | DisplacementCharacteristicsReq;

  if (liquidDropRate.calculationMethod === "displacementCharacteristics") {
    liquid_decline = displacementCharacteristicsToReq(liquidDropRate.displacementCharacteristics);
  } else {
    liquid_decline = dropRatioToReq(liquidDropRate.dropRatio);
  }

  if (oilDropRate.calculationMethod === "wellsAnalog") {
    oil_decline = wellsAnalogToReq(oilDropRate.wellsAnalog);
  } else if (oilDropRate.calculationMethod === "dropRatio") {
    oil_decline = dropRatioToReq(oilDropRate.dropRatio);
  } else if (oilDropRate.calculationMethod === "displacementCharacteristics") {
    oil_decline = displacementCharacteristicsToReq(oilDropRate.displacementCharacteristics);
  } else {
    oil_decline = dropCurvesToReq(oilDropRate.dropCurves);
  }

  return {
    well_id: wellId,
    stratum_id: stratumId ?? -1,
    gtm_id: gtmId ?? null,
    scenario_id: scenarioId,
    liquid_decline,
    oil_decline,
    prod_time_ratio: operationCoefficient,
    stop_criterion: {
      water_cut: waterCut,
      oil_rate: oilFlowRate,
      oil_prod_month_duration: periodOilWorkingOff,
    },
  };
};

const updateForecast = async (forecast: WellCharts[], scenarioId: number) => {
  const forecastReqBody = forecast.map(({ wellId, stratumId, gtmId, forecast }) => ({
    wellId,
    stratumId,
    gtmId,
    forecast,
    scenarioId: scenarioId,
  }));
  //@ts-ignore
  return reqLogged.post("productions/forecast/", forecastReqBody);
};

const getForecast = async (allTechParameters: WellParameters[], scenarioId: number) => {
  const reqBody = allTechParameters.map((techParameters) => convertToTechRequest(techParameters, scenarioId));
  const response = await reqCamel.post<WellCharts[]>("decline/forecast/", reqBody);
  return response.map(({ fact, forecast, oilRelativeDensity, ...rest }) => ({
    fact: fact ? convertChart(fact, oilRelativeDensity) : null,
    forecast: convertChart(forecast, oilRelativeDensity),
    oilRelativeDensity,
    ...rest,
  }));
};

const getTechParameters = (scenarioId: number): Promise<TechParametersRequest[]> => {
  return reqCamel.get(`decline/forecast/setting/${scenarioId}`) as any;
};

const updateTechParameters = (
  params: Omit<TechParametersRequest, "scenarioId">[],
  scenarioId: number
): Promise<TechParametersRequest[]> => {
  const techParameters = params.map((techParameters) => ({
    well_id: techParameters.wellId,
    gtm_id: techParameters.gtmId ?? null,
    scenario_id: scenarioId,
    data: techParameters.data,
  }));
  return reqCamel.post("decline/forecast/setting/", techParameters) as any;
};

const deleteTechParameters = (wellsInfo: { wellId: number; gtmId: number | null; scenarioId: number }[]) => {
  return reqCamel.delete("decline/forecast/setting/", wellsInfo);
};

export { deleteTechParameters, getForecast, getTechParameters, updateForecast, updateTechParameters };
export type { ForecastChart, ParamsByStratum, WellCharts };
