import { action, computed, makeObservable, observable } from "mobx";

import { UOM } from "elements/uom";
import type { ParamsRow } from "features/useMetrics/paramsRow";
import { global } from "models/global";
import type { Metric as Param, MetricTree as RawMetric } from "services/finance/finance";
import { conditionally } from "utils/conditionally";
import { ofTree } from "utils/tree";

export class ParamsNode {
  public parent: ParamsNode | null = null;

  constructor(
    public readonly key: number,
    public title: string,
    public readonly children: ParamsNode[] | null,
    public readonly unit: UOM,
    public personalValues: Array<number | null> | null,
    public readonly id?: number
  ) {
    makeObservable(this, {
      title: observable,
      setTitle: action,
      personalValues: observable,
      values: computed,
      isCompleted: computed,
    });
  }

  public setTitle(newTitle: string) {
    this.title = newTitle;
  }

  // clone node with all children, but parent is null
  public cloneAsRoot(): ParamsNode {
    const values = Array.isArray(this.personalValues) ? Array.from(this.personalValues) : null;
    if (this.children === null) {
      return new ParamsNode(this.key, this.title, null, this.unit, values);
    }
    const children = [];
    for (const child of this.children) {
      children.push(child.cloneAsRoot());
    }
    const node = new ParamsNode(this.key, this.title, children, this.unit, values);
    node.propagateParent();
    return node;
  }

  static fromRaw({ key, title, children, unit, values }: RawMetric): ParamsNode {
    return new ParamsNode(
      key,
      title,
      Array.isArray(children) ? children.map(ParamsNode.fromRaw) : null,
      new UOM(unit?.quantity ?? 0, unit?.measure ?? 0, global.uomResolver),
      Array.isArray(children) ? null : values ?? null
    );
  }

  static solutionFromRaw({ key, title, children, unit, values, wellId }: RawMetric): ParamsNode {
    return new ParamsNode(
      key,
      title,
      Array.isArray(children) ? children.map(ParamsNode.solutionFromRaw) : null,
      new UOM(unit?.quantity ?? 0, unit?.measure ?? 0, global.uomResolver),
      values ?? null,
      Number(wellId)
    );
  }

  get isCompleted() {
    if (this.personalValues !== null && this.personalValues.includes(null)) {
      return false;
    }
    for (const child of this.children ?? []) {
      if (!child.isCompleted) {
        return false;
      }
    }
    return true;
  }

  propagateParent() {
    for (const child of this.children ?? []) {
      child.parent = this;
      child.propagateParent();
    }
  }

  get level(): number {
    let cur = this.parent;
    let r = 0;
    while (cur !== null) {
      cur = cur.parent;
      ++r;
    }
    return r;
  }

  get tableItems(): ParamsRow {
    return {
      row: -1,
      title: this.title,
      measure: this.unit.unit,
      key: this.key,
      id: this.id,
      values: this.values,
      level: this.level,
      ...conditionally(Array.isArray(this.personalValues), {
        [this.unit.isBoolean ? "setBooleanValue" : "setValue"]: this.#setValue,
      }),
      booleanLabels: ["Нет", "Да"],
      ...conditionally(Array.isArray(this.children), {
        children: this.children?.map((child) => child.tableItems),
      }),
    };
  }

  #setValue = action((value: number | null, yearId: number) => {
    this.personalValues![yearId] = value;
    this.personalValues = [...this.personalValues!];
  });

  get values(): Array<number | null> {
    if (this.children === null || (Array.isArray(this.personalValues) && this.personalValues.length > 1)) {
      return this.personalValues!;
    }
    const nullArray = new Array(this.children[0].values.length).fill(null);
    if (this.unit.isNull) {
      return nullArray;
    }
    for (const { unit } of this.children) {
      if (unit.unit !== this.unit.unit) {
        return nullArray;
      }
    }
    // null если у всех потомков null, в остальных случаях сумма не налов
    const sum: Array<number | null> = new Array(this.children[0].values.length).fill(null);
    for (const { values: childValue } of this.children) {
      for (let i = 0; i < childValue.length; ++i) {
        if (childValue[i] !== null) {
          sum[i] = (sum[i] ?? 0) + childValue[i]!;
        }
      }
    }
    return sum;
  }

  get value(): null | number {
    let sum = null;
    for (const v of this.values)
      if (v !== null) {
        sum = (sum ?? 0) + v;
      }
    return sum;
  }

  get metric(): Param {
    const obj = {
      key: this.key,
      title: this.title,
      parent: this.parent && this.parent.key !== -1 ? this.parent.key : null,
      unit: {
        measure: this.unit.unitId,
        quantity: this.unit.quantityId,
      },
      values: [...(this.personalValues ?? [])],
    };
    return JSON.parse(JSON.stringify(obj)); // TODO: fix
  }

  setScalarValue = action((value: number | null) => {
    console.assert(
      this.personalValues?.length === 1,
      "setScalarValue предназначена для изменения только скалярных значений"
    );
    this.personalValues![0] = value;
  });

  public flatten(asIs: boolean = true): Param[] {
    const result: Param[] = [this.metric];
    if (
      !asIs &&
      Array.isArray(this.children) &&
      this.children.length === 1 &&
      !Array.isArray(this.children[0].children) &&
      this.children[0].values.length === 1
    ) {
      const child = this.children[0];
      return [
        {
          key: this.key,
          title: this.title,
          parent: null,
          unit: {
            measure: child.unit.unitId,
            quantity: child.unit.quantityId,
          },
          values: child.values,
        },
      ];
    }
    for (const child of this.children ?? []) {
      for (const metric of child.flatten(asIs)) {
        result.push(metric);
      }
    }
    return result;
  }

  public findByTitle(title: string): ParamsNode | null | undefined {
    for (const metric of ofTree(this.children ?? [])) {
      if (metric.title === title) {
        return metric;
      }
    }
    return null;
  }

  public isEqualShallow(other: ParamsNode): boolean {
    if (!this.values.every((value, index) => value === other.values[index])) return false;
    if (this.key !== other.key) return false;
    if (this.title !== other.title) return false;
    if (this.parent?.key !== other.parent?.key) return false;
    if (this.unit.unitId !== other.unit.unitId) return false;
    if (this.unit.quantityId !== other.unit.quantityId) return false;

    return true;
  }

  public static isEqual(model1: ParamsNode, model2: ParamsNode): boolean {
    const metrics1 = ofTree([model1])[Symbol.iterator]();
    const metrics2 = ofTree([model2])[Symbol.iterator]();
    while (true) {
      const node1 = metrics1.next();
      const node2 = metrics2.next();
      if (node1.done && node2.done) return true;
      if (node1.done || node2.done) return false;
      if (!node1.value.isEqualShallow(node2.value)) {
        return false;
      }
    }
  }

  public static isCompleted(model: ParamsNode): boolean {
    return model.isCompleted;
  }
}
