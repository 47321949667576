import { FC, useCallback } from "react";

import { ValidationInput, ValidationInputProps } from "elements/inputs/validationInput/validationInput";
import { setupAccuracy } from "utils/round";

import { WellParametersForm } from "../types";
import { getPropertyFromNestedObject } from "../utils";

type CustomInputProps = ValidationInputProps & {
  path: string;
  techParameters: WellParametersForm;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  generalInput?: boolean;
  percent?: boolean;
};

const toPercents = (x: number): number => (1 - x) * 100;
const fromPercents = (p: number): number => 1 - p / 100;

const CustomInput: FC<CustomInputProps> = ({
  path,
  techParameters,
  onSave,
  className,
  disabled = false,
  placeholder,
  generalInput = false,
  percent = false,
  min,
  max,
  ...props
}) => {
  const onSavePercent: ValidationInputProps["onSave"] = useCallback(
    (value: number | null) => {
      if (value === null) {
        onSave(null);
      } else {
        onSave(fromPercents(value));
      }
    },
    [onSave]
  );

  let value = disabled ? undefined : getPropertyFromNestedObject(techParameters, path) ?? null;
  if (percent && typeof value === "number") {
    value = setupAccuracy(toPercents(value), "round", 2);
  }
  const minFinal = percent ? toPercents(max) : min;
  const maxFinal = percent ? toPercents(min) : max;
  const ph = `${placeholder ?? "Задать"}${percent ? " %" : ""}`;
  return (
    <ValidationInput
      {...props}
      min={minFinal}
      max={maxFinal}
      style={{ fontSize: "12px" }}
      className={className}
      value={value}
      onSave={percent ? onSavePercent : onSave}
      placeholder={ph}
      disabled={disabled}
      generalInput={generalInput}
    />
  );
};

export { CustomInput };
