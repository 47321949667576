import { type FC, ReactNode } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { Status, StatusType } from "./wizard";

import cn from "./wizardSteps.module.less";

interface WizardStepsProps {
  steps: Step[];
}

interface Step {
  title: string;
  status?: StatusType;
  icon: (className: string) => ReactNode;
}

const WizardSteps: FC<WizardStepsProps> = ({ steps }) => {
  const iconMap = (icon: (className: string) => ReactNode) => ({
    [Status.Loading]: (
      <span className={cn["icon-container"]}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
      </span>
    ),
    [Status.Wait]: <span className={cn["icon-container"]}>{icon(cn["icon"])}</span>,
    [Status.Finish]: <span className={cn["icon-container"]}>{icon(cn["blue-icon"])}</span>,
  });

  return (
    <div className={cn["steps-container"]}>
      <div className={cn.title}>Прогресс расчета:</div>
      <div className={cn.steps}>
        {steps.map(({ title, icon, status }, stepNumber) => {
          return (
            <div className={cn.step} key={title}>
              <div
                style={{ display: stepNumber === 0 ? "none" : "block" }}
                className={status === Status.Finish ? cn["step-blue-line"] : cn["step-line"]}
              ></div>
              {iconMap(icon)[status ?? Status.Wait]}
              <span className={status === Status.Finish ? cn["step-bold-title"] : ""} key={title}>
                {title}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { WizardSteps };
