import { EventNode } from "models/project/fact/forecast/techPrediction/techPrediction";
import { WellCharts } from "services/techParameters";

import { WellNodePart, WellTypes } from "./techForecastMock";
import { WellParameters, WellParametersForm, WellTechForecast } from "./types";

interface IndexSignatureType {
  [key: string]: any;
}

const setPropertyInNestedObject = <T extends IndexSignatureType>(obj: T, path: string, value: any): void => {
  const keys = path.split(",");
  const lastKey = keys.pop();

  keys.reduce((acc: any, key: string) => {
    if (!acc[key]) {
      acc[key] = {};
    }
    return acc[key];
  }, obj)[lastKey as string] = value;
};

export const changeProp = (
  techParameters: WellParametersForm,
  path: string[],
  value: any,
  id?: number,
  gtmId?: number | null
) => {
  const cloneTech = JSON.parse(JSON.stringify(techParameters));
  if (id !== undefined && (id !== cloneTech.wellId || gtmId !== cloneTech.gtmId)) {
    return cloneTech;
  }

  setPropertyInNestedObject(cloneTech, path.join(","), value);

  return cloneTech;
};

export const orderedTechParameters = (
  wells: WellNodePart[],
  convertedTechParameters: WellParameters[],
  techForecast: WellCharts[] | null
): WellTechForecast[] => {
  return wells.map(({ well: { id }, gtmId }) => ({
    techParameters: convertedTechParameters.find(
      ({ wellId, gtmId: convertedGtmId }) => wellId === id && gtmId === convertedGtmId
    )!,
    forecast: techForecast?.find(
      ({ wellId: wId, gtmId: gId }) => (gtmId === gId || (gId === null && !gtmId)) && id === wId
    ),
  }));
};

export const getTooltip = (chosenType: WellTypes, techParameters: Record<WellTypes, WellParametersForm[]>): string => {
  const wellTypesNameMap: Record<WellTypes, string> = {
    base: "Базовый фонд",
    new: "Новый фонд",
    gtm: "ГТМ",
  };

  const tooltipForCurrentType = validateForm(
    techParameters[chosenType].map((techP) => ({ ...techP, wellType: chosenType }))
  );
  if (tooltipForCurrentType) {
    return `Ошибка ввода в поле: ${tooltipForCurrentType}`;
  }

  const remainedTypes = (["base", "new", "gtm"] as WellTypes[]).filter((type) => type !== chosenType);
  for (const wellType of remainedTypes) {
    if (validateForm(techParameters[wellType].map((techP) => ({ ...techP, wellType })))) {
      return `Ошибка ввода в группе: ${wellTypesNameMap[wellType]}`;
    }
  }

  return "";
};

export const filterOnlyNew = (
  wells: Record<WellTypes, WellNodePart[]>,
  notCalculatedWells: { wellId: number; gtmId?: number | null }[]
): Record<WellTypes, WellNodePart[]> => {
  const filter = (wells: WellNodePart[]) =>
    wells.filter(({ well: { id }, gtmId }) =>
      notCalculatedWells.find(({ gtmId: gId, wellId: wId }) => gId === gtmId && wId === id)
    );
  return {
    base: filter(wells.base),
    new: filter(wells.new),
    gtm: filter(wells.gtm),
  };
};

const hasNullField = (obj: any): string => {
  for (const key in obj) {
    if (obj[key] === null) {
      return key;
    } else if (typeof obj[key] === "object" && hasNullField(obj[key])) {
      return hasNullField(obj[key]);
    }
  }
  return "";
};

const inputNameMap: Record<string, string> = {
  "liquidDropRate,dropRatio": "Коэффициент падения дебита жидкости",
  "liquidDropRate,displacementCharacteristics": "Характеристики вытеснения в темпах падения жидкости",
  "oilDropRate,dropRatio": "Коэффициент падения дебита нефти",
  "oilDropRate,displacementCharacteristics": "Характеристики вытеснения в темпах падения нефти",
  "oilDropRate,dropCurves": "Кривые падения",
  stopCriterion: "Критерии остановки",
  operationCoefficient: "Коэффициент эксплуатации",
};

export const validateForm = (techForm: (WellParametersForm & { wellType?: WellTypes })[]): string => {
  for (const techParameters of techForm) {
    const { wellType } = techParameters;
    const liquidDropRateKey = hasNullField(
      techParameters.liquidDropRate[techParameters.liquidDropRate.calculationMethod]
    );
    if (liquidDropRateKey) {
      if (techParameters.liquidDropRate.calculationMethod !== "displacementCharacteristics") {
        return inputNameMap[`liquidDropRate,${techParameters.liquidDropRate.calculationMethod}`];
      } else {
        const DC = techParameters.liquidDropRate.displacementCharacteristics;
        if ((hasNullField(DC[DC.calculationMethod]) || DC[DC.calculationMethod] === null) && wellType !== "base") {
          return inputNameMap["liquidDropRate,displacementCharacteristics"];
        }
      }
    }
    const oilDropRateKey = hasNullField(techParameters.oilDropRate[techParameters.oilDropRate.calculationMethod]);
    if (oilDropRateKey) {
      if (
        techParameters.oilDropRate.calculationMethod !== "displacementCharacteristics" &&
        techParameters.oilDropRate.calculationMethod !== "dropCurves"
      ) {
        const calculationMethod = techParameters.oilDropRate.calculationMethod;
        return inputNameMap[`oilDropRate,${calculationMethod}`];
      } else if (techParameters.oilDropRate.calculationMethod === "displacementCharacteristics") {
        const DC = techParameters.oilDropRate.displacementCharacteristics;
        if ((hasNullField(DC[DC.calculationMethod]) || DC[DC.calculationMethod] === null) && wellType !== "base") {
          return inputNameMap["oilDropRate,displacementCharacteristics"];
        }
      } else if (techParameters.oilDropRate.calculationMethod === "dropCurves") {
        const dropCurves = techParameters.oilDropRate.dropCurves;
        if (hasNullField(dropCurves[dropCurves.method])) {
          return inputNameMap["oilDropRate,dropCurves"];
        }
      }
    }
    if (techParameters.operationCoefficient === null) {
      return inputNameMap["operationCoefficient"];
    }
    const { waterCut, oilFlowRate, periodOilWorkingOff } = techParameters.stopCriterion;
    if (waterCut === null && oilFlowRate === null && periodOilWorkingOff === null) {
      return inputNameMap["stopCriterion"];
    }
  }
  return "";
};

export const getPropertyFromNestedObject = <T extends IndexSignatureType>(obj: T, path: string): any =>
  path.split(",").reduce((acc, key) => (acc ? acc[key] : undefined), obj);

export const getWellNodeParts = (eventNodes: EventNode[]) =>
  eventNodes.map(({ well: { data, forecast, pad, type, licenseRegion, stratum }, techParameters, intervention }) => ({
    well: data,
    wellInfo: {
      bush: pad?.title ?? "",
      deposit: "",
      licenseRegion: licenseRegion ? licenseRegion.title : "",
      construction: type ?? "",
      enterDate: data,
      stratum: stratum?.title ?? "",
      stratumId: stratum?.id ?? -1,
    },
    techParameters,
    gtmId: intervention?.id,
    typeName: intervention?.typeName,
    forecast,
  }));

export const convertWellNodes = (wellNodes: WellNodePart[]): Record<WellTypes, WellNodePart[]> => {
  const wellNodesMap: Record<WellTypes, WellNodePart[]> = {
    base: [],
    new: [],
    gtm: [],
  };
  wellNodes.forEach((wellNode) => {
    wellNodesMap[wellNode.gtmId ? "gtm" : wellNode.forecast !== null ? "new" : "base"].push(wellNode);
  });
  return wellNodesMap;
};
